import { createSlice } from "@reduxjs/toolkit";
import { IDocumentState } from "../../interfaces/CreateDocumentInterfaces";

const initialDocumentState: IDocumentState = {
    document: [],
    sendDocumentInfo: false,
    editDocumentInfo: false,
    previewDocumentInfo: false,
    documentLoading: false,
    recipients: [],
    images: []
}

const documentSlice = createSlice({
    name: "document",
    initialState: initialDocumentState,
    reducers: {
        setSendDocument: (state, action) => {
            state.sendDocumentInfo = action.payload.send;
            state.editDocumentInfo = action.payload.edit;
        },
        clearSendDocument: (state, action) => {
            state.sendDocumentInfo = initialDocumentState.sendDocumentInfo;
        },
        setPreviewDocument: (state, action) => {
            state.previewDocumentInfo = action.payload.preview;
        },
        clearPreviewDocument: (state) => {
            state.previewDocumentInfo = initialDocumentState.previewDocumentInfo;
        },
        setEditDocument: (state, action) => {
            state.editDocumentInfo = action.payload.edit;
            state.sendDocumentInfo = action.payload.send;
        },
        clearEditDocument: (state) => {
            state.editDocumentInfo = initialDocumentState.editDocumentInfo;
        },
        setDocument: (state, action) => {
            state.document = action.payload.report;
        },
        clearDocument: (state) => {
            state.document = initialDocumentState.document;
        },
        setDocumentLoading: (state, action) => {
            state.documentLoading = action.payload.loading;
        },
        clearDocumentLoading: (state) => {
            state.documentLoading = initialDocumentState.documentLoading;
        },
        addRecipient: (state, action) => {
            state.recipients.push(action.payload.recipient)
        },
        removeRecipient: (state, action) => {
            state.recipients = state.recipients.filter((recipient) => recipient.user.email !== action.payload.email)
        },
        setRecipients: (state, action) => {
            state.recipients = (action.payload.recipients)
        },
        clearRecipients: (state) => {
            state.recipients = initialDocumentState.recipients
        },
        setImages: (state, action) => {
            state.images = action.payload.images
        },
        clearImages: (state) => {
            state.images = initialDocumentState.images
        },
        clearDocumentState: (state) => {
            state.document = initialDocumentState.document;
            state.sendDocumentInfo = initialDocumentState.sendDocumentInfo;
            state.documentLoading = initialDocumentState.documentLoading;
            state.recipients = initialDocumentState.recipients
            state.images = initialDocumentState.images
            state.previewDocumentInfo = initialDocumentState.previewDocumentInfo;
            state.editDocumentInfo = initialDocumentState.editDocumentInfo;
        }
    }
})

export const {setDocument, clearDocument, clearDocumentState, setSendDocument, clearSendDocument, setDocumentLoading, clearDocumentLoading, addRecipient, clearRecipients, setRecipients, removeRecipient, setImages, clearImages, setPreviewDocument, clearPreviewDocument, setEditDocument, clearEditDocument } = documentSlice.actions;
export default documentSlice.reducer;