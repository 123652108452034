import { useGetAllHomeReceiptsQuery } from "../features/api";
import { useEffect, useState } from "react";
import { SelectItemI } from "@homefile/components/dist/interfaces";

export const useHomeBoardReceipts = (id: string) => {
    const [selectedRoom, updateSelectedRoom] = useState("")
    const [selectedCategory, updateSelectedCategory] = useState("")
    const [selectedItems, updateSelectedItems] = useState<string[]>([])
    const [receipts, updateReceipts] = useState<any[]>([])
    const [skip, updateSkip] = useState(true)
    const {data} = useGetAllHomeReceiptsQuery(id, {skip})
    const [openInfo, updateOpenInfo] = useState(false)
    const [buttonsDisabled, updateButtonsDisabled] = useState(true)

    useEffect(() => {
        if (id === "") {
            updateSkip(true)
        } else {
            updateSkip(false)
        }
    }, [id])

    const onLeftSelectChange = (value: string | SelectItemI) => {

        if (typeof value === "object") {
            updateSelectedRoom(value._id)
        } else {
            updateSelectedRoom(value)
        }
    }

    const onRightSelectChange = (value: string | SelectItemI) => {
        if (typeof value === "object") {
            updateSelectedCategory(value._id)
        } else {
            updateSelectedCategory(value)
        }
    }

    const onItemSelectedChange = (_ids: string[]) => {
        updateSelectedItems(_ids)
        if (_ids.length > 0) {
            updateButtonsDisabled(false)
        } else {
            updateButtonsDisabled(true)
        }
    }

    const onReceiptSelectedChange = (value: string | SelectItemI) => {
        console.log('receipt selected', value)
    }

    useEffect(() => {
        if (data) {
            updateReceipts(data as any[])
        }
    }, [data])

    return {
        receipts,
        onLeftSelectChange,
        onRightSelectChange,
        onReceiptSelectedChange,
        onItemSelectedChange,
        selectedCategory,
        selectedRoom,
        selectedItems,
        openInfo,
        updateOpenInfo,
        buttonsDisabled
    }
}