import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { RootState } from "../../../app/store";
import { closeDialog } from "../../../features/dialog/dialogSlice";

const PermissionDialog = () => {
    const dispatch = useAppDispatch()
    const openModal = useAppSelector((state: RootState) => state.dialog.isOpen)
    const errorMessage = useAppSelector((state: RootState) => state.dialog.message)

    const closeModal = () => {
        dispatch(closeDialog())
    }

    return (
        <Modal isOpen={openModal} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Error</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Text variant="title" fontWeight="medium">
                    Something went wrong.
                </Text>
                <Text color="#E24C5D" variant="body" fontWeight="medium">
                    {errorMessage}
                </Text>
            </ModalBody>

            <ModalFooter>
                <Button m="1rem" variant="secondaryFooter" onClick={closeModal}>
                    Continue
                </Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default PermissionDialog;