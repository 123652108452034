import { useState} from "react";
import { useUserPasswordRecoveryMutation } from "../features/api";
import { IEmailForm } from "../interfaces/ResetPassword.interface";
import { ResponseType } from "../interfaces/DataInterfaces";


export const useForgotPassword = () => {
    const [showConfirmation, updateConfirmation] = useState(false)
    const [sendPasswordReset] = useUserPasswordRecoveryMutation();
    const [loading, setLoading] = useState(false);
    const [error, updateError] = useState(false);
    const [emailVal, updateEmail] = useState("")


    const handleEmailSubmit = async (value: IEmailForm) => {
        setLoading(true)
        updateError(false)
        try {
            const passwordReset: ResponseType = await sendPasswordReset(value.email);
            if (passwordReset.data) {
                setLoading(false)
                updateConfirmation(true)
                updateEmail(value.email)
            } else {
                console.log('error here reset:',passwordReset)
                updateConfirmation(false)
                updateError(true)
                updateEmail("")
                setLoading(false)
            }
        } catch (err) {
            console.log('error here',err)
            updateConfirmation(false)
            updateError(true)
            updateEmail("")
            setLoading(false)
        }
    }

    return {
        showConfirmation,
        loading,
        error,
        emailVal,
        handleEmailSubmit
    }
}