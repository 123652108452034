import {useEffect, useState} from 'react';
import { useGetHomeReportsSetToDeleteQuery} from "../features/api";


export const useGetReportsSetToDelete = (skip: boolean) => {
    const [itemsSetForDeletion, updateItems] = useState([])
    const {data} = useGetHomeReportsSetToDeleteQuery(null, {refetchOnMountOrArgChange: true, skip})
    const [itemsLoading, updateItemsLoading] = useState(true)

    useEffect(() => {
        if (data) {
            updateItems(data)
            updateItemsLoading(false)
        }
    }, [data])

    return {
        itemsSetForDeletion,
        itemsLoading
    }
}