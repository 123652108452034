export const Tooltip = {
  variants: {
    primary: {
      bg: 'violet.1',
    },
  },
  defaultProps: {
    variant: 'primary',
  },
}

export default Tooltip
