import Dashboard from '../pages/Dashboard';
import HomeBoard from '../pages/HomeBoard';
import RoomBoard from '../pages/RoomBoard';
import Login from '../pages/Login';
import AccountSignUp from '../pages/AccountSignUp';
import ResetPassword from '../pages/ResetPassword';
import ForgotPassword from '../pages/ForgotPassword';
import DeleteAccount from '../pages/DeleteAccount';
import PrivateImageTest from '../pages/PrivateImageTest';
import Payment from '../pages/Payment';
import {FC} from 'react';
import { Navigate, Outlet } from "react-router-dom";
import Layout from '../components/Layout';


export interface RouteInterface {
    path: string
    element: FC
    children?: RouteInterface[]
}

const AppRoutes = (isAuthenticated: boolean) => ([
    {
        path: "/login",
        element: !isAuthenticated ? <Login /> : <Navigate to="/dashboard" />,
    },
    {
        path: "/sign-up",
        element: !isAuthenticated ? <AccountSignUp /> : <Navigate to="/dashboard" />,
    },
    {
        path: "/reset-password",
        element: <ResetPassword />,
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword />
    },
    {
        path: "/secure-image",
        element: <PrivateImageTest />
    },
    {
        path: "/add-payment",
        element: <Payment authenticated={isAuthenticated}/>
    },
    {
        path: "/",
        element: isAuthenticated ? <Layout><Outlet /></Layout> : <Navigate to="/login" />,
        children: [
            {
                path: "dashboard",
                element: <Dashboard />
            },
            {
                path: "",
                element: <Navigate to="/dashboard" />
            },
            {
                path: "homes",
                element: <Outlet />,
                children: [
                    {
                        path: "base",
                        element: <HomeBoard />
                    }
                ]
            },
            {
                path: "rooms",
                element: <Outlet />,
                children: [
                    {
                        path: ":roomId",
                        element: <RoomBoard />
                    }
                ]
            },
            {
                path: "payment",
                element: <Payment authenticated={isAuthenticated}/>
            },
            {
                path: "delete-account",
                element: <DeleteAccount />
            }
        ]
    }
])

export default AppRoutes;