import {useState, useEffect} from 'react'
import { t } from 'i18next'
import {PanelHeader, CreateHouseholdItemHeader, ItemNameHeader, FooterDrawer, FooterButtons, FlowStep} from '@homefile/components';
import {
    DrawerBody,
    DrawerHeader,
    DrawerFooter,
  } from "@chakra-ui/react";
import { Wizard } from "../../../../assets/images";
import { SelectItemI, ReportsI } from "@homefile/components/dist/interfaces";
import QuickFlow from './QuickFlow'
import DetailedFlow from './DetailedFlow'
import {RoomItemI} from '@homefile/components/dist/interfaces/rooms/RoomsMenu.interface'
import { useAppSelector } from "../../../../app/hooks"
import { RootState } from "../../../../app/store";

interface WizardI {
    handleClose: () => void
    handleTurnOffWizard: () => void
    categories: SelectItemI[]
    handleCategoryChange: (form: string | SelectItemI) => void
    handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleRoomChange: (form: string | SelectItemI) => void
    initialCategory: string
    initialRoom: string
    nameValue: string
    rooms: RoomItemI[]
    newItemId: string
    report: any
    handleSave: () => void
    handleUpdate: (flag: boolean) => void
    handleAnother: () => void
    newCompleted: ReportsI
}

const HouseholdItemWizard = ({handleClose, 
    handleTurnOffWizard,
    categories,
    handleCategoryChange,
    handleNameChange,
    handleRoomChange,
    initialCategory,
    initialRoom,
    nameValue,
    rooms,
    newItemId,
    report,
    handleSave,
    handleAnother,
    newCompleted,
    handleUpdate
}: WizardI) => {

    const [firstStep, updateFirstStep] = useState(true)
    const [isQuickFlow, updateQuickFlow] = useState(false)
    const [step, updateStep] = useState(1)
    const [showDuplicate, updateShowDuplicate] = useState(false)
    const [buttonTwoLabel, updateButtonTwo] = useState('Next')
    const isLoading = useAppSelector((state: RootState) => state.document.documentLoading);
    const [tabIndex, updateTabIndex] = useState(0)

    useEffect(() => {
        if (newCompleted.title) {
            if (isQuickFlow) {
                updateButtonTwo('Save')
                updateShowDuplicate(true)
            } else {
                handleTabChange(0)
            }
            const newStep = step + 1
            updateStep(newStep)
        }
    }, [newCompleted])

    const handleTabChange = (index: number) => {
        if (index === 0) {
            updateStep(2)
            updateButtonTwo('Next')
            updateShowDuplicate(false)
        } else if (index === 1) {
            updateStep(3)
            updateButtonTwo('Save')
            updateShowDuplicate(true)
        }

        updateTabIndex(index)
    }

    const clearWizard = () => {
        updateFirstStep(true)
        updateQuickFlow(false)
        handleTabChange(0)
        updateStep(1)
        updateButtonTwo('next')
        updateShowDuplicate(false)
        handleAnother()
    }

    const handleNextStep = () => {
        if (step === 1) {
            if (newCompleted.title) {
                if (isQuickFlow) {
                    updateButtonTwo('Save')
                    updateShowDuplicate(true)
                } else {
                    handleTabChange(0)
                }
                const newStep = step + 1
                updateStep(newStep)
            } else {
                handleSave()
            }
        } else if (step === 2) {
            if (!isQuickFlow) {
                handleTabChange(1)
                const newStep = step + 1
                updateStep(newStep)
                updateButtonTwo('Save')
                updateShowDuplicate(true)
            } else {
                handleUpdate(isQuickFlow)
            }
        } else if (step === 3) {
            if (!isQuickFlow) {
                handleUpdate(true)
            }
        }

    }

    const handleBackStep = () => {
        if (step === 1) {
            clearWizard()
        } else if (step === 2) {
            if (!isQuickFlow) {
                handleTabChange(0)
            }
            updateButtonTwo('Next')
            updateShowDuplicate(false)
            updateStep(1)
        } else if (step === 3) {
            if (!isQuickFlow) {
                handleTabChange(0)
            }
            updateButtonTwo('Next')
            updateShowDuplicate(false)
            updateStep(2)
        }
    }

    const ButtonsFooter = {
        button1: {
            buttonStyle: 'secondaryFooter',
            label: "Back",
            onClick: handleBackStep,
        },
        button2: {
            buttonStyle: 'primaryFooter',
            label: buttonTwoLabel,
            onClick: handleNextStep,
        },
            button3: {
            buttonStyle: 'secondaryFooter',
            label: t('Add Another'),
            onClick: clearWizard,
        },
    }

    const handleQuickFlow = () => {
        updateFirstStep(false)
        updateQuickFlow(true)
    }

    const handleDetailedFlow = () => {
        updateFirstStep(false)
        updateQuickFlow(false)
    }

    return (
        <>
            <DrawerHeader p="0">
                <PanelHeader
                    handleCloseButton={handleClose}
                    icon={Wizard}
                    title={t('addItemWizard.title')}
                />
            </DrawerHeader>

            <DrawerBody p={"0"}>
                {
                    (firstStep && !isQuickFlow) && <FlowStep
                        handleQuickFlow={handleQuickFlow}
                        handleDetailedFlow={handleDetailedFlow}
                        handleTurnOffWizard={handleTurnOffWizard}
                    />
                }
                {
                    (!firstStep && isQuickFlow) && <QuickFlow 
                        categories={categories}
                        handleCategoryChange={handleCategoryChange}
                        handleNameChange={handleNameChange}
                        handleRoomChange={handleRoomChange}
                        initialCategory={initialCategory}
                        initialRoom={initialRoom}
                        nameValue={nameValue}
                        rooms={rooms}
                        report={report}
                        newItemId={newItemId}
                        step={step}
                    />
                }
                {
                    (!firstStep && !isQuickFlow) && <DetailedFlow 
                        categories={categories}
                        handleCategoryChange={handleCategoryChange}
                        handleNameChange={handleNameChange}
                        handleRoomChange={handleRoomChange}
                        initialCategory={initialCategory}
                        initialRoom={initialRoom}
                        nameValue={nameValue}
                        rooms={rooms}
                        report={report}
                        newItemId={newItemId}
                        step={step}
                        tabIndex={tabIndex}
                        handleTabChange={handleTabChange}
                    />
                }

            </DrawerBody>

            {
                !firstStep && <DrawerFooter zIndex={"999"}>
                <FooterDrawer
                        children={
                            <FooterButtons
                                button1={ButtonsFooter.button1}
                                button2={ButtonsFooter.button2}
                                button3={showDuplicate ? ButtonsFooter.button3 : undefined}
                            />
                        }
                        isOpen={true}
                        />
    
                </DrawerFooter>
            }
        </>
    )
}

export default HouseholdItemWizard