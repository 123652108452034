import { homefileApi } from './emptySplitApi'

const roomApi = homefileApi.injectEndpoints({
    endpoints: (builder) => ({
        getRoomsByHome: builder.query({
            query: (homeId: string) => `/api/room/home/${homeId}`,
            providesTags: ["Rooms"]
        }),
        getRoom: builder.query({
            query: (roomId: string) => `/api/room/${roomId}`,
            providesTags: ["Room", "ProgressReport", "ProgressReports"]
        }),
        createRoom: builder.mutation({
            query: (data: any) => ({
                url: `/api/room`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["Rooms"],
        }),
        addItemToRoom: builder.mutation({
            query: (data: any) => ({
                url: `/api/room/add-item/${data.roomId}`,
                method: "PATCH",
                body: data.body
            }),
            invalidatesTags: ["Room"],
        }),
        removeItemFromRoom: builder.mutation({
            query: (data: any) => ({
                url: `/api/room/remove-item`,
                method: "PATCH",
                body: data
            }),
            invalidatesTags: ["Room"],
        }),
        updateRoom: builder.mutation({
            query: (data: any) => ({
                url: `/api/room/update/${data.roomId}`,
                method: "PATCH",
                body: data.body
            }),
            invalidatesTags: ["Room", "Rooms"],
        }),
        reactivateRoom: builder.mutation({
            query: (roomId: string) => ({
                url: `/api/room/${roomId}`,
                method: "PATCH",
            }),
            invalidatesTags: ["Room"],
        }),
        deleteRoom: builder.mutation({
            query: (roomId: string) => ({
                url: `/api/room/${roomId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Rooms"],
        }),
        addSubTypeToRoom: builder.mutation({
            query: (data: any) => ({
                url: `/api/room/sub-type`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["Rooms", "Room"],
        }),
        removeSubTypeFromRoom: builder.mutation({
            query: (data: any) => ({
                url: `/api/room/sub-type`,
                method: "DELETE",
                body: data
            }),
            invalidatesTags: ["Rooms", "Room"]
        })
    })
})

export const {
    useGetRoomsByHomeQuery,
    useGetRoomQuery,
    useCreateRoomMutation,
    useAddItemToRoomMutation,
    useRemoveItemFromRoomMutation,
    useUpdateRoomMutation,
    useReactivateRoomMutation,
    useDeleteRoomMutation,
    
    useAddSubTypeToRoomMutation,
    useRemoveSubTypeFromRoomMutation,
} = roomApi

