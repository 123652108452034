import {useState, useEffect} from 'react';
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {HomeMonitor, HomeMonitorSteps, MonitorAlerts, Notifications, HomefileMonitoring} from '@homefile/components';
import {MonitorAlertsType} from '@homefile/components/dist/interfaces'
import {Flex, Stack} from '@chakra-ui/react'
import {fullStateObj} from '../../../util/stateArray'

const stepAlerts: Record<number, MonitorAlertsType> = {
    2: 'smoke-detector-expired',
    3: 'change-air-filter',
    5: 'smoke-detector-battery',
}

export const HomeMonitorModule = ({address, handleStep, activeTour}: {address: any, handleStep: (step: number) => void, activeTour: boolean}) => {
    const [date, updateDate] = useState("")
    const [location, updateLocation] = useState("")
    const currentPanel = useAppSelector((state: RootState) => state.assistant.currentPanel)
    const homeBoardTourState = useAppSelector((state: RootState) => state.tour.homeBoardTour);
    const [alertId, setAlertId] = useState<MonitorAlertsType>('smoke-detector-battery')

    const days: string[] = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"]
    const months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]

    useEffect(() => {
        const date = new Date()
        const month = date.getMonth()
        const tMonth = months[month]
        const dateNum = date.getDate()
        const day = date.getDay()
        const dayOW = days[day]
        const year = date.getFullYear().toString()
        const tDate = `${dayOW} ${tMonth} ${dateNum}`
        updateDate(tDate)
    }, [])

    useEffect(() => {
        const city = `${address.city}`
        const state: string = `${address.state}`
        const lState = state.length > 2 ? fullStateObj[state] : state
        updateLocation(`${city}, ${lState}`)
    }, [address])
    
    const handleAlertClick = (step: number) => {
        setAlertId(stepAlerts[step])
    }

    const menuItems = [

        {
          handleClick: handleStep,
          label: "Details",
        },
      ]

    return (
        <HomeMonitor zIndex={(activeTour || homeBoardTourState) ? "2" : "auto"}>
          <Stack p="base" spacing="base" w="full">
            <Flex gap="base">
              {/* <MonitorAlerts alertId={alertId} /> */}
              <HomefileMonitoring />
              <Notifications date={date} local={location} />
            </Flex>
            <HomeMonitorSteps
              stepsWithAlerts={[]}
              onStepClick={handleAlertClick}
              menuItems={menuItems}
            />
          </Stack>
        </HomeMonitor>
      )
}
