import { useEffect, useState } from "react";
import { GroupCard, useCustomToast, RoomVideo } from '@homefile/components';
import { SimpleGrid, Flex} from '@chakra-ui/react';
import { GroupCardsI } from "../../../interfaces/Room.interfaces";
import { ReportsI, ImagesI } from '@homefile/components/dist/interfaces';
import { useFileDetails } from "../../../hooks/useFileDetails";
import {useUploadMedia} from '../../../hooks/useUploadMedia';
import DeleteFileDialog from "../../../components/Dialogs/DeleteFileDialog";
import { useAppDispatch,useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { setDrawer } from "../../../features/drawer/drawerSlice";
import { useRoomData } from '../../../hooks/useRoomData';
import Loading from "../../Loading";
import { useGetHouseholdItemSubTypes } from "../../../hooks/useGetHouseholdItemSubTypes";
import {RoomItemI} from '@homefile/components/dist/interfaces/rooms/RoomsMenu.interface'
import {useGetRoomsByHomeQuery} from '../../../features/api';
import { useUpdateItem } from "../../../hooks/useUpdateItem";
import {useFileUpload} from '../../../hooks/useFileUpload';
import { FileInfoI } from '@homefile/components/dist/interfaces';
import { useDeleteFileUploadMutation } from "../../../features/api";
import GenericDialog from "../../../components/Dialogs/GenericDialog";
import { ResponseType } from '../../../interfaces/DataInterfaces';

const RoomGroupsModule = () => {
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const userType = useAppSelector((state: RootState) => state.auth.homeAssociationType)
    const dispatch = useAppDispatch()
    const [documentId, updateId] = useState("")
    const [fileUploads, updateFiles] = useState<ImagesI[]>([])
    const {itemSubTypes} = useGetHouseholdItemSubTypes(homeId)
    const [moveItem, updateMoveItem] = useState({} as any)
    const {data } = useGetRoomsByHomeQuery(homeId)
    const [rooms, updateRooms] = useState([] as RoomItemI[])
    const {handleUpload} = useUploadMedia({documentId})
    const toast = useCustomToast()
    const {deleteFileCallback, handleCloseDeleteModal, deleteModal, deleteFileId, handleRoomDelete, handleOpenFile, handleDelete} = useFileDetails([])
    const {handleNewRoomMedia, fileUploading} = useFileUpload()
    const [roomVideos, updateRoomVideos] = useState<FileInfoI[]>([])
    const {roomData, activeGroups, isLoading} = useRoomData()
    const [deleteVideo] = useDeleteFileUploadMutation()
    const [deleteVideoId, updateDeleteVideoId] = useState("")
    const [dialogOpen, updateDialogOpen] = useState(false)
    const [deleteFail, updateDeleteFail] = useState(false)
    const [dialogBody, updateDialogBody] = useState("Would you like to permanently delete this room video?")
    const [dialogAction, updateDialogAction] = useState("Delete")

    const handleClickDetails = (id: string) => {
        const item = roomData.items!.find((item) => item._id === id)

        // handleOpenFile(newItem as ReportsI)
        handleOpenFile(item as ReportsI)
    }

    const successFunction = () => {

        toast({
            title: 'Success!',
            description: 'Item Updated',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: "top-right"
          })
      }

    const {handleUpdate} = useUpdateItem(successFunction);

    useEffect(() => {
        if (roomData.videos) {
            if (roomData.videos.length > 0) {
                let updateVideos:FileInfoI[] = []
                roomData.videos.forEach((video: any) => {
                    if (updateVideos.length < 4) {
                        const { REACT_APP_STORAGE_URL: storageUrl } = process.env
                        const imageUrl = `${storageUrl}/${video.bucketName}/${video.fileName}.${video.extension}`
                        const updateVideo = {...video, imageUrl}
                        updateVideos.push(updateVideo)
                    }
                })
                updateRoomVideos(updateVideos)
            } else {
                updateRoomVideos([])
            }
        }
    }, [roomData])


    useEffect(() => {
        // use here to update init values for rooms and form title
        if (data) {
            updateRooms(data as RoomItemI[])
        }  
    }, [data])

    useEffect(() => {
        if (fileUploads.length > 0) {
            if (documentId === fileUploads[0]._id) {
                handleUpload(fileUploads)
            }
        }
    }, [documentId, fileUploads])

    const handleAddImage = (files: ImagesI[]) => {
        updateId(files[0]._id)
        updateFiles(files)
    }

    const handleAddItem = (group: string) => {
        const item = activeGroups.find((tGroup) => tGroup.title === group)
        const itemData = {
            room: roomData._id,
            subType: item!._id
        }
        dispatch(setDrawer({ location: "right", content:'CreateHouseholdItem', size: "md", data: itemData}))
    }

    const handleOnMove = (data: Partial<ReportsI>) => {
        updateMoveItem(data)
    }

    const saveMove = (data: any) => {
        let room = moveItem.room
        let subType = moveItem.subType

        if (data.firstOption !== "") {
            room = data.firstOption._id
        }

        if (data.secondOption !== "") {
            subType = data.secondOption._id
        }

        handleUpdate({
            newReportId: moveItem._id,
            homeId,
            reportTitle: moveItem.title,
            type: moveItem.type,
            subType: subType,
            room: room,
            report: moveItem.report
        })
    }

    const onUpload = (files: any) => {
        if (roomData._id && files.length > 0) {
            handleNewRoomMedia(files, roomData._id)
        }
    }

    const onDelete = (id: string) => {
        updateDeleteVideoId(id)
        updateDialogOpen(true)
    }

    const handleDialogDelete = async () => {
        const videoDeleted: ResponseType = await deleteVideo(deleteVideoId)

        if (videoDeleted.data) {
            toast({
                title: 'Success',
                description: `File Deleted`,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top-right"
              })
              updateDialogOpen(false)
        } else {
            updateDeleteFail(true)
            updateDialogAction("Continue")
            updateDialogBody("Something went wrong, please try again later.")
        }
    }

    const onPlay = (url?: string) => {
        if (url) {
            window.open(url)
        }
    }

    return (
        <>  
            <Flex flexDir={["column", "column", "row"]} justifyContent="flex-start">
                <Loading
                    isLoading={isLoading}
                    children={
                        <>
                        <Flex mb={["0.875rem", "0.875rem", "0"]} maxW={["none","none", "360px"]} w="100%">
                            <RoomVideo 
                                files={roomVideos}
                                onDelete={onDelete}
                                onUpload={onUpload}
                                // onPlay={onPlay}
                                uploading={fileUploading}
                                loading={isLoading}
                            />
                        </Flex>
                        <Flex width="100%" ml={["0", "0","0.875rem"]}>
                            {
                                activeGroups.filter((activeGroup: GroupCardsI) => activeGroup.title === "Uncategorized").map((activeGroup: GroupCardsI) => {
                                    return (
                                        <GroupCard
                                        key={activeGroup._id}
                                        items={activeGroup.items}
                                        title={activeGroup.title}
                                        totalAmount={activeGroup.totalAmount}
                                        _id={activeGroup._id}
                                        onAddItem={handleAddItem}
                                        onAddImage={handleAddImage}
                                        onClickDelete={handleRoomDelete}
                                        onClickDetails={handleClickDetails}
                                        menuItems={userType === "member" ? [] : activeGroup.menuItems}
                                        onSave={saveMove}
                                        onMove={handleOnMove}
                                        firstFilterItems={rooms}
                                        secondFilterItems={itemSubTypes}
                                        onFirstFilterClick={() => {}}
                                        firstInitialFilter={roomData.name}
                                        secondInitialFilter={activeGroup.title}
                                        disabled={userType === "member"}
                                    />
                                    ) 
                                })
                            }
                        </Flex>
                        </>
                        
                    }
                />

            </Flex>
            <SimpleGrid
                minChildWidth={['full', '27rem']}
                spacing="base"
                textAlign="left"
                marginTop="0.875rem !important"
                >
                <Loading 
                    isLoading={isLoading}
                    children={
                        <>
                            {
                                activeGroups.filter((activeGroup: GroupCardsI) => activeGroup.title !== "Uncategorized").map((activeGroup: GroupCardsI) => {
                                    return (
                                        <GroupCard
                                        key={activeGroup._id}
                                        items={activeGroup.items}
                                        title={activeGroup.title}
                                        totalAmount={activeGroup.totalAmount}
                                        _id={activeGroup._id}
                                        onAddItem={handleAddItem}
                                        onAddImage={handleAddImage}
                                        onClickDelete={handleRoomDelete}
                                        onClickDetails={handleClickDetails}
                                        menuItems={userType === "member" ? [] : activeGroup.menuItems}
                                        onSave={saveMove}
                                        onMove={handleOnMove}
                                        firstFilterItems={rooms}
                                        secondFilterItems={itemSubTypes}
                                        onFirstFilterClick={() => {}}
                                        firstInitialFilter={roomData.name}
                                        secondInitialFilter={activeGroup.title}
                                        disabled={userType === "member"}
                                        itemsDisabled={userType === "member"}
                                    />
                                    ) 
                                })
                            }
                        </>
                    }
                />
            </SimpleGrid>
            <DeleteFileDialog closeModal={handleCloseDeleteModal} openModal={deleteModal} fileId={deleteFileId} deleteFile={deleteFileCallback} />
            <GenericDialog header={"Delete Video"} body={dialogBody} action={deleteFail ? () => {updateDialogOpen(false)} : handleDialogDelete} close={() => {updateDialogOpen(false)}} openModal={dialogOpen} actionText={dialogAction} cancelText={"Cancel"}  />
        </>
        
    )
}

export default RoomGroupsModule;