import { useState, useEffect } from "react"
import { useAddTileToHomeMutation, useAddTileToUserMutation, useGetPartnerTilesQuery, useAddPartnerToUserMutation, useListAllPartnersQuery } from "../features/api";
import homeSlice from "../features/home/homeSlice";
import {usePartnerInfo} from './usePartnerInfo';
import {ResponseType} from '../interfaces/DataInterfaces';
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import {useCustomToast} from '@homefile/components'

export const usePartnerCatalog = ({existingTiles, id}: {existingTiles?: any[], id: string}) => {
    // const id = useAppSelector((state: RootState) => state.home.id)
    const [addPartnerToUser] = useAddPartnerToUserMutation()
    const [addTileToUser] = useAddTileToUserMutation()
    const [addTileToHome] = useAddTileToHomeMutation()
    const {data} = useListAllPartnersQuery(null)
    const {partnerData, tiles} = usePartnerInfo()
    const [allPartnerData, updateAllPartnerData] = useState([])
    const [dialogHeader, updateDialogHeader] = useState("")
    const [dialogBody, updateDialogBody] = useState("")
    const [dialogOpen, updateDialogOpen] = useState(false)
    const toast = useCustomToast()

    // steps to add tile to user:
    //     - addPartnerToUser(code/_id)
    //     - check if partner has multiple tiles - if so pop up dialog for user to select which one if not then the only tile for next call:
    //     - addTileToUser(partner: data.partnerId, tile: data.tileId)
    //     - already in a home for the catalog so then add tle to home:
    //     - addTileToHome(tile: data.tileId, display: data.display, home: data.homeId)


    useEffect(() => {
        if (data) {
            updateAllPartnerData(data)
        }
    }, [data])

    const handleCloseDialog = () => {
        updateDialogOpen(false)
    }

    const fireGenericError = () => {
        updateDialogHeader("Error")
        updateDialogBody("Something Went Wrong. Please Try Again Later.")
        updateDialogOpen(true)
    }

    const fireTileToHome = async (tileId: string) => {
        const connectedHomeTile: ResponseType = await addTileToHome({tileId, display: true, homeId: id})
        if (connectedHomeTile.data) {
            // success
            toast({
                title: 'Success',
                description: `Tile Connected`,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top-right"
                })
        } else {
            //error 
            console.log(connectedHomeTile.error)
            fireGenericError()
        }
    }

    const handleAddPartner = async (code: string) => {
        const selectedPartner: any = allPartnerData.find((partner: any) => partner.code === code)
        // partner selected from code
        if (selectedPartner) {
            // selected partner so check if tile already exists on home
            let match = false
            if (existingTiles && existingTiles.length > 0) {
                for (const tile of selectedPartner.tiles) {
                    const tileMatch = existingTiles.find((existingTile: any) => tile._id === existingTile._id)
                    if (tileMatch) {
                        // tile on home check if partner and tile are connected to user:
                        const existingPartner: any = partnerData.find((partner: any) => partner._id === selectedPartner._id)
                        if (existingPartner) {
                            // partner already connected check tile
                            // check if tile already connected to user
                            let tilePartnerMatch = false
                            for (const tile of tiles) {
                                const existingTile: any = selectedPartner.tiles.find((selectedPartnerTile: any) => tile._id === selectedPartnerTile._id)
                                if (existingTile) {
                                    tilePartnerMatch = true
                                    break;
                                }
                            }

                            if (tilePartnerMatch) {
                                // partner and tile already connected to this user so tile should already be on display:
                                updateDialogHeader("Tile Already Connected")
                                updateDialogBody("Selected Partner's tile is already connected to this Home.")
                                updateDialogOpen(true)
                                match = true
                                break;
                            } else {
                                // add tile to user
                                // tile not connected to user
                                // check if selected partner has multiple tiles
                                if (selectedPartner.tiles.length > 1) {
                                    // need to pop up modal asking user to select tile

                                    // for now just going to add first active tile
                                    for (const tile of selectedPartner.tiles) {
                                        if (tile.active) {
                                            // add tile to user
                                            const connectedUserTile: ResponseType = await addTileToUser({partnerId: selectedPartner._id, tileId: tile._id})

                                            if (connectedUserTile.data) {
                                                // tile connected
                                                // tile should show up on home
                                                toast({
                                                    title: 'Success',
                                                    description: `Tile Connected`,
                                                    status: 'success',
                                                    duration: 5000,
                                                    isClosable: true,
                                                    position: "top-right"
                                                })
                                            } else {
                                                //error 
                                                console.log(connectedUserTile.error)
                                                fireGenericError()
                                            }
                                            break;
                                        }
                                    }

                                } else {
                                    // add tile to user
                                    const connectedUserTile: ResponseType = await addTileToUser({partnerId: selectedPartner._id, tileId: selectedPartner.tiles[0]._id})

                                    if (connectedUserTile.data) {
                                        // tile connected
                                        // tile should show up on home
                                        toast({
                                            title: 'Success',
                                            description: `Tile Connected`,
                                            status: 'success',
                                            duration: 5000,
                                            isClosable: true,
                                            position: "top-right"
                                        })

                                    } else {
                                        //error 
                                        console.log(connectedUserTile.error)
                                        fireGenericError()
                                    }
                                }
                            }

                        } else {
                            // add partner
                            const connectedPartnerUser: ResponseType = await addPartnerToUser(selectedPartner.code)

                            if (connectedPartnerUser.data) {
                                // partner now connected to user
                                // add tile to user
                                // check if selected partner has multiple tiles
                                if (selectedPartner.tiles.length > 1) {
                                    
                                    // need to pop up modal asking user to select tile

                                    // for now just going to add first active tile
                                    for (const tile of selectedPartner.tiles) {
                                        if (tile.active) {
                                            // add tile to user
                                            const connectedUserTile: ResponseType = await addTileToUser({partnerId: selectedPartner._id, tileId: tile._id})

                                            if (connectedUserTile.data) {
                                                // tile connected
                                                // tile should show up on home
                                                toast({
                                                    title: 'Success',
                                                    description: `Tile Connected`,
                                                    status: 'success',
                                                    duration: 5000,
                                                    isClosable: true,
                                                    position: "top-right"
                                                })

                                            } else {
                                                //error 
                                                console.log(connectedUserTile.error)
                                                fireGenericError()
                                            }
                                            break;
                                        }
                                    }

                                } else {
                                    // add tile to user
                                    const connectedUserTile: ResponseType = await addTileToUser({partnerId: selectedPartner._id, tileId: selectedPartner.tiles[0]._id})
                                    if (connectedUserTile.data) {
                                        // tile connected
                                        // tile should show up on home
                                        toast({
                                            title: 'Success',
                                            description: `Tile Connected`,
                                            status: 'success',
                                            duration: 5000,
                                            isClosable: true,
                                            position: "top-right"
                                        })

                                    } else {
                                        //error 
                                        console.log(connectedUserTile.error)
                                        fireGenericError()
                                    }
                                }
                            } else {
                                console.log(connectedPartnerUser.error)
                                fireGenericError()
                            }
                        }
                        match = true
                        break;
                    }
                }
            }

            if (!match) {
                // tile not already on the home
                // check if partner is associated with user
                const existingPartner: any = partnerData.find((partner: any) => partner._id === selectedPartner._id)

                if (existingPartner) {
                    // partnerassociation already exists 
                    // check if tile already connected to user
                    let tileMatch = false
                    for (const tile of tiles) {
                        const existingTile: any = selectedPartner.tiles.find((selectedPartnerTile: any) => tile._id === selectedPartnerTile._id)
                        if (existingTile) {
                            tileMatch = true
                            break;
                        }
                    }
                    if (tileMatch) {
                        // tile already connected to user
                        // add tile to home
                        if (selectedPartner.tiles.length > 1) {
                            for (const tile of selectedPartner.tiles) {
                                if (tile.active) {
                                    fireTileToHome(tile._id)
                                }
                            }
                        } else {
                            fireTileToHome(selectedPartner.tiles[0]._id)
                        }
                    } else {
                        // tile not connected to user
                        // check if selected partner has multiple tiles
                        if (selectedPartner.tiles.length > 1) {
                            // need to pop up modal asking user to select tile

                            // for now just going to add first active tile
                            for (const tile of selectedPartner.tiles) {
                                if (tile.active) {
                                    // add tile to user
                                    const connectedUserTile: ResponseType = await addTileToUser({partnerId: selectedPartner._id, tileId: tile._id})

                                    if (connectedUserTile.data) {
                                        // tile connected
                                        // add tile to home
                                        fireTileToHome(tile._id)

                                    } else {
                                        //error 
                                        console.log(connectedUserTile.error)
                                        fireGenericError()
                                    }
                                    break;
                                }
                            }

                        } else {
                            // add tile to user
                            const connectedUserTile: ResponseType = await addTileToUser({partnerId: selectedPartner._id, tileId: selectedPartner.tiles[0]._id})

                            if (connectedUserTile.data) {
                                // tile connected
                                // add tile to home
                                fireTileToHome(selectedPartner.tiles[0]._id)

                            } else {
                                //error 
                                console.log(connectedUserTile.error)
                                fireGenericError()
                            }
                        }
                    }

                } else {
                    // partnerassociation does not exist
                    // add partner to user 
                    const connectedPartnerUser: ResponseType = await addPartnerToUser(selectedPartner.code)

                    if (connectedPartnerUser.data) {
                        // partner now connected to user
                        // add tile to user
                        // check if selected partner has multiple tiles
                        if (selectedPartner.tiles.length > 1) {
                            
                            // need to pop up modal asking user to select tile

                            // for now just going to add first active tile
                            for (const tile of selectedPartner.tiles) {
                                if (tile.active) {
                                    // add tile to user
                                    const connectedUserTile: ResponseType = await addTileToUser({partnerId: selectedPartner._id, tileId: tile._id})

                                    if (connectedUserTile.data) {
                                        // tile connected
                                        // add tile to home
                                        fireTileToHome(tile._id)

                                    } else {
                                        //error 
                                        console.log(connectedUserTile.error)
                                        fireGenericError()
                                    }
                                    break;
                                }
                            }

                        } else {
                            // add tile to user
                            const connectedUserTile: ResponseType = await addTileToUser({partnerId: selectedPartner._id, tileId: selectedPartner.tiles[0]._id})
                            if (connectedUserTile.data) {
                                // tile connected
                                // add tile to home
                                fireTileToHome(selectedPartner.tiles[0]._id)

                            } else {
                                //error 
                                console.log(connectedUserTile.error)
                                fireGenericError()
                            }
                        }
                    } else {
                        console.log(connectedPartnerUser.error)
                        fireGenericError()
                    }
                }
            }

        } else {
            fireGenericError()
        }
    }

    return {
        allPartnerData,
        handleAddPartner,
        handleCloseDialog,
        dialogHeader,
        dialogBody,
        dialogOpen
    }

}
