import { createSlice } from "@reduxjs/toolkit";

interface GettingStartedI {
    currentStep: number
    currentPanel: number
}

const startedInitialState: GettingStartedI = {
    currentPanel: 0,
    currentStep: 0
}

const assistantSlice = createSlice({
    name: "assistant",
    initialState: startedInitialState,
    reducers: {
        setPanel: (state, action) => {
            state.currentPanel = action.payload.currentPanel
        },
        setStep: (state, action) => {
            state.currentStep = action.payload.currentStep
        }
    }
})

export const {setPanel, setStep } = assistantSlice.actions;
export default assistantSlice.reducer;

