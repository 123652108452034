import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
} from '@chakra-ui/react'
import { DeleteRoomDialogI } from '../../../interfaces/Room.interfaces';

const DeleteRoomDialog = ({deleteRoom, openModal, closeModal}: DeleteRoomDialogI) => {


    return (
        <Modal isOpen={openModal} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Delete Room</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Text variant="title" fontWeight="medium" isTruncated>
                    Are you sure you want to delete this room?
                </Text>
            </ModalBody>

            <ModalFooter>
                <Button m="1rem" variant="primaryFooter" onClick={() => deleteRoom()}>
                    Delete
                </Button>
                <Button m="1rem" variant="secondaryFooter" onClick={closeModal}>
                    Cancel
                </Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default DeleteRoomDialog;