import {
    SubscriptionCard,
    SubscriptionTable,
    ProfilePaymentTab,
    AddCardBanner,
    NotBeChargedBanner,
    PartnerActiveSubscription
} from "@homefile/components"
import { Flex, Center, Text } from '@chakra-ui/react'
import {useState, useEffect} from 'react'
import {ActivePaymentI} from '../../Drawers/Profile/AccountTab'
import BeatLoader from "react-spinners/BeatLoader";
import CreditCardDialog from "../../Dialogs/CreditCardDialog";
import StripePaymentsModule from '../StripePaymentsModule'
import { useLocation } from "react-router-dom";
import { usePaymentInfo } from "../../../hooks/usePaymentInfo";
import {usePartnerInfo} from '../../../hooks/usePartnerInfo'
import { RootState } from "../../../app/store";
import { useAppSelector } from "../../../app/hooks";
import {useSubscriptionData} from '../../../hooks/useSubscriptionData'

const AddSubscriptionModule = ({
    activePayment,
    userCard,
    cardLoading,
    authToken,
    handleCardSuccess,
    cardSuccess,
}: ActivePaymentI) => {
    const user = useAppSelector((state: RootState) => state.auth.user);
    const [openModal, updateOpenModal] = useState(false)
    const [dialogHeader, updateDialogHeader] = useState("")
    const [dialogBody, updateDialogBody] = useState("")
    const [mainSubscription, updateMainSubscription] = useState(false)
    const [partnerSubscription, updatePartnerSubscription] = useState(false)
    const [paymentDate, updatePaymentDate] = useState<Date | string>("")
    const [subscriptionPrice, updateSubscriptionPrice] = useState(36)

    const {pathname} = useLocation()
    const {mainPartner} = usePartnerInfo()

    const {
        homes,
        totalStorage,
        usedStorage,
        state,
        availableStorage
    } = useSubscriptionData(0)

    useEffect(() => {
        if (!activePayment && pathname !== "/add-payment") {
            if (mainPartner.name) {
                updatePartnerSubscription(true)
                updateMainSubscription(false)
            } else {
                updateMainSubscription(true)
                updatePartnerSubscription(false)
            }
        }
    }, [mainPartner, activePayment, pathname])

    useEffect(() => {
        if (user) {
            updatePaymentDate(user.createdAt)
        }
    }, [user])

    const closeModal = () => {
        updateOpenModal(false)
    }


    const handleNewCard = () => {
        updateDialogHeader("Replace Payment Method")
        updateDialogBody("Only one payment method is valid with an active subscription. Your existing payment method will be removed and replaced with the new credit card information entered below.")
        updateOpenModal(true)
    }

    return (
        <Flex flexDir={"column"} w="100%" alignItems={"center"}>
            {
                (mainSubscription) && (
                    <>
                        <SubscriptionCard 
                        storage={2}
                        totalCharged={36}
                        />

                        <Flex flexDir="column" w="100%">
                            <AddCardBanner />
                            <NotBeChargedBanner />
                        </Flex>
                    </>
                )
            }
            {
                (partnerSubscription) && (
                    <>
                        <PartnerActiveSubscription 
                            partner={mainPartner.name}
                            renew={paymentDate}
                            subscriptionPrice={subscriptionPrice}
                            totalStorage={totalStorage}
                            totalUsed={usedStorage}
                        />
                        <Flex flexDir="column" w="100%">
                            <AddCardBanner />
                            <NotBeChargedBanner />
                        </Flex>
                    </>
                )
            }

            {
                cardLoading ? (
                    <Center w={"100%"} h="4rem" bg="white">
                        <BeatLoader color="gray" size={8} />
                    </Center>
                ) : (
                    userCard.isPaymentMethodValid ? (
                        <ProfilePaymentTab buttonLabel="Subscribe" handleNewCard={handleNewCard} paymentMethod={userCard} receipts={[]} handleDelete={handleNewCard} handleAddCard={() =>{}} isStandAlone={true} />
                    ) : (
                        <Flex w={"100%"} alignItems="center" justifyContent="center" p={"1rem"}>
                            <StripePaymentsModule handleCardSuccess={handleCardSuccess} authToken={authToken} />
                        </Flex>
                    )   
                    
                )
            }

            <CreditCardDialog handleCardSuccess={handleCardSuccess} authToken={authToken} cardSuccess={cardSuccess} cardLoading={cardLoading} openModal={openModal && !cardSuccess} close={closeModal} header={dialogHeader} body={dialogBody} cancelText="Cancel" />

        </Flex>
    )
}

export default AddSubscriptionModule