export const RoomsToAdd = [
    {
        name: "Kitchen",
        type: "kitchen"
    },
    {
        name: "Laundry",
        type: "laundry"
    },
    {
        name: "Living Room",
        type: "living_room"
    },
    {
        name: "Bedroom",
        type: "bedroom"
    },
    {
        name: "Office",
        type: "office"
    },
    {
        name: "Patio",
        type: "patio"
    },
    {
        name: "Theater",
        type: "theater"
    },
    {
        name: "Bathroom",
        type: "bathroom"
    },
    {
        name: "Garage",
        type: "garage"
    },
    {
        name: "Mudroom",
        type: "mudroom"
    },
    {
        name: "Dining Room",
        type: "dining_room"
    },
    {
        name: "Entryway",
        type: "entryway"
    },
    {
        name: "Closet",
        type: "closet"
    },
    {
        name: "Hallway",
        type: "hallway"
    },
    // {
    //     name: "Structure",
    //     type: "structure"
    // },

]