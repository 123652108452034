import { homefileApi } from './emptySplitApi'

const contactApi = homefileApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserContacts: builder.query({
            query: () => ({
                url: `/api/contact/list`,
            }),
            providesTags: ["Contacts"]
        }),
        createContact: builder.mutation({
            query: (data: any) => ({
                url: "/api/contact",
                method: "POST",
                body: data,
                headers: {
                "Content-Type": "application/json",
                },
            }),
            invalidatesTags: ["Contacts"],
        }),
        shareContact: builder.mutation({
            query: (data: any) => ({
                url: "/api/contact/share-contact",
                method: "POST",
                body: data,
                headers: {
                    "Content-Type": "application/json",
                },
            }),
        }),
        updateContact: builder.mutation({
            query: (data: any) => ({
                url: `/api/contact/${data.id}`,
                method: "PATCH",
                body: data,
                headers: {
                "Content-Type": "application/json",
                },
            }),
            invalidatesTags: ["Contacts"],
        }),
        deleteContact: builder.mutation({
            query: (id: string) => ({
                url: `/api/contact/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Contacts"],
        }),
    })
})

export const {
    useGetUserContactsQuery,
    useCreateContactMutation,
    useShareContactMutation,
    useUpdateContactMutation,
    useDeleteContactMutation
} = contactApi