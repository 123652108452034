import {useEffect, useState} from 'react';
import { useGetDefaultConfig } from "./useGetDefaultConfig";
import { useGetProgressReportsQuery } from "../features/api";

export const useCalculateHomeItemsValue = (id: string) => {
    const [value, updateValue] = useState(0)
    const {defaultConfig} = useGetDefaultConfig()
    const [receiptSkip, updateReceiptSkip] = useState(true)
    const { data: receiptData } = useGetProgressReportsQuery({homeId: id, type: defaultConfig.config.receiptItemTypeId, subType: ""}, {skip: receiptSkip});
    const [itemSkip, updateItemSkip] = useState(true)
    const { data: itemData } = useGetProgressReportsQuery({homeId: id, type: defaultConfig.config.householdItemTypeId, subType: ""}, {skip: itemSkip});

    useEffect(() => {
        if (defaultConfig.config.receiptItemTypeId !== undefined) {
            updateReceiptSkip(false)
        }

        if (defaultConfig.config.householdItemTypeId !== undefined) {
            updateItemSkip(false)
        }
        
    }, [defaultConfig])

    useEffect(() => {
        
        if (receiptData !== undefined && itemData !== undefined) {
            let tempVal = 0
            if (itemData.length > 0) {
                itemData.forEach((item: any) => {
                    if (!item.deleted && item.report) {
                        if (item.report.length === 6) {
                            const costField = item.report.find((field: any) => field.name === "Purchase Details").children[2].children.find((aField: any) => aField.name === "Amount Paid for Item")
                            const value = costField.value ? costField.value : 0;
                            tempVal += value
                        } else {
                            const costField = item.report.find((field: any) => field.name === "Amount Paid for Item")
                            if (costField !== undefined && costField.value !== undefined) {
                                const fieldValue = costField.value !== "" ? costField.value : 0;
                                tempVal += fieldValue
                            }
                        }
                    }
                })
            }

            if (receiptData.length > 0) {
                receiptData.forEach((item: any) => {
                    if (!item.deleted) {
                        if (item.metadata.total !== "NaN") {
                            tempVal += Number(item.metadata.total)
                        }
                    }
                })
            }
            updateValue(tempVal)
        }
    }, [itemData, receiptData])

    return {
        value
    }

}