export const Checkbox = {
  baseStyle: {
    control: {
      width: '21px',
      minHeight: '21px',
      borderWidth: '1px',
      borderColor: 'lightBlue.6',
      bg: 'neutral.white',
      borderRadius: 'none',
      _checked: {
        bg: 'blue.2',
      },
    },
  },
}

export default Checkbox
