import { useState, useEffect } from "react";
import {
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerFooter,
  Box,
  Flex,
  Image,
  Center
} from "@chakra-ui/react";
import {ResponseType} from '../../../interfaces/DataInterfaces';
import {
    useGetServiceRequestsQuery, 
    useCreateServiceRequestMutation, 
    useCreateServiceRequestNoteMutation, 
    useDeleteServiceRequestMutation,
    useAddServiceRequestImagesMutation
} from '../../../features/api'
import { Calendar } from "../../../assets/images";
import {PanelHeader, FooterDrawer, FooterButtons, DynamicForm, dynamicFormProxy, useCustomToast} from '@homefile/components';
import {useFileUpload} from "../../../hooks/useFileUpload"
import BeatLoader from "react-spinners/BeatLoader";


interface TileFormDrawerI {
    handleClose: () => void
    formData: any
}

const TileFormDrawer = ({handleClose, formData}: TileFormDrawerI) => {
    const [submitTileForm] = useCreateServiceRequestMutation()
    const toast = useCustomToast()
    const [form, updateForm] = useState<any[]>([])
    const [tileId, updateTileId] = useState("")
    const [headerIcon, updateHeaderIcon] = useState("")
    const [mainLogo, updateMainLogo] = useState("")
    const [requestType, updateRequestType] = useState("")
    const [displayImages, updateDisplayImages] = useState<string[]>([])
    const [sendImages, updateSendImages] = useState<any[]>([])
    const [addImages] = useAddServiceRequestImagesMutation()
    const [loading, setLoading] = useState(false)

    const {handleServiceImage} = useFileUpload()
    const { REACT_APP_STORAGE_URL: storageUrl } = process.env

    useEffect(() => {
        if (formData.form) {
            updateRequestType(formData.form.name)
            updateForm(formData.form.children)
            updateMainLogo(formData.logo)
            updateTileId(formData.tile)
        }
    }, [formData])

    const recursiveObj = (obj: any, flatten: any[] = []) => {
        // base case
        if (obj.children !== undefined && obj.children.length > 0) {
            const children = obj.children
            delete obj.children
            if (obj.type !== "checkbox") {
                const passObj: any = {
                    id: obj.id,
                    description: obj.description ? obj.description : "", 
                    value: obj.value, 
                    type: obj.type,
                    name: obj.name ? obj.name : ""
                }
                flatten = [...flatten, passObj]
            }
            children.forEach((child: any) => {
                const childObj = recursiveObj(child, flatten)
                flatten = childObj
            })
        } else {
            if (obj.type !== "checkbox") {
                const passObj: any = {
                    id: obj.id,
                    description: obj.description ? obj.description : "", 
                    value: obj.value, 
                    type: obj.type,
                    name: obj.name ? obj.name : ""
                }
                return [...flatten, passObj]
            }
        }
        return flatten
    }

    const handleSubmit = async () => {
        setLoading(true)
        const {fields} = dynamicFormProxy
        const nestedForm: any = {...formData.form, children: fields}
        const sendForm = recursiveObj(nestedForm)


        const passData = {
            tile: tileId,
            form: sendForm,
            request: requestType
        }
        const submitForm: ResponseType = await submitTileForm(passData)
        
        if (submitForm.data) {

            if (sendImages.length > 0) {
                let serviceImages: string[] = []
                // first upload images 
                let success = true
                for (const image of sendImages) {
                    const newIamge: any = await handleServiceImage(image, submitForm.data._id)
                    if (newIamge.response.status === 200) {
                        // create urls and add to service request
                        const url = `${storageUrl}/${newIamge.data.file.bucketName}/${newIamge.data.file.fileName}.${newIamge.data.file.extension}`
                        serviceImages = [...serviceImages, url]
                    } else {
                        success = false
                    }
                }
                if (success) {
                    // endpoint to add images to service request
                    const updateRequest: ResponseType = await addImages({id: submitForm.data._id, body: {images: serviceImages}})

                    if (updateRequest.data) {
                        toast({
                            title: 'Success',
                            description: `${formData.form.name} submitted`,
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                            position: "top-right"
                        })
                        handleClose()
                        setLoading(false)
                    } else {
                        console.log('add images to service req failed', updateRequest)
                    }
                } else {
                    console.log('fail to add service images to bucket')
                }
            } else {
                toast({
                    title: 'Success',
                    description: `${formData.form.name} submitted`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: "top-right"
                })
                handleClose()
                setLoading(false)
            }
        } else {
            console.log('submit form error', submitForm)
            toast({
                title: 'Failure',
                description: `Please try again later`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top-right"
            })
            setLoading(false)
        }
    }

    const ButtonsFooter = {
        button1: {
            buttonStyle: 'primaryFooter',
            label: ('Submit'),
            onClick: () => {handleSubmit()},
        },
        button2: {
            buttonStyle: 'secondaryFooter',
            label: ('Cancel'),
            onClick: handleClose,
        }
      }

    const handleUpload = (obj: any) => {
        // const id = Object.keys(obj)[0]
        const images: any[] = Object.values(obj)
        if (images.length > 0) {
            // add to images to send in body req
            let newSendVals: any[] = [...sendImages]
            // for local rendering
            let newVals: string[] = [...displayImages]
            images[0].forEach((img: any) => {
                newSendVals = [...newSendVals, img]
                newVals = [...newVals, img.imageUrl]
            })
            updateSendImages(newSendVals)
            updateDisplayImages(newVals)
        }
    }

    const handleRemoveImage = (url: string) => {
        // remove from images to send
        let newSendVals: any[] = [...sendImages]
        newSendVals = newSendVals.filter((img: any) => img.imageUrl !== url)
        updateSendImages(newSendVals)
        // remove from local rendering
        let newVals: string[] = [...displayImages]
        newVals = newVals.filter((val: string) => val !== url)
        updateDisplayImages(newVals)
    }

    return (
        <DrawerContent p="0">
            <DrawerHeader p="0">
                <PanelHeader
                    handleCloseButton={handleClose}
                    icon={Calendar}
                    title={formData.form.name}
                />
            </DrawerHeader>

            <DrawerBody p="0" background={"#e9edef"}>
                <Flex borderTop={"4px solid #f4f7f8"} p={".5rem"} background={"#ffffff"}>
                    <Image
                        src={mainLogo}
                        alt={"Partner Logo"}
                    />
                </Flex>

                <Box overflow="hidden" >
                    {
                        loading && (
                            <Center h="4rem" bg="white">
                                <BeatLoader color="gray" size={8} />
                            </Center>
                        )
                    }
                    {
                        (!loading && form.length > 0) && (
                            <DynamicForm 
                                form={form}
                                showTitle={formData.form.description !== "" ? true : false}
                                title={formData.form.description}
                                onUpload={handleUpload}
                                onRemoveImage={handleRemoveImage}
                                displayImages={displayImages}
                            />
                        )
                    }
                </Box>

            </DrawerBody>

            <DrawerFooter zIndex={"999"}>
                <FooterDrawer
                    children={
                        <FooterButtons
                        button1={ButtonsFooter.button1}
                        button2={ButtonsFooter.button2}
                        />
                    }
                    isOpen={true}
                    />

            </DrawerFooter>
        </DrawerContent>
    )
}

export default TileFormDrawer