import { createSlice } from "@reduxjs/toolkit";

interface TourStateInterface {
    launchpadTour: boolean
    homeBoardTour: boolean
    roomTour: boolean
}

const tourInitialState: TourStateInterface = {
    launchpadTour: false,
    homeBoardTour: false,
    roomTour: false
}

const tourSlice = createSlice({
    name: "tour",
    initialState: tourInitialState,
    reducers: {
        setLaunchpad: (state, action) => {
            state.launchpadTour = action.payload.launchpadTour;
        },
        setHomeBoard: (state, action) => {
            state.homeBoardTour = action.payload.homeBoardTour;
        },
        setRoom: (state, action) => {
            state.roomTour = action.payload.roomTour;
        },
    }
})

export const {setLaunchpad, setHomeBoard, setRoom } = tourSlice.actions;
export default tourSlice.reducer;