import { ResponseType } from '../interfaces/DataInterfaces';
import {
    useSendProgressReportMutation
  } from "../features/api";
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { setDocumentLoading } from "../features/document/documentSlice";
import { RootState } from "../app/store";
import { openDialog } from '../features/dialog/dialogSlice';
import {useCustomToast} from '@homefile/components'

export const useSendDocument = (successFunction: () => void) => {
    const dispatch = useAppDispatch();
    const recipients = useAppSelector((state: RootState) => state.document.recipients)
    const [sendReport] = useSendProgressReportMutation();
    const toast = useCustomToast()

    const handleSend = async (documentId:string) => {
        const emailList = recipients.reduce((newArr:any, recipient) => {
            return [...newArr, recipient.user.email]
        },[])

        const sendData = {
          progressReportId: documentId,
          emailList
        };

    
        const sentReport: ResponseType = await sendReport(sendData);
    
        if (sentReport.data) {
            const recipientList = emailList.join(" ")
            dispatch(setDocumentLoading({loading: false}))
            toast({
                title: 'Document Sent',
                description: `Recipients: ${recipientList}`,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top-right"
              })
              successFunction()
        } else if (sentReport.error) {
          console.log(sentReport.error);
          dispatch(setDocumentLoading({loading: false}))
          dispatch(openDialog({message: sentReport.error.data.message}))
        }
      };


    return {
        handleSend
    }
}