import { 
    RolePermissionsTab,
    FooterButtons, 
    FooterDrawer,
    useCustomToast
} from "@homefile/components";
import {useHomeUpdatePermissionsMutation, useGetHomeQuery} from '../../../features/api'
import { RolePermissionObject } from '@homefile/components/dist/interfaces'
import {useState, useEffect} from 'react';
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {mapPermissions, reverseMap, featuresSelectedMock} from '../../../util/mapPermissions';
import {ResponseType} from '../../../interfaces/DataInterfaces';

const RolePermissionsModule = () => {
    const toast = useCustomToast()
    const id = useAppSelector((state: RootState) => state.home.id)
    const [skipValue, updateSkip] = useState(true)
    const {data, error, isLoading} = useGetHomeQuery(id, {skip: skipValue})
    const [updatePermissions] = useHomeUpdatePermissionsMutation()
    const [selectedPermissions, updateSelectedPermissions] = useState(featuresSelectedMock)

    useEffect(() => {
        if (id.length > 0) {
            updateSkip(false)
        }
    }, [id])

    useEffect(() => {
        if (data) {
            if (data.rolesPermissions) {
                const mappedPermissions = mapPermissions(data.rolesPermissions)
                updateSelectedPermissions(mappedPermissions)
            } else {
                updateSelectedPermissions(featuresSelectedMock)
            }
        }
    }, [data])

    const handleSave = async () => {
        const updated: any = reverseMap(selectedPermissions)
        const permissionsUpdate: ResponseType = await updatePermissions(updated)

        if (permissionsUpdate.data) {
            toast({
                title: 'Success!',
                description: `Permissions Updated`,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top-right"
            })
        } else {
            toast({
                title: 'Error',
                description: `Something went wrong, please try again later.`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top-right"
              })
        }
    }

    const handleSelect = (obj: RolePermissionObject) => {
        updateSelectedPermissions(obj)
    }

    const ButtonsFooter = {
        button1: {
            buttonStyle: 'primaryFooter',
            label: "Save",
            onClick: () => {handleSave()},
        },
    }

    return (
        <>
            <RolePermissionsTab
                selected={selectedPermissions}
                onSelect={handleSelect}
            />
            <FooterDrawer
                children={
                    <FooterButtons
                    button1={ButtonsFooter.button1}
                    />
                }
                isOpen={true}
            />
        </>
    )
}

export default RolePermissionsModule