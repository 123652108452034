import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
} from '@chakra-ui/react'

interface GenericDialogI {
    action: () => void
    close: () => void
    header: string
    body: string
    actionText: string
    cancelText: string
    openModal: boolean
}

const GenericDialog = (props: GenericDialogI) => {

    const {action, close, header, body, actionText, cancelText, openModal} = props;

    return (
        <Modal isOpen={openModal} onClose={close}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>{header}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Text variant="title" fontWeight="medium" isTruncated whiteSpace={'initial'}>
                    {body}
                </Text>
            </ModalBody>

            <ModalFooter>
                {
                    (actionText !== "") && (
                        <Button m="1rem" variant="primaryFooter" onClick={action}>
                            {actionText}
                        </Button>
                    )
                }
                <Button m="1rem" variant="secondaryFooter" onClick={close}>
                    {cancelText}
                </Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default GenericDialog;