import {useState, useEffect} from 'react'
import {PropertyRecords, PanelHeader, useCustomToast} from '@homefile/components';
import { DrawerContent, DrawerHeader, Center, DrawerBody, useDisclosure } from '@chakra-ui/react'
import { MagnifyingGlassReport } from "../../../assets/images";
import {usePropertyInfo} from '../../../hooks/usePropertyInfo';
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import EditPropertyDetails from "../../Sliders/EditPropertyDetails";
import {EditCallbackI} from '@homefile/components/dist/interfaces'
import { ResponseType } from "../../../interfaces/DataInterfaces";
import { openDialog } from "../../../features/dialog/dialogSlice";


const PropertyRecordsDrawer = ({ handleClose }: { handleClose: () => void }) => {
    const id = useAppSelector((state: RootState) => state.home.id)
    const {propertyData, editHomeInfo, infoData} = usePropertyInfo(id)
    let { isOpen, onToggle } = useDisclosure()
    const [sectionData, updateSectionData] = useState({})
    const [section, updateSection] = useState("")
    const [isLoading, updateLoading] = useState(false)
    const toast = useCustomToast()
    const dispatch = useAppDispatch()

    const handleOpenEdit = ({ data, session }: EditCallbackI) => {
      updateSectionData(data)
      updateSection(session)
      onToggle()
    }

    const handleSaveEdit = async (data: any) => {
      updateLoading(true)
      let sendData = {...infoData, propertyInfo: {...infoData.propertyInfo}, tax: {...infoData.tax}}
      for (const [k,v] of Object.entries(data)) {
        if (sendData[k] !== undefined) {
          sendData[k] = v

        } else if (sendData.tax[k] !== undefined) {
          sendData.tax[k] = v
        } else {
          sendData.propertyInfo[k] = v
        }
      }
      
      const updatedHome: ResponseType = await editHomeInfo(sendData)
      if (updatedHome.error) {
        
        if ('status' in updatedHome.error) {
          if (updatedHome.error.status === 403) {
              dispatch(openDialog({message: "Error: access denied, no permissions to edit property info."}))
          } else {
              toast({
                  title: 'Error!',
                  description: "Please try again",
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  position: "top-right"
              }) 
          }
      } else {
          toast({
              title: 'Error!',
              description: "Please try again",
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: "top-right"
          }) 
      }
      } else {
        toast({
          title: 'Success!',
          description: "Property Updated",
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: "top-right"
        })
      }

      updateLoading(false)
      onToggle()

    }

    return (
        <DrawerContent bg="lightBlue.2">
        <DrawerHeader p="0">
          <PanelHeader
            handleCloseButton={handleClose}
            title={'Property Details'}
            icon={MagnifyingGlassReport}
          />
        </DrawerHeader>
        <DrawerBody p="0">
            <PropertyRecords 
                data={propertyData}
                onEdit={handleOpenEdit}
            />

        </DrawerBody>
        <EditPropertyDetails isLoading={isLoading} handleSaveEdit={handleSaveEdit} session={section} data={sectionData} back={onToggle} open={isOpen} />
      </DrawerContent>
    )
}

export default PropertyRecordsDrawer