import AddSubscriptionModule from "../../Modules/AddSubscriptionModule"
import SubscriptionDetailsModule from "../../Modules/SubscriptionDetailsModule"
import { PaymentMethodI, ReceiptCardI } from "@homefile/components/dist/interfaces"

export interface ActivePaymentI {
    activePayment: boolean
    userCard: PaymentMethodI
    receipts: ReceiptCardI[]
    handleCardSubmit: (form: any) => void
    cardLoading: boolean
    cardSuccess: boolean
    authToken?: string
    handleCardSuccess: () => void
}

const AccountTab = ({activePayment,
        userCard,
        receipts,
        cardLoading,
        cardSuccess,
        handleCardSuccess,
    }: ActivePaymentI) => {

    return (
        <>
            {
                activePayment ? (
                    <SubscriptionDetailsModule />
                ) : (
                    <AddSubscriptionModule handleCardSuccess={handleCardSuccess} cardSuccess={cardSuccess} cardLoading={cardLoading} userCard={userCard} receipts={receipts} handleCardSubmit={() => {}} activePayment={false} />
                )
            }
        </>
    )
}

export default AccountTab