export const Progress = {
  baseStyle: {
    filledTrack: {
      bg: 'green.1',
      borderRightRadius: '8px',
    },
    track: {
      bg: 'lightBlue.2',
      borderRightRadius: '8px',
    },
  },
  variants: {
    storageTrial: {
      filledTrack: {
        bg: 'violet.1',
      },
      track: {
        bg: 'lightGray.1',
      },
    },
    storageTrialDark: {
      filledTrack: {
        bg: 'violet.1',
      },
      track: {
        bg: 'neutral.white',
      },
    },
    storagePaid: {
      filledTrack: {
        bg: 'green.1',
      },
      track: {
        bg: 'lightGray.1',
      },
    },
    storagePaidDark: {
      filledTrack: {
        bg: 'green.1',
      },
      track: {
        bg: 'neutral.white',
      },
    },
    storageRunningOut: {
      filledTrack: {
        bg: 'error.2',
      },
      track: {
        bg: 'lightGray.1',
      },
    },
    storageRunningOutDark: {
      filledTrack: {
        bg: 'error.2',
      },
      track: {
        bg: 'neutral.white',
      },
    },
    incidental: {
      filledTrack: {
        bg: 'lightBlue.9',
      },
      track: {
        bg: 'lightGray.1',
      },
    },
    inventory: {
      filledTrack: {
        bg: 'blue.9',
      },
      track: {
        bg: 'lightGray.1',
      },
    },
    partnerCustomers: {
      filledTrack: {
        bg: 'lightBlue.8',
      },
      track: {
        bg: 'violet.5',
      },
    },
  },
}
