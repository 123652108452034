import { homefileApi } from './emptySplitApi'

const productApi = homefileApi.injectEndpoints({
    endpoints: (builder) => ({
        searchProducts: builder.query({
            query: (data: any) => ({
              url: `api/product/search?model=${data.model}&brand=${data.brand}`,
            }),
        }),
    })
})

export const {
    useSearchProductsQuery
} = productApi