import {useState, useEffect} from 'react'
import {MortgageInfo, PanelHeader, useCustomToast, FooterDrawer, FooterButtons, dynamicFormProxy} from '@homefile/components';
import { DrawerContent, DrawerHeader, Center, DrawerBody, DrawerFooter } from '@chakra-ui/react'
import { MagnifyingGlassReport } from "../../../assets/images";
import {usePropertyInfo} from '../../../hooks/usePropertyInfo';
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { ResponseType } from "../../../interfaces/DataInterfaces";
import {ReportI} from '@homefile/components/dist/interfaces'
import BeatLoader from "react-spinners/BeatLoader";
import { openDialog } from "../../../features/dialog/dialogSlice";


const MortgageInfoDrawer = ({ handleClose }: { handleClose: () => void }) => {
    const id = useAppSelector((state: RootState) => state.home.id)
    const {editHomeInfo, infoData, mortgageInfo, granteeName, saleDate} = usePropertyInfo(id)
    const [isLoading, updateLoading] = useState(false)
    const toast = useCustomToast()
    const dispatch = useAppDispatch()

    const emptyMortgage = {
        amount: 0,
        maturityDate: "",
        documentDate: "",
        interestRate: "",
        interestRateType: "",
        lenderName: "",
        granteeName: ""
    }

    const handleSaveEdit = async () => {
        updateLoading(true)
        let sendData = {...infoData, mortgagesInfo: {...infoData.mortgagesInfo}, saleHistory: [...infoData.saleHistory], mortgages: [...infoData.mortgages]}
        
        const {fields} = dynamicFormProxy
        let fieldData: any = {
            amount: 0,
            maturityDate: "",
            documentDate: "",
            estimatedMortgageBalance: 0,
            estimatedMortgagePayment: 0,
            interestRate: "",
            interestRateType: "",
            lenderName: "",
            purchasePrice: ""
        }

        if (fields) {
            fields.forEach((field:ReportI) => {
                if (field.id === "lender") {
                    fieldData.lenderName = field.value
                } else if (field.id === "purchase-price") {
                    fieldData.purchasePrice = field.value
                } else if (field.id === "group-1") {
                    field.children!.forEach((child:any) => {
                        if (child.id === "group-1-loan") {
                            fieldData.amount = child.value
                        } else if (child.id === "group-1-grid") {
                            child.children.forEach((nChild:any) => {
                                if (nChild.id === "group-1-grid-origination") {
                                    fieldData.documentDate = nChild.value
                                } else if (nChild.id === "group-1-grid-interest") {
                                    fieldData.interestRate = nChild.value
                                } else if (nChild.id === "group-1-grid-interest-type") {
                                    fieldData.interestRateType = nChild.value
                                }
                            })
                        }
                    })
                } else if (field.id === "payment-grid") {
                    field.children!.forEach((child:any) => {
                        if (child.id === "payment-grid-mortgage-payment") {
                            fieldData.estimatedMortgagePayment = child.value
                        } else if (child.id === "payment-grid-maturity-date") {
                            fieldData.maturityDate = child.value
                        }
                    })
                } else if (field.id === "balance") {
                    fieldData.estimatedMortgageBalance = field.value
                }
            })

            // handling where there are multiple objects in mortgages array - need to make sure targeting same object as displayed
            const returnMortgage = () => {
                let mortgageData = {...emptyMortgage}
                if (infoData.mortgages.length > 1) {
                    const mArray = [...infoData.mortgages]
                    mArray.sort((a,b) => Date.parse(b.documentDate) - Date.parse(a.documentDate))
                    mortgageData = {...mArray[0]}
                    if (granteeName === mortgageData.granteeName) {
                        return mortgageData
                    } else {
                        // need to figure out a better way to target correct mortgage from array
                        const returnMortgage = mArray.find((mortgage: any) => mortgage.granteeName === granteeName)
                        if (returnMortgage) {
                            return returnMortgage
                        } else {
                            return mortgageData
                        }
                    }
                } else {
                    mortgageData = {...infoData.mortgages[0]}
                    return mortgageData
                }
            }

            let firstMortgage = infoData.mortgages.length > 0 ? returnMortgage() : {...emptyMortgage}

            // handling where there are multiple objects in saleHistory array - need to make sure targeting same object as displayed
            const returnSale = () => {
                let sale: any = {}
                if (infoData.saleHistory.length > 1) {
                    const sArray = [...infoData.saleHistory]
                    sArray.sort((a,b) => Date.parse(b.saleDate) - Date.parse(a.saleDate))
                    sale = {...sArray[0]}
                    return sale
                } else {
                    sale = {...infoData.saleHistory[0]}
                    return sale
                }
            }

            let recentSale = infoData.saleHistory.length > 0 ? returnSale() : {saleAmount: ""}

            for (const [k,v] of Object.entries(fieldData)) {
                if (sendData[k]) {
                  sendData[k] = v
                } else if (k === "estimatedMortgageBalance" || k === "estimatedMortgagePayment") {
                    sendData.mortgagesInfo[k] = v
                } else if (firstMortgage[k] !== undefined) {
                    firstMortgage[k] = v
                } else if (k === "purchasePrice") {

                    recentSale["saleAmount"] = v
                }
            }

            if (!firstMortgage["maturityDate"]) {
                firstMortgage["maturityDate"] = fieldData["maturityDate"]
            }



            if (sendData.mortgages.length > 0) {
                const arrayI = sendData.mortgages.findIndex((m:any) => m.granteeName === firstMortgage.granteeName)
                sendData.mortgages[arrayI] = firstMortgage
            } else {
                sendData.mortgages = [firstMortgage]
            }

            if (sendData.saleHistory.length > 0) {
                sendData.saleHistory[0] = recentSale
            } else {
                sendData.saleHistory = [recentSale]
            }

            const updatedHome: ResponseType = await editHomeInfo(sendData)
            if (updatedHome.error) {
                if ('status' in updatedHome.error) {
                    if (updatedHome.error.status === 403) {
                        dispatch(openDialog({message: "Error: access denied, no permissions to edit property info."}))
                    } else {
                        toast({
                            title: 'Error!',
                            description: "Please try again",
                            status: 'error',
                            duration: 3000,
                            isClosable: true,
                            position: "top-right"
                        }) 
                    }
                } else {
                    toast({
                        title: 'Error!',
                        description: "Please try again",
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                        position: "top-right"
                    }) 
                }
            } else {
                toast({
                  title: 'Success!',
                  description: "Property Updated",
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                  position: "top-right"
                })
            }

        } else {
            toast({
                title: 'Error!',
                description: "Please try again",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: "top-right"
              }) 
        }

      updateLoading(false)
    }

    const ButtonsFooter = {
        button1: {
            buttonStyle: 'secondaryFooter',
            label: "Back",
            onClick: handleClose,
        },
        button2: {
            buttonStyle: 'primaryFooter',
            label: "Save",
            onClick: handleSaveEdit,
        }
    }

    return (
        <DrawerContent bg="lightBlue.2">
        <DrawerHeader p="0">
          <PanelHeader
            handleCloseButton={handleClose}
            title={'Property Details'}
            icon={MagnifyingGlassReport}
          />
        </DrawerHeader>
        {
            !isLoading ? (
                <MortgageInfo 
                    form={mortgageInfo}
                />
            ) : (
                <Center bg="lightBlue.1" h="100%">
                        <Center h="4rem">
                            <BeatLoader color="gray" size={8} />
                        </Center>
                    </Center>
            )
        }

        <DrawerFooter zIndex={"999"}>
                <FooterDrawer
                        children={
                            <FooterButtons
                                button1={ButtonsFooter.button1}
                                button2={ButtonsFooter.button2}
                            />
                        }
                        isOpen={true}
                        />
    
                </DrawerFooter>
      </DrawerContent>
    )
}

export default MortgageInfoDrawer