import { useGetItemSubTypesByItemTypeAndHomeQuery } from "../features/api";
import { useEffect, useState } from "react";
import { SelectItemI } from "@homefile/components/dist/interfaces";
import { useGetDefaultConfig } from "./useGetDefaultConfig";

export const useGetHouseholdItemSubTypes = (homeId: string) => {
    const {defaultConfig} = useGetDefaultConfig()
    const [skip, updateSkip] = useState(true)
    const [itemTypeId, updateId] = useState("")
    const {data} = useGetItemSubTypesByItemTypeAndHomeQuery({itemTypeId, homeId}, {skip})
    const [itemSubTypes, updateSubTypes] = useState([] as SelectItemI[])

    useEffect(() => {
        if (itemTypeId !== "") {
            updateSkip(false)
        }
    }, [itemTypeId])

    useEffect(() => {
        if (defaultConfig.config?.householdItemTypeId) {
            updateId(defaultConfig.config.householdItemTypeId)
        }
    }, [defaultConfig])

    useEffect(() => {
        if (data) {
            updateSubTypes(data as SelectItemI[])
        }
    }, [data])

    return {
        itemSubTypes
    }
}