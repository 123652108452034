import {ItemNameHeader, ItemSubTypeSelect, Overlay} from '@homefile/components';
import CreateItemTabs from './CreateItemTabs'
import { SelectItemI, ReportsI } from "@homefile/components/dist/interfaces";
import {IFormItem} from "../../../interfaces/DocumentMenu.interface";
import {Box} from '@chakra-ui/react'
import { useEffect, useState } from 'react';

interface CreateItemBodyI {
    handleSave: () => void
    itemName: string
    handleNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleSubTypeChange: (form: string | SelectItemI) => void
    subTypes: any[]
    initSubType: SelectItemI
    subTypeLabel: string
    completedForm: any
    newItemId: string
    homeId: string
    form: IFormItem,
    showOverlay: boolean
}


export const CreateItemBody = ({
    handleSave,
    itemName,
    handleNameChange,
    handleSubTypeChange,
    subTypes,
    initSubType,
    subTypeLabel,
    completedForm,
    newItemId,
    homeId,
    form,
    showOverlay
}: CreateItemBodyI) => {

    return (
        <>
            <ItemNameHeader handleSave={handleSave} value={itemName} handleChange={handleNameChange} />
            <Box display="block" position="relative">
                <Overlay showOverlay={showOverlay} position="absolute" w="inherit" h="inherit" zIndex="9"/>
                {
                    subTypes.length > 1 && (
                    <ItemSubTypeSelect handleSelectSubType={handleSubTypeChange} subTypes={subTypes} initialSubType={initSubType} label={subTypeLabel} />
                    )
                }
                <CreateItemTabs 
                    reportId={!completedForm._id ? newItemId : completedForm._id} 
                    reportTitle={itemName} 
                    homeId={homeId} 
                    report={!completedForm.title ? form : completedForm}
                    wizardTab={false}
                    detailedWizard={false}
                    showMediaIcon={true}
                />
            </Box>
        </>
    )

}
