import { useAppSelector, useAppDispatch } from "../app/hooks";
import { RootState } from "../app/store";
import { 
    useGetSharedUsersByFolderQuery,
    useAddSharedUserToFolderMutation,
    useRemoveSharedUserFromFolderMutation,
    useGetHomeQuery
} from "../features/api";
import {useState, useEffect} from 'react'
import { AssociatedAccountI } from "@homefile/components/dist/interfaces";
import { useDisclosure } from "@chakra-ui/react";
import { useAssociatedAccount } from './useAssociatedAccount';
import { ResponseType } from "../interfaces/DataInterfaces";
import {useCustomToast} from '@homefile/components'

export const useFolderSharedAccounts = ({id, skip}: {id: string, skip: boolean}) => {
    const {data} = useGetSharedUsersByFolderQuery(id, {skip})
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const userEmail = useAppSelector((state: RootState) => state.auth.user.email);
    const { data: homeData } = useGetHomeQuery(homeId);
    const [deleteUser] = useRemoveSharedUserFromFolderMutation()
    const [addUser] = useAddSharedUserToFolderMutation()
    const [accounts, updateAccounts] = useState<AssociatedAccountI[]>([])
    const [shareAccounts, updateShareAccounts] = useState<AssociatedAccountI[]>([])
    const [connectedAccounts, updateConnectedAccounts] = useState<AssociatedAccountI[]>([])
    const isHomeowner = (accountTypes: string[]) => accountTypes.some((account) => account.toLowerCase() === 'homeowner')
    const toast = useCustomToast()
    const {handleAdd} = useAssociatedAccount(connectedAccounts)

    const isShared = (email: string) => {
        const existingAccount = accounts.filter((account: AssociatedAccountI) => account.user.email === email)
        if (existingAccount.length > 0) {
            return true
        } else {
            return false
        }
    }

    const {
        isOpen: isShareOpen,
        onOpen: onShareOpen,
        onClose: onShareClose,
    } = useDisclosure()

    useEffect(() => {
        if (data) {
            let newAccounts: AssociatedAccountI[] = []
            data.filter((account: any) => account.email !== userEmail).forEach((account: any) => {
                const targetedAccount = connectedAccounts.find((cAccount: AssociatedAccountI) => account.email === cAccount.user.email)
                if (targetedAccount) {
                    newAccounts.push(targetedAccount)
                }
            })
            updateAccounts(newAccounts)
        }
    }, [data, connectedAccounts])

    useEffect(() => {
        if (homeData) {
            updateConnectedAccounts(homeData.associations)
            
            let recipientState = [] as AssociatedAccountI[];
            homeData.associations.filter((account: AssociatedAccountI) => account.user.email !== userEmail && !isHomeowner(account.accountTypes) && !isShared(account.user.email)).forEach((account: AssociatedAccountI) => {
                if (account.user) {
                    recipientState.push(account)
                }
            });
            updateShareAccounts(recipientState)
        }
    }, [homeData, accounts])

    const addNewUser = (account: AssociatedAccountI) => {
        handleAdd(account)
    }

    const shareNewUser = async (email: string) => {
        const targetUser = shareAccounts.find((account: AssociatedAccountI) => account.user.email === email)
        if (targetUser) {
            const sendData = {
                user: targetUser.user._id,
                home: homeId,
                folder: id
            }

            const shareUser: ResponseType = await addUser(sendData)

            if (shareUser.data) {
                toast({
                    title: 'Success',
                    description: `User Shared`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: "top-right"
                  })
            } else {
                toast({
                    title: 'Error',
                    description: `Please Try Again`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: "top-right"
                  })
            }
        }

    }

    const removeExistingUser = async (account: any) => {
        const targetUser: AssociatedAccountI | undefined = accounts.find((sAccount: AssociatedAccountI) => sAccount.user.email === account._id)
        if (targetUser) {
            const sendData = {
                user: targetUser.user._id,
                home: homeId,
                folder: id
            }

            const removeUser: ResponseType = await deleteUser(sendData)

            if (removeUser.data) {
                toast({
                    title: 'Success',
                    description: `User Removed`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: "top-right"
                  })
            } else {
                toast({
                    title: 'Error',
                    description: `Please Try Again`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: "top-right"
                  })
            }

        }
    }

    return {
        accounts,
        addNewUser,
        removeExistingUser,
        isShareOpen,
        onShareClose,
        onShareOpen,
        shareAccounts,
        shareNewUser
    }
}