import { homefileApi } from './emptySplitApi'

const notificationApi = homefileApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserNotifications: builder.query({
            query: () => "api/notification",
            providesTags: ['Notifications']
        }),
        getNewUserNotifications: builder.query({
            query: () => "api/notification/new",
            providesTags: ['Notifications']
        }),
        getUserNotificationsByType: builder.query({
            query: (type: string) => `api/notification/${type}`,
            providesTags: ['Notifications']
        }),
        createNotification: builder.mutation({
            query: (data: any) => ({
                url: "api/notification",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ['Notifications'],
        }),
        dispatchNotification: builder.mutation({
            query: (data: any) => ({
                url: "api/notification/dispatch",
                method: "POST",
                body: {notificationId: data.notificationId},
            }),
            invalidatesTags: ['Notifications'],
        }),
        updateNotification: builder.mutation({
            query: (data: any) => ({
                url: "api/notification",
                method: "PATCH",
                body: data,
            }),
            invalidatesTags: ['Notifications'],
        }),
        updateNotificationToRead: builder.mutation({
            query: (id: string) => ({
                url: `api/notification/read/${id}`,
                method: "PATCH",
            }),
            invalidatesTags: ['Notifications'],
        })
    })
})

export const {
    useGetUserNotificationsQuery,
    useCreateNotificationMutation,
    useDispatchNotificationMutation,
    useUpdateNotificationMutation,
    useGetNewUserNotificationsQuery,
    useGetUserNotificationsByTypeQuery,
    useUpdateNotificationToReadMutation
} = notificationApi