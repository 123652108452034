import { useAppDispatch } from "../../../app/hooks";
import { setDrawer } from '../../../features/drawer/drawerSlice';
import {SendCommunication} from '@homefile/components';
import {useEffect, useState} from 'react';
import {IFormItem} from "../../../interfaces/DocumentMenu.interface";
import {useGetFormsList} from '../../../hooks/useGetFormsList';
import { ViewInterface } from "../../../interfaces/CreateDocumentInterfaces";


const SendReportView = ({homesLength, homeDisabled}: ViewInterface) => {

    const dispatch = useAppDispatch();
    const [disabled, updatedDisabled] = useState(false)

    const handleClick = (form: IFormItem) => {
        dispatch(setDrawer({ location: "right", content:'CreateDocument', size: "md",  data: form }))
    }

    const handleItemClick = (form: IFormItem) => {
      dispatch(setDrawer({ location: "right", content:'CreateItem', size: "md",  data: {completedForm: {}, formName: form.name, formType: form.type._id} }))
  }

    const {forms} = useGetFormsList({documentClick: handleClick, itemClick: handleItemClick, formTypes: "Contact,Note"})

    useEffect(() => {
      if (homesLength < 1) {
        updatedDisabled(true)
      } else {
        updatedDisabled(false)
      }
    }, [homesLength])


    return (
      <SendCommunication
          documentList={forms}
          isDisabled={disabled || homeDisabled}
      />
    )
}

export default SendReportView;