import { useAppSelector} from "../app/hooks";
import { RootState } from "../app/store";
import { useGetHomeQuery} from "../features/api";
import {useState, useEffect} from 'react';


export const useHasPermission = () => {
    // const id = useAppSelector((state: RootState) => state.home.id)
    const user = useAppSelector((state: RootState) => state.auth.user)
    // const [skipValue, updateSkip] = useState(true)
    // const {data, error, isLoading} = useGetHomeQuery(id, {skip: skipValue})
    // const [associations, updateAssociations] = useState<any[]>(accounts)

    const isHomeowner = (accountTypes: string[]) => accountTypes.some((account) => account.toLowerCase() === 'homeowner')

    // useEffect(() => {
    //     updateAssociations(accounts)
    // }, [accounts])

    const checkPermission = (p: string, associations: any[]) => {
        let returnVal = false
        if (associations.length > 0) {
            const userMatch = associations.find((association: any) => association.user._id === user._id)
            if (userMatch) {
                const homeownerCheck = isHomeowner(userMatch.accountTypes)
                if (!homeownerCheck) {
                    const viewPermissions = userMatch.permissions.find((permission: string) => permission === p)
                    if (viewPermissions) {
                        returnVal = true
                    } else {
                        returnVal = false
                    }
                } else {
                    returnVal = true
                }
            } else {
                returnVal = false
            }
        } else {
            returnVal = false
        }
        return returnVal
    }

    return {
        checkPermission
    }

}