import {useState, useEffect} from 'react'
import {RoomStep, MediaDetailsStep, StepHeader} from '@homefile/components';
import { SelectItemI, ReportsI } from "@homefile/components/dist/interfaces";
import CreateHouseholdItemTabs from '../CreateHouseholdItemTabs'
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { RootState } from "../../../../app/store";
import {RoomItemI} from '@homefile/components/dist/interfaces/rooms/RoomsMenu.interface'


interface QuickFlowI {
    categories: SelectItemI[]
    handleCategoryChange: (form: string | SelectItemI) => void
    handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleRoomChange: (form: string | SelectItemI) => void
    initialCategory: string
    initialRoom: string
    nameValue: string
    rooms: RoomItemI[]
    newItemId: string
    report: any
    step: number
}

const QuickFlow = ({
    categories,
    handleCategoryChange,
    handleNameChange,
    handleRoomChange,
    initialCategory,
    initialRoom,
    nameValue,
    rooms,
    newItemId,
    report,
    step
}: QuickFlowI) => {
    const homeId = useAppSelector((state: RootState) => state.home.id)

    return (
        <>
            {
                step === 1 && (
                    <RoomStep 
                        categories={categories}
                        currentStep={2}
                        handleCategoryChange={handleCategoryChange}
                        handleNameChange={handleNameChange}
                        handleRoomChange={handleRoomChange}
                        // initialCategory={initialCategory}
                        // initialRoom={initialRoom}
                        nameValue={nameValue}
                        rooms={rooms}
                        totalSteps={3}
                    />
                )
            }
            {
                step === 2 && (
                    // <MediaDetailsStep />
                    <>
                    <StepHeader currentStep={3} description="Take pictures or videos of your item and
                        upload them here." steps={3}/>
                    <CreateHouseholdItemTabs 
                        reportId={newItemId} 
                        reportTitle={nameValue} 
                        homeId={homeId} 
                        report={report}
                        wizardTab={true}
                        detailedWizard={false}
                        showMediaIcon={false}
                    />
                    </>
                )
            }
        </>
    )
}

export default QuickFlow