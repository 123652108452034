
export const DefaultPermissions: any = {
    "member": [
        "GetHomeInfo",
        "GetHomeId",
        "GetItemTypeId",
        "GetItemTypeIdSubTypesId",
        "GetItemTypeIdSubTypes",
        "GetPartnerTilesUser",
        "GetReceiptAllId",
        "GetReceiptWithoutHome",
        "GetReceiptItemsId",
        "GetReportInfoId",
        "GetReportProgressReportsId",
        "GetReportForms",
        "GetReportSetForDeletion",
        "GetReportId",
        "GetRoomHomeId",
        "GetRoomId",
        "GetUserAssociationHomeId",
        "GetUserAssociationFolderId",
        "GetUserAssociationReportsIdId",
        "PostPartnerTile"
    ],
    "contributor": [
        "GetHomeInfo",
        "GetHomeId",
        "PostHomeTile",
        "GetItemTypeId",
        "GetItemTypeIdSubTypesId",
        "GetItemTypeIdSubTypes",
        "PostItemType",
        "PostItemTypeIdSubType",
        "GetPartnerTilesUser",
        "GetReceiptAllId",
        "GetReceiptWithoutHome",
        "GetReceiptItemsId",
        "PatchReceiptUpdateHome",
        "PatchReceiptId",
        "DeleteReceipt",
        "GetReportInfoId",
        "GetReportProgressReportsId",
        "GetReportForms",
        "GetReportSetForDeletion",
        "GetReportId",
        "PostReportProgressReport",
        "PatchReportChanceFolder",
        "PatchReportRestoreId",
        "PatchRoomRemoveItem",
        "GetRoomHomeId",
        "GetRoomId",
        "PostRoomSubType",
        "PostRoom",
        "PatchRoomAddItemId",
        "PatchRoomUpdateId",
        "DeleteRoomSubType",
        "GetUserAssociationHomeId",
        "GetUserAssociationFolderId",
        "GetUserAssociationReportsIdId",
        "GetPartnerAll",
        "PostPartnerTile"
    ],
    "manager": [
        "GetHomeInfo",
        "GetHomeId",
        "PostHomeTile",
        "PostHomeRealState",
        "PatchHomeRealState",
        "PatchHomeTile",
        "PatchHomeRestoreId",
        "PatchHomeId",
        "DeleteHomeExclude",
        "DeleteHomeId",
        "GetItemTypeId",
        "GetItemTypeIdSubTypesId",
        "GetItemTypeIdSubTypes",
        "PostItemType",
        "PostItemTypeIdSubType",
        "GetPartnerTilesUser",
        "GetPartnerTiles",
        "GetPartnerAll",
        "GetPartner",
        "GetReceiptAllId",
        "GetReceiptWithoutHome",
        "GetReceiptItemsId",
        "PatchReceiptUpdateHome",
        "PatchReceiptId",
        "DeleteReceipt",
        "GetReportInfoId",
        "GetReportProgressReportsId",
        "GetReportForms",
        "GetReportSetForDeletion",
        "GetReportId",
        "PostReportProgressReport",
        "PatchReportChanceFolder",
        "PatchReportRestoreId",
        "PatchReportId",
        "DeleteReportId",
        "GetRoomHomeId",
        "GetRoomId",
        "PostRoomSubType",
        "PostRoom",
        "PatchRoomAddItemId",
        "PatchRoomRemoveItem",
        "PatchRoomUpdateId",
        "DeleteRoomSubType",
        "DeleteRoomId",
        "GetUserAssociationHomeId",
        "GetUserAssociationFolderId",
        "GetUserAssociationReportsIdId",
        "PostUserAssociationHome",
        "PostUserAssociationFolder",
        "PatchUserAssociationHome",
        "DeleteUserAssociationHome",
        "DeleteUserAssociationFolder",
        "PostPartnerTile"
    ]
}