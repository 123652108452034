export const Container = {
  baseStyle: {
    borderRadius: 'sm',
    padding: 0,
  },
  sizes: {
    small: {
      w: 'container.sm',
    },
    medium: {
      w: 'container.md',
    },
    large: {
      w: 'container.lg',
    },
    full: {
      w: 'container.full',
    },
    onboarding: {
      maxW: 'container.md',
    },
    validation: {
      maxW: 'container.lg',
    },
  },
  variants: {
    primary: {
      backgroundColor: 'neutral.white',
      boxShadow: 'base',
    },
    secondary: {
      backgroundColor: 'lightGreen.1',
    },
    tertiary: {
      backgroundColor: 'lightBlue.1',
    },
    launchpad: {
      backgroundColor: 'lightBlue.1',
      boxShadow: 'base',
    },
    ghost: {
      backgroundColor: 'transparent',
    },
    footer: {
      backgroundColor: 'neutral.white',
      boxShadow: '2xl',
      borderTopRadius: 'xl',
    },
    footerSquare: {
      backgroundColor: 'neutral.white',
      boxShadow: '2xl',
    },
    list: {
      backgroundColor: 'neutral.white',
      border: '1px solid',
      borderColor: 'lightBlue.6',
      transition: 'all 0.2s ease-in-out',
      _hover: {
        backgroundColor: 'lightGreen.1',
      },
    },
    dragDrop: {
      backgroundColor: 'transparent',
      border: '1px solid',
      borderColor: 'lightBlue.4',
      transition: 'all 0.2s ease-in-out',
      _hover: {
        backgroundColor: 'lightGreen.1',
      },
    },
    dragDropDashed: {
      backgroundColor: 'lightBlue.3',
      border: '1px dashed',
      borderRadius: 'md',
      borderColor: 'lightBlue.6',
      transition: 'all 0.2s ease-in-out',
      _hover: {
        backgroundColor: 'lightGreen.1',
        cursor: 'pointer',
      },
    },
    partnerCard: {
      backgroundColor: 'lightBlue.1',
      boxShadow: 'base',
      border: '1px solid transparent',
      transition: 'all 0.2s ease-in-out',
      _hover: {
        boxShadow: 'lg',
        borderColor: 'blue.3',
      },
    },
    partnerCardWithoutHover: {
      backgroundColor: 'lightBlue.1',
      boxShadow: 'base',
      border: '1px solid transparent',
    },
    popup: {
      backgroundColor: 'lightBlue.1',
      boxShadow: 'xl',
      borderRadius: '12px',
      overflow: 'hidden',
    },
  },
  defaultProps: {
    size: 'full',
    variant: 'primary',
  },
}

export default Container
