import { useAppSelector, useAppDispatch } from "../app/hooks";
import { RootState } from "../app/store";
import {  useCreateFolderMutation, useUpdateFolderMutation, useDeleteFolderMutation } from "../features/api";
import { clearDrawer } from "../features/drawer/drawerSlice";
import { ResponseType } from '../interfaces/DataInterfaces';
import { openDialog } from "../features/dialog/dialogSlice";
import { FolderI } from "../interfaces/FolderInterfaces";
import { useGetHomeFoldersQuery } from "../features/api";
import { useEffect, useState } from "react";
import { FolderInfoFormI } from "@homefile/components/dist/interfaces";
import {useCustomToast} from '@homefile/components'

export const useFolderDetails = ({icon, fileLength, deleteCallback, folderId, folderData}: {icon?: string, fileLength?: number, deleteCallback?: () => void, folderId?: string, folderData?: FolderI}) => {
    const dispatch = useAppDispatch();
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const [createFolder] = useCreateFolderMutation()
    const [editFolder] = useUpdateFolderMutation()
    const [removeFolder] = useDeleteFolderMutation()
    const {data} = useGetHomeFoldersQuery(homeId)
    const toast = useCustomToast()
    const [firstFolder, updateFolder] = useState({} as FolderI)
    const [folderType, updateFolderType] = useState("")
    const [canDelete, updateCanDelete] = useState(false)
    const [openDeleteFolderDialog, updateOpenDeleteFolderDialog] = useState(false)
    const [deleteFolderId, updateDeleteFolderId] = useState("")
    const [deleteDialogHeader, updateDeleteDialogHeader] = useState("")
    const [deleteDialogBody, updateDeleteDialogBody] = useState("")
    const [passFolder, updatePassFolder] = useState<FolderI>(folderData || {} as FolderI)
    const [openPrivateFolderDialog, updatePrivateFolderDialog] = useState(false)
    const [folderToEdit, updateFolderToEdit] = useState({} as FolderInfoFormI)

    useEffect(() => {
        let fetchData = true;
        if (data && fetchData) {
            updateFolder(data[0])
        }
        return () => {
            fetchData = false
        }
    }, [data])

    useEffect(() => {
        if (fileLength !== undefined) {
            if (fileLength > 0) {
                updateCanDelete(false)
            } else {
                updateCanDelete(true)
            }
        }
    }, [fileLength])

    const addFolder = async (name: string) => {
        const shareable = (folderType === "shared" || folderType === "project") ? true : false
        
        const sendData = {
            home: homeId,
            name,
            icon: folderType === "" ? "private" : folderType,
            shareable
        }

        const newFolder: ResponseType = await createFolder(sendData)
        if (newFolder.data) {
            dispatch(clearDrawer())
        } else {
            console.log('folder error', newFolder.error.data.message)
            dispatch(openDialog({message: newFolder.error.data.message}))
        }

    }

    const sendFolderEdit = async (editableFolder: FolderInfoFormI) => {

        let folderShareable = true
        if (editableFolder.folderType === "private") {
            folderShareable = false
        }

        const sendData = {
            homeId, 
            name: editableFolder.folderName, 
            folderId,
            shareable: folderShareable,
            icon: editableFolder.folderType
        }
        const updatedFolder: ResponseType = await editFolder(sendData)

        if (updatedFolder.data) {
            updatePassFolder(updatedFolder.data)
            toast({
                title: 'Success',
                description: `Folder Updated`,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top-right"
              })
              updatePrivateFolderDialog(false)
        } else {
            console.log('folder update error', updatedFolder.error.data.message)
            dispatch(openDialog({message: updatedFolder.error.data.message}))
        }
    }

    const editPrivateFolder = () => {
        sendFolderEdit(folderToEdit)
    }

    const editFolderDetails = async (folder: FolderInfoFormI) => {
        updateFolderToEdit(folder)
        
        if (folder.folderType === "private" && passFolder.shareable) {
            updatePrivateFolderDialog(true)
        } else {
            sendFolderEdit(folder)
        }
        
    }

    const deleteFolder = () => {
        if (canDelete && folderId) {
            updateDeleteFolderId(folderId)
            updateDeleteDialogHeader("Remove Folder?")
            updateDeleteDialogBody("This action will permanently delete this folder.")
        } else {
            updateDeleteDialogHeader("Cannot Remove Folder")
            updateDeleteDialogBody("Folders with active items cannot be deleted. Remove or move items before attempting to delete.")
        }
        updateOpenDeleteFolderDialog(true)
        
    }

    const serverDelete = async () => {
        const deletedFolder: ResponseType = await removeFolder(deleteFolderId) 
        if (deletedFolder.data) {
            if (deleteCallback) {
                toast({
                    title: 'Success',
                    description: `Folder Removed`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: "top-right"
                  })
                deleteCallback()
            }
        } else {
            console.log('delete folder fail', deletedFolder.error.data.message)
            toast({
                title: 'Error',
                description: `Please try again later.`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top-right"
              })

              updateDeleteDialogHeader("Cannot Remove Folder")
              updateDeleteDialogBody(`Folders with associated items cannot be deleted. ${deletedFolder.error.data.message}. Some items may have been set to be deleted and can be removed manually from the Homeboard.`)
        }
    }

    const handleFolderType = (value: string) => {
        updateFolderType(value)
    }

    return {
        addFolder,
        editFolderDetails,
        firstFolder,
        handleFolderType,
        deleteFolder,
        serverDelete,
        openDeleteFolderDialog,
        deleteDialogHeader,
        deleteDialogBody,
        updateOpenDeleteFolderDialog,
        canDelete,
        passFolder,
        openPrivateFolderDialog,
        updatePrivateFolderDialog,
        editPrivateFolder
    }

}