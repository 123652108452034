import { useState, useEffect} from "react";
import { useGetUserSubscriptionQuery } from "../features/api";


export const useGetUserSubscriptionData = (isAuthenticated: boolean) => {
    const [activeSubscription, updateActiveSubscription] = useState(false)
    const [subscriptionData, updateSubscriptionData] = useState({} as any)
    const {data, isLoading} = useGetUserSubscriptionQuery(null, {skip: !isAuthenticated})

    useEffect(() => {
        if (data && data.message !== "Missing subscription") {
            updateActiveSubscription(true)
            updateSubscriptionData(data)
        } else {
            updateActiveSubscription(false)
            updateSubscriptionData({})
        }
    }, [data])

    return {
        activeSubscription,
        subscriptionData,
        isLoading
    }
}