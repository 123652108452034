import {useEffect, useState} from 'react'
import {useCustomToast} from '@homefile/components'
import { ResponseType } from '../interfaces/DataInterfaces';
import {
    useGetServiceRequestsQuery, 
    useCreateServiceRequestMutation, 
    useCreateServiceRequestNoteMutation, 
    useDeleteServiceRequestMutation,
    useSendViewServiceRequestMutation,
    useLazyGetServiceRequestQuery
} from '../features/api'
import {InboxMessageI} from '@homefile/components/dist/interfaces'
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { RootState } from "../app/store";

export const useMessages = () => {
    const [messages, updateMessages] = useState<InboxMessageI[]>([])
    const user = useAppSelector((state: RootState) => state.auth.user)
    const [newNote] = useCreateServiceRequestNoteMutation()
    const [markViewed] = useSendViewServiceRequestMutation()
    const toast = useCustomToast()
    const [getDocument] = useLazyGetServiceRequestQuery()

    const mapMessages = async (notifications: any[]) => {
        let newMessages: any[] = []
        let success = true
        for (const notification of notifications) {
            // lazy query fetch data
            const doc: ResponseType = await getDocument(notification.docId)

            if (doc.data) {
                const partnerName = doc.data.partner.name
                const userName = user.firstName
                const serviceDate = new Date(doc.data.createdAt)
                const subject = `${doc.data.request} - ${serviceDate}`
                let chatReplies: any[] = []
                if (doc.data.notes.length > 0) {
                    doc.data.notes.forEach((note: any) => {
                        const reply = {
                            date: note.createdAt,
                            description: note.text,
                            from: note.from === "customer" ? userName : partnerName,
                            _id: note._id,
                            viewedAt: note.viewedAt
                        }
                        chatReplies = [...chatReplies, reply]
                    })
                }
                const parentMessage = {
                    date: doc.data.createdAt,
                    from: partnerName,
                    subject,
                    chatReplies: chatReplies,
                    _id: doc.data._id,
                    isNew: true
                }
                
                newMessages = [...newMessages, parentMessage]
            } else {
                success = false
            }
        }
        updateMessages(newMessages)
        if (!success) {
            // need to let user know issue with one or more note
        }
    }

    const createNote = async (message: any) => {
        const sendData = {
            serviceId: message.id,
            body: {
                note: message.message
            }
        }
        const note: ResponseType = await newNote(sendData)

        if (note.data) {
            mapMessages([{docId: note.data._id}])
            toast({
                title: 'Success',
                description: `Message sent.`,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top-right"
              })

        } else {
            console.log('note error', note)
            toast({
                title: 'Error',
                description: `Something went wrong. Please try again later.`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top-right"
              })
        }

    }

    return {
        messages,
        mapMessages,
        markViewed,
        createNote
    }

}