import { createSlice } from "@reduxjs/toolkit";

interface RoomStateI {
    value: number
    roomId: string
}

const initialState: RoomStateI = {
    value: 0,
    roomId: ""
}

const roomSlice = createSlice({
    name: "room",
    initialState: initialState,
    reducers: {
        addValue: (state, action) => {
            state.value += action.payload.value
        },
        clearValue: (state) => {
            state.value = 0
        },
        addRoomId: (state, action) => {
            state.roomId = action.payload.roomId
        },
        clearRoomId: (state) => {
            state.roomId = ""
        }
    }
})

export const {addValue, clearValue, addRoomId, clearRoomId} = roomSlice.actions;
export default roomSlice.reducer;