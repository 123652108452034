import {useState, useEffect} from 'react'
import { useUpdateRoomMutation, useDeleteRoomMutation } from '../../features/api';
import { RoomItemI, GroupOptionI } from '@homefile/components/dist/interfaces';
import { RoomHeader, GroupsContainer, GroupsHeader, RoomsBoardTour } from '@homefile/components';
import { ResponseType } from '../../interfaces/DataInterfaces';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import RoomGroupsModule from '../../components/Modules/RoomGroupsModule';
import {RoomsSettleRefs} from '../../util/StepsSettleRefs'
import { useUpdateUserTour } from "../../hooks/useUpdateUserTour";
import { setRoom } from "../../features/tour/tourSlice";
import { setDrawer, clearDrawer } from "../../features/drawer/drawerSlice";
import { useRoomData } from '../../hooks/useRoomData';
import {useHasPermission} from '../../hooks/useHasPermission'
import { useRoomSubType } from '../../hooks/useRoomSubType';
import DeleteRoomDialog from '../../components/Dialogs/DeleteRoomDialog';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import GenericDialog from "../../components/Dialogs/GenericDialog";
import { openDialog } from "../../features/dialog/dialogSlice";
  
const RoomBoard = () => {
    const dispatch = useAppDispatch()
    const roomValue = useAppSelector((state: RootState) => state.room.value)
    const roomId = useAppSelector((state: RootState) => state.room.roomId)
    const user = useAppSelector((state: RootState) => state.auth.user)
    const accounts = useAppSelector((state: RootState) => state.home.associatedAccounts)
    const [updateRoom] = useUpdateRoomMutation()
    const [deleteRoom] = useDeleteRoomMutation()
    const {updateTour} = useUpdateUserTour()
    const roomTourState = useAppSelector((state: RootState) => state.tour.roomTour);
    const [activeTour, updateActiveTour] = useState(false)
    const [currentStep, setCurrentStep] = useState<string>()
    const {addRoomSubType} = useRoomSubType()
    const [deleteDisabled, updateDisabled] = useState(true)
    const [deleteModal, updateModal] = useState(false)
    const [dialogOpen, updateDialogOpen] = useState(false)
    const navigate = useNavigate()
    const {roomData, groups, isLoading} = useRoomData()
    const userType = useAppSelector((state: RootState) => state.auth.homeAssociationType)
    const {checkPermission} = useHasPermission()

    useEffect(() => {
        if (user.showTour.room) {
            updateActiveTour(true)
        } else {
            updateActiveTour(false)
        }
    }, [user])

    useEffect(() => {
        if (roomData.items !== undefined) {
            if (roomData.items.length > 0) {
                let testDisabled = false
                for (const item of roomData.items) {
                    if (item.deleted === false) {
                        testDisabled = true
                        break;
                    }
                }
                updateDisabled(testDisabled)
            } else {
                updateDisabled(false)
            }
        }
    }, [roomData])

    const handleUpdateName = async (room: RoomItemI) => {
        const updatedRoom: ResponseType = await updateRoom({
            roomId: room._id,
            body: room
        })

        if (updatedRoom.error) {
            if ('status' in updatedRoom.error) {
                if (updatedRoom.error.status === 403) {
                    dispatch(openDialog({message: "Error: access denied, no permissions to edit room."}))
                }
            }
        } 
    }

    const handleAddGroup = async (group: GroupOptionI) => {
        if (roomId !== undefined) {
            addRoomSubType({
                room: roomId,
                subType: group._id
            })
        }
    }

    const handleStep = (step: string) => {
        setCurrentStep(step)
    }

    const handleClose = () => {
        updateTour("room")
        dispatch(setRoom({roomTour: false}))
    }

    const handleDeleteRoom = async () => {
        if (roomId !== undefined) {
            const deletedRoom: ResponseType = await deleteRoom(roomId)

            if (deletedRoom.data) {
                navigate('/homes/base')
            } else {
                if ('status' in deletedRoom.error) {
                    if (deletedRoom.error.status === 403) {
                        dispatch(openDialog({message: "Error: access denied, no permissions to delete room."}))
                    }
                }
            }
        }
    }

    const deleteStatus = () => {
        if (deleteDisabled) {
            updateDialogOpen(true)
        } else {
            updateModal(true)
        }
    }

    const handleCloseDeleteModal = () => {
        updateModal(false)
    }

    return (
        <>
            <Loading 
                isLoading={isLoading}
                children={
                    <>
                        <RoomHeader 
                            onSubmit={handleUpdateName}
                            room={roomData}
                            roomAmount={roomValue.toString()}
                            isDeleteDisabled={userType === "member" || !checkPermission("DeleteRoomId", accounts) || roomData.type === "structure"}
                            disabled={userType === "member" || !checkPermission("PatchRoomUpdateId", accounts)}
                            onClickDelete={deleteStatus}
                        />
                        <GroupsContainer>
                            <GroupsHeader disabled={userType === "member"} groupList={groups} onClick={handleAddGroup} showingTour={currentStep === 'creatingGroups'} />
                            <RoomGroupsModule />
                        </GroupsContainer>
                        <RoomsBoardTour
                            handleClose={handleClose}
                            handleStep={handleStep}
                            isActive={activeTour || roomTourState}
                            currentStep={currentStep}
                            meetStepUrl="https://storage.googleapis.com/homefile-videos/Room_Overview.mp4"
                        />
                    </>
                }
            />
            <DeleteRoomDialog closeModal={handleCloseDeleteModal} openModal={deleteModal} deleteRoom={handleDeleteRoom}  />
            <GenericDialog header={"Delete Room"} body={"Move or delete the items in this room before removing the room."} action={() => {}} close={() => {updateDialogOpen(false)}} openModal={dialogOpen} actionText={""} cancelText={"Continue"}  />
        </>
    )
}

export default RoomBoard;