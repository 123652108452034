import {IFormItem, IDocumentList} from "../interfaces/DocumentMenu.interface";
import { useGetFormsQuery } from "../features/api";
import {useEffect, useState} from 'react';
import { ReactElement } from 'react';
import { ProgressReport, NoteItem, ContactItem } from '../assets/images';

interface IconObject {
  Contact: string
  Note: string
  ProgressReport: string
}

export const useGetFormsList = ({documentClick, itemClick, formTypes, formSubTypes = ""}: IDocumentList) => {
  const { data } = useGetFormsQuery({type: formTypes, subType: formSubTypes});
  const [forms, updateForms] = useState<IFormItem[]>([]);

  const getIcon = (name: string) => {

    const iconType: IconObject  = {
        Note: NoteItem,
        Contact: ContactItem,
        ProgressReport: ProgressReport
    }

    return iconType[name as keyof IconObject] ? iconType[name as keyof IconObject] : iconType['ProgressReport'];

  }

  useEffect(() => {
      if (data) {
        let formArray: IFormItem[] = [] 
        data.forEach((form: IFormItem) => {
          const formItem = {
            name: form.name,
            _id: form._id,
            form: form.form,
            onClick: form.name === "Progress Report" ? () => {documentClick(form)} : () => {itemClick(form)},
            icon: getIcon(form.name),
            active: true,
            label: form.name,
            needsReview: form.needsReview
          }
          formArray = [...formArray, formItem]
        });

        updateForms(formArray)
      }
    }, [data]);

  return {
      forms
  }
}