import {useState, useEffect} from 'react'
import {RoomStep, MediaDetailsStep, StepHeader} from '@homefile/components';
import { SelectItemI, ReportsI } from "@homefile/components/dist/interfaces";
import CreateHouseholdItemTabs from '../CreateHouseholdItemTabs'
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { RootState } from "../../../../app/store";
import {RoomItemI} from '@homefile/components/dist/interfaces/rooms/RoomsMenu.interface'


interface DetailedFlowI {
    categories: SelectItemI[]
    handleCategoryChange: (form: string | SelectItemI) => void
    handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleRoomChange: (form: string | SelectItemI) => void
    initialCategory: string
    initialRoom: string
    nameValue: string
    rooms: RoomItemI[]
    newItemId: string
    report: any
    step: number
    handleTabChange: (index: number) => void
    tabIndex: number
}

const DetailedFlow = ({
    categories,
    handleCategoryChange,
    handleNameChange,
    handleRoomChange,
    initialCategory,
    initialRoom,
    nameValue,
    rooms,
    newItemId,
    report,
    step,
    handleTabChange,
    tabIndex
}: DetailedFlowI) => {
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const [stepDesc, updateDesc] = useState("Take pictures or videos of your item and upload them here.")
    const [displayStep, updateDisplayStep] = useState(3)

    useEffect(() => {
        if (tabIndex === 0) {
            updateDesc("Take pictures or videos of your item and upload them here.")
            updateDisplayStep(3)
        } else if (tabIndex === 1) {
            updateDesc("Add as much detail as you can. Not every Item will use all of these fields.")
            updateDisplayStep(4)
        }
    }, [tabIndex])

    return (
        <>
            {
                step === 1 && (
                    <RoomStep 
                        categories={categories}
                        currentStep={1}
                        handleCategoryChange={handleCategoryChange}
                        handleNameChange={handleNameChange}
                        handleRoomChange={handleRoomChange}
                        // initialCategory={initialCategory}
                        // initialRoom={initialRoom}
                        nameValue={nameValue}
                        rooms={rooms}
                        totalSteps={4}
                    />
                )
            }
            {
                (step === 2 || step === 3) && (
                    <>
                    <StepHeader currentStep={displayStep} description={stepDesc} steps={4}/>
                    <CreateHouseholdItemTabs 
                        reportId={newItemId} 
                        reportTitle={nameValue} 
                        homeId={homeId} 
                        report={report}
                        wizardTab={true}
                        detailedWizard={true}
                        tabIndex={tabIndex}
                        onChange={handleTabChange}
                        showMediaIcon={false}
                    />
                    </>
                )
            }
        </>
    )

}

export default DetailedFlow