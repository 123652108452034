import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
} from '@chakra-ui/react'
import {DeleteDialogI} from '../../../interfaces/FolderInterfaces';

const DeleteFileDialog = (props: DeleteDialogI) => {

    const {deleteFile, fileId, openModal, closeModal} = props;

    return (
        <Modal isOpen={openModal} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Delete File</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Text variant="title" fontWeight="medium" isTruncated>
                    Are you sure you want to delete this file?
                </Text>
            </ModalBody>

            <ModalFooter>
                <Button m="1rem" variant="primaryFooter" onClick={() => deleteFile(fileId)}>
                    Delete
                </Button>
                <Button m="1rem" variant="secondaryFooter" onClick={closeModal}>
                    Cancel
                </Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default DeleteFileDialog;