import {useState, useEffect} from 'react';
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { RootState } from "../app/store";
import { useGetDefaultConfig } from "../hooks/useGetDefaultConfig";
import { useGetFormsQuery, useGetProgressReportsQuery } from "../features/api";
import {ReportI} from '@homefile/components/dist/interfaces'

export const useHomeAssistant = () => {
    const dispatch = useAppDispatch()
    const currentStep = useAppSelector((state: RootState) => state.assistant.currentStep)
    const currentPanel = useAppSelector((state: RootState) => state.assistant.currentPanel)
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const { data } = useGetFormsQuery({type: "Home Assistant", subType: ""});
    const [forms, updateForms] = useState({} as Record<number, ReportI[]>)
    const {defaultConfig} = useGetDefaultConfig()
    const [skip, updateSkip] = useState(true)
    const [completeNum, updateCompleteNum] = useState(0)
    const { data: existingForms, isLoading } = useGetProgressReportsQuery({homeId, type: defaultConfig.config.assistantTypeId, subType: ""}, {skip});


    useEffect(() => {
        if (defaultConfig.config.assistantTypeId !== "") {
            updateSkip(false)
        }
    }, [defaultConfig])


    const stepMapping: any = {
        "Appliances": 4,
        "Goals": 1,
        "Heating & Cooling": 3,
        "Landscape": 6,
        "Smoke & CO2 Detectors": 2,
        "Structure": 5
    }

    useEffect(() => {
        if (data && existingForms) {
            if (existingForms.length > 0) {
                let tempForms: Record<number, ReportI[]> = {}
                let maxNum = 0
                existingForms.forEach((res: any) => {
                    const stepNum: number = stepMapping[res.title]
                    tempForms[stepNum] = res.report
                    if (stepNum > maxNum) {
                        maxNum = stepNum
                    }
                })

                updateCompleteNum(maxNum)

                data.forEach((res: any) => {
                    const stepNum: number = stepMapping[res.name]
                    if (tempForms[stepNum] === undefined) {
                        tempForms[stepNum] = res.form
                    }
                })
                updateForms(tempForms)
            } else {
                let tempForms: Record<number, ReportI[]> = {}
                data.forEach((res: any) => {
                    const stepNum: number = stepMapping[res.name]
                    tempForms[stepNum] = res.form
                })
                updateForms(tempForms)
            }
        }
    }, [data, existingForms])

    return {
        forms,
        existingForms,
        completeNum,
        stepMapping,
        data
    }
}
