import { Box } from "@chakra-ui/react";
import { ResetPassword, ResendResetPassword } from "@homefile/components";
import { useLogin } from "../../hooks/useLogin";
import { useForgotPassword } from "../../hooks/useForgotPassword";
import { IEmailForm } from "../../interfaces/ResetPassword.interface";

const ForgotPassword = () => {

  const {
    navigateToLogin
  } = useLogin();

  const {
    showConfirmation,
    loading,
    error,
    emailVal,
    handleEmailSubmit
  } = useForgotPassword()

  return (
    <Box mt="50px">
        {
            !showConfirmation ? (
                <ResetPassword
                    isLoading={loading}
                    handleReset={(form: IEmailForm) => {
                        handleEmailSubmit(form)
                    }}
                    handleSignIn={() => navigateToLogin()}
                    resetError={error}
                />
            ) : (
                <ResendResetPassword
                    email={emailVal}
                    isLoading={loading}
                    handleResendBt={() => {
                        handleEmailSubmit({email: emailVal})
                    }}
                />
            )
        }
    </Box>
  );
};

export default ForgotPassword;
