import { useState, useEffect } from "react";
import { useCreateUserMutation, useLoginMutation, useCreatePinMutation } from "../features/api";
import { useNavigate, useSearchParams} from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { setToken } from "../features/auth/authSlice";
import {ISignUp, LoginFormValues, FormValues} from '../interfaces/Login.interfaces';
import { openDialog } from "../features/dialog/dialogSlice";
import { ResponseType } from "../interfaces/DataInterfaces";

export const useLogin = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createUser] = useCreateUserMutation();
  const [loginUser] = useLoginMutation();
  const [generatePin] = useCreatePinMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [signUpVal, updateSignUp] = useState({email: '', firstName: '', lastName: ''})
  const [searchParams] = useSearchParams();
  const [showPin, updateShowPin] = useState(false)
  const [loginValues, updateLoginValues] = useState({} as LoginFormValues)
  const [twoFactorMethod, updateTwoFactorMethod] = useState<'sms' | 'email'>('email')
  const [ttlValue, updateTtlValue] = useState(false)

  useEffect(() => {
      if (searchParams.get('email') !== null && searchParams.get('firstname') && searchParams.get('lastname')) {
        if (window.location.pathname === "/login") {
          navigate(`/sign-up?email=${searchParams.get('email')}&firstname=${searchParams.get('firstname')}&lastname=${searchParams.get('lastname')}`)
        } else if (window.location.pathname === "/sign-up") {
          updateSignUp({
            email: searchParams.get('email'),
            firstName: searchParams.get('firstname'),
            lastName: searchParams.get('lastname')
          } as ISignUp)
        }
      } else if (searchParams.get('email') !== null) {
        if (window.location.pathname === "/login") {
          navigate(`/sign-up?email=${searchParams.get('email')}`)
        } else if (window.location.pathname === "/sign-up") {
          updateSignUp({
            email: searchParams.get('email'),
            firstName: "",
            lastName: ""
          } as ISignUp)
        }
      }
  }, [searchParams])

  const navigateToLogin = () => {
    navigate("/login")
  }

  const navigateToSignUp = () => {
    navigate("/sign-up")
  }

  const navigateToForgotPassword = () => {
    navigate("/forgot-password")
  }

  const handleSubmit = async (values: FormValues) => {
    setError(false);
    const passData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email.toLowerCase(),
      password: values.password,
      partnerCode: values.promoCode ? values.promoCode.toLowerCase() : null,
      homeAccountType: "SingleHome",
      accountType: "User",
      paymentType: "Free",
      monthlyCharge: 0,
      showTour: {
        launchpad: true,
        homeBoard: true,
        room: true
      }
    };

    setLoading(true);
    const response: ResponseType = await createUser(passData);
    if (response.data) {
      setLoading(false)
      if (response.data.active) {
        dispatch(openDialog({message: "An account with this email already exists."}))
      } else {
        navigate('/login', {state:{
          email: passData.email,
          password: passData.password,
          signUpSuccess: true
        } as LoginFormValues})
      }
    } else {
      setLoading(false);
      setError(true);
      console.log(response)
      console.log(`Error creating user`);
      dispatch(openDialog({message: response.error.data.message}))
    }

  };

  const handleGeneratePin = async (values: LoginFormValues) => {
    setError(false);
    setLoading(true)
    const response: ResponseType = await generatePin({email: values.email.toLowerCase(), password: values.password});
    if (response.data) {
      if (response.data.provider == "email") {
        updateTwoFactorMethod('email')
        updateLoginValues({...values, email: values.email.toLowerCase()})
        updateShowPin(true)
        setLoading(false)
      } else if (response.data.provider == "sms") {
        updateTwoFactorMethod('sms')
        updateLoginValues({...values, email: values.email.toLowerCase()})
        updateShowPin(true)
        setLoading(false)
      } else {
        handleDirectLogin({...values, email: values.email.toLowerCase()})
      }
    } else {
      setLoading(false);
      setError(true);
      console.log(response)
      console.log(`Error generating pin`);
      dispatch(openDialog({message: response.error.data.message}))
    }
  }

  const handleResendPin = async () => {
    setError(false);
    setLoading(true)
    const response: ResponseType = await generatePin({email: loginValues.email.toLowerCase(), password: loginValues.password});
    if (response.data) {
      updateShowPin(true)
      setLoading(false)
    } else {
      setLoading(false);
      setError(true);
      console.log(response)
      console.log(`Error resend pin`);
      dispatch(openDialog({message: response.error.data.message}))
    }
    
  }

  const handleDirectLogin = async ({email, password}: LoginFormValues) => {
    setError(false);

    const sendData = {
      email,
      password,
      code: "",
      ttl: false
    }

    setLoading(true);
    const response: ResponseType = await loginUser(sendData);
    if (response.data) {
      if (response.data.paymentRequired) {
        navigate("/add-payment", {state: response.data})
      } else {
        dispatch(setToken(response.data));
      }
    } else {
      console.log(response)
      console.log(`Error direct login`);
      setLoading(false);
      dispatch(openDialog({message: response.error.data.message}))
    }
  }

  const handleLogin = async (pin: string) => {
    setError(false);

    const sendData = {
      email: loginValues.email,
      password: loginValues.password,
      code: pin,
      ttl: ttlValue
    }

    setLoading(true);
    const response: ResponseType = await loginUser(sendData);
    if (response.data) {
      if (response.data.paymentRequired) {
        navigate("/add-payment", {state: response.data})
      } else {
        dispatch(setToken(response.data));
      }
    } else {
      console.log(response)
      console.log(`Error login after pin`);
      setLoading(false);
      dispatch(openDialog({message: response.error.data.message}))
    }

  };

  const handleTrustDevice = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateTtlValue(event.target.checked)
  }

  return {
    error,
    handleLogin,
    handleSubmit,
    loading,
    signUpVal,
    navigateToLogin,
    navigateToSignUp,
    navigateToForgotPassword,
    showPin,
    handleGeneratePin,
    handleResendPin,
    twoFactorMethod,
    handleTrustDevice
  };
};
