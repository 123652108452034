import { Center } from "@chakra-ui/react";
import { SignUp } from "@homefile/components";
import { useLogin } from "../../hooks/useLogin";
import PermissionDialog from '../../components/Dialogs/PermissionDialog';

const AccountSignUp = () => {

  const {
    error,
    handleSubmit,
    loading,
    signUpVal,
    navigateToLogin
  } = useLogin();

  return (
    <>
      <Center>
        <SignUp
            isLoading={loading}
            handleCreateAccount={(form) => handleSubmit(form)}
            handleSignIn={() => navigateToLogin()}
            signupError={error}
            values={signUpVal}
            showPromoCode={true}
          />
      </Center>
      <PermissionDialog />
    </>
  );
};

export default AccountSignUp;
