import { useState, useEffect, ReactNode } from "react";
import {PartnerCatalogPanel} from '@homefile/components'
import {usePartnerCatalog} from '../../../hooks/usePartnerCatalog'
import GenericDialog from "../../../components/Dialogs/GenericDialog";
import { useAppSelector} from "../../../app/hooks";
import { RootState } from "../../../app/store";

export interface PartnerCardI {
    _id: string
    category?: any
    description: string
    name: string
    service?: any
    code: string
}

const PartnerCatalogDrawer = ({onClose, existingTiles}: {onClose: () => void, existingTiles: any[]}) => {

  const [partners, updatePartners] = useState<PartnerCardI[]>([])
  const id = useAppSelector((state: RootState) => state.home.id)

    const {
      allPartnerData, 
      handleAddPartner,
      handleCloseDialog,
      dialogHeader,
      dialogBody,
      dialogOpen
    } = usePartnerCatalog({existingTiles, id})

    useEffect(() => {
      if (allPartnerData.length > 0) {
        const mappedPartnerData: PartnerCardI[] = allPartnerData.filter((pData: any) => pData.active && pData.code !== 'homefile').map((p: any) => {
          let description = ""
          let logo = ""
          if (p.tiles.length > 0) {
            description = p.tiles[0].description
            logo = p.tiles[0].logo
          }
          return {
            _id: p._id,
            description: description,
            name: p.name,
            code: p.code,
            service: p.service,
            category: p.category,
            logo: logo
          }
        })
        updatePartners(mappedPartnerData)
      }
    }, [allPartnerData])

    return (
      <>
        <PartnerCatalogPanel onClose={onClose} config={{showCode: true, showCategories: true}} onPartnerAdd={handleAddPartner} partners={partners} />
        <GenericDialog header={dialogHeader} body={dialogBody} action={() => {}} close={handleCloseDialog} openModal={dialogOpen} actionText={""} cancelText={"Continue"}  />
      </>
    )
}

export default PartnerCatalogDrawer