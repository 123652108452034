import { useState, useEffect } from "react";
import { useUserResetPasswordMutation } from "../features/api";
import { INewPasswordForm } from "../interfaces/NewPassword.interface";
import { useSearchParams } from "react-router-dom";
import { useNavigate} from "react-router-dom";
import { ResponseType } from "../interfaces/DataInterfaces";


export const useResetPassword = () => {
    const [sendPasswordReset] = useUserResetPasswordMutation();
    const [loading, setLoading] = useState(false);
    const [error, updateError] = useState(false);
    const [paramValues, updateParams] = useState({email: "", code: ""})
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    interface IParams {
        email: string;
        code: string;
    }

    useEffect(() => {
        if (searchParams.get('email') !== null && searchParams.get('code')) {
            updateParams({
                email: searchParams.get('email'),
                code: searchParams.get('code')
            } as IParams)
        } 
      }, [])

    const handlePasswordSubmit = async (values: INewPasswordForm) => {
        try {
            setLoading(true)
            updateError(false)
            const updatePassword: ResponseType =  await sendPasswordReset({email: paramValues.email, code: paramValues.code, password: values.password});
            if (updatePassword.data) {
                navigate('/login', {state:{
                    email: paramValues.email,
                    password: values.password
                }})
            } else {
                updateError(true)
            }
            setLoading(false)
        } catch (err) {
            console.log('error here',err)
            updateError(true)
            setLoading(false)
        }
    }

    return {
        loading,
        error,
        handlePasswordSubmit,
        paramValues
    }
}