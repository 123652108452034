import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { setUser, clearAuth } from "../../features/auth/authSlice";
import { useGetUserQuery } from "../../features/api";
import { Header } from "@homefile/components";
import MainHome from "./MainHome";
import { isFetchBaseQueryError} from '../../util/fetchError';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state: RootState) => state.auth.token);
  const user = useAppSelector((state: RootState) => state.auth.user);
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const [skipQuery, setSkip] = useState(true);
  const { data, error } = useGetUserQuery({ skip: skipQuery });

  // checks if authenticated then get user info
  useEffect(() => {
    if (isAuthenticated) {
      setSkip(false);
    }
  }, [isAuthenticated, token]);

  useEffect(() => {
    if (data) {
      dispatch(setUser(data));
    } else if (isFetchBaseQueryError(error)) {
        if (error.status === 401) {
          dispatch(clearAuth())
        }
    }
  }, [data, dispatch, error]);

  return (
    <div>
      <Header
        firstName={user !== undefined ? user.firstName : ""}
        handleViews={() => {}}
      />
      {token && <MainHome {...user} />}
    </div>
  );
};

export default Dashboard;
