import { Box } from "@chakra-ui/react";
import { SignIn, TwoFactor } from "@homefile/components";
import { useLogin } from "../../hooks/useLogin";
import {useLocation, useNavigate} from 'react-router-dom';
import {useState, useEffect} from "react";
import PermissionDialog from '../../components/Dialogs/PermissionDialog';
import GenericDialog from '../../components/Dialogs/GenericDialog'
import {LoginFormValues} from '../../interfaces/Login.interfaces';

interface LocationState {
  email: string
  password: string
  signUpSuccess: boolean
}

const Login = () => {
  const [signInVal, updateSignIn] = useState({ email: '', password: '' })
  const location = useLocation();
  const {email, password, signUpSuccess} = location.state as LocationState || { email: '', password: '', signUpSuccess: false };
  const [showSuccess, updateSuccess] = useState(false)
  const navigate = useNavigate()
  const {
    error,
    handleLogin,
    loading,
    navigateToSignUp,
    navigateToForgotPassword,
    showPin,
    handleGeneratePin,
    handleResendPin,
    twoFactorMethod,
    handleTrustDevice
  } = useLogin();

  useEffect(() => {
    if (signUpSuccess) {
      updateSuccess(true)
    }
    if (email && password) {
      updateSignIn({email, password})
    }
  }, [])


  const handleSubmitPin = (pin: string) => {
    handleLogin(pin.trim())
  }

  const handleClose = () => {
    updateSuccess(false)
    navigate('/login', {state:{
      email: email,
      password: password,
      signUpSuccess: false
    } as LoginFormValues})
  }

  return (
    <Box>
      {
        !showPin ? <SignIn
          isLoading={loading}
          handleForgotPassword={() => navigateToForgotPassword()}
          handleSignIn={(form) => handleGeneratePin(form)}
          handleSignUp={() => navigateToSignUp()}
          loginError={error}
          values={signInVal}
        /> : <Box mt="50px"><TwoFactor twoFactorMethod={twoFactorMethod} isLoading={loading} handleReset={handleSubmitPin} handleResend={handleResendPin} /></Box>
      }
      <PermissionDialog />
      <GenericDialog openModal={showSuccess} close={handleClose} action={() => null} actionText="" cancelText="Continue" header="Sign Up Successful!" body="Continue to login" />
    </Box>
  );
};

export default Login;
