import React, {useEffect} from 'react';
import './App.css';
import RenderRoutes from './routing/RenderRoutes';
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from 'react-ga4';

// google analytics implementation
ReactGA.initialize('G-KQBRE2NLTQ');

function App() {
  
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);

  return (
    <Router>
      <div className="homefile">
        <RenderRoutes />
      </div>
    </Router>
  );
}

export default App;
