import { AddMedia } from "@homefile/components";
import { useState } from "react";
import {useUploadMedia} from '../hooks/useUploadMedia';
import { UploadI } from "../interfaces/ImageInterfaces";
import DocumentImagesCarousel from "../components/Carousels/DocumentImagesCarousel";
import { 
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  ModalCloseButton
 } from "@chakra-ui/react";

const DocumentImageUpload = ({ documentId, showMediaIcon }: UploadI) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [initialSlide, setSlide] = useState(0);
  const fireModal = (image: any) => {
    const index = images.findIndex(
      (file: any) => file._id === image._id
    )
    setSlide(index);
    onOpen();
  };

  const {isLoading, images, uploading, handleDelete, handleUpload, handleEdit} = useUploadMedia({documentId})

  return (
    <>
      <AddMedia showHeader={showMediaIcon} loading={isLoading} images={images} uploading={uploading} handleDelete={handleDelete} handleEdit={handleEdit} handleUpload={handleUpload} handleOpen={fireModal} />
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Images</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DocumentImagesCarousel
              images={images}
              initialSlide={initialSlide}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DocumentImageUpload;
