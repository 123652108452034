import { useUpdateUserMutation } from "../features/api"
import { useAppSelector, useAppDispatch } from "../app/hooks"
import { RootState } from "../app/store"
import { ResponseType } from "../interfaces/DataInterfaces"
import { setUser } from "../features/auth/authSlice"

export const useUpdateUserTour = () => {
    let user = useAppSelector((state: RootState) => state.auth.user);
    const [updateUser] = useUpdateUserMutation()
    const dispatch = useAppDispatch();

    const updateTour = async (tour: string) => {
        user = {...user, showTour: {...user.showTour, [tour]: false}}
        const updatedUser: ResponseType = await updateUser(user)

        if (updatedUser.data) {
            dispatch(setUser(updatedUser.data));
        }
    }

    return {
        updateTour
    }
}