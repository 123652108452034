import { useEffect, useState } from "react";
import {FolderSharing} from '@homefile/components';
import { useGetHomeFoldersQuery } from "../../../features/api";
import { FoldersModuleI} from "../../../interfaces/FolderInterfaces";
import { SelectItemI } from "@homefile/components/dist/interfaces/inputs/Select.interface";
import { useAppDispatch} from "../../../app/hooks"
import { setDrawer } from "../../../features/drawer/drawerSlice";
import { FolderI } from "@homefile/components/dist/interfaces";

const FoldersModule = ({homeId, disabled}: FoldersModuleI) => {
    const dispatch = useAppDispatch();
    const {data} = useGetHomeFoldersQuery(homeId)
    const [folders, updateFolders] = useState([] as FolderI[])
    const [currentSort, updateCurrentSort] = useState("")

    useEffect(() => {
        let fetchData = true;
        if (data && fetchData) {
            let folderData: FolderI[] = []
            data.forEach((folder:any) => {
                if (!folder.public && folder.icon === "folder") {
                    if (folder.shareable) {
                        folder = {...folder, icon: "shared"}
                    } else {
                        folder = {...folder, icon: "private"}
                    }
                }
                folderData = [...folderData, folder]
            })
            updateCurrentSort("By Type")
            const sortedByType = [...folderData].sort(sortByType)
            updateFolders(sortedByType)
        }
        return () => {
            fetchData = false
        }
    }, [data])

    const sortByType = (a: FolderI, b: FolderI) => {
        if (a.icon === undefined || b.icon === undefined) return 1
        if (a.icon.toLowerCase() < b.icon.toLowerCase()) return -1
        if (a.icon.toLowerCase() > b.icon.toLowerCase()) return 1
        return 0
      }

    const folderClick = (folder: FolderI) => {
        dispatch(setDrawer({ location: "right", content:'FolderDetails', size: "md",  data: folder }))
    }

    const handleAddNewFolder = () => {
        dispatch(setDrawer({ location: "right", content:'AddFolder', size: "md", data: "folder"}))
    }

    const selectOptions: SelectItemI[] = [
        {
          name: 'Shared',
          _id: '1',
        },
        {
          name: 'Unshared',
          _id: '2',
        },
        {
          name: 'New',
          _id: '3',
        },
    ]

    return (
        <>
            <FolderSharing
                folders={folders}
                handleFolderClick={folderClick}
                handleAddNewFolder={handleAddNewFolder}
                handleSelect={() => {}}
                initialSelectItem={selectOptions[0]}
                menuItems={[]}
                selectItems={selectOptions}
                disabled={disabled}
                currentSort={currentSort}
            /> 
        </>
    )

}

export default FoldersModule;