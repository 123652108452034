import { ImageDBI, UploadI } from "../interfaces/ImageInterfaces";
import {useState, useEffect} from 'react';
import { useGetProgressReportQuery, useDeleteReportImageMutation, useUpdateFileUploadMutation } from "../features/api";
import { ResponseType } from "../interfaces/DataInterfaces";
import { useFileUpload } from "./useFileUpload";
import { useAppDispatch } from "../app/hooks";
import { openDialog } from "../features/dialog/dialogSlice";

export const useUploadMedia = (props: UploadI) => {
    const dispatch = useAppDispatch()
    let {documentId} = props
    const [isLoading, updateLoading] = useState(false)
    const [uploading, updateUploading] = useState(false)
    const [images, updateImages] = useState([] as ImageDBI[])
    const [editImage] = useUpdateFileUploadMutation();
    const [deleteImage] = useDeleteReportImageMutation();
    const [skipValue, updateSkip] = useState(true);
    const {handleNewDocumentImage}  = useFileUpload()

    const { data } = useGetProgressReportQuery({ documentId: documentId }, { skip: skipValue });

    useEffect(() => {
        if (documentId !== undefined && documentId !== "") {
            updateSkip(false)
        }
    }, [documentId])

    useEffect(() => {
        if (data !== undefined && data.images.length > 0) {
            updateImages(data.images)
        }
    }, [data])

    const handleDelete = (id: string) => {
        deleteImage({id: documentId, body: {imageId: id}})
    }

    const handleEdit = async (file: ImageDBI) => {
        updateLoading(true)
        updateUploading(true)
        const sendData = {
            imageId: file._id,
            description: file.description
        }
        const updatedImage: ResponseType = await editImage(sendData) 

        if (updatedImage.data) {
            updateLoading(false)
            updateUploading(false)
        } else {
            updateLoading(false)
            updateUploading(false)
            dispatch(openDialog({message: updatedImage.error.data.message}))
        }
    }

    const handleUpload = async (images: ImageDBI[]) => {
        if (images.length > 0) {
            updateLoading(true)
            updateUploading(true)
            for (const image of images) {
                if (documentId === "") {
                    documentId = image._id
                }
                await handleNewDocumentImage(image, documentId)
            }

            updateLoading(false)
            updateUploading(false)
        }
    }

    return {
        isLoading,
        uploading,
        images,
        handleDelete,
        handleEdit,
        handleUpload
    }
}

