import { ResponseType } from '../interfaces/DataInterfaces';
import {
    useUpdateProgressReportMutation,
  } from "../features/api";
import { useAppDispatch } from "../app/hooks"
import { setDocumentLoading } from "../features/document/documentSlice";
import {useSendDocument} from './useSendDocument';
import {openDialog} from '../features/dialog/dialogSlice'

export interface IUpdateDocument {
    newReportId: string
    homeId: string
    reportTitle: string
    report: any
    type?: string
    subType?: string
    room?: string
    subTypeId?: string
}

export const useSendReport = (successFunction: (data?: any) => void) => {
    const dispatch = useAppDispatch();
    const [updateReport] = useUpdateProgressReportMutation();
    const {handleSend} = useSendDocument(() => successFunction())

    const handleSendReport = async (props: IUpdateDocument) => {
        dispatch(setDocumentLoading({loading: true}))
        const {newReportId, homeId, reportTitle, report} = props;
        const reportSendData = {
            id: newReportId,
            home: homeId,
            title: reportTitle,
            report: report
        };

        const updatedReportResponse: ResponseType = await updateReport(
            reportSendData
        );
        if (updatedReportResponse.data) {
            handleSend(updatedReportResponse.data._id)
        } else {
            console.log("fail",updatedReportResponse);
            dispatch(setDocumentLoading({loading: false}))
            dispatch(openDialog({message: updatedReportResponse.error.data.message}))
        }
    };


    return {
        handleSendReport
    }
}