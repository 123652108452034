import { HomeInterface } from "../interfaces/HomeInterfaces";

export const TourHomeCard: HomeInterface[] = [
    {
        
        _id: "6663375132be989bca2f2b93test",
        name: "Tour Home Card",
        address: {
            apartmentNumber: "",
            city: "Austin",
            obs: "",
            state: "Texas",
            street: "Tour St.",
            zip: "73301",
            number: "12"
        },
        active: true,
        association: [
            {
                user: "test",
                accountTypes: [
                    "Homeowner"
                ]
            }
        ],
        accountType: "Homeowner"
    }   
]
