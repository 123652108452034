import { RecipientsToShare, PanelHeader } from "@homefile/components";
import {
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    Box,
    Flex,
    Text,
  } from '@chakra-ui/react'
import { BlueFolderShared } from "../../../assets/images";
import { AssociatedAccountI } from "@homefile/components/dist/interfaces";

interface AddSharedAccountI {
    handleAdd: (recipient: AssociatedAccountI) => void
    handleClose: () => void
    handleShare: (recipient: string) => void
    recipients: AssociatedAccountI[]
}

const AddSharedAccount = ({handleAdd, handleClose, handleShare, recipients}: AddSharedAccountI) => {

    return (
        <DrawerContent bg="lightBlue.1">
          <DrawerHeader p="0">
            <PanelHeader
              handleCloseButton={handleClose}
              icon={BlueFolderShared}
              title={"Share Folder"}
            />
          </DrawerHeader>
          <DrawerBody p="0">
            <RecipientsToShare 
                onAddRecipient={handleAdd}
                onShare={handleShare}
                recipients={recipients}
            />

          </DrawerBody>
        </DrawerContent>
      )
}

export default AddSharedAccount