export const Button = {
  baseStyle: {
    fontFamily: 'primary',
    transition: 'all 0.2s ease-in-out',
    _focus: {
      boxShadow: 'none',
    },
  },
  sizes: {
    small: {
      w: 'button.sm',
    },
    medium: {
      w: 'button.md',
    },
    large: {
      w: 'button.lg',
    },
    full: {
      w: 'button.full',
    },
    onboarding: {
      w: ['button.full', 'button.md'],
    },
  },
  variants: {
    primary: {
      fontSize: 'md',
      textTransform: 'uppercase',
      h: 'button.xs',
      fontWeight: 'regular',
      borderRadius: 'none',
      boxShadow: 'base',
      color: 'neutral.white',
      backgroundColor: 'blue.2',

      _disabled: {
        filter: 'grayscale(100%)',
        pointerEvents: 'none',
      },
      _active: {
        backgroundColor: 'blue.3',
        borderRadius: 'lg',
        fontWeight: 'medium',
        boxShadow: 'none',
      },
      _hover: {
        boxShadow: 'xl',
      },
    },
    secondary: {
      fontSize: 'md',
      textTransform: 'uppercase',
      h: 'button.xs',
      px: '1rem',
      fontWeight: 'regular',
      borderRadius: 'none',
      boxShadow: 'base',
      backgroundColor: 'transparent',
      border: '1px',
      borderColor: 'blue.2',
      color: 'blue.2',
      _active: {
        backgroundColor: 'lightBlue.3',
        borderRadius: 'lg',
        fontWeight: 'medium',
        boxShadow: 'none',
      },
      _hover: {
        backgroundColor: 'lightGreen.3',
        boxShadow: 'lg',
      },
      _disabled: {
        filter: 'grayscale(100%)',
        pointerEvents: 'none',
      },
    },
    tertiary: {
      fontSize: 'md',
      w: 'fit-content',
      px: '1rem',
      fontWeight: 'regular',
      borderRadius: 'none',
      boxShadow: 'base',
      backgroundColor: 'neutral.white',
      border: '1px',
      borderColor: 'blue.2',
      color: 'blue.2',
      _active: {
        backgroundColor: 'lightBlue.3',
        borderRadius: 'lg',
        fontWeight: 'medium',
        boxShadow: 'none',
      },
      _hover: {
        backgroundColor: 'lightGreen.3',
        boxShadow: 'lg',
      },
      _disabled: {
        filter: 'grayscale(100%)',
        pointerEvents: 'none',
      },
    },
    iconOutlined: {
      maxW: '3rem',
      borderRadius: 'sm',
      boxShadow: 'base',
      backgroundColor: 'neutral.white',
      border: '1px',
      borderColor: 'blue.2',
      color: 'blue.2',
      _active: {
        backgroundColor: 'lightBlue.3',
        boxShadow: 'none',
      },
      _hover: {
        backgroundColor: 'lightGreen.3',
        boxShadow: 'lg',
      },
      _disabled: {
        filter: 'grayscale(100%)',
        pointerEvents: 'none',
      },
    },
    icon: {
      color: 'lightBlue.6',
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      _hover: {
        backgroundColor: 'transparent',
        color: 'blue.2',
      },
      _focus: {
        backgroundColor: 'transparent',
      },
    },
    displayIcon: {
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      _hover: {
        filter: 'drop-shadow(2px 2px 8px rgb(0 0 0 / 0.3))',
      },
    },
    menuIcon: {
      color: 'blue.2',
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      _hover: {
        backgroundColor: 'transparent',
        color: 'blue.3',
      },
      _focus: {
        backgroundColor: 'transparent',
      },
      _disabled: {
        filter: 'grayscale(100%)',
      },
    },
    menuIconWithShadow: {
      color: 'blue.2',
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: 'none',
      _hover: {
        backgroundColor: 'transparent',
        color: 'blue.3',
        boxShadow: 'lg',
      },
      _focus: {
        backgroundColor: 'transparent',
      },
    },
    folder: {
      color: 'blue.2',
      fontWeight: 'regular',
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: 'none',
      _disabled: {
        pointerEvents: 'none',
        filter: 'grayscale(100%)',
      },
      _hover: {
        backgroundColor: 'neutral.white',
        color: 'blue.3',
        boxShadow: 'lg',
        transform: 'scale(1.05)',
      },
      _focus: {
        backgroundColor: 'transparent',
      },
    },
    text: {
      w: 'button.max',
      fontFamily: 'secondary',
      fontSize: 'md',
      fontWeight: 'regular',
      color: 'blue.2',
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      _hover: {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        textUnderlineOffset: '2px',
        color: 'blue.3',
      },
      _focus: {
        backgroundColor: 'transparent',
      },
    },
    leftRounded: {
      overflow: 'hidden',
      gap: '10px',
      lineHeight: '30px',
      fontSize: 'lg',
      fontWeight: 'regular',
      borderRightRadius: 'none',
      borderLeftRadius: 'full',
      boxShadow: 'base',
      color: 'neutral.white',
      backgroundColor: 'blue.2',
      _disabled: {
        filter: 'grayscale(100%)',
        pointerEvents: 'none',
      },
      _active: {
        backgroundColor: 'blue.3',
        boxShadow: 'none',
      },
      _hover: {
        boxShadow: 'xl',
      },
    },
    rightRounded: {
      fontSize: 'md',
      textTransform: 'uppercase',
      h: 'button.xs',
      pr: '3rem',
      fontWeight: 'semibold',
      borderRightRadius: 'full',
      borderLeftRadius: 'none',
      boxShadow: 'base',
      color: 'neutral.white',
      backgroundColor: 'blue.2',
      _disabled: {
        filter: 'grayscale(100%)',
        pointerEvents: 'none',
      },
      _active: {
        backgroundColor: 'blue.3',
        boxShadow: 'none',
      },
      _hover: {
        boxShadow: 'xl',
      },
    },
    menu: {
      fontSize: 'md',
      textTransform: 'uppercase',
      h: 'button.xs',
      fontWeight: 'regular',
      borderRadius: 'none',
      boxShadow: 'base',
      color: 'neutral.white',
      backgroundColor: 'blue.2',
      _disabled: {
        filter: 'grayscale(100%)',
        pointerEvents: 'none',
      },
      _active: {
        backgroundColor: 'blue.3',
        borderTopRadius: 'md',
        fontWeight: 'medium',
        boxShadow: 'none',
      },
      _hover: {
        boxShadow: 'xl',
      },
    },
    primaryFooter: {
      minW: ['fit-content', '7.5rem'],
      maxW: 'fit-content',
      px: '1rem',
      fontSize: 'md',
      textTransform: 'uppercase',
      h: 'button.xxs',
      fontWeight: 'semibold',
      borderRadius: 'none',
      boxShadow: 'base',
      color: 'neutral.white',
      backgroundColor: 'blue.2',
      _disabled: {
        filter: 'grayscale(100%)',
        pointerEvents: 'none',
      },
      _active: {
        backgroundColor: 'blue.3',
        borderRadius: 'lg',
        fontWeight: 'medium',
        boxShadow: 'none',
      },
      _hover: {
        boxShadow: 'xl',
      },
    },
    secondaryFooter: {
      minW: ['fit-content', '6.25rem'],
      maxW: 'fit-content',
      fontSize: 'md',
      h: 'button.xxs',
      px: '1rem',
      fontWeight: 'regular',
      borderRadius: 'none',
      boxShadow: 'base',
      backgroundColor: 'transparent',
      border: '1px',
      borderColor: 'blue.2',
      color: 'blue.2',
      _active: {
        backgroundColor: 'lightBlue.3',
        borderRadius: 'lg',
        fontWeight: 'medium',
        boxShadow: 'none',
      },
      _hover: {
        backgroundColor: 'lightGreen.3',
        boxShadow: 'lg',
      },
      _disabled: {
        filter: 'grayscale(100%)',
        pointerEvents: 'none',
      },
    },
    tertiaryFooter: {
      minW: ['fit-content', '6.25rem'],
      maxW: 'fit-content',
      px: '1rem',
      fontSize: 'md',
      textTransform: 'capitalize',
      h: 'button.xxs',
      fontWeight: 'regular',
      borderRadius: 'none',
      boxShadow: 'base',
      color: 'neutral.white',
      backgroundColor: 'blue.2',
      _disabled: {
        filter: 'grayscale(100%)',
        pointerEvents: 'none',
      },
      _active: {
        backgroundColor: 'blue.3',
        borderRadius: 'lg',
        fontWeight: 'medium',
        boxShadow: 'none',
      },
      _hover: {
        boxShadow: 'xl',
      },
    },
    tour: {
      fontSize: 'md',
      fontFamily: 'secondary',
      py: '2',
      px: '4',
      w: 'fit-content',
      rounded: 'full',
      fontWeight: 'regular',
      boxShadow: 'base',
      color: 'neutral.white',
      backgroundColor: 'green.1',

      _disabled: {
        filter: 'grayscale(100%)',
        pointerEvents: 'none',
      },
      _hover: {
        boxShadow: 'xl',
      },
    },
    banner: {
      fontSize: 'sm',
      fontFamily: 'primary',
      fontWeight: 'semibold',
      color: 'blue.8',
      px: '6',
      w: 'fit-content',
      h: 'button.xxs',
      backgroundColor: 'neutral.white',
      borderRadius: '3px',

      _hover: {
        backgroundColor: 'lightGreen.3',
      },
    },
  },
  defaultProps: {
    size: 'full',
    variant: 'primary',
  },
}

export default Button
