const colors: string[] = [
    '#4CC35A',
    '#442B48',
    '#726E60',
    '#98B06F',
    '#B6DC76',
    '#5DD39E',
    '#348AA7',
    '#525074',
    '#513B56',
    '#C5EBC3',
    '#B7C8B5',
    '#A790A5',
    '#875C74',
    '#4A2140',
    '#EC9DED',
    '#F7717D',
    '#7F2A82',
    '#ABE188',
    '#F1BB87',
    '#F78D69',
    '#5D675B',
    '#5D675B',
    '#91AEC1',
    '#508CA4',
    '#0E8754',
    '#61E294',
    '#7BCDBA',
    '#9799CA',
    '#BD93D8',
    '#B47AEA',
    '#DEB841',
    '#DE9E35',
    '#37323E',
  ]
  
  export const randomColor = () => {
    const maxInt = colors.length
    const randomInt = Math.floor(Math.random() * maxInt)
    const color: string = colors[randomInt]
    return color
  }