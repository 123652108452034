import {useEffect, useState} from 'react'
import { 
    useGetNewUserNotificationsQuery,
    useGetUserNotificationsByTypeQuery,
    useUpdateNotificationToReadMutation
} from "../features/api";
import {useCustomToast} from '@homefile/components'
import { ResponseType } from '../interfaces/DataInterfaces';

export const useNotifications = () => {
    const [simpleNotifications, updateSimpleNotifications] = useState<any[]>([])
    const [messages, updateMessages] = useState<any[]>([])
    const {data} = useGetNewUserNotificationsQuery(null)
    const [updateToRead] = useUpdateNotificationToReadMutation()
    const [type, updateType] = useState("")
    const [skip, updateSkip] = useState(true)
    const {data: notifications} = useGetUserNotificationsByTypeQuery(type, {skip})
    const toast = useCustomToast()

    useEffect(() => {
        if (data) {
            const simple: any[] = data.filter((notification: any) => notification.type === "simple")
            updateSimpleNotifications(simple)

            const messages: any[] = data.filter((notification: any) => notification.type === "service")
            updateMessages(messages)
        }
    }, [data])

    useEffect(() => {
        if (type !== "") {
            updateSkip(false)
        }
    }, [type])

    const markRead = async (id: string) => {

        const updateNotification: ResponseType = await updateToRead(id)

        if (updateNotification.error) {
            toast({
                title: 'Something Went Wrong',
                description: "Please try again later",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top-right"
            })
        }
    }

    return {
        simpleNotifications,
        messages,
        notifications,
        updateType,
        markRead
    }
}