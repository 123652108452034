export const Drawer = {
  sizes: {
    md: {
      dialog: {
        maxWidth: '500px',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
}

export default Drawer
