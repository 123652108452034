import { Flex, Box, Stack } from "@chakra-ui/react";
import { HomeInterface, UserAssociation } from "../../interfaces/HomeInterfaces";
import { useGetHomesQuery, useArchiveHomeMutation } from "../../features/api";
import { useState, useEffect, ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setDrawer, clearDrawer } from "../../features/drawer/drawerSlice";
import { setHome, clearHome } from "../../features/home/homeSlice";
import { MyHomes, ReceiptAutofiler, LaunchpadAutofilerBanner, LaunchpadReceiptAutofiler } from "@homefile/components";
import { MyHomeMenuItems } from "../../helpers/launchpad/MenuItems.helper";
import { MyHomeFilters } from "../../helpers/myHomes";
import { useNavigate } from "react-router-dom";
import DeleteHomeDialog from "../../components/Dialogs/DeleteHomeDialog";
import { ResponseType } from "../../interfaces/DataInterfaces";
import { openDialog } from "../../features/dialog/dialogSlice";
import { RootState } from "../../app/store";
import { useFileUpload } from "../../hooks/useFileUpload";
import { HomeImageI } from "@homefile/components/dist/interfaces/myHomes/MyHomes.interface";
import { LaunchpadTour, useCustomToast } from "@homefile/components";
import { useUpdateUserTour } from "../../hooks/useUpdateUserTour";
import { setLaunchpad } from "../../features/tour/tourSlice";
import {UserState} from '../../features/auth/authSlice'
import { SelectItemI } from "@homefile/components/dist/interfaces/inputs/Select.interface";
import { ActiveHomeI } from "@homefile/components/dist/interfaces";
import SubscriptionModule from "../../components/Modules/SubscriptionModule";
import { useLaunchPadReceipts } from "../../hooks/useLaunchPadReceipts";
import { TourHomeCard } from '../../util/TourHomeCard';

const MainHome = (user: UserState) => {


  const { data } = useGetHomesQuery(null);
  const [homeList, updateHomes] = useState<HomeInterface[]>(
    [] as HomeInterface[]
  );
  const [filteredHomeList, updateFilteredHomes] = useState<HomeInterface[]>(
    [] as HomeInterface[]
  );
  const {isOpen} = useAppSelector((state: RootState) => state.drawer)
  const [inactiveHomes, updateInactiveHomes] = useState<any[]>([])
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state: RootState) => state.auth.user._id);
  let navigate = useNavigate();
  const [deleteHome] = useArchiveHomeMutation()
  const [homeId, updateHomeId] = useState("")
  const [deleteModal, updateDeleteModal] = useState(false)
  const toast = useCustomToast()
  const {handleNewHomeImage, fileUploading, selectedId} = useFileUpload()
  const [activeTour, updateActiveTour] = useState(false)
  const {updateTour} = useUpdateUserTour()
  const launchpadTourState = useAppSelector((state: RootState) => state.tour.launchpadTour);
  const {receipts} = useLaunchPadReceipts("")
  const [loading, updateLoading] = useState(true)
  const [receiptBanner, updateReceiptBanner] = useState<ReactNode>(<></>)
  const [hasOpened, updateHasOpened] = useState(false)
  const [currentStep, setCurrentStep] = useState<string>("")
  // intercom 
  useEffect(() => {
    if (user.firstName) {
      window.Intercom("boot", {
        api_base: process.env.REACT_APP_INTERCOM_API_BASE,
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        name: `${user.firstName} ${user.lastName}`, // Full name
        email: user.email, // the email for your user 
      });
    }
  }, [user])

  const handleStep = (step: string) => {
    setCurrentStep(step)
    if (step === "addressLines") {
        dispatch(setDrawer({ location: "left", content:'AddHome', size: "md"}))
    } else if (step === "homeCard") {
        dispatch(clearDrawer())
    }
  }

  const handleClose = () => {
    updateTour("launchpad")
    dispatch(setLaunchpad({launchpadTour: false}))
  }

  const handleClickDelete = (home: ActiveHomeI) => {
    updateHomeId(home.id)
    updateDeleteModal(true)
  }

  const MenuItems = [
    {
      label: 'Edit Home',
      handleClick: ({_id, name}: SelectItemI) => {
       dispatch(setHome({ id: _id, name }));
       dispatch(setDrawer({ location: "right", content: "ViewHome", size: "md" }));
      },
    },
    {
      label: 'Delete Home',
      handleClick: ({_id}: SelectItemI) => {
        updateHomeId(_id)
        updateDeleteModal(true)
       }
    },
  ]

  const handleDeleteHome = async (id: string) => {
    const removeHome: ResponseType = await deleteHome(id)

    updateDeleteModal(false)

    if (removeHome.data) {
      toast({
        title: 'Success',
        description: `Home Removed`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: "top-right"
      })

    } else {
      dispatch(openDialog({message: removeHome.error.data.message}))
    }
  }

  const handleCloseDeleteModal = () => {
    updateDeleteModal(false)
  }

  const handleClick = ({_id, name}: {_id: string | number, name: string}) => {
    dispatch(setHome({ id: _id, name }));
    navigate("/homes/base")
  };

  const newHome = () => {
    dispatch(setDrawer({ location: "left", content: "AddHome", size: "md" }));
  };


  useEffect(() => {
    dispatch(clearHome())
  }, [])

  useEffect(() => {
    if (receipts.length > 0) {
      updateReceiptBanner(<LaunchpadAutofilerBanner onClick={() => updateReceiptBanner(<></>)} message="Due to having multiple homes, you must associate your receipts to a single home."/>)
    }  else {
      updateReceiptBanner(<></>)
    }
  }, [receipts])

  const addImage = (props: HomeImageI) => {
    const {event, _id} = props;
    handleNewHomeImage(event, _id)

  };

  const sortCompare = (a: HomeInterface, b: HomeInterface) => {
    if (a.accountType === "Homeowner") {
      return -1
    } else if (a.accountType === "Manager" && b.accountType !== "Homeowner") {
      return -1
    } else if (a.accountType === "Contributor" && (b.accountType !== "Homeowner" && b.accountType !== "Manager")) {
      return -1
    } else if (a.accountType === "Member" && b.accountType === "Member") {
      return -1
    } else {
      return 1
    }
  }

  const sortHomes = (homes: HomeInterface[]) => {
    homes.sort(sortCompare)
    updateHomes(homes);
    updateFilteredHomes(homes)
  }

  useEffect(() => {
    if (data && data.status !== 400) {

      const activeHomes = data.filter((home: HomeInterface) => home.active).map((activeHome: HomeInterface) => {
        activeHome.association?.forEach((a: UserAssociation) => {
          if (a.user === userId) {
            activeHome = {...activeHome, accountType: a.accountTypes[0]}
          }
        })
        return activeHome
      })
      sortHomes(activeHomes)

      if (user.showTour && !isOpen) {
        if (data.filter((home: HomeInterface) => home.active).length < 1 && !hasOpened) {
          updateHasOpened(true)
          dispatch(setDrawer({ location: "left", content: "AddHome", size: "md" }));
        } else if (data.filter((home: HomeInterface) => home.active).length < 2 && user.showTour.launchpad) {
          updateActiveTour(true)
        } else if (data.filter((home: HomeInterface) => home.active).length < 2 && !user.showTour.launchpad) {
          updateActiveTour(false)
        } 
      }

      const removedHomes = data.filter((home: HomeInterface) => !home.active)

      if (removedHomes.length > 0) {
        updateInactiveHomes(removedHomes)
      } else {
        updateInactiveHomes([])
      }
      updateLoading(false)
    } 
  }, [data, dispatch, user, isOpen]);

  const openReceipts = () => {
    dispatch(setDrawer({ location: "right", content: "ReceiptList", size: "md", data: {homes: homeList, onLaunchpad: true} }));
  }

  const handleFilterClick = (form: SelectItemI | string) => {
    if (form !== "view all") {
      const activeHomes = homeList.filter((home: HomeInterface) => home.accountType?.toLocaleLowerCase() === form)
      updateFilteredHomes(activeHomes)
    } else {
      updateFilteredHomes(homeList)
    }
  } 

  return (
    <Flex>
      <Flex
            direction={['column', 'row']}
            gap="base"
            w="full"
            px={[0, 'base']}
          >
        <Box w={['full', null, '60%', '100%']}>
          <MyHomes
            handleFilterClick={handleFilterClick}
            cardList={(filteredHomeList.length < 1 && (launchpadTourState || activeTour)) ? TourHomeCard : filteredHomeList}
            cardMenuItems={MenuItems}
            handleCardClick={({_id, name}) => {
              handleClick({_id, name});
            }}
            handleNewHomeClick={newHome}
            headerMenuItems={MyHomeMenuItems}
            addImage={addImage}
            loading={fileUploading}
            loadingId={selectedId}
          />
        </Box>
        <Box w={['full', null, '40%', 'auto']} minW={['full', '20rem']}>
          <Stack spacing="base">
            {
              (homeList.length > 1 || (launchpadTourState || activeTour)) && (
                <LaunchpadReceiptAutofiler 
                onClick={openReceipts}
                totalReceipts={receipts.length}
                totalTitle="Receipts to be Assigned"
                />
              )
            }
            <SubscriptionModule deleteLoading={loading} deletedLength={inactiveHomes.length} homeStorage={0} onLaunchpad={true} handleDelete={handleClickDelete} />
          </Stack>
        </Box>
      </Flex>
      <LaunchpadTour 
        handleClose={handleClose} 
        handleStep={handleStep} 
        isActive={activeTour || launchpadTourState} 
        currentStep={currentStep} 
        meetStepUrl="https://storage.googleapis.com/homefile-videos/Launchpad_Overview.mp4"
        />
      <DeleteHomeDialog deleteHome={handleDeleteHome} homeId={homeId} openModal={deleteModal} closeModal={handleCloseDeleteModal} />
    </Flex>
  );
};

export default MainHome;