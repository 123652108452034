import { useDeleteReportMutation, useUpdateProgressReportMutation, useUpdateReportFolderMutation, useGetHomeFoldersQuery, useGetFileUploadQuery } from "../features/api";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { RootState } from "../app/store";
import {useState, useEffect} from 'react';
import { useGetUserData } from "./useGetUserData";
import { ResponseType } from '../interfaces/DataInterfaces';
import { useDisclosure } from "@chakra-ui/react";
import { openDialog } from "../features/dialog/dialogSlice";
import { AssociatedAccountI } from "@homefile/components/dist/interfaces/shareHome/ShareHome.interface";
import { setDrawer } from "../features/drawer/drawerSlice";
import { useGetDefaultConfig } from "./useGetDefaultConfig";
import { SelectItemI, SelectFiltersFormI, FileInfoI, FileCallbackI } from "@homefile/components/dist/interfaces";
import {useCreateReceiptReport} from './useCreateReceiptReport'
import {useCustomToast} from '@homefile/components'
import {setPanel, setStep} from "../features/assistant/assistantSlice"
import {useHomeAssistant} from './useHomeAssistant'

export const useFileDetails = (fileList: FileInfoI[]) => {
    const dispatch = useAppDispatch()
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const {data: folderData} = useGetHomeFoldersQuery(homeId)
    const [signedId, updateSignedfId] = useState("")
    const [skipPdf, updateSkipPdf] = useState(true)
    const {data: pdfData} = useGetFileUploadQuery(signedId, {skip: skipPdf})
    const [editFile] = useUpdateProgressReportMutation()
    const [deleteFile] = useDeleteReportMutation()
    const [updateFolder] = useUpdateReportFolderMutation()
    const [addedUser, updateUser] = useState("")
    const [featuredFile, updateFile] = useState({} as FileInfoI)
    const { isOpen, onToggle } = useDisclosure();
    const toast = useCustomToast()
    const [deleteFileId, updateDeleteFileId] = useState("")
    const [deleteModal, updateDeleteModal] = useState(false)
    const [editing, updateEditing] = useState(false)
    const {defaultConfig} = useGetDefaultConfig()
    const {
        isOpen: isRightOpen,
        onOpen: onRightOpen,
        onClose: onRightClose,
      } = useDisclosure()
    const [moveId, updateMoveId] = useState("")
    const [firstFilterItems, updateFirstFilterItems]= useState<SelectItemI[] | string[]>(["Folders"])
    const [firstInitialFilter, updateFirstInitialFilter]= useState("Folders")
    const [secondFilterItems, updateSecondFilterItems]= useState<SelectItemI[] | string[]>([])
    const [secondInitialFilter, updateSecondInitialFilter]= useState("Select")
    const [showModal, setShowModal] = useState<boolean>(false)
    const {stepMapping} = useHomeAssistant()
    const handleCloseModal = () => setShowModal(false)

    const handleOpenModal = (file: FileCallbackI ) => {
        updateMoveId(file._id)
        setShowModal(true)
    }

    const {updateReceiptItem} = useCreateReceiptReport()
    const {userData} = useGetUserData(addedUser)

    useEffect(() => {
        if (folderData) {
            updateSecondFilterItems(folderData)
        }
    }, [folderData])

    useEffect(() => {
        if (signedId.length > 0) {
            updateSkipPdf(false)
        }
    }, [signedId])

    useEffect(() => {
        if (pdfData && pdfData.length > 0) {
            const src = pdfData[0]
            window.open(src)
        }
    }, [pdfData])

    const handleAddRecipient = (account: AssociatedAccountI) => {
        // addRecipient({
        //     report: sendId,
        //     home: homeId,
        //     email: account.user.email
        // })
    }

    const handleDeleteRecipient = (email: string) => {
        // const recipient = recipients.filter((account: AssociatedAccountI) => account.user.email === email)
        // const recipientId = recipient[0].user._id
        // removeRecipient({user: recipientId, report: sendId})
    }

    const handleEditFile = async (file: FileInfoI) => {
        updateEditing(true)
        const sendData = {
            report: file.report,
            title: file.title,
            description: file.description,
            home: homeId,
            id: file._id
        }

        const updatedReportResponse: ResponseType = await editFile(
            sendData
        );
        if (updatedReportResponse.data) {
            updateEditing(false)
            toast({
                title: 'Success',
                description: `Details Updated`,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top-right"
              })
        } else {
            updateEditing(false)
            console.log("update fail",updatedReportResponse);
            dispatch(openDialog({message: updatedReportResponse.error.data.message}))
        }
    }


    const handleFileClick = (file: FileInfoI) => {
        const selectedFile: FileInfoI = fileList.filter((fileListFile: FileInfoI) => fileListFile._id === file._id)[0]
        updateFile(selectedFile)
        onRightOpen()
        if (selectedFile.user) {
            updateUser(selectedFile.user)
        }
    }


    const handleDelete = (file: FileInfoI) => {
        updateDeleteFileId(file._id)
        updateDeleteModal(true)
    }

    const handleRoomDelete = (id: string) => {
        updateDeleteFileId(id)
        updateDeleteModal(true)
    }

    const handleCloseDeleteModal = () => {
        updateDeleteModal(false)
    }

    const deleteFileCallback = async (id: string) => {
        const fileDeleted: ResponseType = await deleteFile(id)

        if (fileDeleted.data) {
            toast({
                title: 'Success',
                description: `File Deleted`,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top-right"
              })
              updateDeleteModal(false)
        } else {
            console.log('delete failed', fileDeleted)
            dispatch(openDialog({message: fileDeleted.error.data.message}))
        }
    }

    const mapOldItem = (file: FileInfoI) => {
        if (file.report[0].id === undefined) {
            let counter = 1
            const mappedReport = file.report.map((field: any) => {
                const randomId = Math.random().toString(36).substring(2) + counter
                counter += 1
                return {...field, id: randomId}
            })
            return {...file, report: mappedReport}
        } else {
            return file
        }
    }

    const mapStep = (name: string) => {
        if (stepMapping[name] !== undefined) {
            return stepMapping[name]
        } else {
            return 1
        }
    }

    const handleOpenFile = async (file: FileInfoI) => {
        if (file.type === defaultConfig.config.constructionTypeId) {
            updateFile(file)
            onToggle()
        } else if (file.type === defaultConfig.config.householdItemTypeId || (file.type === defaultConfig.config.receiptItemTypeId && file.report !== undefined && file.report !== null)) {
            if (file.report.length === 9) {
                const mappedFile = mapOldItem(file)
                dispatch(setDrawer({ location: "right", content:'CreateHouseholdItem', size: "md", data: mappedFile}))
            } else {
                dispatch(setDrawer({ location: "right", content:'CreateHouseholdItem', size: "md", data: file}))
            }
        } else if (file.type === defaultConfig.config.assistantTypeId) {
            const step = mapStep(file.title)
            dispatch(setPanel({currentPanel: step}))
            dispatch(setStep({currentStep: step}))
            dispatch(setDrawer({ location: "right", content:'HomeAssistant', size: "md"}))
        } else if (file.type === defaultConfig.config.noteTypeId) {
            if (file.report[0].id === undefined) {
                const mappedFile = mapOldItem(file)
                dispatch(setDrawer({ location: "right", content:'CreateItem', size: "md", data: {completedForm: mappedFile, formName: "Note", formType: file.type}}))
            } else {
                dispatch(setDrawer({ location: "right", content:'CreateItem', size: "md", data: {completedForm: file, formName: "Note", formType: file.type}}))
            }
        } else if (file.type === defaultConfig.config.receiptItemTypeId) {
            // opem receipt item here
            const updatedFile = await updateReceiptItem(file)
            if (updatedFile.report) {
                dispatch(setDrawer({ location: "right", content:'CreateHouseholdItem', size: "md", data: updatedFile}))
            }
        } else if (file.files !== undefined) {
            const firstFile = file.files[0]
            // need to confirm if videos bucket can be private
            // (firstFile.extension === "pdf" ||(firstFile.extension === "mp4" || firstFile.extension === "mv"))
            if (firstFile.extension === "pdf") {
                updateSignedfId(firstFile._id)
            } else {
                const { REACT_APP_STORAGE_URL: storageUrl } = process.env
                const imageUrl = `${storageUrl}/${firstFile?.bucketName}/${firstFile?.fileName}.${firstFile?.extension}`
                window.open(imageUrl)
            }
        } 
    }

    const closeDocument = () => {
        onToggle()
    }

    const handleSaveFolderMove = async (form: SelectFiltersFormI) => {

        if (typeof form.secondOption === "object") {
            const updateBody = {
                report: moveId,
                destinationFolder: form.secondOption._id
            }
    
            const folderUpdate: ResponseType = await updateFolder(updateBody)
    
            if (folderUpdate.data) {
                toast({
                    title: 'Success',
                    description: `Item Moved`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: "top-right"
                })
                setShowModal(false)
            } else {
                toast({
                    title: 'Error',
                    description: `Something went wrong`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: "top-right"
                  })
            }
        } else {
            toast({
                title: 'Error',
                description: `Select A Destination Folder`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top-right"
              })
        }
    }

    const onFirstFilterClick = () => {}

    return {
        handleAddRecipient,
        handleDeleteRecipient,
        handleEditFile,
        handleFileClick,
        handleDelete,
        handleOpenFile,
        userData,
        featuredFile,
        closeDocument,
        isOpen,
        deleteFileId,
        deleteFileCallback,
        deleteModal,
        handleCloseDeleteModal,
        editing,
        onRightClose,
        onRightOpen,
        isRightOpen,
        firstFilterItems,
        firstInitialFilter,
        secondFilterItems,
        secondInitialFilter,
        handleSaveFolderMove,
        onFirstFilterClick,
        showModal,
        handleCloseModal,
        handleOpenModal,
        handleRoomDelete,
        mapOldItem
    }
}
