import { useState, useEffect } from "react";
import {MessagePanel, MessageChatPanel, RightPanel} from '@homefile/components';
import {InboxMessageI} from '@homefile/components/dist/interfaces'
import {useNotifications} from "../../../hooks/useNotifications"
import {useMessages} from "../../../hooks/useMessages"
import { create } from "domain";

const MessagesDrawer = ({ handleClose }: { handleClose: () => void }) => {
    const {updateType, notifications, markRead} = useNotifications()
    const {messages, mapMessages, markViewed, createNote} = useMessages()
    const [openChatPanel, updateOpenChatPanel] = useState(false)
    const [selectedMessage, updateSelectedMessage] = useState<InboxMessageI | undefined>(undefined)

    useEffect(() => {
        updateType("service")
    }, [])

    useEffect(() => {
        if (notifications) {
            mapMessages(notifications)
        }
    }, [notifications])

    useEffect(() => {
        if (selectedMessage !== undefined) {
            updateOpenChatPanel(true)
        }
    }, [selectedMessage])

    const handleReply = (message: any) => {
        createNote(message)
    }

    const onMessageClick = async (message: any) => {
        updateSelectedMessage(message)
        const selectedNotifcation = notifications.find((notification: any) => notification.docId === message._id)

        // this notification has been selected so mark as 'read'
        if (selectedNotifcation) {
            markRead(selectedNotifcation._id)
        }

        // update the notes 'viewed at'
        await markViewed(message._id)
    }

    const handleCloseChat = () => {
        updateOpenChatPanel(false)
        updateSelectedMessage(undefined)
    }

    useEffect(() => {
        if (selectedMessage !== undefined) {
            const newSelected = messages.find((message: InboxMessageI) => message._id === selectedMessage._id)
            if (newSelected) {
                updateSelectedMessage(newSelected)
            }
        }
    }, [messages])

    return (
        <>
            <MessagePanel
                messages={messages}
                onClick={onMessageClick}
                onClose={handleClose}
            />
            <RightPanel isOpen={openChatPanel} onClose={handleCloseChat}>
                <MessageChatPanel 
                    message={selectedMessage}
                    onReply={handleReply}
                    onBack={handleCloseChat}
                    onClose={handleCloseChat}
                />
            </RightPanel>
        </>
    )
}

export default MessagesDrawer