import { homefileApi } from './emptySplitApi'

const paymentApi = homefileApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserCards: builder.query({
            query: () => "api/credit-card",
            providesTags: ['Payment']
        }),
        getTempUserCards: builder.query({
            query: (token: string) => ({
                url: "api/credit-card",
                headers: {
                    "authorization": `Bearer ${token}`,
                  }
            }),
            providesTags: ['Payment']
        }),
        createUserCard: builder.mutation({
            query: (data: any) => ({
                url: "/api/credit-card",
                method: "POST",
                body: data
            }),
            invalidatesTags: ["Payment"],
        })
    })
})

export const {
    useGetUserCardsQuery,
    useCreateUserCardMutation,
    useGetTempUserCardsQuery
} = paymentApi
