import { createSlice } from "@reduxjs/toolkit";

interface DialogStateInterface {
    isOpen: boolean
    message: string
    paymentModalOpen: boolean
    paymentModalHeader: string
    paymentModalBody: string
}

const dialogInitialState: DialogStateInterface = {
    isOpen: false,
    message: "",
    paymentModalOpen: false,
    paymentModalHeader: "",
    paymentModalBody: "",
}

const dialogSlice = createSlice({
    name: "dialog",
    initialState: dialogInitialState,
    reducers: {
        openDialog: (state, action) => {
            state.isOpen = true;
            state.message = action.payload.message
        },
        closeDialog: (state) => {
            state.isOpen = dialogInitialState.isOpen;
            state.message = dialogInitialState.message
        },
        openPaymentDialog: (state, action) => {
            state.paymentModalOpen = true
            state.paymentModalHeader = action.payload.header
            state.paymentModalBody = action.payload.body
        },
        closePaymentDialog: (state) => {
            state.paymentModalOpen = false
            state.paymentModalHeader = ""
            state.paymentModalBody = ""
        }
    }
})

export const {openDialog, closeDialog, openPaymentDialog, closePaymentDialog } = dialogSlice.actions;
export default dialogSlice.reducer;