import AppRoutes from "./routes";
import { useRoutes, useLocation } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import {useEffect} from 'react'

const RenderRoutes = () => {
  const location = useLocation()
  useEffect(() => {
    window.Intercom("update");
  }, [location.pathname])
  
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const routing = useRoutes(AppRoutes(isAuthenticated));

  return <>{routing}</>;
};

export default RenderRoutes;
