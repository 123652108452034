import { useGetReceiptsWithoutHomeQuery, useUpdateHomeReceiptsMutation } from "../features/api";
import { useEffect, useState } from "react";

export const useLaunchPadReceipts = (id: string) => {

    const [receipts, updateReceipts] = useState<any[]>([])
    const [skip, updateSkip] = useState(true)
    const {data} = useGetReceiptsWithoutHomeQuery(null, {skip})
    
    useEffect(() => {
        if (id === "") {
            updateSkip(false)
        } else {
            updateSkip(true)
        }
    }, [id])

    useEffect(() => {
        if (data) {
            updateReceipts(data as any[])
        }
    }, [data])

    return {
        receipts
    }
}