import { Slide, Box, Drawer, DrawerOverlay, useDisclosure} from '@chakra-ui/react';
import { useState, useEffect, ChangeEvent } from 'react';
import { ReceiptItems, ReceiptDetails, ReceiptHeader, ReceiptBody, ReceiptFooter, useCustomToast } from '@homefile/components';
import { useGetReceiptItemsQuery, useDeleteReportMutation, useUpdateReceiptMutation } from '../../../features/api';
import { ReceiptItemFeaturesI, ReportsI } from '@homefile/components/dist/interfaces';
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { clearDrawer } from "../../../features/drawer/drawerSlice";
import { RootState } from "../../../app/store";
import { useGetHouseholdItemSubTypes } from '../../../hooks/useGetHouseholdItemSubTypes';
import { useGetHomeRooms } from '../../../hooks/useGetHomeRooms';
import { useHomeBoardReceipts } from '../../../hooks/useHomeBoardReceipts';
import { useUpdateItem } from '../../../hooks/useUpdateItem';
import { Price, HomeDepot, Lowes } from '../../../assets/images';
import GenericDialog from "../../Dialogs/GenericDialog";
import { ResponseType } from "../../../interfaces/DataInterfaces";
import {RoomItemI} from '@homefile/components/dist/interfaces/rooms/RoomsMenu.interface'
import { useNavigate} from "react-router-dom";
import {addRoomId} from '../../../features/room/roomSlice'


interface ReceiptListSliderI {
    back: () => void
    open: boolean
    receiptId: string
    receipt: any
    handleDeleteReceipt: (id:string) => void
}

const HomeboardReceiptListSlider = ({back, open, receiptId, receipt, handleDeleteReceipt}: ReceiptListSliderI) => {
    const [skip, updateSkip] = useState(true)
    const {data} = useGetReceiptItemsQuery(receiptId, {skip})
    const [items, updateItems] = useState<ReceiptItemFeaturesI[]>([])
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const {itemSubTypes} = useGetHouseholdItemSubTypes(homeId)
    const {rooms} = useGetHomeRooms(homeId)
    const [deleteFile] = useDeleteReportMutation()
    const [openDeleteDialog, updateOpenDeleteDialog] = useState(false)
    const [validReceipt, updateValidReceipt] = useState(false)
    const [updateSingleReceipt] = useUpdateReceiptMutation()
    const [receiptName, updateReceiptName] = useState("")
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        onLeftSelectChange,
        onRightSelectChange,
        onReceiptSelectedChange,
        onItemSelectedChange,
        selectedCategory,
        selectedRoom,
        selectedItems,
        buttonsDisabled
    } = useHomeBoardReceipts(homeId)
    const toast = useCustomToast()

    const successFunction = () => {

        toast({
            title: 'Selection Updated!',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: "top-right"
        })
    }

    useEffect(() => {
        if (receipt.purchaseDate !== undefined) {
            updateValidReceipt(true)
        }

        if (receipt.name) {
            updateReceiptName(receipt.name)
        } else {
            updateReceiptName("")
        }
    }, [receipt])

    useEffect(() => {
        onLeftSelectChange(rooms[0])
    }, [rooms])

    useEffect(() => {
        onRightSelectChange(itemSubTypes[0])
    }, [itemSubTypes])

    const {handleUpdate} = useUpdateItem(successFunction);

    useEffect(() => {
        if (receiptId !== "") {
            updateSkip(false)
        } else {
            updateSkip(true)
        }
    }, [receiptId])

    useEffect(() => {
        if (data && rooms.length > 0) {
            const dataItems: any[] = data as []
            let items: any = []

            dataItems.filter((item: any) => !item.deleted).forEach((item: any) => {
                if (item.room) {
                    const tRoom = rooms.find((room: RoomItemI) => item.room === room._id)
                    if (tRoom) {
                        items = [...items, {_id: item._id, title: item.title, icon: Price, roomType: tRoom.type, roomId: item.room, ...item.metadata}]
                    } else {
                        items = [...items, {_id: item._id, title: item.title, icon: Price, roomId: item.room, ...item.metadata}]
                    }
                } else {
                    items = [...items, {_id: item._id, title: item.title, icon: Price, ...item.metadata}]
                }
            })
            updateItems(items as ReceiptItemFeaturesI[])
        }
    }, [data, rooms])

    const handleDeleteItem = () => {
        if (selectedItems.length > 0) {
            selectedItems.forEach(async (item: string) => {
                const removeItem: ResponseType = await deleteFile(item)

                if (removeItem.data) {
                    toast({
                        title: 'Item Removed',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                        position: "top-right"
                    })
                    updateOpenDeleteDialog(false)
                } else {
                    toast({
                        title: 'Error, please try again',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                        position: "top-right"
                    })
                }
            })
        }
    }


    const onUpdateReceiptName = (e: ChangeEvent<HTMLInputElement>) => {
        updateReceiptName(e.target.value)
    }

    const onSaveReceipt = async () => {
        const sendData = {
            receiptId,
            name: receiptName
        }
        const updateReceiptName: ResponseType = await updateSingleReceipt(sendData)

                if (updateReceiptName.data) {
                    toast({
                        title: 'Name Updated!',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                        position: "top-right"
                    })
                    updateOpenDeleteDialog(false)
                } else {
                    toast({
                        title: 'Error, please try again',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                        position: "top-right"
                    })
                }
    }

    const handleUpdateItem = () => {
        if (selectedItems.length > 0) {
            selectedItems.forEach((item: string) => {
                const dataItems: ReportsI[] = data as []
                const targetData = dataItems.filter((dataItem: ReportsI) => dataItem._id === item)
                handleUpdate({
                    newReportId: item,
                    homeId,
                    reportTitle: targetData[0].title,
                    type: targetData[0].type,
                    subType: selectedCategory,
                    room: selectedRoom,
                    report: targetData[0].report || null
                })
            })

        } else {
            toast({
                title: 'Select Items First',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: "top-right"
            })
        }
    }

    const handleRoomClick = (id: string) => {
        dispatch(clearDrawer());
        dispatch(addRoomId({roomId: id}))
        const targetRoom = rooms.find((room: any) => room._id === id)
        if (targetRoom) {
            const name = targetRoom.name.replaceAll(" ", "");
            navigate(`/rooms/${name}`)
        }
    }

    return (
        <Slide direction="right" in={open} style={{ zIndex: 9999 }}>
            {
                validReceipt && (
                    <Box bg="lightBlue.1" h="100%" zIndex="dropdown" overflow="scroll">
                        <ReceiptHeader 
                            onClose={back}
                            value={receiptName}
                            onChange={onUpdateReceiptName}
                            onSave={onSaveReceipt}
                        >
                            <ReceiptDetails 
                                _id={receipt._id}
                                cashier=""
                                itemQuantity={receipt.itemQuantity}
                                paymentMethod={receipt.paymentMethod}
                                purchaseDate={receipt.purchaseDate}
                                tax={receipt.tax}
                                store={receipt.storeNumber}
                                receiptOrigin={receipt.receiptOrigin}
                                storePhone=""
                                total={receipt.total}
                            />
                        </ReceiptHeader>
                        <ReceiptBody>
                            <ReceiptItems 
                                isSelectDisabled={buttonsDisabled}
                                receipts={items}
                                rightOptions={itemSubTypes}
                                leftOptions={rooms}
                                onItemSelectedChange={onItemSelectedChange}
                                onLeftSelectChange={onLeftSelectChange}
                                onRightSelectChange={onRightSelectChange}
                                onReceiptSelectedChange={onReceiptSelectedChange}
                                onRoomClick={handleRoomClick}
                            />
                        </ReceiptBody>
                    </Box>
                )
            }
            <ReceiptFooter
                isDeleteItemDisabled={buttonsDisabled}
                isUpdateItemDisabled={buttonsDisabled}
                onDeleteItemClick={() => {updateOpenDeleteDialog(true)}}
                onDeleteReceiptClick={() => {handleDeleteReceipt(receiptId)}}
                onUpdateItemClick={handleUpdateItem}
            />
            <GenericDialog header={"Warning!"} body={"Delete selected item(s)?"} action={handleDeleteItem} close={() => {updateOpenDeleteDialog(false)}} openModal={openDeleteDialog} actionText={"Delete"} cancelText={"Cancel"}  />
        </Slide>
    )
}

export default HomeboardReceiptListSlider