import React, { useState, useEffect } from "react";
import { loadStripe, StripeElementsOptionsMode, Appearance } from "@stripe/stripe-js";
import { 
    Elements, 
 } from "@stripe/react-stripe-js";
import AddCreditCardForm from "./AddCreditCardForm"

const StripePaymentsModule = ({authToken, handleCardSuccess}: {authToken?: string, handleCardSuccess: () => void}) => {

    // Make sure to call loadStripe outside of a component’s render to avoid
    // recreating the Stripe object on every render.
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "");

    const appearance: Appearance = {
        theme: 'flat',
        variables: {
          fontFamily: ' "Gill Sans", sans-serif',
          fontLineHeight: '1.5',
          borderRadius: '10px',
          colorBackground: '#F6F8FA',
          colorPrimaryText: '#262626'
        },
        rules: {
          '.Block': {
            backgroundColor: 'var(--colorBackground)',
            boxShadow: 'none',
            padding: '12px'
          },
          '.Input': {
            padding: '12px',
            backgroundColor:"white"
          },
          '.Input:disabled, .Input--invalid:disabled': {
            color: 'lightgray'
          },
          '.Tab': {
            padding: '10px 12px 8px 12px',
            border: 'none'
          },
          '.Tab:hover': {
            border: 'none',
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
          },
          '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
            border: 'none',
            backgroundColor: '#fff',
            boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
          },
          '.Label': {
            fontWeight: '500'
          }
        }
      };
      
    
    const options: StripeElementsOptionsMode  = {
        currency: 'usd',
        mode: 'setup',
        appearance
    };

    return (
        <>
            {
                (stripePromise) && (
                    <Elements stripe={stripePromise} options={options}>
                        <AddCreditCardForm handleCardSuccess={handleCardSuccess} authToken={authToken} />
                    </Elements>
                )
            }
        </>
    )

}

export default StripePaymentsModule