import { useState, useEffect } from "react";
import {
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerFooter,
  Center,
  Drawer,
  DrawerOverlay,
  useDisclosure
} from "@chakra-ui/react";
import CreateDocumentTabs from "./CreateDocumentTabs";
import {PanelHeader, CreateDocumentHeader, DocumentNameHeader, FooterDrawer, FooterButtons, DocumentPreview, useCustomToast} from '@homefile/components';
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store";
import { clearDrawer } from "../../../features/drawer/drawerSlice";
import { ProgressReport } from "../../../assets/images";
import { t } from 'i18next';
import { useGetHomesQuery } from "../../../features/api";
import { SelectItemI } from "@homefile/components/dist/interfaces/inputs/Select.interface";
import { useCreateReport } from "../../../hooks/useCreateReport";
import { useSendReport } from "../../../hooks/useSendReport";
import { useUpdateItem } from "../../../hooks/useUpdateItem";
import { setHome } from "../../../features/home/homeSlice";
import { setSendDocument, clearDocumentState, setPreviewDocument, clearPreviewDocument, setEditDocument } from "../../../features/document/documentSlice";
import BeatLoader from "react-spinners/BeatLoader";
import {useUploadMedia} from '../../../hooks/useUploadMedia';
import { useLocation } from "react-router-dom";
import {CreateDocumentDrawerI} from '../../../interfaces/CreateDocumentInterfaces'

const CreateDocumentDrawer = (formData: CreateDocumentDrawerI) => {
  const dispatch = useAppDispatch();
  const [skipValue, updateSkip] = useState(true)
  const { data } = useGetHomesQuery(null, {skip: skipValue});
  const homeId = useAppSelector((state: RootState) => state.home.id)
  const homeName = useAppSelector((state: RootState) => state.home.name)
  const [initHome, updateInitHome] = useState<SelectItemI>({} as SelectItemI);
  const reportData = useAppSelector((state: RootState) => state.document.document)
  const sendData = useAppSelector((state: RootState) => state.document.sendDocumentInfo)
  const editData = useAppSelector((state: RootState) => state.document.editDocumentInfo)
  const previewData = useAppSelector((state: RootState) => state.document.previewDocumentInfo)
  const recipients = useAppSelector((state: RootState) => state.document.recipients)
  const isLoading = useAppSelector((state: RootState) => state.document.documentLoading);
  const dateString = new Date().toLocaleDateString();
  const toast = useCustomToast()
  const [homeList, updateHomes] = useState<SelectItemI[]>(
    [] as SelectItemI[]
  );
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isDashboard, updatePath] = useState(true)
  const {pathname} = useLocation()

  const successFunction = () => {
    dispatch(clearDocumentState())
    dispatch(clearDrawer())
  }

  const {reportTitle, newReportId} = useCreateReport({formId: formData._id, homeId, homeName, success: () => {}})
  const {images} = useUploadMedia({documentId: newReportId})
  const {handleSendReport} = useSendReport(successFunction);
  const {handleUpdate} = useUpdateItem(successFunction);

  const [documentName, updateName] = useState("")

  const handleSend = () => {
    if (recipients.length > 0) {
      dispatch(setSendDocument({send: true, edit: false}))
    } else {
      toast({
        title: 'Add Recipients to Send Document',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: "top-right"
      })
    }
  }

  const handleEdit = () => {
    dispatch(setEditDocument({edit: true, send: false}))
  }

  const handlePreview = () => {
    dispatch(setPreviewDocument({preview: true}))
  }

  useEffect(() => {
    if (pathname === "/dashboard") {
      updatePath(true)
      updateSkip(false)
    } else {
      updatePath(false)
      updateSkip(true)
    }
  }, [pathname])

  useEffect(() => {
    if (previewData && reportData) {
      onOpen()
    }
  }, [previewData])

  useEffect(() => {
    if (reportData && sendData && newReportId !== "") {
      handleSendReport({
        newReportId,
        homeId,
        reportTitle: documentName,
        report: reportData
      })
    } else if (reportData && editData && newReportId !== "") {
      handleUpdate({
        newReportId,
        homeId,
        reportTitle: documentName,
        report: reportData
      })
    }
  }, [reportData])


  const ButtonsFooter = {
    button1: {
      buttonStyle: 'primaryFooter',
      label: t('createDocument.buttons.send'),
      onClick: () => {handleSend()},
    },
    button2: {
      buttonStyle: 'secondaryFooter',
      label: t('createDocument.buttons.save'),
      onClick: () => {handleEdit()},
    },
    button3: {
      buttonStyle: 'secondaryFooter',
      label: t('createDocument.buttons.preview'),
      onClick: () => {handlePreview()},
    },
  }

  const updateSelectedHome = (home: SelectItemI) => {
    dispatch(setHome({id: home._id, name: home.name}))
  }
  

  useEffect(() => {
    if (data && isDashboard) {
      updateHomes(data);
      updateInitHome({_id: data[0]._id, name: data[0].name})
      dispatch(setHome({id: data[0]._id, name: data[0].name}))
    }
  }, [data]);

  useEffect(() => {
    updateName(reportTitle)
  }, [reportTitle])


  const handleDateChange = (date: string) => {
    console.log(date)
  }

  const handleClose = () => {
    dispatch(clearPreviewDocument())
    onClose()
  }


  return (
    <DrawerContent bg="lightBlue.1">
      <DrawerHeader p="0">
        <PanelHeader
          handleCloseButton={() => {dispatch(clearDrawer())}}
          icon={ProgressReport}
          title={formData.name}
        />
      </DrawerHeader>

      <DrawerBody padding="0" bg="lightBlue.1">
      <CreateDocumentHeader
          handleDateRange={(date) => handleDateChange(date)}
          handleSelectHome={(form) =>
            updateSelectedHome(form as SelectItemI)
          }
          homes={isDashboard ? homeList : null}
          initialHome={initHome.name}
        />
        <DocumentNameHeader
          handleChange={(event) => {
            updateName(event.target.value)
          }}
          value={documentName}
        />

        {(homeId !== undefined && reportTitle !== "" && !isLoading && formData.form !== undefined) && (
          <CreateDocumentTabs wizardTab={false} detailedWizard={false} reportId={newReportId} reportTitle={reportTitle} homeId={homeId} report={formData.form} showMediaIcon={true}/>
        )}
        {
          isLoading && (
            <Center h="8rem" bg="container.primary">
              <BeatLoader color="gray" size={8} />
            </Center>
          )
        }
      </DrawerBody>
      <DrawerFooter p="0" zIndex={"999"}>
        <FooterDrawer
          children={
            <FooterButtons
              button1={ButtonsFooter.button1}
              button2={ButtonsFooter.button2}
              button3={ButtonsFooter.button3}
            />
          }
          isOpen={true}
        />
      </DrawerFooter>
      <Drawer placement="right" onClose={handleClose} isOpen={isOpen} size="lg">
        <DrawerOverlay />
        <DocumentPreview
          date={dateString}
          document={documentName}
          form={reportData}
          handleClose={handleClose}
          handleSend={handleSend}
          home={homeName}
          images={images}
          />
      </Drawer>
    </DrawerContent>
  );
};

export default CreateDocumentDrawer;
