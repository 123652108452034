import React, {useState, FormEvent, useEffect} from 'react';
import {PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {Box, Button, Center} from '@chakra-ui/react'
import {useGetStripePaymentIntentQuery, useUpdatePaymentMethodMutation, useGetUserCardsQuery, useGetUnAuthStripePaymentIntentQuery, useGetTempUserCardsQuery, useUnAuthUpdatePaymentMethodMutation} from '../../../features/api'
import BeatLoader from "react-spinners/BeatLoader";
import {ResponseType} from '../../../interfaces/DataInterfaces';
import { openPaymentDialog } from "../../../features/dialog/dialogSlice";
import { useAppDispatch } from "../../../app/hooks";

const AddCreditCardForm = ({authToken, handleCardSuccess}: {authToken?: string, handleCardSuccess: () => void}) => {

    const stripe = useStripe();
    const elements = useElements();
    const [skipValue, updateSkip] = useState(true)
    const {data} = useGetStripePaymentIntentQuery('', {skip: skipValue})
    const [token, updateToken] = useState(authToken? authToken : "")
    const [unAuthSkip, updateUnAuthSkip] = useState(true)
    const {data: unAuthData} = useGetUnAuthStripePaymentIntentQuery(token, {skip: unAuthSkip})
    const [cardSkip, updateCardSkip] = useState(true)
    const {data: cardData} = useGetUserCardsQuery(null, {skip: cardSkip})
    const [unAuthCardSkip, updateUnAuthCardSkip] = useState(true)
    const {data: unAuthCardData} = useGetTempUserCardsQuery(token, {skip: unAuthCardSkip})
    const [loading, setLoading] = useState(false);
    const [hasError, updateHasError] = useState(false)
    const [unAuthUpdatePaymentMethod] = useUnAuthUpdatePaymentMethodMutation()
    const [updatePaymentMethod] = useUpdatePaymentMethodMutation()
    const [called, updateCalled] = useState(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (cardData) {
            handleSuccess()
        }
    }, [cardData])

    useEffect(() => {
        if (unAuthCardData) {
            handleSuccess()
        }
    }, [unAuthCardData])

    const handleError = (error: any) => {
        setLoading(false);
        updateHasError(true)

        dispatch(openPaymentDialog({
            header: "An error occurred.",
            body: `${error.message} Please try again`,
        }))

    }

    const handleSuccess = () => {
        setLoading(false);
        updateHasError(false)
        handleCardSuccess()
        dispatch(openPaymentDialog({
            header: "Card Successfully Added!",
            body: "",
        }))
    }

    const continueUpdate = async () => {
        updateCalled(true)
        // Your customer is redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer is redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        if (authToken) {
            const updatedMethod: ResponseType = await unAuthUpdatePaymentMethod(token)

            if (updatedMethod.error) {
                console.log('update failed', updatedMethod)
            } else {
                updateUnAuthCardSkip(false)
            }

        } else {
            const updatedMethod: ResponseType = await updatePaymentMethod(null)

            if (updatedMethod.error) {
                console.log('update failed', updatedMethod)
            } else {
                updateCardSkip(false)
            }
        }
    }

    const confirm = async (data: any) => {
        const clientSecret = data.cliente_secret
            if (elements !== null && stripe !== null && !called) {
                // Confirm the SetupIntent using the details collected by the Payment Element
                const {error} = await stripe.confirmSetup({
                    elements,
                    clientSecret,
                    redirect: 'if_required',
                    confirmParams: {
                        return_url: `${window.location.origin}/dashboard`,
                    },
                });
                if (error) {
                    console.log('error', error)
                    // This point is only reached if there's an immediate error when
                    // confirming the setup. Show the error to your customer (for example, payment details incomplete)
                    handleError(error);
                } else {
                    continueUpdate()
                }
            }
            setLoading(false);

    }

    useEffect(() => {
        if (data) {
            confirm(data)
        }
    }, [data])

    useEffect(() => {
        if (unAuthData && authToken) {
            confirm(unAuthData)
        }
    }, [unAuthData])

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        

        if (!stripe) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true);

        // Trigger form validation and wallet collection
        const {error: submitError} = await elements!.submit();
        if (submitError) {
            console.log('submit error', submitError)
            handleError(submitError);
            return;
        } else {
            if (authToken) {
                updateUnAuthSkip(false)
            } else {
                updateSkip(false)
            }
        }


        
    };

    return (
        <Box width={"100%"}>
            {
                loading && (
                    <Center w={"100%"} h="4rem">
                        <BeatLoader color="gray" size={8} />
                    </Center>
                )
            }
            <form onSubmit={handleSubmit}>
                    <PaymentElement />
                    <Button type="submit" disabled={!stripe || loading} m="1rem" variant="primaryFooter">
                        Submit
                    </Button>
                </form>
        </Box>
    );
}

export default AddCreditCardForm