import { TabsHeader, DynamicForm } from "@homefile/components";
import { t } from 'i18next'
import DocumentImageUpload from "../../../forms/DocumentImageUpload";
import { TabsInterface } from "../../../interfaces/CreateDocumentInterfaces";
import DynamicProgressReport from "../../../forms/DynamicProgressReport";
import { Text, Center, Box } from "@chakra-ui/react";

const CreateItemTabs = (props: TabsInterface) => {
    const { report, reportId } = props;
    const tabList = [
        {
          label: t('createDocument.tabs.tab1'),
          component: (
            <Box overflow="scroll" minH="100vh">
              <DynamicForm 
                form={report.form !== undefined ? report.form : report.report}
              />
            </Box>

          )
        },
        {
          label: t('createDocument.tabs.tab2'),
          component: (
          <>
            {
              (reportId !== "" && reportId !== undefined) ?
                <>
                  <DocumentImageUpload documentId={reportId} />
                </> : (
                    <>
                        <Center>
                            <Text p="1rem">Please save form to add images.</Text>
                        </Center>
                    </>
                )
            }
          </>
          ),
        }
      ];
    
      return <TabsHeader tabList={tabList} />;
}

export default CreateItemTabs