import { useState, useEffect } from "react";
import {
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerFooter,
  Slide, 
  Box
} from "@chakra-ui/react";
import { clearDrawer } from "../../../features/drawer/drawerSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store";
import {PanelHeader, FooterDrawer, dynamicFormProxy, useCustomToast} from '@homefile/components';
import { Create } from "../../../assets/images";
import { useGetForm } from "../../../hooks/useGetForm";
import { ReportsI } from "@homefile/components/dist/interfaces";
import { setSendDocument, clearDocumentState } from "../../../features/document/documentSlice";
import { useCreateItem } from "../../../hooks/useCreateItem";
import { useUpdateItem } from "../../../hooks/useUpdateItem";
import {CreateItemFooter} from './CreateItemFooter'
import {CreateItemBody} from './CreateItemBody'
import {CreateItemI } from '../../../interfaces/CreateDocumentInterfaces';

const CreateItemDrawer = ({completedForm, formName, formType, isSlider, open, back}: CreateItemI) => {
    const dispatch = useAppDispatch();
    const {getForm, form} = useGetForm()
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const toast = useCustomToast()
    const [newCompleted, updateNew] = useState({} as ReportsI)
    const [showFooter, updateShowFooter] = useState(false)
    const [showOverlay, updateOverlay] = useState(true)

    const successFunction = () => {
        dispatch(clearDocumentState())

        toast({
            title: 'Item Updated!',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: "top-right"
          })
      }

      const createSuccessFunction = (data: any) => {
        dispatch(clearDocumentState())

        updateNew(data)

        toast({
            title: 'Item Created!',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: "top-right"
          })

          updateOverlay(false)
          updateShowFooter(true)
      }

      const {handleUpdate, handleContactUpdate} = useUpdateItem(successFunction);

      const {
        handleCreateAndUpdate, 
        handleCreateContact,
        newItemId,
        itemName,
        subTypes,
        initSubType,
        subTypeLabel,
        selectedSubType,
        handleNameChange,
        handleSubTypeChange,
        typeId,
        folderId,
        defaultConfig
      } = useCreateItem({
        success: createSuccessFunction,
        formType,
        completedForm
      })


    useEffect(() => {
        getForm(formName)
    }, [])

    useEffect(() => {
        if (completedForm.name === undefined) {
            updateOverlay(true)
        } 
    }, [completedForm])


    const handleSave = () => {
        const {fields} = dynamicFormProxy
        if (itemName.length > 0) {
            if (newItemId === "" && completedForm._id === undefined) {
                // not an existing report so create report or contact
                if (formName === "Contact") {
                    handleCreateContact({
                        title: itemName,
                        type: typeId,
                        subType: selectedSubType === "" ? defaultConfig.config.miscSubTypeId : selectedSubType,
                        folder: folderId,
                        report: fields,
                        formId: form._id, 
                        homeId
                  })
                } else {
                    handleCreateAndUpdate({
                        title: itemName,
                        type: typeId,
                        subType: selectedSubType === "" ? defaultConfig.config.miscSubTypeId : selectedSubType,
                        folder: folderId,
                        report: fields,
                        formId: form._id, 
                        homeId
                  })
                }
    
            } else {
                // existing report or contact so update 
                const id = completedForm._id ? completedForm._id : newCompleted._id
                if (formName === "Contact") {
                    handleContactUpdate({
                        newReportId: id,
                        homeId,
                        reportTitle: itemName,
                        type: typeId,
                        subType: selectedSubType === "" ? defaultConfig.config.miscSubTypeId : selectedSubType,
                        report: fields,
                    })
                } else {
                    handleUpdate({
                        newReportId: id,
                        homeId,
                        reportTitle: itemName,
                        type: typeId,
                        subType: selectedSubType === "" ? defaultConfig.config.miscSubTypeId : selectedSubType,
                        report: fields,
                    })
                }

            }

        } else {
            toast({
              title: 'Add Item Name to Save Item',
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: "top-right"
            })
        }
    }

    useEffect(() => {
        if (completedForm._id) {
            updateOverlay(false)
            updateShowFooter(true)
        }
    }, [completedForm])



    return (
        <>
            {
                !isSlider && (
                    <DrawerContent bg="lightBlue.1" zIndex={4}>
                        {
                            form.name && (
                                <>
                                    <DrawerHeader p="0">
                                        <PanelHeader
                                            handleCloseButton={() => {dispatch(clearDrawer())}}
                                            icon={Create}
                                            title={form.name}
                                        />
                                    </DrawerHeader>

                                    <DrawerBody padding="0" bg="lightBlue.1">

                                        <CreateItemBody 
                                            handleSave={handleSave}
                                            handleNameChange={handleNameChange}
                                            handleSubTypeChange={handleSubTypeChange}
                                            itemName={itemName}
                                            subTypes={subTypes}
                                            initSubType={initSubType}
                                            subTypeLabel={subTypeLabel}
                                            completedForm={completedForm}
                                            newItemId={newItemId}
                                            homeId={homeId}
                                            form={form}
                                            showOverlay={showOverlay}
                                        />

                                    </DrawerBody>
                                    <FooterDrawer isOpen={showFooter}>
                                        <DrawerFooter zIndex={"999"}>
                                            <CreateItemFooter handleSave={handleSave} />

                                        </DrawerFooter>
                                    </FooterDrawer>
                                </>
                            )
                        }
                    </DrawerContent>
                )
            }
            {
                isSlider && (
                    <Slide direction="right" in={open} style={{ zIndex: 4 }}>
                        <Box bg="lightBlue.1" h="100%" overflow="scroll">
                        {
                            (form.name && open) && (
                                <>
                                    <PanelHeader
                                        handleCloseButton={back ? back: () => {dispatch(clearDrawer())}}
                                        icon={Create}
                                        title={form.name}
                                    />

                                    <CreateItemBody 
                                        handleSave={handleSave}
                                        handleNameChange={handleNameChange}
                                        handleSubTypeChange={handleSubTypeChange}
                                        itemName={itemName}
                                        subTypes={subTypes}
                                        initSubType={initSubType}
                                        subTypeLabel={subTypeLabel}
                                        completedForm={completedForm}
                                        newItemId={newItemId}
                                        homeId={homeId}
                                        form={form}
                                        showOverlay={showOverlay}
                                    />

                                    <CreateItemFooter handleSave={handleSave} />

                                </>
                            )
                        }

                        </Box>
                    </Slide>
                )
            }
        </>
    )
}

export default CreateItemDrawer