import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
} from '@chakra-ui/react'
import {DeleteAssociationDialogI} from '../../../interfaces/FolderInterfaces';

const DeleteAssociationDialog = (props: DeleteAssociationDialogI) => {

    const {deleteAssociation, associationId, openModal, closeModal} = props;

    return (
        <Modal isOpen={openModal} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Remove Account</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Text variant="title" fontWeight="medium" isTruncated whiteSpace={'initial'}>
                    Removing an account will disable this person's ability to view your home. Would you like to continue?
                </Text>
            </ModalBody>

            <ModalFooter>
                <Button m="1rem" variant="primaryFooter" onClick={() => deleteAssociation(associationId)}>
                    Remove
                </Button>
                <Button m="1rem" variant="secondaryFooter" onClick={closeModal}>
                    Cancel
                </Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default DeleteAssociationDialog;