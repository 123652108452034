import { homefileApi } from './emptySplitApi'

const receiptApi = homefileApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllHomeReceipts: builder.query({
            query: (homeId: string) => `/api/receipt/all/${homeId}`,
            providesTags: ["Receipts"]
        }),
        getReceiptsWithoutHome: builder.query({
            query: () => "/api/receipt/without-home",
            providesTags: ["Receipts"]
        }),
        getReceiptItems: builder.query({
            query: (receiptId: string) => `api/receipt/items/${receiptId}`,
            providesTags: [ "Receipts", "ProgressReports"]
        }),
        updateHomeReceipts: builder.mutation({
            query: (data: any) => ({
              url: '/api/receipt/update-home',
              method: "PATCH",
              body: data
            }),
            invalidatesTags: ['User', "Receipts", "ProgressReports"],
        }),
        updateReceipt: builder.mutation({
            query: (data: any) => ({
              url: `api/receipt/${data.receiptId}`,
              method: "PATCH",
              body: {name: data.name}
            }),
            invalidatesTags: ["Receipts"],
        }),
        deleteHomeReceipt: builder.mutation({
            query: (id: string) => ({
                url: '/api/receipt',
                method: "DELETE",
                body: {receipt_id: id}
            }),
            invalidatesTags: ['User', "Receipts"],
        })
    })
})

export const {
    useGetAllHomeReceiptsQuery,
    useGetReceiptsWithoutHomeQuery,
    useGetReceiptItemsQuery,
    useUpdateHomeReceiptsMutation,
    useDeleteHomeReceiptMutation,
    useUpdateReceiptMutation
} = receiptApi
