export const colors = {
  red: {
    1: '#F7717D',
  },
  orange: {
    1: '#FDEDE2',
    2: '#EA6808',
  },
  lightViolet: {
    1: '#EFECF8',
    2: '#D0CAE8',
    3: '#B2A5DB',
  },
  violet: {
    1: '#6041B7',
    2: '#9799CA',
    3: '#BD93D8',
    4: '#B47AEA',
    5: '#525074',
  },
  pink: {
    1: '#EC9DED',
    2: '#F544DA',
    3: '#7F2A82',
    4: '#875C74',
    5: '#4A2140',
    6: '#A790A5',
    7: '#513B56',
    8: '#442B48',
  },
  lightYellow: {
    1: '#FFFAED',
  },
  yellow: {
    1: '#DEB841',
    2: '#F8AD20',
    3: '#DE9E35',
    4: '#F1BB87',
    5: '#F78D69',
  },
  lightBlue: {
    1: '#F4F7F8',
    2: '#E9EDEF',
    3: '#DFEFF8',
    4: '#DCE8EC',
    5: '#D0E0E6',
    6: '#BCD1D8',
    7: '#D0E3F2',
    8: '#90DAF7',
    9: '#84BAE2',
    10: '#cff1fc',
    11: '#99AEB4',
  },
  blue: {
    1: '#3E9FDB',
    2: '#2488C6',
    3: '#1D7AB4',
    4: '#508CA4',
    5: '#348AA7',
    6: '#40566B',
    7: '#3770DB',
    8: '#1E6388',
    9: '#558AB1',
    10: '#232669',
    11: '#424CBF',
  },
  lightGreen: {
    1: '#F0F8F0',
    2: '#E1EBE1',
    3: '#E8F8E1',
    4: '#CBF0BD',
    5: '#C5EBC3',
    6: '#D1DDD1',
    7: '#EDF7EC',
    8: '#DFEDDE',
    9: '#C0DBBE',
  },
  green: {
    1: '#519F0E',
    2: '#77C102',
    3: '#5DD39E',
    4: '#61E294',
    5: '#0E8754',
    6: '#ABE188',
    7: '#B6DC76',
    8: '#98B06F',
    9: '#726E60',
    10: '#5D675B',
    11: '#4C8827',
  },
  teal: {
    1: '#F2EFF9',
    2: '#7BCDBA',
    3: '#13C0B7',
    4: '#529F9C',
  },
  neutral: {
    white: '#fff',
    black: '#000',
  },
  gray: {
    1: '#ADB6BA',
    2: '#818181',
    3: '#464646',
    4: '#37323E',
  },

  lightGray: {
    1: '#E2EBEF',
    2: '#CCDCE1',
  },

  error: {
    1: '#f5ddf4',
    2: '#F544DA',
  },
  skyGradient: 'linear(to-b, #cff1fc, #F9FEFF)',
  overlay: '#00000040',
  overlayWhite: '#FFFFFF80',
}
