import {useState} from 'react'
import { useDeleteItemMutation, useDeleteHomeMutation, useRestoreHomeMutation, useRestoreItemMutation } from '../features/api'
import { HomesToBeDeletedI, ItemsToBeDeletedI } from '@homefile/components/dist/interfaces';
import { ResponseType } from '../interfaces/DataInterfaces';
import { openDialog } from "../features/dialog/dialogSlice";
import { useAppDispatch } from "../app/hooks";
import {useCustomToast} from '@homefile/components'

export const useHardDeleteHomesItems = (deleteHomes: boolean) => {
    const [isLoading, updateLoading] = useState(false)
    const [openModal, updateModal] = useState(false)
    const [deleteHome] = useDeleteHomeMutation()
    const [deleteItem] = useDeleteItemMutation()
    const [reactivateHome] = useRestoreHomeMutation()
    const [reactivateItem] = useRestoreItemMutation()
    const toast = useCustomToast()
    const dispatch = useAppDispatch()
    const [hasClosed, updateHasClosed] = useState(false)

    const handleCloseModal = () => {
        updateModal(false)
        updateHasClosed(true)
    }

    const handleOpenModal = () => {
        updateModal(true)
    }

    const fireSuccess = (deletion: boolean) => {

        toast({
            title: 'Success',
            description: deletion ? "Selection Permanently Removed" : "Selection Reactivated",
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: "top-right"
          })
    }

    const fireFailure = (message: string) => {
        dispatch(openDialog({message}))
    }

    const deleteHomesSelection = async (homes: HomesToBeDeletedI[]) => {
        updateLoading(true)
        for (const home of homes) {
            const deletedHome: ResponseType = await deleteHome(home._id)

            if (deletedHome.data) {
                fireSuccess(true)
            } else {
                console.log('delete home fail', deletedHome)
                fireFailure(deletedHome.error.data.message)
            }
        }
        updateLoading(false)
        handleCloseModal()
    }  

    const deleteItemsSelection = async (items: ItemsToBeDeletedI[]) => {
        updateLoading(true)
        for (const item of items) {
            const deletedItem: ResponseType = await deleteItem(item._id)

            if (deletedItem.data) {
                fireSuccess(true)
            } else {
                console.log('delete item fail', deletedItem)
                fireFailure(deletedItem.error.data.message)
            }
        }
        updateLoading(false)
        handleCloseModal()
    }

    const handleReactivateHomes = async (homes: HomesToBeDeletedI[]) => {
        updateLoading(true)
        for (const home of homes) {
            const reactivatedHome: ResponseType = await reactivateHome(home._id)

            if (reactivatedHome.data) {
                fireSuccess(false)
            } else {
                console.log('reactivated home fail', reactivatedHome)
                fireFailure(reactivatedHome.error.data.message)
            }
        }
        updateLoading(false)
        handleCloseModal()
    }

    const handleReactivateItems = async(items: ItemsToBeDeletedI[]) => {
        updateLoading(true)
        for (const item of items) {
            const reactivatedItem: ResponseType = await reactivateItem(item._id)

            if (reactivatedItem.data) {
                fireSuccess(false)
            } else {
                console.log('reactivated item fail', reactivatedItem)
                fireFailure(reactivatedItem.error.data.message)
            }
        }
        updateLoading(false)
        handleCloseModal()
    }

    return {
        isLoading,
        openModal,
        hasClosed,
        handleCloseModal,
        deleteHomesSelection,
        deleteItemsSelection,
        handleOpenModal,
        handleReactivateHomes,
        handleReactivateItems
    }
}