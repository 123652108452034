export const Text = {
  baseStyle: {
    fontWeight: 'regular',
    lineHeight: '20px',
  },
  variants: {
    heading: {
      fontSize: '3xl',
      color: 'gray.3',
      lineHeight: '30px',
    },
    heading6: {
      fontSize: '2xl',
      color: 'gray.3',
      lineHeight: '30px',
    },
    title: {
      fontSize: 'lg',
      color: 'gray.3',
      lineHeight: '22px',
    },
    titleBolder: {
      fontSize: 'lg',
      color: 'gray.3',
      fontWeight: 'semibold',
    },
    body: {
      fontSize: 'md',
      color: 'gray.3',
    },
    caption: {
      fontSize: 'sm',
      color: 'gray.4',
    },
    info: {
      fontSize: 'md',
      fontFamily: 'secondary',
    },
    label: {
      fontSize: 'xs',
      fontFamily: 'secondary',
      color: 'gray.4',
    },
    home: {
      lineHeight: '16px',
      fontSize: 'sm',
      fontFamily: 'secondary',
    },
    report: {
      fontSize: 'xs',
    },
    error: {
      fontSize: 'xs',
      fontFamily: 'secondary',
      color: 'pink.500',
    },
    email: {
      fontSize: 'xs',
      color: 'blue.2',
      fontFamily: 'secondary',
    },
    bagde: {
      fontSize: '0.625rem',
      color: 'neutral.white',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    date: {
      fontSize: '0.625rem',
      color: 'gray.3',
      fontFamily: 'secondary',
    },
    dragDrop: {
      fontSize: 'md',
      fontFamily: 'secondary',
      color: 'blue.6',
      fontWeight: 'regular',
    },
    dragDropLink: {
      fontSize: 'md',
      fontFamily: 'secondary',
      color: 'blue.2',
      fontWeight: 'regular',
    },
    address: {
      fontSize: 'xs',
      fontFamily: 'secondary',
      diplay: 'block-inline',
      marginTop: 'auto',
    },
    receipt: {
      fontSize: 'xs',
      textTransform: 'uppercase',
    },
    legend: {
      fontSize: '0.625rem',
      textTransform: 'uppercase',
    },
    legendSatisfaction: {
      fontSize: 'xs',
      textTransform: 'uppercase',
      fontWeight: 'semibold',
    },
    chartTitle: {
      fontSize: 'xs',
      textTransform: 'uppercase',
    },
  },
  defaultProps: {
    variant: 'body',
  },
}

export default Text
