import {AddFolder, FolderTypeSelection} from '@homefile/components';
import { useAppDispatch } from "../../../app/hooks"
import { clearDrawer } from "../../../features/drawer/drawerSlice";
import { useFolderDetails } from '../../../hooks/useFolderDetails';
import {useState, useEffect} from 'react'

const AddFolderDrawer = (data: {icon: string}) => {
    const dispatch = useAppDispatch();
    const handleCloseButton = () => {
        dispatch(clearDrawer())
    }
    const [headerTitle, updateHeaderTitle] = useState("")

    useEffect(() => {
        if (data.icon === "folder") {
            updateHeaderTitle("Add Folder")
        } else {
            updateHeaderTitle("Add Project")
        }
    }, [data])

    const {addFolder, handleFolderType} = useFolderDetails({folderData: undefined})

    return (
        <AddFolder icon={data.icon} headerTitle={headerTitle} handleClose={handleCloseButton} handleSubmit={addFolder}>
            {
                data.icon === "folder" && (
                    <FolderTypeSelection onChange={handleFolderType} />
                )
            }
        </AddFolder>
    )
}

export default AddFolderDrawer;