import BeatLoader from "react-spinners/BeatLoader";
import { Center } from "@chakra-ui/react";
import { ReactElement } from 'react';

interface LoadingI {
    isLoading: boolean
    children: ReactElement | ReactElement[]
}

const Loading = ({isLoading, children}: LoadingI) => {
    
    return (
        <>
            {isLoading ? (
                <Center h="4rem" bg="white">
                    <BeatLoader color="gray" size={8} />
                </Center>
            ) : (
                <>{children}</>
            )}
        </>
    )
}

export default Loading;