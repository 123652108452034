import {  useCreateFileUploadMutation, useCreateHomeImageFileUploadMutation, homefileApi } from "../features/api";
import { useAppDispatch } from "../app/hooks";
import { ResponseType } from "../interfaces/DataInterfaces";
import axios from "axios"
import {UploadI, TypeObject} from '../interfaces/FolderInterfaces';
import {useState} from 'react';
import { openDialog } from "../features/dialog/dialogSlice";
import heic2any from "heic2any";
import { UploadFileI } from "@homefile/components/dist/interfaces";

export const useFileUpload = () => {
    const dispatch = useAppDispatch()
    const [createUrl] = useCreateFileUploadMutation()
    const [createHomeImageUrl] = useCreateHomeImageFileUploadMutation()
    const [fileUploading, updateUploading] = useState(false)
    const [selectedId, updateSelected] = useState("")

    const formatSize = (bytes: number) => {
        const v =(bytes / Math.pow(1024,2)).toFixed(2)
        return Number(v) > 0.01 ? Number(v) : 0.01
    }

    const handleUpload = async ({file, url, contentType}: UploadI) => {
        try {
            const res = await axios({
              method: "PUT",
              url,
              data: file,
              headers: {
                "Content-Type": contentType,
              },
            })
            dispatch(homefileApi.util.invalidateTags(["ProgressReport", "ProgressReports", "Homes", "Home", "Room", "User"]))
            updateUploading(false)
            return res
          } catch (err: any) {
            console.log("handle upload response fail", err)
            updateUploading(false)
            return err
          }
    }

    const getContentType = (extension: string) => {
        const contentType: TypeObject  = {
            jpeg: "image",
            jpg: "image",
            png: "image",
            svg: "image",
            mp4: "video",
            mv: "video",
            pdf: "pdf",
            docx: "doc",
            default: "image"
        }

        return contentType[extension as keyof TypeObject] ? contentType[extension as keyof TypeObject] : contentType['default'];

    }


    const convertHeic = async (file: File) => {
        await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 1
        })
        .then((newImage) => {
            let pos = file.name.lastIndexOf(".");
            const newName = file.name.substring(0, pos < 0 ? file.name.length : pos) + ".jpeg";
            file = new File([newImage as BlobPart], newName, {lastModified: file.lastModified, type: "image/jpeg"});
        })
        .catch((error) => {
            console.log('conversion error', error)
        })
        return file
    }

    const handleNewFile = async (files: UploadFileI[], id: string, folderId: string) => {
        if (files.length > 0) {
            updateUploading(true)
            files.forEach(async (uploadFile: UploadFileI) => {
                if (uploadFile.file !== undefined) {
                    let file = uploadFile.file as File
                    if (file.type.toLowerCase() === "image/heic" || file.name.toLowerCase().includes(".heic")) {
                        file = await convertHeic(file)
                    }

                    let extension = file.type.replace(/(.*)\//g, '')

                    let customContentType = null;

                    if (extension === "quicktime" || extension === "mov") {
                        customContentType = "video/quicktime"
                        extension = "mov"
                    }

                    const fileSize = formatSize(file.size)

                    const sendData = {
                        type: getContentType(extension),
                        fileName: file.name,
                        originalName: file.name,
                        extension,
                        collectionName: "homes",
                        docId: id,
                        folder: folderId,
                        customContentType,
                        size: fileSize
                    }

                    const uploadResponse: ResponseType = await createUrl(sendData)
            
                    if (uploadResponse.data) {
                        const url = uploadResponse.data.signedUrl
                        await handleUpload({
                            url,
                            contentType: file.type,
                            file
                        })
            
                    } else {
                        console.log('err', uploadResponse)
                        updateUploading(false)
                        dispatch(openDialog({message: uploadResponse.error.data.message}))
                    }
                }

            })

        }
    }

    const handleNewHomeImage = async (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        updateUploading(true)
        updateSelected(id)
        if (e.target.files !== null && e.target.files[0] !== undefined) {
            let file = e.target.files[0];
            if (file.type.toLowerCase() === "image/heic" || file.name.toLowerCase().includes(".heic")) {
                file = await convertHeic(file)
            }
            const extension = file.type.replace(/(.*)\//g, '')
            const fileSize = formatSize(file.size)
            const sendData = {
                type: getContentType(extension),
                fileName: file.name,
                originalName: file.name,
                extension,
                collectionName: "homes",
                docId: id,
                size: fileSize
            }
    
            const uploadResponse: ResponseType = await createHomeImageUrl(sendData)
    
            if (uploadResponse.data) {
                const url = uploadResponse.data.signedUrl
    
                handleUpload({
                    url,
                    contentType: file.type,
                    file
                })
    
            } else {
                console.log(uploadResponse)
                updateUploading(false)
                dispatch(openDialog({message: uploadResponse.error.data.message}))
            }
        }
    }

    const handleNewDocumentImage = async (image: any, id: string) => {
        updateUploading(true)
        const extension = image.type.replace(/(.*)\//g, '')
        const fileSize = formatSize(image.size)
        const sendData = {
            type: getContentType(extension),
            fileName: image.name,
            originalName: image.name,
            extension,
            description: image.description,
            collectionName: "progressreports",
            docId: id,
            size: fileSize
        }

        const uploadResponse: ResponseType = await createUrl(sendData)


        if (uploadResponse.data) {
            const url = uploadResponse.data.signedUrl
            const returnUpload = await handleUpload({
                url,
                contentType: image.type,
                file: image.file
            })
            return returnUpload

        } else {
            updateUploading(false)
            dispatch(openDialog({message: uploadResponse.error.data.message}))
            return uploadResponse
        }
    }

    const handleServiceImage = async (image: any, id: string) => {
        updateUploading(true)
        const extension = image.file.type.replace(/(.*)\//g, '')
        const fileSize = formatSize(image.size)
        const sendData = {
            type: getContentType(extension),
            fileName: image.file.name,
            originalName: image.file.name,
            extension,
            description: image.description,
            collectionName: "requestservices",
            docId: id,
            size: fileSize
        }

        const uploadResponse: ResponseType = await createUrl(sendData)


        if (uploadResponse.data) {
            const url = uploadResponse.data.signedUrl
            const returnUpload = await handleUpload({
                url,
                contentType: image.file.type,
                file: image.file
            })
            return {response: returnUpload, data: uploadResponse.data}

        } else {
            updateUploading(false)
            dispatch(openDialog({message: uploadResponse.error.data.message}))
            return uploadResponse
        }
    }

    const handleNewRoomMedia = async (files: UploadFileI[], id: string) => {
        if (files.length > 0) {
            updateUploading(true)
            files.forEach(async (uploadFile: UploadFileI) => {
                if (uploadFile.file !== undefined) {
                    let file = uploadFile.file as File
                    if (file.type.toLowerCase() === "image/heic" || file.name.toLowerCase().includes(".heic")) {
                        file = await convertHeic(file)
                    }

                    let extension = file.type.replace(/(.*)\//g, '')

                    let customContentType = null;

                    if (extension === "quicktime" || extension === "mov") {
                        customContentType = "video/quicktime"
                        extension = "mov"
                    }

                    const fileSize = formatSize(file.size)

                    const sendData = {
                        type: getContentType(extension),
                        fileName: file.name,
                        originalName: file.name,
                        extension,
                        collectionName: "rooms",
                        docId: id,
                        customContentType,
                        size: fileSize
                    }

                    const uploadResponse: ResponseType = await createUrl(sendData)
            
                    if (uploadResponse.data) {
                        const url = uploadResponse.data.signedUrl
                        await handleUpload({
                            url,
                            contentType: file.type,
                            file
                        })
            
                    } else {
                        console.log('err', uploadResponse)
                        updateUploading(false)
                        dispatch(openDialog({message: uploadResponse.error.data.message}))
                    }
                }

            })

        }
    }

    return {
        handleNewFile,
        handleNewHomeImage,
        handleNewDocumentImage,
        handleNewRoomMedia,
        handleServiceImage,
        fileUploading,
        selectedId
    }

}