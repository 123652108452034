import { TutorialI } from "@homefile/components/dist/interfaces/help/HelpContent.interface";

export const Tutorials: TutorialI[] = [
    {
      subtitle: 'Here are a few videos to get you up and running.',
      thumbnail: 'https://i.ytimg.com/vi/Y10_MquTwo0/hqdefault.jpg',
      title: 'Getting Started',
      url: 'https://www.youtube.com/embed/Y10_MquTwo0',
      updatedAt: '08/23',
    },
    {
      subtitle: 'Represents your home. Click the bottom to view the home.',
      thumbnail: 'https://i.ytimg.com/vi/-Tx8m2VadOw/hqdefault.jpg',
      title: 'Launchpad & Home Card',
      url: 'https://www.youtube.com/embed/-Tx8m2VadOw',
      updatedAt: '08/23',
    },
    {
      subtitle: 'Represents your home. Click the bottom to view the home.',
      thumbnail: 'https://i.ytimg.com/vi/klba4FcdqyA/hqdefault.jpg',
      title: 'Adding Folders & Files',
      url: 'https://www.youtube.com/embed/klba4FcdqyA',
      updatedAt: '08/23',
    },
    {
      subtitle: 'Represents your home. Click the bottom to view the home.',
      thumbnail: 'https://i.ytimg.com/vi/ZrJNykmbAGg/hqdefault.jpg',
      title: 'Sharing & Collaborating',
      url: 'https://www.youtube.com/embed/ZrJNykmbAGg',
      updatedAt: '08/23',
    }
  ]