import { createSlice } from "@reduxjs/toolkit";

interface ShowTour {
    launchpad: boolean
    homeBoard: boolean
    room: boolean
}

export interface StorageI {
    storage: number
    name: string
    active: boolean
    _id: string
}

export interface UserState {
    email: string
    firstName: string
    lastName: string
    _id: string
    showTour: ShowTour
    storage: StorageI[]
    paymentInfo: {
        trial: boolean
        trialDay: number
    },
    createdAt: string
    provider?: 'sms' | 'email' | null
    phone?: string
    partnerCode?: string
}

interface InitialStateInterface {
    token: string | null
    user: UserState,
    isAuthenticated: boolean
    homeAssociationType: string
    avatarBg: string
}

const authInitialState: InitialStateInterface = {
    token: null,
    user: {
        email: "",
        firstName: "",
        lastName: "",
        _id: "",
        showTour: {
            launchpad: true,
            homeBoard: true,
            room: true
        },
        storage: [],
        paymentInfo: {
            trial: false,
            trialDay: 0
        },
        createdAt: "",
    },
    isAuthenticated: false,
    homeAssociationType: "",
    avatarBg: ""
}

const authSlice = createSlice({
    name: "auth",
    initialState: authInitialState,
    reducers: {
        setAuth: (state, action) => {
            state.token = action.payload.token;
            state.user = action.payload.user;
        },
        setToken: (state, action) => {
            state.token = action.payload.token;
            state.isAuthenticated = true;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setAvatarBg: (state, action) => {
            state.avatarBg = action.payload
        },
        clearAuth: (state) => {
            state.token = authInitialState.token;
            state.user = authInitialState.user;
            state.isAuthenticated = false;
            state.homeAssociationType = ""
            state.avatarBg = ""
        },
        setAssociationType: (state, action) => {
            state.homeAssociationType = action.payload
        },
        clearAssociationType: (state) => {
            state.homeAssociationType = ""
        }
    }
})

export const {setAuth, clearAuth, setToken, setUser, setAssociationType, clearAssociationType, setAvatarBg } = authSlice.actions;
export default authSlice.reducer;