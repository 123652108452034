import {
  DrawerBody,
  DrawerHeader,
  DrawerContent,
} from "@chakra-ui/react";
import HomeInfoModule from "../../Modules/HomeInfoModule";
import { PanelHeader} from "@homefile/components";
import { t } from "i18next";
import { House } from "../../../assets/images";

const ViewHomeDrawer = ({ handleClose }: { handleClose: () => void }) => {

  return (
    <DrawerContent>
      <DrawerHeader p="0">
        <PanelHeader
          icon={House}
          handleCloseButton={handleClose}
          title={t("myHomes.details.header")}
        />
      </DrawerHeader>

      <DrawerBody padding="0 0 6rem 0" bg={"#F4F7F8"}>
        <HomeInfoModule />
      </DrawerBody>
    </DrawerContent>
  );
};

export default ViewHomeDrawer;
