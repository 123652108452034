import { TabsHeader, DynamicForm } from "@homefile/components";
import { t } from 'i18next'
import DocumentImageUpload from "../../../forms/DocumentImageUpload";
import { TabsInterface } from "../../../interfaces/CreateDocumentInterfaces";
import DynamicProgressReport from "../../../forms/DynamicProgressReport";
import { Text, Center, Box } from "@chakra-ui/react";

const CreateHouseholdItemTabs = (props: TabsInterface) => {
    const { homeId, report, reportTitle, reportId, wizardTab, detailedWizard, tabIndex, onChange, showMediaIcon } = props;
    const reportTab = {
      label: t('createDocument.tabs.tab1'),
      component: (
        <Box overflow="scroll" minH="100vh">
          <DynamicForm 
            form={report.form !== undefined ? report.form : report.report}
          />
        </Box>
      )
    }

    const mediaTab = {
      label: t('createDocument.tabs.tab2'),
      component: (
      <>
        {
          (reportId !== "" && reportId !== undefined) ?
            <>
              <DocumentImageUpload documentId={reportId} showMediaIcon={showMediaIcon} />
            </> : (
                <>
                    <Center>
                        <Text p="1rem">Please save form to add images.</Text>
                    </Center>
                </>
            )
        }
      </>
      ),
    }

    const wizardTabList = detailedWizard ? [mediaTab, reportTab] : [mediaTab]

    const defaultTabList = [reportTab, mediaTab]

    
      return <TabsHeader tabList={wizardTab ? wizardTabList : defaultTabList} tabIndex={tabIndex} onChange={onChange} />;
}

export default CreateHouseholdItemTabs