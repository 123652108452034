import { useState, useEffect } from "react";
import { useCreateHomeMutation, useGetHomesQuery, useAddTileToHomeMutation, useAddTileToUserMutation, useGetHomeQuery, useHomeAddPermissionsMutation, useAddHomePropertyInfoMutation } from "../../../features/api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { clearDrawer } from "../../../features/drawer/drawerSlice";
import { AddHomeContent, useCustomToast } from "@homefile/components";
import { CreateHomeInterface } from "../../../interfaces/HomeInterfaces";
import {ResponseType} from '../../../interfaces/DataInterfaces';
import { openDialog } from "../../../features/dialog/dialogSlice";
import { HomeInterface } from "../../../interfaces/HomeInterfaces";
import {usePartnerInfo} from '../../../hooks/usePartnerInfo';
import {ShortPartnerTileI} from '@homefile/components/dist/interfaces'
import {usePropertyInfo} from '../../../hooks/usePropertyInfo';
import {DefaultPermissions} from '../../../helpers/DefaultPermissions.helper'


const AddHomeDrawer = () => {
  const [createHome] = useCreateHomeMutation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.auth.user);
  const { data } = useGetHomesQuery(null);
  const [firstHome, updateFirstHome] = useState(false);
  const [addTileToHome] = useAddTileToHomeMutation()
  const [addTileToUser] = useAddTileToUserMutation()
  const [addDefualtPermissions] = useHomeAddPermissionsMutation()
  const [isLoading, updateLoading] = useState(false)
  const [mainPartnerTile, updateMainPartnerTile] = useState<ShortPartnerTileI>({} as ShortPartnerTileI)
  const [partnerDialogOpen, updatePartnerDialogOpen] = useState(false)
  const [newHomeId, updateNewHomeId] = useState("")
  const {partnerData, mainTile} = usePartnerInfo()
  const [tileData, updateTileData] = useState<any[]>([])
  const [skipValue, updateSkip] = useState(true)
  const {data: newHomeData} = useGetHomeQuery(newHomeId, {skip: skipValue})
  const [wizardStep, updateWizardStep] = useState<'initial' | 'loading' | 'properties'>('initial')
  const [properties, updateProperties] = useState([] as any)
  const toast = useCustomToast()

  const {handleSearch, addHomeInfo, addHomeInfoUnAuth, searchData} = usePropertyInfo("")

  useEffect(() => {
    if (mainTile._id) {
      updateMainPartnerTile(mainTile)
    }
  }, [mainTile])

  useEffect(() => {
    if (newHomeData) {
      const newTileData = newHomeData.tileList ? newHomeData.tileList : []
      updateTileData(newTileData)
    }
  }, [newHomeData])
  
  useEffect(() => {
    if (newHomeId !== "") {
      updateSkip(false)
    }
  }, [newHomeId])

  useEffect(() => {
    if (data) {
      if (data.filter((home: HomeInterface) => home.active).length < 1) {
        updateFirstHome(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (searchData) {
      updateProperties(searchData.data)
      updateWizardStep('properties')
    }
}, [searchData])

const handleSuccess = () => {
  toast({
    title: 'Success!',
    description: `Home Created`,
    status: 'success',
    duration: 5000,
    isClosable: true,
    position: "top-right"
  })
  updateLoading(false)
  dispatch(clearDrawer())
}

  const handlePropertySearch = async (data: any) => {
    updateWizardStep('loading')
    updateLoading(false)

    const sendData = {
      house: data.number ? data.number : "",
      street: data.street,
      city: data.city,
      state: data.state,
      zip: data.zip
    }

    handleSearch(sendData)

  }


  const handleSubmit = async (values: CreateHomeInterface) => {
    updateLoading(true)
    const sendData = {
      name: values.name,
      address: {
        apartmentNumber: values.apartmentNumber,
        city: values.city,
        obs: values.obs,
        state: values.state,
        street: values.street,
        zip: values.zip,
        number: values.number
      }
    };
    const newHome: ResponseType = await createHome(sendData);

    if (newHome.data) {

      // add tile to home if user has partner and first home
      updateNewHomeId(newHome.data.home._id)

      // POST default role permissions list for newly created 
      const addPermissions: ResponseType = await addDefualtPermissions({body: DefaultPermissions, homeId: newHome.data.home._id})
      if (mainPartnerTile._id) {
        // add main tile to home
          const newTile: ResponseType = await addTileToHome({
            tileId: mainPartnerTile._id,
            display: true,
            homeId: newHome.data.home._id
          })

          if (newTile.data) {
            // check if need to fire property details
            if (values.isOwner && values.searchRecords) {
              handlePropertySearch(newHome.data.home.address)
            } else {
              handleSuccess()
            }
          } else {
            updateLoading(false)
            dispatch(clearDrawer())
            dispatch(openDialog({message: "Something went wrong please try again."}))
          }

      } else if (partnerData.length > 0) {
        // updatePartnerDialogOpen(true)
        // to do: check if multiple tiles and/or multiple partners
        // not first home so close drawer
        // removing partner dialog in add home flow for now
        // check if need to fire property details
        if (values.isOwner && values.searchRecords) {
          handlePropertySearch(newHome.data.home.address)
        } else {
          handleSuccess()
        }
      } else {
        // not first home so close drawer
        // check if need to fire property details
        if (values.isOwner && values.searchRecords) {
          handlePropertySearch(newHome.data.home.address)
        } else {
          handleSuccess()
        }
      }
    } else {
      updateLoading(false)
      dispatch(clearDrawer())
      dispatch(openDialog({message: newHome.error.data.message}))
    }
  };

  const handleAddress = async (id: string) => {
    if (id === "none" || properties.length < 1) {
      handleSuccess()
    } else {
      const sendData = {
        realStateId: id,
        home: newHomeId
      }
      const homeInfo: ResponseType = await addHomeInfoUnAuth(sendData)
      if (homeInfo.data) {
        handleSuccess()
      } else {
        dispatch(clearDrawer())
        dispatch(openDialog({message: "Something went wrong please try again."}))
      }
    }
  }

  // const handleCloseModal = () => {
  //   updatePartnerDialogOpen(false)
  //   updateLoading(false)
  //   dispatch(clearDrawer())
  // }

  return (
    <>
      <AddHomeContent
        handleCreateHomeClick={(form) => {
          handleSubmit(form);
        }}
        handleSkipClick={() => {
          dispatch(clearDrawer());
        }}
        userFirstName={user.firstName}
        isFirstHome={firstHome}
        isLoading={isLoading}
        properties={properties}
        step={wizardStep}
        handleAddress={handleAddress}
      />
      {/* <AddPartnerTileDialog partnerData={partnerData} existingTiles={tileData} id={newHomeId} openModal={partnerDialogOpen} closeModal={handleCloseModal} /> */}
    </>
  );
};

export default AddHomeDrawer;
