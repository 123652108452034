import { createSlice } from "@reduxjs/toolkit";

interface DrawerStateInterface {
    isOpen: boolean
    location: string | any
    content: string
    size: string
    data: any
}

const drawerInitialState: DrawerStateInterface = {
    isOpen: false,
    location: 'left',
    content: 'none',
    size: 'md',
    data: {}
}

const drawerSlice = createSlice({
    name: "drawer",
    initialState: drawerInitialState,
    reducers: {
        setDrawer: (state, action) => {
            state.isOpen = true;
            state.location = action.payload.location;
            state.content = action.payload.content;
            state.size = action.payload.size ? action.payload.size : drawerInitialState.size;
            state.data = action.payload.data ? action.payload.data : drawerInitialState.data;
        },
        clearDrawer: (state) => {
            state.isOpen = drawerInitialState.isOpen;
            state.content = drawerInitialState.content;
            state.size = drawerInitialState.size;
            state.data = drawerInitialState.data;
        }
    }
})

export const {setDrawer, clearDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;