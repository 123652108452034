import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
} from '@chakra-ui/react'
import {DeleteHomeDialogI} from '../../../interfaces/FolderInterfaces';

const DeleteHomeDialog = (props: DeleteHomeDialogI) => {

    const {deleteHome, homeId, openModal, closeModal} = props;

    return (
        <Modal isOpen={openModal} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Remove Home</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Text variant="title" fontWeight="medium">
                    Are you sure you want to remove this home?
                </Text>
            </ModalBody>

            <ModalFooter>
                <Button m="1rem" variant="primaryFooter" onClick={() => deleteHome(homeId)}>
                    Remove
                </Button>
                <Button m="1rem" variant="secondaryFooter" onClick={closeModal}>
                    Cancel
                </Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default DeleteHomeDialog;