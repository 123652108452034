import { homefileApi } from './emptySplitApi'

const folderApi = homefileApi.injectEndpoints({
    endpoints: (builder) => ({
        createFolder: builder.mutation({
            query: (data: any) => ({
                url: `/api/dynamic-folder`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["Folders"],
        }),
        deleteFolder: builder.mutation({
            query: (folderId: string) => ({
                url: `/api/dynamic-folder/${folderId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Folders"],
        }),
        getHomeFolders: builder.query({
            query: (homeId: string) => ({
                url: `/api/dynamic-folder/${homeId}`,
            }),
            providesTags: ["Folders"],
        }),
        updateFolder: builder.mutation({
            query: (data: any) => ({
                url: `/api/dynamic-folder/${data.folderId}`,
                method: "PATCH",
                body: {home: data.homeId, name: data.name, shareable: data.shareable, icon: data.icon}
            }),
            invalidatesTags: ["Folders"],
        }),
        getHomeReportsFolderTotals: builder.query({
            query: (homeId: string) => ({
                url: `/api/report/info/${homeId}`,
            }),
            providesTags: ["ProgressReports"],
        }),
        updateReportFolder: builder.mutation({
            query: (data: any) => ({
                url: `/api/report/change-folder`,
                method: "PATCH",
                body: data
            }),
            invalidatesTags: ["Folders", "ProgressReports"],
        }),
        getUserReportsByFolder: builder.query({
            query: (data: {homeId: string, folderId: string}) => ({
                url: `/api/user-association/reports/${data.homeId}/${data.folderId}`,
            }),
            providesTags: ["ProgressReports", "Folders"],
        }),
        getSharedUsersByFolder: builder.query({
            query: (folderId: string) => ({
                url: `/api/user-association/folder/${folderId}`,
            }),
            providesTags: ["Users", "Folders"],
        }),
        addSharedUserToFolder: builder.mutation({
            query: (data: any) => ({
                url: `/api/user-association/folder`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["Folders", "Users"],
        }),
        removeSharedUserFromFolder: builder.mutation({
            query: (data: any) => ({
                url: `/api/user-association/folder`,
                method: "DELETE",
                body: data
            }),
            invalidatesTags: ["Users", "Folders"],
        }),
    })
})

export const {
    useGetHomeFoldersQuery,
    useGetUserReportsByFolderQuery,
    useCreateFolderMutation,
    useUpdateFolderMutation,
    useGetHomeReportsFolderTotalsQuery,
    useUpdateReportFolderMutation,
    useGetSharedUsersByFolderQuery,
    useAddSharedUserToFolderMutation,
    useRemoveSharedUserFromFolderMutation,
    useDeleteFolderMutation
} = folderApi

