import { Icon } from '@chakra-ui/react';

export const ArrowRight = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    
    <defs>
    <clipPath id="clip-Arrow">
      <rect width="50" height="50"/>
    </clipPath>
  </defs>
  <g id="Arrow" clipPath="url(#clip-Arrow)">
    <rect width="50" height="50" fill="#fff"/>
    <g id="Special_Combo_Dropdown" transform="translate(-0.174)">
      <g id="Round_Icon_Atom" transform="translate(0.174)">
        <circle id="Ellipse_684" cx="25" cy="25" r="25" fill="#1D7AB4"/>
      </g>
      <g id="Combo_Arrow" transform="translate(21.207 34.375) rotate(-90)">
        <path id="Path_8407" d="M0,0,8.75,8.75,17.5,0" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
      </g>
    </g>
  </g>

  </Icon>

);

export const ArrowLeft = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    
    <g id="Round_Icon_Atom">
    <circle id="Ellipse_684" cx="25" cy="25" r="25" fill="#1D7AB4"/>
    </g>
    <g id="Combo_Arrow" transform="translate(20.217 34.375) rotate(-90)">
      <path id="Path_8407" d="M0,8.75,8.75,0,17.5,8.75" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
    </g>

  </Icon>

);

export const Appliance = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 4">
    <path fill="#bee39d" d="M28.109 57.784a22.409 22.409 0 0 0 22.387 21.631 22.407 22.407 0 0 0 22.388-21.564c.011-.285.02-.56.02-.847a22.4 22.4 0 0 0-22.408-22.4 22.4 22.4 0 0 0-22.408 22.4c0 .261.01.516.021.78Zm60.392-38.779v76h-76v-76Z" data-name="Path 6462"/>
    <path d="M50.494 31.669a25.336 25.336 0 0 0-25.339 25.328c0 .3.012.586.023.893a25.34 25.34 0 0 0 25.316 24.456 25.339 25.339 0 0 0 25.318-24.381c.01-.32.019-.635.019-.968a25.327 25.327 0 0 0-25.337-25.328Z" className="cls-3" data-name="Path 6463"/>
    <path fill="#d2edef" d="M28.088 56.997a22.407 22.407 0 0 1 22.408-22.4 22.4 22.4 0 0 1 22.408 22.4c0 .294-.009.571-.019.851-20.378 5.313-24.531-5.258-44.776-.065-.01-.261-.021-.519-.021-.786Z" data-name="Path 6464"/>
    <path fill="#6ab528" d="M12.502 0H88.5v19.002H12.502z" data-name="Rectangle 8011"/>
    <path fill="#548e20" d="M28.107 57.784c20.246-5.192 24.4 5.376 44.777.065A22.4 22.4 0 0 1 50.495 79.41a22.41 22.41 0 0 1-22.388-21.626Z" data-name="Path 6465"/>
    <path d="M21.997 8.084h17.162v2.846H21.997z" classNameName="cls-3" data-name="Rectangle 8012"/>
    <path d="M75.531 8.084h3.463v2.846h-3.463z" classNameName="cls-3" data-name="Rectangle 8013"/>
    <path d="M69.498 8.084h3.462v2.846h-3.462z" classNameName="cls-3" data-name="Rectangle 8014"/>
    <path fill="#e24c5d" d="M63.464 8.084h3.463v2.846h-3.463z" data-name="Rectangle 8015"/>
  </g>

  </Icon>
);

export const Architecture = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h5.022v2.158H0z" className="cls-6" data-name="Rectangle 12881"/>
    <path id="c" d="M0 0h4.173v2.158H0z" className="cls-6" data-name="Rectangle 12882"/>
    <path id="d" d="M0 0h5.014v2.158H0z" className="cls-6" data-name="Rectangle 12884"/>
    <path id="e" d="M0 0h2.158v5.015H0z" className="cls-6" data-name="Rectangle 12889"/>
    <path id="f" d="M0 0h2.158v5.022H0z" className="cls-6" data-name="Rectangle 12890"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 10597" transform="translate(-2468.712 -1721.59)">
      <path d="m2526.187 1805.297-2.873 4.667h-8.13l-2.863-4.676 6.893-4.971Z" className="cls-2" data-name="Path 9169"/>
      <path d="M2544.259 1791.676h11.806v11.806h-11.806z" className="cls-3" data-name="Rectangle 12877"/>
      <path d="M2544.259 1730.777h11.806v11.805h-11.806z" className="cls-3" data-name="Rectangle 12878"/>
      <path fill="#fea856" d="M2526.187 1732.576v72.72l-6.971-4.98v-67.74h6.971Z" data-name="Path 9170"/>
      <path d="M2526.115 1728.079v4.5h-13.87v-4.5Z" className="cls-2" data-name="Path 9171"/>
      <path fill="#e24c5d" d="M2526.116 1724.669v3.41h-13.871v-3.41a3.015 3.015 0 0 1 2.921-3.079h8.029a3.02 3.02 0 0 1 2.921 3.079" data-name="Path 9172"/>
      <path d="m2523.317 1809.964-4.065 6.626-4.065-6.626Z" className="cls-6" data-name="Path 9173"/>
      <path fill="#f6cf76" d="M2519.216 1732.576v67.74l-6.971 4.98v-72.72Z" data-name="Path 9174"/>
      <path d="M2482.359 1791.676h11.813v11.806h-11.813z" className="cls-3" data-name="Rectangle 12879"/>
      <path d="M2482.359 1730.777h11.813v11.805h-11.813z" className="cls-3" data-name="Rectangle 12880"/>
      <use xlinkHref="#b" className="cls-6" data-name="Rectangle 12881" transform="translate(2499.439 1735.597)"/>
      <use xlinkHref="#c" className="cls-6" data-name="Rectangle 12882" transform="translate(2508.072 1735.597)"/>
      <use xlinkHref="#c" className="cls-6" data-name="Rectangle 12883" transform="translate(2526.187 1735.597)"/>
      <use xlinkHref="#d" className="cls-6" data-name="Rectangle 12884" transform="translate(2533.971 1735.597)"/>
      <use xlinkHref="#b" className="cls-6" data-name="Rectangle 12885" transform="translate(2499.439 1797.496)"/>
      <use xlinkHref="#c" className="cls-6" data-name="Rectangle 12886" transform="translate(2508.072 1797.496)"/>
      <use xlinkHref="#c" className="cls-6" data-name="Rectangle 12887" transform="translate(2526.187 1797.496)"/>
      <use xlinkHref="#d" className="cls-6" data-name="Rectangle 12888" transform="translate(2533.971 1797.496)"/>
      <use xlinkHref="#e" className="cls-6" data-name="Rectangle 12889" transform="translate(2549.08 1747.856)"/>
      <use xlinkHref="#f" className="cls-6" data-name="Rectangle 12890" transform="translate(2549.08 1756.482)"/>
      <use xlinkHref="#f" className="cls-6" data-name="Rectangle 12891" transform="translate(2549.08 1765.115)"/>
      <use xlinkHref="#f" className="cls-6" data-name="Rectangle 12892" transform="translate(2549.08 1773.749)"/>
      <use xlinkHref="#f" className="cls-6" data-name="Rectangle 12893" transform="translate(2549.08 1782.382)"/>
      <use xlinkHref="#e" className="cls-6" data-name="Rectangle 12894" transform="translate(2487.187 1747.856)"/>
      <use xlinkHref="#f" className="cls-6" data-name="Rectangle 12895" transform="translate(2487.187 1756.482)"/>
      <use xlinkHref="#f" className="cls-6" data-name="Rectangle 12896" transform="translate(2487.187 1765.115)"/>
      <use xlinkHref="#f" className="cls-6" data-name="Rectangle 12897" transform="translate(2487.187 1773.749)"/>
      <use xlinkHref="#f" className="cls-6" data-name="Rectangle 12898" transform="translate(2487.187 1782.382)"/>
    </g>
  </g>

  </Icon>
);

export const Art = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10565">
    <path fill="#40566b" d="M75.103 21.21 50.282 3.083 25.459 21.21l-1.624-2.221L49.47.266a1.373 1.373 0 0 1 1.625 0l25.632 18.723Z" data-name="Path 27"/>
    <path fill="#d2edef" d="M90.206 75.921 64.837 54.835 43.911 72.229l-14.1-11.728-19.438 16.161V27.241h80v56.712h-.165ZM51.722 45.513a6.305 6.305 0 0 0-6.305-6.314 6.314 6.314 0 0 0-6.315 6.314 6.313 6.313 0 0 0 6.315 6.3 6.3 6.3 0 0 0 6.304-6.301Z" data-name="Path 28"/>
    <path d="M10.376 76.662v7.289L.133 95V16.19l10.243 11.049Z" className="cls-4" data-name="Path 29"/>
    <path d="M90.371 83.951 100.439 95H.133l10.243-11.049h79.995Z" className="cls-5" data-name="Path 30"/>
    <path d="M100.437 16.189v78.81L90.37 83.95V27.238Z" className="cls-4" data-name="Path 31"/>
    <path d="M75.145 16.189h25.294L90.371 27.238H10.376L.133 16.189h75.012Z" className="cls-5" data-name="Path 32"/>
    <path fill="#f6cf76" d="M45.416 39.198a6.306 6.306 0 0 1 6.305 6.315 6.3 6.3 0 0 1-6.305 6.306 6.312 6.312 0 0 1-6.315-6.306 6.315 6.315 0 0 1 6.315-6.315Z" data-name="Path 33"/>
    <path fill="#6ab528" d="M90.206 75.919v8.029h-79.83V76.66l19.438-16.161 14.1 11.728 20.925-17.39Z" data-name="Path 34"/>
  </g>

  </Icon>
);

export const Bathroom = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 13">
    <path d="m20.018 94.12 7.256-9.282 1.979 1.547-7.256 9.281z" className="cls-2" data-name="Rectangle 8323"/>
    <path d="m70.792 85.713 1.98-1.546 7.25 9.286-1.98 1.546z" className="cls-2" data-name="Rectangle 8324"/>
    <path d="M95.802 48.599a3.119 3.119 0 0 1 3.114 3.117 3.119 3.119 0 0 1-3.114 3.121H42.413v-6.238h53.389Z" className="cls-3" data-name="Path 6765"/>
    <path fill="#6ab528" d="M42.413 64.019v-9.182h51.622c0 17.2-5.039 32.14-22.253 32.14H29.151c-17.212 0-22.186-14.937-22.186-32.14h17.448v16.066h18Z" data-name="Path 6766"/>
    <path d="M24.412 64.021h18v6.882h-18z" className="cls-5" data-name="Rectangle 8325"/>
    <path d="M42.412 54.837v9.182h-18v-15.42h18Z" className="cls-5" data-name="Path 6767"/>
    <path d="M24.413 48.599v6.238H5.199a3.119 3.119 0 0 1-3.115-3.121 3.119 3.119 0 0 1 3.115-3.117Z" className="cls-3" data-name="Path 6768"/>
    <path d="M95.29 48.597h-2.512V12.883a9.646 9.646 0 0 0-9.636-9.635 9.63 9.63 0 0 0-9.614 9.046 7.964 7.964 0 0 0-.022.589h-2.512c0-.257.009-.507.028-.755A12.14 12.14 0 0 1 83.142.736 12.16 12.16 0 0 1 95.29 12.883Z" className="cls-2" data-name="Path 6769"/>
    <path fill="#f6cf76" d="M24.412 62.765h18.134v2.512H24.412z" data-name="Rectangle 8326"/>
    <path fill="#548e20" d="M72.276 11.384a10.04 10.04 0 0 1 4.588 13.078l-18.318-8.27a10.05 10.05 0 0 1 13.295-5.026 4.35 4.35 0 0 1 .435.218Z" data-name="Path 6770"/>
  </g>

  </Icon>
);

export const Bedroom = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h10.636v2.364H0z" className="cls-6" data-name="Rectangle 8142"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 10" transform="translate(-1466.895 4423.216)">
      <path d="M1529.638-4380.694v-21.459h2.364v21.388Z" className="cls-2" data-name="Path 6556"/>
      <path fill="#f6cf76" d="m1541.842-4423.217 5.19 22.54h-32.427l5.184-22.54Z" data-name="Path 6557"/>
      <path d="M1493.98-4381.137h16.087v7.729h-16.087z" className="cls-2" data-name="Rectangle 8138"/>
      <path d="M1530.824-4382.237a8.817 8.817 0 0 1 8.816 8.823h-17.639a8.823 8.823 0 0 1 8.823-8.823Z" className="cls-2" data-name="Path 6558"/>
      <path d="M1492.618-4333.204h6.744v4.987h-6.744z" className="cls-2" data-name="Rectangle 8139"/>
      <path d="M1535.421-4333.204h6.751v4.987h-6.751z" className="cls-2" data-name="Rectangle 8140"/>
      <path fill="#cd4160" d="M1550.209-4353.31v20.105h-65.628v-20.105Z" data-name="Path 6559"/>
      <path d="M1550.209-4373.412v20.1h-65.628v-20.1h65.628Z" className="cls-5" data-name="Path 6560"/>
      <path d="M1519.136-4400.676h1.576v12.102h-1.576z" className="cls-5" data-name="Rectangle 8141"/>
      <use xlinkHref="#b" className="cls-6" data-name="Rectangle 8142" transform="translate(1512.077 -4344.708)"/>
      <use xlinkHref="#b" className="cls-6" data-name="Rectangle 8143" transform="translate(1512.077 -4364.08)"/>
    </g>
  </g>

  </Icon>
);

export const Bedroom1 = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h10.636v2.364H0z" className="cls-5" data-name="Rectangle 8142"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Bedroom &#x2013; 1">
    <g data-name="Group 9" transform="translate(-1466.895 4423.216)">
      <path d="M1529.638-4380.694v-21.459h2.364v21.388Z" className="cls-2" data-name="Path 6556"/>
      <path fill="#f6cf76" d="m1541.842-4423.217 5.19 22.54h-32.427l5.184-22.54Z" data-name="Path 6557"/>
      <path d="M1493.98-4381.137h16.087v7.729h-16.087z" className="cls-2" data-name="Rectangle 8138"/>
      <path d="M1530.824-4382.237a8.817 8.817 0 0 1 8.816 8.823h-17.639a8.823 8.823 0 0 1 8.823-8.823Z" className="cls-2" data-name="Path 6558"/>
      <path d="M1492.618-4333.204h6.744v4.987h-6.744z" className="cls-2" data-name="Rectangle 8139"/>
      <path d="M1535.421-4333.204h6.751v4.987h-6.751z" className="cls-2" data-name="Rectangle 8140"/>
      <path d="M1550.209-4353.31v20.105h-65.628v-20.105Z" className="cls-2" data-name="Path 6559"/>
      <path d="M1550.209-4373.412v20.1h-65.628v-20.1h65.628Z" className="cls-2" data-name="Path 6560"/>
      <path fill="#e24c5d" d="M1519.136-4400.676h1.576v12.102h-1.576z" data-name="Rectangle 8141"/>
      <use xlinkHref="#b" className="cls-5" data-name="Rectangle 8142" transform="translate(1512.077 -4344.708)"/>
      <use xlinkHref="#b" className="cls-5" data-name="Rectangle 8143" transform="translate(1512.077 -4364.08)"/>
    </g>
  </g>

  </Icon>
);

export const Bedroom2 = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h10.636v2.364H0z" className="cls-7" data-name="Rectangle 8142"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Bedroom &#x2013; 2">
    <g data-name="Group 7" transform="translate(-1466.895 4423.216)">
      <path d="M1529.638-4380.694v-21.459h2.364v21.388Z" className="cls-2" data-name="Path 6556"/>
      <path fill="#f6cf76" d="m1541.842-4423.217 5.19 22.54h-32.427l5.184-22.54Z" data-name="Path 6557"/>
      <path d="M1493.98-4381.137h16.087v7.729h-16.087z" className="cls-2" data-name="Rectangle 8138"/>
      <path d="M1530.824-4382.237a8.817 8.817 0 0 1 8.816 8.823h-17.639a8.823 8.823 0 0 1 8.823-8.823Z" className="cls-2" data-name="Path 6558"/>
      <path d="M1492.618-4333.204h6.744v4.987h-6.744z" className="cls-2" data-name="Rectangle 8139"/>
      <path d="M1535.421-4333.204h6.751v4.987h-6.751z" className="cls-2" data-name="Rectangle 8140"/>
      <path fill="#548e20" d="M1550.209-4353.31v20.105h-65.628v-20.105Z" data-name="Path 6559"/>
      <path fill="#6ab528" d="M1550.209-4373.412v20.1h-65.628v-20.1h65.628Z" data-name="Path 6560"/>
      <path fill="#e24c5d" d="M1519.136-4400.676h1.576v12.102h-1.576z" data-name="Rectangle 8141"/>
      <use xlinkHref="#b" className="cls-7" data-name="Rectangle 8142" transform="translate(1512.077 -4344.708)"/>
      <use xlinkHref="#b" className="cls-7" data-name="Rectangle 8143" transform="translate(1512.077 -4364.08)"/>
    </g>
  </g>

  </Icon>
);

export const Bedroom3 = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h10.636v2.364H0z" className="cls-6" data-name="Rectangle 8142"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Bedroom &#x2013; 3">
    <g data-name="Group 8" transform="translate(-1466.895 4423.216)">
      <path d="M1529.638-4380.694v-21.459h2.364v21.388Z" className="cls-2" data-name="Path 6556"/>
      <path d="m1541.842-4423.217 5.19 22.54h-32.427l5.184-22.54Z" className="cls-3" data-name="Path 6557"/>
      <path d="M1493.98-4381.137h16.087v7.729h-16.087z" className="cls-2" data-name="Rectangle 8138"/>
      <path d="M1530.824-4382.237a8.817 8.817 0 0 1 8.816 8.823h-17.639a8.823 8.823 0 0 1 8.823-8.823Z" className="cls-2" data-name="Path 6558"/>
      <path d="M1492.618-4333.204h6.744v4.987h-6.744z" className="cls-2" data-name="Rectangle 8139"/>
      <path d="M1535.421-4333.204h6.751v4.987h-6.751z" className="cls-2" data-name="Rectangle 8140"/>
      <path fill="#fea856" d="M1550.209-4353.31v20.105h-65.628v-20.105Z" data-name="Path 6559"/>
      <path d="M1550.209-4373.412v20.1h-65.628v-20.1h65.628Z" className="cls-3" data-name="Path 6560"/>
      <path fill="#e24c5d" d="M1519.136-4400.676h1.576v12.102h-1.576z" data-name="Rectangle 8141"/>
      <use xlinkHref="#b" className="cls-6" data-name="Rectangle 8142" transform="translate(1512.077 -4344.708)"/>
      <use xlinkHref="#b" className="cls-6" data-name="Rectangle 8143" transform="translate(1512.077 -4364.08)"/>
    </g>
  </g>

  </Icon>
);

export const Brick = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h33.144v29.66H0z" className="cls-3" data-name="Rectangle 23"/>
    <path id="c" d="M0 0h101.081v3.256H0z" className="cls-4" data-name="Rectangle 25"/>
    <path id="d" d="M0 0h3.256v29.66H0z" className="cls-4" data-name="Rectangle 28"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 10566" transform="translate(428.02 -828.379)">
      <path d="M-327.02 890.703v29.66h-67.938v-29.66h67.938Z" className="cls-2" data-name="Path 24"/>
      <path d="M-394.959 861.044h34.794v29.657h-67.937v-29.657Z" className="cls-2" data-name="Path 25"/>
      <path d="M-327.02 831.396v29.65h-67.938v-29.65Z" className="cls-2" data-name="Path 26"/>
      <path d="M-428.102 831.396h33.144v29.648h-33.144z" className="cls-3" data-name="Rectangle 22"/>
      <use xlinkHref="#b" className="cls-3" data-name="Rectangle 23" transform="translate(-360.164 861.043)"/>
      <use xlinkHref="#b" className="cls-3" data-name="Rectangle 24" transform="translate(-428.102 890.703)"/>
      <use xlinkHref="#c" className="cls-4" data-name="Rectangle 25" transform="translate(-428.102 889.078)"/>
      <use xlinkHref="#c" className="cls-4" data-name="Rectangle 26" transform="translate(-428.102 859.417)"/>
      <path d="M-396.586 831.396h3.256v31.125h-3.256z" className="cls-4" data-name="Rectangle 27"/>
      <use xlinkHref="#d" className="cls-4" data-name="Rectangle 28" transform="translate(-361.792 861.043)"/>
      <use xlinkHref="#d" className="cls-4" data-name="Rectangle 29" transform="translate(-396.586 890.703)"/>
    </g>
  </g>

  </Icon>
);

export const Cabinet = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h2.659v10.182H0z" className="cls-2" data-name="Rectangle 13030"/>
    <path id="c" d="M0 0h8.865v5.097H0z" className="cls-2" data-name="Rectangle 13028"/>
    <path id="d" d="M0 0h2.881v2.659H0z" className="cls-3" data-name="Rectangle 13031"/>
    <path id="e" d="M0 0h13.74v2.659H0z" className="cls-2" data-name="Rectangle 13026"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 10585" transform="translate(-1956.865 -1403.343)">
      <use xlinkHref="#b" className="cls-2" data-name="Rectangle 13030" transform="translate(2045.811 1443.847)"/>
      <use xlinkHref="#b" className="cls-2" data-name="Rectangle 13025" transform="translate(1966.03 1443.847)"/>
      <path d="M1972.793 1416.434v6.676h-10.859v-6.675a5.43 5.43 0 1 1 10.859 0" className="cls-3" data-name="Path 9390"/>
      <path fill="#d2edef" d="M2034.845 1433.183v7.074h-54.96v-27.484h54.96Z" data-name="Path 9391"/>
      <path d="M2052.574 1416.434v6.676h-10.859v-6.675a5.43 5.43 0 1 1 10.859 0" className="cls-3" data-name="Path 9397"/>
      <path d="M1972.793 1423.11v16.691a5.43 5.43 0 1 1-10.859 0v-16.691Z" className="cls-2" data-name="Path 9392"/>
      <path d="M2052.574 1423.11v16.691a5.43 5.43 0 1 1-10.859 0v-16.691Z" className="cls-2" data-name="Path 9398"/>
      <path fill="#323954" d="m2010.689 1447.009 3.546 7.021h-13.962l3.55-7.021Z" data-name="Path 9393"/>
      <path d="M2034.845 1440.253v6.755h-54.96v-6.755h54.96Z" className="cls-2" data-name="Path 9394"/>
      <use xlinkHref="#c" className="cls-2" data-name="Rectangle 13028" transform="translate(1969.132 1493.245)"/>
      <use xlinkHref="#c" className="cls-2" data-name="Rectangle 13029" transform="translate(2036.618 1493.245)"/>
      <path fill="#fea856" d="M2054.79 1473.638v19.608h-94.85v-19.608Z" data-name="Path 9396"/>
      <path fill="#f6cf76" d="M2054.79 1454.029v19.608h-94.85v-19.608h94.85Z" data-name="Path 9395"/>
      <use xlinkHref="#d" className="cls-3" data-name="Rectangle 13031" transform="translate(2045.704 1428.872)"/>
      <use xlinkHref="#d" className="cls-3" data-name="Rectangle 13032" transform="translate(2045.704 1434.074)"/>
      <use xlinkHref="#d" className="cls-3" data-name="Rectangle 13023" transform="translate(1965.923 1428.872)"/>
      <use xlinkHref="#d" className="cls-3" data-name="Rectangle 13024" transform="translate(1965.923 1434.074)"/>
      <use xlinkHref="#e" className="cls-2" data-name="Rectangle 13026" transform="translate(2000.495 1462.503)"/>
      <use xlinkHref="#e" className="cls-2" data-name="Rectangle 13027" transform="translate(2000.495 1482.111)"/>
    </g>
  </g>

  </Icon>
);

export const Change = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h49.213v2.856H0z" className="cls-4" data-name="Rectangle 9086"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g data-name="Group 10111">
    <g clipPath="url(#a)" data-name="Group 6783">
      <g data-name="Group 6814" transform="translate(3.71)">
        <path d="M68.427 14.728H53.299V0Z" className="cls-2" data-name="Path 7238"/>
        <path fill="#d2edef" d="M68.427 87.012V95H0V0h53.3v14.729h15.129v32.667a19.8 19.8 0 0 0-17.718 10.958 19.744 19.744 0 0 0-2.085 8.855 19.8 19.8 0 0 0 19.801 19.803Z" data-name="Path 7239"/>
        <path d="M9.321 12.53h18.566v2.856H9.321z" className="cls-4" data-name="Rectangle 9085"/>
        <use xlinkHref="#b" className="cls-4" data-name="Rectangle 9086" transform="translate(9.608 30.353)"/>
        <use xlinkHref="#b" className="cls-4" data-name="Rectangle 9087" transform="translate(9.608 39.207)"/>
        <use xlinkHref="#b" className="cls-4" data-name="Rectangle 9088" transform="translate(9.608 48.062)"/>
        <use xlinkHref="#b" className="cls-4" data-name="Rectangle 9089" transform="translate(9.608 56.926)"/>
        <use xlinkHref="#b" className="cls-4" data-name="Rectangle 9090" transform="translate(9.608 65.781)"/>
        <path d="M9.608 74.635h26.668v2.856H9.608z" className="cls-4" data-name="Rectangle 9091"/>
        <path d="M68.427 47.396a19.819 19.819 0 0 1 19.815 19.813 19.811 19.811 0 0 1-19.815 19.8 19.8 19.8 0 0 1-19.8-19.8 19.741 19.741 0 0 1 2.086-8.854 19.8 19.8 0 0 1 17.714-10.959Z" className="cls-2" data-name="Path 7240"/>
        <path fill="#fff" d="M66.513 78.339a1.428 1.428 0 0 1-1.009-.418l-9.045-9.045 2.019-2.019 7.9 7.9 13.107-16.815 2.252 1.755-14.1 18.09a1.432 1.432 0 0 1-1.039.548Z" data-name="Path 7241"/>
      </g>
      <g data-name="Group 6815" transform="translate(49.236 43.449)">
        <circle cx="24.027" cy="24.027" r="24.027" fill="#e24c5d" data-name="Ellipse 616"/>
        <text fill="#fff" data-name="!" font-family="Poppins-ExtraBold,Poppins" fontSize="29" fontWeight="800" transform="translate(17.704 32.529)"><tspan x="0" y="0">!</tspan></text>
      </g>
    </g>
  </g>

  </Icon>
);

export const Closeout = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10102">
    <path fill="none" d="M2-1h97v97H2z" data-name="Rectangle 12204"/>
    <g data-name="Group 10102">
      <path fill="#f6cf76" d="M29.776 33.024a3.442 3.442 0 0 0-3.444 3.443 3.446 3.446 0 0 0 3.444 3.442 3.446 3.446 0 0 0 3.442-3.442 3.441 3.441 0 0 0-3.442-3.443Zm0-7.633a14.645 14.645 0 0 1 14.642 14.65 14.647 14.647 0 0 1-9.605 13.738v34.775l-2.7 6.523-7.388-6.523v-5.218l3.279-2.539-3.279-2.531v-3.861l3.279-2.541-3.279-2.539v-3.862l3.279-2.54-3.279-2.532v-6.614a14.647 14.647 0 0 1-9.6-13.738 14.647 14.647 0 0 1 14.651-14.648Z" data-name="Path 7599"/>
      <path fill="#e24c5d" d="M59.8 74.348v10.707h13.615V74.348l-6.812-6.805Zm6.8-20.979h-6.112a2.246 2.246 0 0 0-1.595.657 2.246 2.246 0 0 0-.657 1.586 2.255 2.255 0 0 0 2.252 2.252h11.676a2.248 2.248 0 0 0 2.242-2.252 2.24 2.24 0 0 0-2.242-2.243Zm0-6.476h12.325a3.287 3.287 0 0 1 3.288 3.288v41.092a3.287 3.287 0 0 1-3.288 3.288H54.278a3.286 3.286 0 0 1-3.287-3.288V50.181a3.286 3.286 0 0 1 3.287-3.288Z" data-name="Path 7600"/>
      <path fill="#40566b" d="M66.603 54.601a1.232 1.232 0 0 1-1.232-1.231v-33.8A17.2 17.2 0 0 0 48.189 2.388 17.2 17.2 0 0 0 31.008 19.57v13.649a1.231 1.231 0 0 1-1.233 1.23 1.231 1.231 0 0 1-1.233-1.23V19.57A19.67 19.67 0 0 1 48.188-.078 19.67 19.67 0 0 1 67.835 19.57v33.8a1.232 1.232 0 0 1-1.232 1.231Z" data-name="Path 7601"/>
      <g data-name="Group 7198">
        <path d="M73.416 74.346v10.706H59.801V74.346l6.8-6.8Z" className="cls-7" data-name="Path 7602"/>
        <path d="m76.193 78.868-9.588-9.583-9.582 9.583-1.742-1.745 10.452-10.451a1.231 1.231 0 0 1 1.741 0l10.462 10.452Z" className="cls-7" data-name="Path 7603"/>
      </g>
    </g>
  </g>

  </Icon>
);

export const Closet = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g data-name="Group 16">
    <g clipPath="url(#a)" data-name="Group 6099">
      <path d="M49.052 42.487h2.715V81.4h-2.715z" className="cls-2" data-name="Rectangle 8286"/>
      <path d="M58.354 90.841a9.312 9.312 0 0 1-9.3-9.3v-7.167h2.715v7.167a6.587 6.587 0 1 0 13.173 0h2.713a9.312 9.312 0 0 1-9.301 9.3Z" className="cls-2" data-name="Path 6753"/>
      <path fill="#e24c5d" d="M2.344 52.178A48.023 48.023 0 0 1 50.165 4.167C37.578 19.115 32.231 36.786 34.103 51.798a18.584 18.584 0 0 0-31.759.379Z" data-name="Path 6754"/>
      <path fill="#cd4160" d="M50.653 4.167a48.124 48.124 0 0 1 47.819 48.137 18.5 18.5 0 0 0-31.749-.488c1.871-15.02-3.476-32.693-16.07-47.649Z" data-name="Path 6755"/>
      <path fill="#f6cf76" d="M50.653 4.167c12.6 14.956 17.945 32.629 16.07 47.649-.1.145-.2.289-.289.444a18.446 18.446 0 0 0-16.022-9.2h-.073a18.642 18.642 0 0 0-15.947 9.2c-.1-.154-.2-.308-.29-.461C32.23 36.787 37.58 19.116 50.165 4.168c.082 0 .166-.009.247-.009s.16.008.241.008Z" data-name="Path 6756"/>
    </g>
  </g>

  </Icon>
);

export const Colors = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10571">
    <path fill="#d2edef" d="M6.249 65.968h24.414v29.031H2.101a2.1 2.1 0 0 1-2.1-2.1V68.068a2.1 2.1 0 0 1 2.1-2.1h4.148Zm9.868 14.511a3.156 3.156 0 0 0-3.15-3.15 3.157 3.157 0 0 0-3.158 3.15 3.16 3.16 0 0 0 3.158 3.16 3.158 3.158 0 0 0 3.15-3.161Z" data-name="Path 7022"/>
    <path d="M95.549 49.329a2.107 2.107 0 0 1-.878 2.847l-10.2 5.373L71.01 32.006l-.069-.138 10.19-5.373a2.107 2.107 0 0 1 2.848.877Z" className="cls-3" data-name="Path 7023"/>
    <path d="m71.012 32.007 13.461 25.542-12.4 6.536-13.461-25.543-.07-.139 12.4-6.536Z" className="cls-4" data-name="Path 7024"/>
    <path d="m56.735 39.357 1.805-.954.069.138L72.07 64.084l-3.566 1.883H57.221L46.207 45.076l-.07-.129Z" className="cls-3" data-name="Path 7025"/>
    <path d="m46.208 45.076 11.014 20.891H41.374l-7.568-14.355-.07-.13 10.632-5.607 1.77-.928Z" className="cls-4" data-name="Path 7026"/>
    <path d="m33.804 51.608 7.569 14.354h-15.85l-4.113-7.819-.069-.131 8.809-4.653 3.584-1.882Z" className="cls-3" data-name="Path 7027"/>
    <path d="m21.412 58.15 4.112 7.818H6.249l9.6-5.06 5.494-2.89Z" className="cls-4" data-name="Path 7028"/>
    <path d="M70.517 13.579a2.1 2.1 0 0 1 .661 2.9l-5.894 9.34-24.545-15.5L46.632.98a2.094 2.094 0 0 1 2.9-.651Z" className="cls-5" data-name="Path 7029"/>
    <path d="m32.73 21.874 7.478-11.848 24.548 15.495-7.479 11.848z" className="cls-6" data-name="Rectangle 8732"/>
    <path d="m57.803 37.671-1.068 1.692-10.6 5.59-1.77.929-18.6-11.856 7.49-11.856Z" className="cls-5" data-name="Path 7030"/>
    <path d="m44.368 45.877-10.632 5.6-3.584 1.886-11.864-7.49 7.481-11.856Z" className="cls-6" data-name="Path 7031"/>
    <path d="m30.151 53.36-8.809 4.652-5.494 2.89-5.043-3.185 7.481-11.847Z" className="cls-5" data-name="Path 7032"/>
    <path d="m15.849 60.909-9.6 5.059h-.651l5.207-8.245Z" className="cls-6" data-name="Path 7033"/>
    <path d="M99.87 68.068v24.831a2.1 2.1 0 0 1-2.1 2.1H86.736V65.968h11.039a2.1 2.1 0 0 1 2.095 2.1Z" className="cls-7" data-name="Path 7034"/>
    <path d="M72.714 65.968h14.017V95H72.714z" className="cls-8" data-name="Rectangle 8733"/>
    <path d="M72.713 65.968v29.031H58.697V65.968h14.016Z" className="cls-7" data-name="Path 7035"/>
    <path d="M57.222 65.968h1.475v29.031H44.68V65.968Z" className="cls-8" data-name="Path 7036"/>
    <path d="M44.68 65.968v29.031H30.663V65.968H44.68Z" className="cls-7" data-name="Path 7037"/>
  </g>

  </Icon>
);

export const Concrete = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 8206">
    <path fill="#cd4160" d="M59.056 22.272a7.222 7.222 0 0 1 10.22 0 7.224 7.224 0 0 1 0 10.22 7.223 7.223 0 0 1-8.206 1.407l3.632-3.655a2.458 2.458 0 0 0-.007-3.478 2.457 2.457 0 0 0-3.469.017l-3.618 3.633a7.212 7.212 0 0 1 1.448-8.144Z" data-name="Path 8337"/>
    <path fill="#e24c5d" d="M89.434 2.118a7.239 7.239 0 0 1 0 10.221L69.28 32.493a7.225 7.225 0 0 0 0-10.221 7.222 7.222 0 0 0-10.22 0L79.205 2.118a7.235 7.235 0 0 1 10.229 0Z" data-name="Path 8338"/>
    <path fill="#d2edef" d="M61.227 26.783a2.457 2.457 0 0 1 3.469-.017 2.458 2.458 0 0 1 .007 3.478l-3.639 3.655h.007l-4.052 4.083.047 11.854a2.433 2.433 0 0 1-.716 1.743l-4.487 4.487-7.865 7.864a2.449 2.449 0 0 1-1.734.723 2.478 2.478 0 0 1-1.743-.716 2.464 2.464 0 0 1 0-3.477l7.872-7.864 3.764-3.765-.054-11.846a2.414 2.414 0 0 1 .716-1.742Z" data-name="Path 8339"/>
    <path fill="#00c0b7" d="M40.52 63.942a2.48 2.48 0 0 0 1.743.716 2.452 2.452 0 0 0 1.734-.723l7.865-7.865 13.612 13.62c2.862 2.862 2.007 6.067-1.907 7.11L14.915 94.752c-3.913 1.049-6.255-1.291-5.2-5.2l21.429-43.278c1.05-3.9 4.877-6.036 7.74-3.173l9.514 9.5-7.872 7.864a2.464 2.464 0 0 0-.006 3.477Z" data-name="Path 8340"/>
  </g>

  </Icon>
);

export const Deed = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h68.922v1.769H0z" className="cls-4" data-name="Rectangle 8823"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 8222" transform="translate(0 9.987)">
      <path fill="#d2edef" d="M94.878 6.121v64.411h-13.81v-6.829a7.039 7.039 0 0 0 2.344-5.255 7.045 7.045 0 0 0-7.043-7.05 7.042 7.042 0 0 0-7.05 7.05 7.017 7.017 0 0 0 2.352 5.255v6.829H6.112V6.121Z" data-name="Path 7088"/>
      <path d="M100.997 0v76.654l-6.121-6.122V6.122Z" className="cls-3" data-name="Path 7089"/>
      <path d="m101 0-6.12 6.122H6.112L0 0Z" className="cls-4" data-name="Path 7090"/>
      <path d="M101 76.653H81.068v-6.122h13.811Z" className="cls-4" data-name="Path 7091"/>
      <path fill="#f6cf76" d="M71.672 63.702a7.009 7.009 0 0 1-2.353-5.254 7.042 7.042 0 0 1 7.051-7.051 7.045 7.045 0 0 1 7.042 7.051 7.027 7.027 0 0 1-2.347 5.254v-.008a7.229 7.229 0 0 1-1.777 1.168 6.82 6.82 0 0 1-.662.266 6.17 6.17 0 0 1-.568.158c-.088.027-.186.044-.274.072a2.217 2.217 0 0 1-.247.044 7.287 7.287 0 0 1-1.168.1 7.469 7.469 0 0 1-1.177-.1 2.189 2.189 0 0 0-.247-.044c-.1-.027-.2-.045-.293-.072a.08.08 0 0 0-.044-.008c-.169-.044-.334-.1-.5-.15a7.9 7.9 0 0 1-.875-.371 7.1 7.1 0 0 1-.815-.477 6.148 6.148 0 0 1-.743-.585Z" data-name="Path 7092"/>
      <path fill="#e24c5d" d="M76.372 65.498a7.218 7.218 0 0 0 1.167-.1 2.239 2.239 0 0 0 .248-.044c.087-.027.186-.044.273-.071a4.032 4.032 0 0 0 .568-.158q.346-.122.663-.267a7.2 7.2 0 0 0 1.777-1.159V85.01l-4.7-2.742-4.7 2.742V63.699a6.936 6.936 0 0 0 .746.576 7.033 7.033 0 0 0 .813.478 6.5 6.5 0 0 0 .876.372c.167.052.335.105.5.149a.082.082 0 0 1 .043.008c.1.027.195.045.294.071a2.09 2.09 0 0 1 .245.044 7.414 7.414 0 0 0 1.187.101Z" data-name="Path 7093"/>
      <path d="M71.673 70.53v6.122H.001l6.112-6.122Z" className="cls-4" data-name="Path 7094"/>
      <path d="M6.112 6.122v64.406L0 76.65V-.004Z" className="cls-3" data-name="Path 7095"/>
      <path d="M29.742 14.914h40.356v1.769H29.742z" className="cls-4" data-name="Rectangle 8822"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8823" transform="translate(15.26 38.64)"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8824" transform="translate(15.26 44.666)"/>
      <path d="M15.26 57.563h17.975v1.769H15.26z" className="cls-4" data-name="Rectangle 8825"/>
      <path d="M40.003 57.563H57.97v1.769H40.003z" className="cls-4" data-name="Rectangle 8826"/>
      <g data-name="Group 6668">
        <path d="M33.835 31.494a4.931 4.931 0 0 1-2.034 1.991 6.463 6.463 0 0 1-3.116.714H24.45V22.982h4.235a6.585 6.585 0 0 1 3.127.7 4.875 4.875 0 0 1 2.022 1.967 5.929 5.929 0 0 1 .7 2.919 5.975 5.975 0 0 1-.699 2.926Zm-2.922-.564a3.1 3.1 0 0 0 .882-2.364 3.107 3.107 0 0 0-.882-2.364 3.418 3.418 0 0 0-2.466-.84h-1.28v6.41h1.283a3.417 3.417 0 0 0 2.463-.842Z" className="cls-4" data-name="Path 7096"/>
        <path d="M43.55 25.14v2.348h3.792v2.042H43.55v2.491h4.268v2.174h-6.98V22.978h6.98v2.158Z" className="cls-4" data-name="Path 7097"/>
        <path d="M56.832 25.14v2.348h3.79v2.042h-3.79v2.491h4.265v2.174h-6.979V22.978h6.979v2.158Z" className="cls-4" data-name="Path 7098"/>
        <path d="M76.782 31.494a4.949 4.949 0 0 1-2.032 1.991 6.474 6.474 0 0 1-3.115.714h-4.239V22.982h4.239a6.562 6.562 0 0 1 3.124.7 4.878 4.878 0 0 1 2.023 1.967 5.9 5.9 0 0 1 .706 2.919 5.944 5.944 0 0 1-.706 2.926Zm-2.92-.564a3.107 3.107 0 0 0 .879-2.364 3.111 3.111 0 0 0-.879-2.364 3.423 3.423 0 0 0-2.466-.84h-1.287v6.41h1.287a3.423 3.423 0 0 0 2.466-.842Z" className="cls-4" data-name="Path 7099"/>
      </g>
    </g>
  </g>

  </Icon>
);

export const Default = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10547">
    <path fill="#f2c45e" stroke="transparent" strokeMiterlimit="10" d="M.001 94.999v-44.99H34.89l9.182-12.981h56.929v57.971Z" data-name="Union 31"/>
    <path fill="#f99640" d="M101 21.088v15.939h-9.6V29.04H9.591v11.907h-.01v9.063H-.004V12.365H43.15l9.182 8.722Z" data-name="Path 8965"/>
    <path fill="#ffffff" d="M91.402 36.871v.156H44.071L34.89 50.01H9.584v-9.064h11.909V29.04h69.908Z" className="cls-4" data-name="Path 8966"/>
    <path fill="#ffffff" d="m21.495 29.04-11.9 11.9v-11.9Z" className="cls-4" data-name="Path 8968"/>
  </g>

  </Icon>
);

export const Delays = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h10.313v8.543H0z" className="cls-3" data-name="Rectangle 9624"/>
    <path id="c" d="M0 0h10.314v8.543H0z" className="cls-3" data-name="Rectangle 9626"/>
    <path id="d" d="M0 0h10.322v8.543H0z" className="cls-3" data-name="Rectangle 9635"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10101">
    <g data-name="Group 7154" transform="translate(5.283 14.995)">
      <path d="M75.338 80.005H0V22.258h90.433v43.451Z" className="cls-2" data-name="Path 7523"/>
      <path d="M90.434 65.709v14.3H75.339Z" className="cls-3" data-name="Path 7524"/>
      <path fill="#1e6388" d="M90.433 11.818v10.44H0v-10.44a4.223 4.223 0 0 1 4.327-4.1h13.819v2.258a3.694 3.694 0 0 0 1.181 2.694 4.117 4.117 0 0 0 2.847 1.119 3.92 3.92 0 0 0 4.02-3.813V7.718h38.033v2.258a3.7 3.7 0 0 0 1.181 2.694 4.123 4.123 0 0 0 2.848 1.119 3.927 3.927 0 0 0 4.027-3.813V7.718h13.821a4.223 4.223 0 0 1 4.329 4.1Z" data-name="Path 7525"/>
      <path fill="#324459" d="m90.434 65.709-15.095 14.3v-14.3Z" data-name="Path 7526"/>
      <use xlinkHref="#b" className="cls-3" data-name="Rectangle 9624" transform="translate(71.354 46.356)"/>
      <use xlinkHref="#b" className="cls-6" data-name="Rectangle 9625" transform="translate(71.354 32.375)"/>
      <path d="M72.285 7.72v2.259a3.927 3.927 0 0 1-4.028 3.813 4.125 4.125 0 0 1-2.847-1.12 3.7 3.7 0 0 1-1.181-2.694V3.811a3.927 3.927 0 0 1 4.027-3.813 4.12 4.12 0 0 1 2.848 1.119 3.711 3.711 0 0 1 1.181 2.694Z" className="cls-2" data-name="Path 7527"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 9626" transform="translate(56.004 60.338)"/>
      <use xlinkHref="#c" className="cls-6" data-name="Rectangle 9627" transform="translate(56.004 46.356)"/>
      <use xlinkHref="#c" className="cls-6" data-name="Rectangle 9628" transform="translate(56.004 32.375)"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 9629" transform="translate(40.646 60.338)"/>
      <use xlinkHref="#c" className="cls-6" data-name="Rectangle 9630" transform="translate(40.646 46.356)"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 9631" transform="translate(40.646 32.375)"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 9632" transform="translate(25.295 60.338)"/>
      <use xlinkHref="#c" className="cls-6" data-name="Rectangle 9633" transform="translate(25.295 46.356)"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 9634" transform="translate(25.295 32.375)"/>
      <path d="M26.194 7.72v2.259a3.921 3.921 0 0 1-4.02 3.813 4.118 4.118 0 0 1-2.847-1.12 3.7 3.7 0 0 1-1.181-2.694V3.811a3.927 3.927 0 0 1 4.027-3.813 4.137 4.137 0 0 1 2.847 1.119 3.734 3.734 0 0 1 1.173 2.694Z" className="cls-2" data-name="Path 7528"/>
      <use xlinkHref="#d" className="cls-3" data-name="Rectangle 9635" transform="translate(9.937 60.338)"/>
      <use xlinkHref="#d" className="cls-6" data-name="Rectangle 9636" transform="translate(9.937 46.356)"/>
      <use xlinkHref="#d" className="cls-3" data-name="Rectangle 9637" transform="translate(9.937 32.375)"/>
      <path fill="#fff" d="M56.003 14.99a1.65 1.65 0 0 1-1.649 1.65h-17.1a1.651 1.651 0 0 1-1.65-1.65 1.651 1.651 0 0 1 1.65-1.65h17.1a1.651 1.651 0 0 1 1.649 1.65Z" data-name="Path 7529"/>
    </g>
    <g data-name="Group 7155">
      <path d="M71.996 9.668V0h1.985v9.668z" className="cls-6" data-name="Rectangle 9638"/>
      <path d="M62.818 12.447 55.983 5.61l1.403-1.403 6.836 6.835z" className="cls-6" data-name="Rectangle 9639"/>
      <path d="m83.168 12.452-1.404-1.404L88.6 4.213l1.404 1.403z" className="cls-6" data-name="Rectangle 9640"/>
    </g>
  </g>

  </Icon>
);

export const Design = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10595">
    <path fill="#d2edef" d="M6.249 65.967h24.414v29.031H2.101a2.1 2.1 0 0 1-2.1-2.1V68.067a2.1 2.1 0 0 1 2.1-2.1h4.148Zm9.868 14.511a3.155 3.155 0 1 0-3.147 3.16 3.156 3.156 0 0 0 3.151-3.16" data-name="Path 9213"/>
    <path d="M95.548 49.33a2.106 2.106 0 0 1-.877 2.847l-10.2 5.372L71.01 32.006l-.069-.139 10.19-5.372a2.108 2.108 0 0 1 2.847.877Z" className="cls-3" data-name="Path 9214"/>
    <path d="m71.012 32.006 13.461 25.543-12.4 6.535-13.461-25.543-.069-.139 12.4-6.535Z" className="cls-4" data-name="Path 9215"/>
    <path d="m56.734 39.357 1.805-.955.069.14 13.461 25.542-3.566 1.883H57.221L46.206 45.076l-.07-.13Z" className="cls-3" data-name="Path 9216"/>
    <path d="m46.207 45.077 11.014 20.891H41.374l-7.569-14.355-.069-.129 10.632-5.607 1.77-.929Z" className="cls-4" data-name="Path 9217"/>
    <path d="m33.805 51.612 7.569 14.355H25.522l-4.112-7.82-.069-.13 8.808-4.651 3.584-1.883Z" className="cls-3" data-name="Path 9218"/>
    <path d="m21.411 58.148 4.112 7.82H6.248l9.6-5.06 5.494-2.89Z" className="cls-4" data-name="Path 9219"/>
    <path d="M70.517 13.578a2.1 2.1 0 0 1 .661 2.9l-5.893 9.339-24.545-15.5L46.633.978a2.1 2.1 0 0 1 2.9-.651Z" className="cls-5" data-name="Path 9220"/>
    <path d="m33.258 22.163 7.482-11.846 24.544 15.5-7.482 11.847z" className="cls-6" data-name="Rectangle 12913"/>
    <path d="m57.802 37.665-1.068 1.692-10.6 5.59-1.77.929-18.6-11.856 7.49-11.856Z" className="cls-5" data-name="Path 9221"/>
    <path d="m44.367 45.876-10.632 5.607-3.584 1.883-11.864-7.49 7.481-11.856Z" className="cls-6" data-name="Path 9222"/>
    <path d="m30.151 53.366-8.808 4.651-5.494 2.89-5.043-3.184 7.481-11.847Z" className="cls-5" data-name="Path 9223"/>
    <path d="m15.848 60.908-9.6 5.06h-.651l5.207-8.245Z" className="cls-6" data-name="Path 9224"/>
    <path d="M99.87 68.067v24.831a2.1 2.1 0 0 1-2.1 2.1H86.73V65.967h11.04a2.1 2.1 0 0 1 2.1 2.1" className="cls-7" data-name="Path 9225"/>
    <path d="M72.713 65.968H86.73V95H72.713z" className="cls-8" data-name="Rectangle 12914"/>
    <path d="M72.713 65.968v29.031H58.698V65.968h14.016Z" className="cls-7" data-name="Path 9226"/>
    <path d="M57.221 65.968h1.475v29.031H44.68V65.968Z" className="cls-8" data-name="Path 9227"/>
    <path d="M44.68 65.968v29.031H30.663V65.968H44.68Z" className="cls-7" data-name="Path 9228"/>
  </g>

  </Icon>
);

export const Document = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h25.594v20.171H0z" className="cls-3" data-name="Rectangle 8541"/>
    <path id="c" d="M0 0h25.594v20.03H0z" className="cls-3" data-name="Rectangle 8542"/>
    <path id="d" d="M0 0h23.454v2.815H0z" className="cls-6" data-name="Rectangle 8546"/>
    <path id="e" d="M0 0h54.912v2.815H0z" className="cls-6" data-name="Rectangle 8549"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 6788" transform="translate(6.823)">
      <path fill="#00c0b7" d="M14.28 80.72v7.14a7.14 7.14 0 0 1-14.281 0v-66.3H14.28Z" data-name="Path 6859"/>
      <use xlinkHref="#b" className="cls-3" data-name="Rectangle 8541" transform="translate(23.323 64.649)"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 8542" transform="translate(23.323 17.881)"/>
      <path fill="#d2edef" d="M7.139 95h73.074a7.144 7.144 0 0 0 7.141-7.139V.001H14.279v87.86A7.143 7.143 0 0 1 7.139 95Z" data-name="Path 6860"/>
      <use xlinkHref="#c" className="cls-5" data-name="Rectangle 8543" transform="translate(23.323 17.881)"/>
      <use xlinkHref="#b" className="cls-5" data-name="Rectangle 8544" transform="translate(23.323 64.649)"/>
      <path d="M23.323 9.025h55.146v2.815H23.323z" className="cls-6" data-name="Rectangle 8545"/>
      <use xlinkHref="#d" className="cls-6" data-name="Rectangle 8546" transform="translate(55.016 17.525)"/>
      <use xlinkHref="#d" className="cls-6" data-name="Rectangle 8547" transform="translate(55.016 26.71)"/>
      <use xlinkHref="#d" className="cls-6" data-name="Rectangle 8548" transform="translate(55.016 35.894)"/>
      <use xlinkHref="#e" className="cls-6" data-name="Rectangle 8549" transform="translate(23.558 45.079)"/>
      <use xlinkHref="#e" className="cls-6" data-name="Rectangle 8550" transform="translate(23.558 54.264)"/>
      <use xlinkHref="#d" className="cls-6" data-name="Rectangle 8551" transform="translate(55.016 63.449)"/>
      <use xlinkHref="#d" className="cls-6" data-name="Rectangle 8552" transform="translate(55.016 72.634)"/>
      <use xlinkHref="#d" className="cls-6" data-name="Rectangle 8553" transform="translate(55.016 81.819)"/>
    </g>
  </g>

  </Icon>
);

export const Doors = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10574">
    <path fill="#cd4160" d="M83.503 0v95h-7.3V7.1H24.797V95h-7.3V0Z" data-name="Path 17"/>
    <path fill="#e24c5d" d="M76.207 7.097v87.9H24.793v-87.9Zm-12.35 28.218v-16H37.145v16ZM40.866 60.95a3.677 3.677 0 0 0-3.721-3.631 3.679 3.679 0 0 0-3.732 3.631 3.676 3.676 0 0 0 3.732 3.619 3.674 3.674 0 0 0 3.721-3.616Z" data-name="Path 18"/>
    <path fill="#d2edef" d="M37.143 19.315h26.713v16.001H37.143z" data-name="Rectangle 3"/>
    <path fill="#40566b" d="M40.866 60.946a3.674 3.674 0 0 1-3.721 3.62 3.675 3.675 0 0 1-3.731-3.62 3.677 3.677 0 0 1 3.731-3.63 3.676 3.676 0 0 1 3.721 3.63Z" data-name="Path 19"/>
  </g>

  </Icon>
);

export const Electrics = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="c" d="M0 0h7.755v11.417H0z" className="cls-7" data-name="Rectangle 12777"/>
  </defs>
  <defs>
    <clipPath id="b">
      <path fill="none" d="M0 0h66.747v95H0z" data-name="Rectangle 12780"/>
    </clipPath>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10573">
    <g clipPath="url(#b)" data-name="Group 10572" transform="translate(17.127)">
      <path fill="#40566b" d="M5.709 54.714v-8.343h55.32v10.978c0 11.013-8.483 20.375-17.4 20.375H23.115c-8.834 0-17.406-9.362-17.406-20.375Z" data-name="Path 8978"/>
      <path fill="#323954" d="M29.921 77.725h6.895V95h-6.895z" data-name="Rectangle 12776"/>
      <path d="M6.393 34.954v11.417H2.635A2.631 2.631 0 0 1 0 43.736v-6.147a2.637 2.637 0 0 1 2.635-2.635Z" className="cls-6" data-name="Path 8979"/>
      <path d="M14.14 34.954v11.417H6.394V34.954h7.746Z" className="cls-7" data-name="Path 8980"/>
      <path d="M21.886 34.954v11.417H14.14V34.954h7.746Z" className="cls-6" data-name="Path 8981"/>
      <use xlinkHref="#c" className="cls-7" data-name="Rectangle 12777" transform="translate(21.886 34.954)"/>
      <path d="M29.641 34.954h7.745v11.417h-7.745z" className="cls-6" data-name="Rectangle 12778"/>
      <use xlinkHref="#c" className="cls-7" data-name="Rectangle 12779" transform="translate(37.386 34.954)"/>
      <path d="M45.142 34.954h7.746v11.417h-7.746Z" className="cls-6" data-name="Path 8982"/>
      <path d="M66.747 37.589v6.148a2.637 2.637 0 0 1-2.635 2.635h-3.469V34.954h3.469a2.643 2.643 0 0 1 2.635 2.635" className="cls-6" data-name="Path 8983"/>
      <path d="M52.888 34.954h7.755v11.417h-7.755Z" className="cls-7" data-name="Path 8984"/>
      <path d="M20.586 4.286v30.668h-8.572V4.286a4.286 4.286 0 0 1 8.572 0" className="cls-8" data-name="Path 8985"/>
      <path d="M54.732 4.286v30.668H46.16V4.286a4.286 4.286 0 0 1 8.572 0" className="cls-8" data-name="Path 8986"/>
    </g>
  </g>

  </Icon>
);

export const Electronics = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="c" d="M0 0h7.755v11.417H0z" className="cls-7" data-name="Rectangle 12777"/>
  </defs>
  <defs>
    <clipPath id="b">
      <path fill="none" d="M0 0h66.747v95H0z" data-name="Rectangle 12780"/>
    </clipPath>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10573">
    <g clipPath="url(#b)" data-name="Group 10572" transform="translate(17.127)">
      <path fill="#40566b" d="M5.709 54.714v-8.343h55.32v10.978c0 11.013-8.483 20.375-17.4 20.375H23.115c-8.834 0-17.406-9.362-17.406-20.375Z" data-name="Path 8978"/>
      <path fill="#323954" d="M29.921 77.725h6.895V95h-6.895z" data-name="Rectangle 12776"/>
      <path d="M6.393 34.954v11.417H2.635A2.631 2.631 0 0 1 0 43.736v-6.147a2.637 2.637 0 0 1 2.635-2.635Z" className="cls-6" data-name="Path 8979"/>
      <path d="M14.14 34.954v11.417H6.394V34.954h7.746Z" className="cls-7" data-name="Path 8980"/>
      <path d="M21.886 34.954v11.417H14.14V34.954h7.746Z" className="cls-6" data-name="Path 8981"/>
      <use xlinkHref="#c" className="cls-7" data-name="Rectangle 12777" transform="translate(21.886 34.954)"/>
      <path d="M29.641 34.954h7.745v11.417h-7.745z" className="cls-6" data-name="Rectangle 12778"/>
      <use xlinkHref="#c" className="cls-7" data-name="Rectangle 12779" transform="translate(37.386 34.954)"/>
      <path d="M45.142 34.954h7.746v11.417h-7.746Z" className="cls-6" data-name="Path 8982"/>
      <path d="M66.747 37.589v6.148a2.637 2.637 0 0 1-2.635 2.635h-3.469V34.954h3.469a2.643 2.643 0 0 1 2.635 2.635" className="cls-6" data-name="Path 8983"/>
      <path d="M52.888 34.954h7.755v11.417h-7.755Z" className="cls-7" data-name="Path 8984"/>
      <path d="M20.586 4.286v30.668h-8.572V4.286a4.286 4.286 0 0 1 8.572 0" className="cls-8" data-name="Path 8985"/>
      <path d="M54.732 4.286v30.668H46.16V4.286a4.286 4.286 0 0 1 8.572 0" className="cls-8" data-name="Path 8986"/>
    </g>
  </g>

  </Icon>
);

export const Email = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 9063">
    <path fill="#d2edef" d="M71.217 0H15.489v46.352L38.825 65.8l11.684 9.744L62.192 65.8l1.292-1.071 21.792-18.167V14.056Z" data-name="Path 6800"/>
    <path d="M85.272 14.055H71.217V0Z" className="cls-3" data-name="Path 6801"/>
    <path d="M15.49 26.673v19.678l-11.8-9.835Z" className="cls-3" data-name="Path 6802"/>
    <path d="M97.314 36.517 85.273 46.562v-20.09Z" className="cls-3" data-name="Path 6803"/>
    <path d="M38.802 65.818 3.687 95V36.627Z" className="cls-4" data-name="Path 6804"/>
    <path d="m97.314 95.001-35.125-29.2 1.292-1.071 33.834-28.111Z" className="cls-4" data-name="Path 6805"/>
    <path fill="#f6cf76" d="m38.821 65.8 11.685 9.743L62.189 65.8 97.314 95H3.688l35.115-29.182Z" data-name="Path 6806"/>
    <path d="M55.238 29.218a8.313 8.313 0 0 0-2.481-.348c-4.358 0-7.884 4.112-7.884 8.927 0 2.179 1.09 3.764 3.278 3.764 2.875 0 5.5-3.663 6.053-6.74Zm11.409 3.223c0 7.435-4.166 11.8-8.828 11.8-1.987 0-3.818-1.337-3.671-4.367h-.2c-1.731 2.93-4.02 4.367-6.987 4.367-2.884 0-5.358-2.335-5.358-6.254a11.7 11.7 0 0 1 11.748-11.748 13.112 13.112 0 0 1 5.312 1.035l-1.686 8.982c-.741 3.763-.146 5.5 1.483 5.558 2.537.046 5.358-3.333 5.358-9.083 0-7.188-4.313-12.352-12-12.352-8.085 0-14.879 6.4-14.879 16.472 0 8.278 5.411 13.388 12.746 13.388a15.592 15.592 0 0 0 7.5-1.731l.786 2.134a18.067 18.067 0 0 1-8.826 1.978c-8.131 0-15.035-5.952-15.035-15.475 0-10.219 7.1-19.046 18.112-19.046 8.62.003 14.426 6.056 14.426 14.342Z" className="cls-3" data-name="Path 6807"/>
  </g>

  </Icon>
);

export const Engineering = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h19.855v19.855H0z" className="cls-4" data-name="Rectangle 10079"/>
    <path id="c" d="M0 0h10.469v2.427H0z" className="cls-3" data-name="Rectangle 10083"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 8216" transform="translate(0 7)">
      <path fill="#d2edef" d="M6.812 15.567h39.711v8.673H6.812z" data-name="Rectangle 10078"/>
      <path d="M50.722 7.695a2.626 2.626 0 0 1 2.621 2.621v63.165a2.619 2.619 0 0 1-2.621 2.615H2.613A2.618 2.618 0 0 1 0 73.481V10.317a2.624 2.624 0 0 1 2.613-2.621Zm-4.2 61.985V29.966H6.812v39.711h39.711Zm0-45.439v-8.675H6.812v8.673Z" className="cls-3" data-name="Path 7862"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 10079" transform="translate(26.667 49.824)"/>
      <use xlinkHref="#b" className="cls-5" data-name="Rectangle 10080" transform="translate(26.667 29.969)"/>
      <use xlinkHref="#b" className="cls-5" data-name="Rectangle 10081" transform="translate(6.812 49.824)"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 10082" transform="translate(6.812 29.969)"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 10083" transform="translate(11.044 38.983)"/>
      <path d="M15.065 34.961h2.427v10.47h-2.427z" className="cls-3" data-name="Rectangle 10084"/>
      <path d="M11.715 57.318 13.43 55.6l7.412 7.403-1.715 1.718z" className="cls-3" data-name="Rectangle 10085"/>
      <path d="m11.714 63.002 7.415-7.4 1.714 1.717-7.414 7.4z" className="cls-3" data-name="Rectangle 10086"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 10087" transform="translate(31.498 38.983)"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 10088" transform="translate(31.498 56.232)"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 10089" transform="translate(31.498 61.16)"/>
      <g data-name="Group 7461">
        <path d="M90.611 74.866 80.335 42.835a23.531 23.531 0 0 0 6.36-2.153Z" className="cls-5" data-name="Path 7863"/>
        <path d="M78.207 28.343a3.2 3.2 0 0 0 .38-.122 2.076 2.076 0 0 0 .34-.122.332.332 0 0 0 .063-.023 3.271 3.271 0 0 0 .332-.138 2.8 2.8 0 0 0 .357-.17 3.032 3.032 0 0 0 .34-.177 8.605 8.605 0 0 0 1.036-.672 6.93 6.93 0 0 0 .832-.72 8.7 8.7 0 0 0 .777-.866 3.692 3.692 0 0 0 .227-.307 3.418 3.418 0 0 0 .219-.324 2.248 2.248 0 0 0 .2-.332 3.557 3.557 0 0 0 .2-.34 8.4 8.4 0 0 0 .574-1.359c.05-.155.1-.315.138-.477a2.776 2.776 0 0 0 .088-.357 3.943 3.943 0 0 0 .082-.429 3.734 3.734 0 0 0 .056-.405l2.25 19.678a23.526 23.526 0 0 1-6.36 2.153l-4.539-14.151a9.061 9.061 0 0 0 1.231-.088 4.77 4.77 0 0 0 .355-.058 6.592 6.592 0 0 0 .822-.194Z" className="cls-5" data-name="Path 7864"/>
        <path d="M75.795 44.072a25.753 25.753 0 0 1-4.688-.434.81.81 0 0 1 .3-1.593 23.717 23.717 0 0 0 8.777-.006 22.723 22.723 0 0 0 6.148-2.08 23.433 23.433 0 0 0 13.051-21.092.81.81 0 0 1 1.619 0 25.036 25.036 0 0 1-13.944 22.538 24.3 24.3 0 0 1-6.574 2.225 24.89 24.89 0 0 1-4.689.442Z" className="cls-6" data-name="Path 7865"/>
        <path d="M78.701 0v6.975h-5.8V0Z" className="cls-6" data-name="Path 7866"/>
        <path d="M74.583 6.974h2.427v4.709h-2.427z" className="cls-5" data-name="Rectangle 10090"/>
        <path d="M75.796 11.264a8.677 8.677 0 0 1 8.65 9.74 3.72 3.72 0 0 1-.057.405 3.861 3.861 0 0 1-.082.429 2.81 2.81 0 0 1-.089.357c-.04.162-.088.322-.138.477a8.738 8.738 0 0 1-.574 1.359 3.469 3.469 0 0 1-.2.34 4.232 4.232 0 0 1-.2.332 3.407 3.407 0 0 1-.219.324 3.705 3.705 0 0 1-.227.307 8.721 8.721 0 0 1-.777.866 8.852 8.852 0 0 1-.832.72 8.647 8.647 0 0 1-1.036.672c-.113.065-.227.12-.34.177a2.828 2.828 0 0 1-.357.17 3.267 3.267 0 0 1-.332.138.328.328 0 0 1-.063.023 2.029 2.029 0 0 1-.34.122 3.153 3.153 0 0 1-.38.122 6.585 6.585 0 0 1-.826.193 4.857 4.857 0 0 1-.355.058 8.591 8.591 0 0 1-2.46 0c-.13-.016-.268-.042-.4-.065-.267-.05-.525-.115-.785-.187s-.5-.153-.744-.25c-.121-.05-.243-.1-.365-.155a2.9 2.9 0 0 1-.355-.17 8.342 8.342 0 0 1-1.659-1.067 8.705 8.705 0 0 1-.549-.5 8.774 8.774 0 0 1-.777-.866 9 9 0 0 1-.655-.962c-.13-.227-.252-.454-.364-.689a9.97 9.97 0 0 1-.43-1.107 9.071 9.071 0 0 1-.332-1.571h-.003a8.35 8.35 0 0 1-.065-1.027 8.716 8.716 0 0 1 8.717-8.715Z" className="cls-6" data-name="Path 7867"/>
        <path d="M67.916 23.682a5.442 5.442 0 0 0 .364.689 7.639 7.639 0 0 0 .655.962 7.064 7.064 0 0 0 .777.866c.177.178.355.34.549.5a8.334 8.334 0 0 0 1.659 1.067 2.9 2.9 0 0 0 .355.17c.122.057.243.105.365.155.242.1.493.177.744.25s.519.137.786.187c.128.023.267.048.4.065a9.021 9.021 0 0 0 1.229.088L71.26 42.839 60.993 74.864l6.158-53.862a7.216 7.216 0 0 0 .339 1.571 7.691 7.691 0 0 0 .426 1.109Z" className="cls-5" data-name="Path 7868"/>
        <path fill="#00a1aa" d="M75.796 16.22a3.753 3.753 0 0 1 3.755 3.755 3.909 3.909 0 0 1-.027.444 1.526 1.526 0 0 1-.025.173 1.741 1.741 0 0 1-.035.185 1.315 1.315 0 0 1-.038.154c-.017.07-.038.138-.058.205a3.7 3.7 0 0 1-.248.585 1.18 1.18 0 0 1-.083.147c-.029.05-.055.1-.087.143s-.06.1-.095.14a1.474 1.474 0 0 1-.1.131 3.966 3.966 0 0 1-.335.374 3.916 3.916 0 0 1-.359.31 3.751 3.751 0 0 1-.447.288c-.048.03-.1.054-.147.078a1.3 1.3 0 0 1-.153.072 1.255 1.255 0 0 1-.144.06l-.027.01a1 1 0 0 1-.147.053 1.365 1.365 0 0 1-.165.052 2.6 2.6 0 0 1-.356.083 1.514 1.514 0 0 1-.153.025 3.683 3.683 0 0 1-1.061 0 2.508 2.508 0 0 1-.17-.028 3.356 3.356 0 0 1-.339-.08 3.757 3.757 0 0 1-.477-.175 1.2 1.2 0 0 1-.153-.072 3.624 3.624 0 0 1-.716-.462 3.193 3.193 0 0 1-.237-.215 3.966 3.966 0 0 1-.335-.374 3.691 3.691 0 0 1-.282-.415c-.057-.1-.108-.2-.158-.3a4.528 4.528 0 0 1-.183-.477 3.887 3.887 0 0 1-.143-.675 3.587 3.587 0 0 1-.028-.444 3.756 3.756 0 0 1 3.756-3.75Z" data-name="Path 7869"/>
      </g>
    </g>
  </g>

  </Icon>
);

export const Fencing = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h9.87v5.08H0z" className="cls-2" data-name="Rectangle 12978"/>
    <path id="c" d="M0 0h9.869v5.08H0z" className="cls-2" data-name="Rectangle 12979"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 10587" transform="translate(-2628.911 -2803.23)">
      <use xlinkHref="#b" className="cls-2" data-name="Rectangle 12978" transform="translate(2699.84 2884.47)"/>
      <use xlinkHref="#c" className="cls-2" data-name="Rectangle 12979" transform="translate(2674.471 2884.47)"/>
      <use xlinkHref="#b" className="cls-2" data-name="Rectangle 12980" transform="translate(2649.101 2884.47)"/>
      <use xlinkHref="#b" className="cls-2" data-name="Rectangle 12981" transform="translate(2649.101 2835.97)"/>
      <use xlinkHref="#c" className="cls-2" data-name="Rectangle 12982" transform="translate(2674.471 2835.97)"/>
      <use xlinkHref="#b" className="cls-2" data-name="Rectangle 12983" transform="translate(2699.84 2835.97)"/>
      <path d="M2725.21 2834.43v61.11a7.75 7.75 0 1 1-15.5 0v-65.559a7.75 7.75 0 1 1 15.5 0Z" className="cls-3" data-name="Path 9323"/>
      <path d="M2699.84 2884.47v11.07a7.75 7.75 0 0 1-15.5 0v-65.559a7.75 7.75 0 0 1 15.5 0v54.489Z" className="cls-3" data-name="Path 9324"/>
      <path d="M2674.471 2884.47v11.07a7.75 7.75 0 0 1-15.5 0v-65.559a7.75 7.75 0 0 1 15.5 0v54.489Z" className="cls-3" data-name="Path 9325"/>
      <path d="M2649.1 2884.47v11.07a7.745 7.745 0 1 1-15.49 0v-65.559a7.745 7.745 0 1 1 15.49 0v54.489Z" className="cls-3" data-name="Path 9326"/>
    </g>
  </g>

  </Icon>
);

export const Flatware = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10582">
    <path fill="#f6cf76" d="M51.976 27.665A20.625 20.625 0 1 1 31.351 48.29a20.629 20.629 0 0 1 20.625-20.625" data-name="Path 9089"/>
    <path fill="#fea856" d="M51.975 68.912A20.625 20.625 0 1 0 31.35 48.287a20.629 20.629 0 0 0 20.625 20.625m0 12.273a32.9 32.9 0 1 1 32.9-32.9 32.892 32.892 0 0 1-32.9 32.9" data-name="Path 9090"/>
    <path d="M97.997 13.003a1.892 1.892 0 0 0-.312-.021c-.773 0-3.422.362-5.075 5.018-2.368 6.654-5.3 19.446-1.251 25.18a6.466 6.466 0 0 0 5.159 2.769v8.375h2.58V14.282a1.338 1.338 0 0 0-1.1-1.275" className="cls-4" data-name="Path 9091"/>
    <path d="M12.402 15.856v8.189c0 .061-.014.12-.016.18H8.781v-8.369h-2.58v8.369h-3.6c0-.06-.018-.119-.018-.18v-8.189H.003v8.189a7.473 7.473 0 0 0 6.2 7.373v23.877h2.58V31.417a7.5 7.5 0 0 0 6.2-7.372v-8.189Z" className="cls-4" data-name="Path 9092"/>
    <path d="M97.809 82.014a3.186 3.186 0 0 1-2.253-.938 3.132 3.132 0 0 1-.937-2.253V55.386a3.189 3.189 0 0 1 3.191-3.192 3.2 3.2 0 0 1 3.191 3.192v23.438a3.19 3.19 0 0 1-3.191 3.191" className="cls-5" data-name="Path 9093"/>
    <path d="M10.682 55.386v23.438a3.191 3.191 0 0 1-6.382 0V55.386a3.19 3.19 0 0 1 3.191-3.192 3.133 3.133 0 0 1 2.253.938 3.186 3.186 0 0 1 .938 2.253" className="cls-5" data-name="Path 9094"/>
  </g>

  </Icon>
);

export const Garage = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 18">
    <path d="M42.76 32.568v13.415H21.48c-4.069 0-3.144-2.62-1.657-4.143 3.27-3.367 6.451-7.015 7.517-8.294.362-.437.813-.978 2.619-.978Z" className="cls-2" data-name="Path 6621"/>
    <path d="M66.919 41.558c3.285 2.98 1.429 4.424-1.1 4.424H42.763V32.568h11.535c3.483.643 8.085 4.875 12.621 8.99Z" className="cls-2" data-name="Path 6622"/>
    <path d="M93.713 45.93c4.372 1.7 7.051 5.934 7.265 9.108a5.915 5.915 0 0 1-2.537.57 6 6 0 0 1-6-5.994 5.974 5.974 0 0 1 1.272-3.684Z" className="cls-3" data-name="Path 6623"/>
    <path d="M74.075 66.448a5.153 5.153 0 0 0 5.15 5.15 5.146 5.146 0 0 0 5.142-5.15 5.141 5.141 0 0 0-5.142-5.143 5.147 5.147 0 0 0-5.15 5.143Zm5.15-11.728a11.731 11.731 0 0 1 11.727 11.728 12.045 12.045 0 0 1-.274 2.5h.008a11.736 11.736 0 0 1-11.461 9.227 11.729 11.729 0 0 1-11.461-9.227h.007c-.044-.2-.081-.407-.118-.607-.03-.177-.051-.348-.073-.517a11.9 11.9 0 0 1-.082-1.377A11.727 11.727 0 0 1 79.225 54.72Z" className="cls-4" data-name="Path 6624"/>
    <path d="M79.221 71.598a5.153 5.153 0 0 1-5.15-5.15 5.147 5.147 0 0 1 5.15-5.142 5.14 5.14 0 0 1 5.142 5.142 5.146 5.146 0 0 1-5.142 5.15Z" className="cls-2" data-name="Path 6625"/>
    <path d="M16.16 66.448a5.148 5.148 0 0 0 5.143 5.15 5.153 5.153 0 0 0 5.149-5.15 5.147 5.147 0 0 0-5.149-5.143 5.142 5.142 0 0 0-5.143 5.143Zm-6.319 2.5h.007c-.044-.2-.081-.407-.118-.607-.029-.177-.052-.348-.075-.517a12.088 12.088 0 0 1-.081-1.377 11.732 11.732 0 0 1 11.728-11.728 11.726 11.726 0 0 1 11.727 11.728 12.274 12.274 0 0 1-.081 1.377c-.023.169-.045.34-.075.517-.037.2-.073.407-.119.607h.008A11.732 11.732 0 0 1 21.3 78.175a11.737 11.737 0 0 1-11.459-9.226Z" className="cls-4" data-name="Path 6626"/>
    <path d="M16.16 66.448a5.141 5.141 0 0 1 5.143-5.142 5.147 5.147 0 0 1 5.15 5.142 5.153 5.153 0 0 1-5.15 5.15 5.148 5.148 0 0 1-5.143-5.15Z" className="cls-2" data-name="Path 6627"/>
    <path fill="#d4334b" d="M42.757 45.986h23.056c2.53 0 4.387-1.443 1.1-4.424-4.536-4.115-9.138-8.348-12.623-8.991H29.957c-1.806 0-2.258.541-2.62.978-1.066 1.279-4.248 4.927-7.518 8.294-1.487 1.524-2.412 4.143 1.658 4.143ZM9.837 68.953H1.912a1.918 1.918 0 0 1-1.917-1.917v-11.7h5.107v-5.741H-.005v-2.782a1.918 1.918 0 0 1 1.917-1.916s6.223-.5 8.621-2.272c3.277-2.434 9.781-9.627 12.4-12.763a8.42 8.42 0 0 1 7.023-3.034h24.772c10.249 1.332 22.975 18.513 22.975 18.513h13.882a10.9 10.9 0 0 1 2.124.592 5.975 5.975 0 0 0-1.273 3.685 6 6 0 0 0 6 5.994 5.918 5.918 0 0 0 2.537-.57c.008.133.022.266.022.4v11.594a1.916 1.916 0 0 1-1.909 1.917H90.68a11.6 11.6 0 0 0 .266-2.5 11.73 11.73 0 0 0-11.727-11.728A11.727 11.727 0 0 0 67.49 66.453a11.961 11.961 0 0 0 .082 1.377c.014.169.044.347.074.517.029.2.066.408.111.607h-35c.044-.2.081-.407.11-.607.03-.17.059-.347.075-.517a12.228 12.228 0 0 0 .081-1.377 11.726 11.726 0 0 0-11.727-11.728A11.731 11.731 0 0 0 9.568 66.453a12.1 12.1 0 0 0 .081 1.377c.016.169.045.347.075.517.033.195.074.403.117.602Z" data-name="Path 6628"/>
    <path d="M5.106 49.593v5.742H0v-5.742Z" className="cls-3" data-name="Path 6629"/>
    <path fill="#bb2a4e" d="M41.651 32.567h2.22v36.382h-2.22z" data-name="Rectangle 8223"/>
    <path d="M52.291 52.892h-3.737a1.11 1.11 0 0 1-1.11-1.11 1.109 1.109 0 0 1 1.11-1.11h3.737a1.11 1.11 0 0 1 1.11 1.11 1.11 1.11 0 0 1-1.11 1.11Z" className="cls-4" data-name="Path 6630"/>
  </g>

  </Icon>
);

export const Gc = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h8.005v2.805H0z" className="cls-4" data-name="Rectangle 10332"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 8219" transform="translate(4.664)">
      <path d="M10.352 74.844V95C4.633 94.625 0 90.119 0 84.558a9.7 9.7 0 0 1 10.352-9.714Z" className="cls-2" data-name="Path 7995"/>
      <path d="M91.671 64.608A10.227 10.227 0 0 0 81.31 54.53V0a10.219 10.219 0 0 1 10.361 10.068Z" className="cls-2" data-name="Path 7996"/>
      <path fill="#d2edef" d="M81.312 54.529a10.226 10.226 0 0 1 10.36 10.076 10.226 10.226 0 0 1-10.36 10.078l-70.96.159A9.7 9.7 0 0 0 0 84.556V25.835a10.21 10.21 0 0 1 10.352-10.066h70.96Z" data-name="Path 7997"/>
      <path d="M57.334 44.835h-2.882V27.251h-39.6v17.584h-2.883V25.849a1.423 1.423 0 0 1 1.442-1.4h42.486a1.422 1.422 0 0 1 1.441 1.4Z" className="cls-4" data-name="Path 7998"/>
      <path d="M26.122 65.214H13.407a1.423 1.423 0 0 1-1.442-1.4V44.838a1.422 1.422 0 0 1 1.442-1.4h12.715v2.805H14.848v16.171h11.274Z" className="cls-4" data-name="Path 7999"/>
      <path d="M41.747 44.835h2.883v18.977h-2.883z" className="cls-4" data-name="Rectangle 10329"/>
      <path d="M55.897 65.214H26.126a1.423 1.423 0 0 1-1.442-1.4V44.838a1.422 1.422 0 0 1 1.442-1.4h29.771a1.422 1.422 0 0 1 1.441 1.4v18.976a1.422 1.422 0 0 1-1.441 1.4Zm-28.33-2.805h26.888V46.238H27.567Z" className="cls-4" data-name="Path 8000"/>
      <path d="M33.213 25.848h2.883v18.986h-2.883z" className="cls-4" data-name="Rectangle 10330"/>
      <path d="M65.531 25.848h2.883v37.964h-2.883z" className="cls-4" data-name="Rectangle 10331"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 10332" transform="translate(62.975 62.41)"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 10333" transform="translate(62.62 24.792)"/>
    </g>
  </g>

  </Icon>
);

export const Green = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h13.129v15.044H0z" className="cls-6" data-name="Rectangle 8156"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 14" transform="translate(46.309 549.555)">
      <path fill="#e24c5d" d="m54.691-511.509-5.054 7.235-3.537-2.47-41.913-29.294-41.911 29.294-3.529 2.471-5.056-7.235 47.973-33.53a4.419 4.419 0 0 1 5.055 0Z" data-name="Path 6567"/>
      <path fill="#f6cf76" d="M-4.752-500.665a56.508 56.508 0 0 0-4.606 13.65 27 27 0 0 0-.441 4.677c0 14.187 6.195 19.808 13.825 19.808s13.829-5.621 13.829-19.808a26.834 26.834 0 0 0-.441-4.677 56.453 56.453 0 0 0-4.6-13.641 108.223 108.223 0 0 0-8.781-15.211 107.944 107.944 0 0 0-8.785 15.202Zm50.85-6.079v44.538h-83.822v-44.541l41.911-29.291Zm-10.2 17.514v-15.044H22.769v15.044Zm-49.9 0v-15.044h-13.134v15.044Z" data-name="Path 6568"/>
      <path fill="#6ab528" d="M17.412-487.015a56.461 56.461 0 0 0-4.6-13.641 108.22 108.22 0 0 0-8.781-15.211 107.925 107.925 0 0 0-8.778 15.2 56.51 56.51 0 0 0-4.606 13.65 27.008 27.008 0 0 0-.441 4.677c0 14.187 6.195 19.808 13.825 19.808s13.826-5.621 13.826-19.808a26.836 26.836 0 0 0-.445-4.675Z" data-name="Path 6569"/>
      <path d="M2.704-500.656h2.647v42.378H2.704z" className="cls-5" data-name="Rectangle 8151"/>
      <path d="m-6.207-479.995 1.871-1.873 9.298 9.291-1.871 1.872z" className="cls-5" data-name="Rectangle 8152"/>
      <path d="m-3.236-489.89 1.872-1.871 6.418 6.418-1.871 1.872z" className="cls-5" data-name="Rectangle 8153"/>
      <path d="m3.092-472.576 9.29-9.29 1.872 1.87-9.29 9.292z" className="cls-5" data-name="Rectangle 8154"/>
      <path d="m3.091-485.301 6.421-6.422 1.872 1.872-6.421 6.421z" className="cls-5" data-name="Rectangle 8155"/>
      <use xlinkHref="#b" className="cls-6" data-name="Rectangle 8156" transform="translate(-27.136 -504.273)"/>
      <use xlinkHref="#b" className="cls-6" data-name="Rectangle 8157" transform="translate(22.769 -504.273)"/>
    </g>
  </g>

  </Icon>
);

export const Growth = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 6112">
    <g data-name="Group 6778">
      <path fill="#a4cb65" d="M50.499 57.926h-.031a28.963 28.963 0 0 1-28.959-28.967A28.961 28.961 0 0 1 50.468 0a28.96 28.96 0 0 1 28.958 28.959 28.961 28.961 0 0 1-28.927 28.967Zm21-28.967A21.034 21.034 0 0 0 50.464 7.925 21.034 21.034 0 0 0 29.43 28.959a21.033 21.033 0 0 0 21.034 21.034 21.034 21.034 0 0 0 21.039-21.034Z" data-name="Path 7228"/>
      <path fill="#658d25" d="M50.47 7.925a21.034 21.034 0 0 1 21.036 21.034A21.034 21.034 0 0 1 50.47 49.993a21.033 21.033 0 0 1-21.033-21.034A21.034 21.034 0 0 1 50.47 7.925Z" data-name="Path 7229"/>
      <path d="m60.639 77.214 24.472-12.149c-.159.437-5.322 14.356-10.4 16.905a10.451 10.451 0 0 1-14.022-4.653l-.05-.103Z" className="cls-4" data-name="Path 7230"/>
      <path d="m85.12 65.056-.007.008L60.64 77.213a10.449 10.449 0 0 1 4.7-13.92c5.162-2.588 19.78 1.763 19.78 1.763Z" className="cls-5" data-name="Path 7231"/>
      <path d="M40.361 77.214c-.017.032-.033.071-.048.1a10.45 10.45 0 0 1-14.021 4.653c-5.083-2.549-10.245-16.468-10.4-16.905Z" className="cls-4" data-name="Path 7232"/>
      <path d="M35.658 63.293a10.449 10.449 0 0 1 4.7 13.92L15.884 65.064l-.007-.008s14.619-4.351 19.781-1.763Z" className="cls-5" data-name="Path 7233"/>
    </g>
    <g data-name="Group 6779">
      <path d="M49.309 57.775h2.382V95h-2.382z" className="cls-4" data-name="Rectangle 9079"/>
      <path d="M49.99 83.323 39.831 78.28l1.059-2.134 10.141 5.034-.5 1.083Z" className="cls-4" data-name="Path 7234"/>
      <path d="m51.031 83.314-1.1-2.111 10.186-5.057 1.06 2.134Z" className="cls-4" data-name="Path 7235"/>
    </g>
    <path fill="#fff" d="M46.449 36.687a5.84 5.84 0 0 0 3.237 1.6v-8.623a21.966 21.966 0 0 1-2.976-.927 7.518 7.518 0 0 1-2.083-1.2 4.167 4.167 0 0 1-1.221-1.708 6.791 6.791 0 0 1-.388-2.439 6.818 6.818 0 0 1 .517-2.685 6.716 6.716 0 0 1 1.417-2.114 6.58 6.58 0 0 1 2.115-1.415 8.07 8.07 0 0 1 2.617-.619v-2.159h1.789v2.178a6.755 6.755 0 0 1 4.2 1.953 9.283 9.283 0 0 1 2.213 4.49h-2.767a4.326 4.326 0 0 0-3.644-3.775v8l1.952.52a8.756 8.756 0 0 1 1.529.569 7.01 7.01 0 0 1 1.692 1.122 6.285 6.285 0 0 1 1.383 1.789 5.414 5.414 0 0 1 .57 2.57 7.015 7.015 0 0 1-.539 2.831 6.42 6.42 0 0 1-1.478 2.13 6.983 6.983 0 0 1-2.247 1.416 9.9 9.9 0 0 1-2.863.652v2.666h-1.789v-2.666a8.161 8.161 0 0 1-5.173-2.263 8.187 8.187 0 0 1-2.179-5.35h2.768a4.817 4.817 0 0 0 1.348 3.457Zm.587-16.412a3.545 3.545 0 0 0-1.09 2.815 3.482 3.482 0 0 0 .277 1.448 2.738 2.738 0 0 0 .781 1.008 5.024 5.024 0 0 0 1.188.7 12.064 12.064 0 0 0 1.5.52v-7.612a4.633 4.633 0 0 0-2.656 1.121Zm6.116 17.68a3.844 3.844 0 0 0 1.334-.764 3.314 3.314 0 0 0 .879-1.268 4.865 4.865 0 0 0 .307-1.806 3.435 3.435 0 0 0-.324-1.577 3.438 3.438 0 0 0-.877-1.09 5.039 5.039 0 0 0-1.336-.765 20.09 20.09 0 0 0-1.66-.57v8.167a6.6 6.6 0 0 0 1.677-.327Z" data-name="Path 7236"/>
  </g>

  </Icon>
);

export const Hallway = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g data-name="Group 17">
    <g clipPath="url(#a)" data-name="Group 6108">
      <path d="M85.679 37.133v10.849L31.33 93.775H18.4v-18.88h21.575V56.013h4.71l-.009.025h9.306l.008-.025h9.428V37.132Z" className="cls-2" data-name="Path 6692"/>
      <path d="M18.401 92.551H85.68V95H18.401z" className="cls-2" data-name="Rectangle 8274"/>
      <path fill="#6ab528" d="M65.615 25.28a2.537 2.537 0 0 1-2.54 2.539H52.113a2.52 2.52 0 0 1-1.8-.742l-6.48-6.481v10.677h-5.176v1.632h13.781a4.655 4.655 0 0 1 4.652 4.653l-3.1 18.456h-9.3l3.1-13.812H36.629a9.382 9.382 0 0 1-9.355-8.808V19.533l-6.873 6.866v12.213a2.536 2.536 0 0 1-2.539 2.538 2.537 2.537 0 0 1-2.54-2.538V25.354a2.535 2.535 0 0 1 .743-1.795l10.173-10.171a2.538 2.538 0 0 1 1.8-.742h4.3a6.733 6.733 0 0 1-3.514-5.918A6.727 6.727 0 0 1 35.55 0a6.729 6.729 0 0 1 6.734 6.728 6.731 6.731 0 0 1-3.516 5.918h3.247a2.534 2.534 0 0 1 1.788.742l9.363 9.354h9.908a2.542 2.542 0 0 1 2.541 2.538Z" data-name="Path 6693"/>
      <path fill="#56981c" d="M27.274 38.561v3.59L24.18 74.837h9.3l3.118-31.054a11.006 11.006 0 0 1-9.324-5.222Z" data-name="Path 6694"/>
    </g>
  </g>

  </Icon>
);

export const Handsaw = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10591">
    <path fill="#e24c5d" d="M58.09 68.492a7.478 7.478 0 0 1-1.862 7.531L39.455 92.795a7.507 7.507 0 0 1-10.627 0L11.412 75.388a7.524 7.524 0 0 1 0-10.629l16.782-16.772a7.488 7.488 0 0 1 6.5-2.113l-.009.009c.193.033.376.066.559.108a.1.1 0 0 0 .043.008c.175.049.351.092.517.15.184.059.367.126.543.2a6.168 6.168 0 0 1 1.1.559 5.2 5.2 0 0 1 .442.291c.017.009.033.025.05.033.134.1.259.2.384.309.168.142.326.284.485.443L56.223 65.39a8.256 8.256 0 0 1 .893 1.077c.075.1.134.2.2.309a5.854 5.854 0 0 1 .467.91c.058.126.109.251.159.376.05.142.1.275.142.417ZM43.346 77.6a4.176 4.176 0 0 0 0-5.9L32.518 60.871a4.179 4.179 0 0 0-5.9 0l-5.8 5.811a4.18 4.18 0 0 0 0 5.9l10.82 10.82a4.177 4.177 0 0 0 5.9 0Z" data-name="Path 8361"/>
    <path fill="#d2edef" d="m91.786 11.221-5.244 8.882.25 2.889-5.26 2.354.5 5.736-5.251 2.345.5 5.735-5.251 2.347.5 5.726-5.26 2.355.51 5.727-2.589 1.152-7.1 12.013a3.741 3.741 0 0 0-.142-.417 2.384 2.384 0 0 0-.159-.375 5.857 5.857 0 0 0-.467-.91c-.059-.1-.125-.2-.2-.309a8.271 8.271 0 0 0-.893-1.077L38.815 47.988c-.159-.16-.317-.3-.485-.443-.125-.108-.259-.208-.384-.309-.017-.008-.033-.024-.05-.033-.15-.108-.292-.2-.442-.292a6.184 6.184 0 0 0-1.1-.559 3.3 3.3 0 0 0-.543-.2 5.407 5.407 0 0 0-.517-.15.1.1 0 0 1-.043-.008c-.184-.051-.366-.084-.55-.118L80.568.001Z" data-name="Path 8362"/>
    <path d="m44.981 40.725 22.58-22.58 1.77 1.772-22.579 22.58z" className="cls-4" data-name="Rectangle 10716"/>
    <path d="m68.726 16.99 3.197-3.198 1.77 1.77-3.197 3.198z" className="cls-4" data-name="Rectangle 10717"/>
  </g>

  </Icon>
);

export const Home = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 19">
    <path  fill='#f6cf76' d="M28.465 85.408a3.344 3.344 0 0 0 .361-1.515 3.913 3.913 0 0 0-3.66-3.71c-.412-3.338-3.581-5.942-7.42-5.942a7.573 7.573 0 0 0-6.753 3.822c-.06-.006-.112-.006-.172-.016v-42.91h6.477l28.76-29.051 4.543-4.6 33.3 33.646h6.491v40.877a2.832 2.832 0 0 0-.352-.259c-.077-.069-.163-.116-.248-.183a8.166 8.166 0 0 0-1.126-.606 8.22 8.22 0 0 0-1.469-.488 5.537 5.537 0 0 0-.583-.123 9.256 9.256 0 0 0-1.453-.117 7.885 7.885 0 0 0-6.976 3.822 6.9 6.9 0 0 0-.755-.054c-3.056 0-5.548 2.158-5.548 4.81a4.361 4.361 0 0 0 .877 2.589h-7.784v-7.054h-5.96V51.804H41.986v26.542h-5.961V85.4Zm53.817-19.5V51.815H67.25v14.093ZM56.915 36.089V24.058H44.082v12.031ZM33.808 65.908V51.815H18.786v14.093Z" className="cls-2" data-name="Path 6410"/>
    <path  fill='#fea856' d="M64.973 78.356v7.049H36.025v-7.049h28.948Z" className="cls-3" data-name="Path 6411"/>
    <path  fill='#6ab528' d="M10.994 78.066a7.57 7.57 0 0 1 6.753-3.824c3.839 0 7.009 2.6 7.421 5.943a3.917 3.917 0 0 1 3.66 3.711 3.34 3.34 0 0 1-.361 1.512H5.728a4.483 4.483 0 0 1-.842-2.587 5.116 5.116 0 0 1 5.378-4.807c.188 0 .377.016.556.034v.008a1.017 1.017 0 0 1 .174.01Z" className="cls-4" data-name="Path 6412"/>
    <path  fill='#6ab528' d="M78.184 78.066a7.882 7.882 0 0 1 6.976-3.824 8.561 8.561 0 0 1 1.453.119c.2.036.394.071.582.123a8.089 8.089 0 0 1 1.469.489 8.738 8.738 0 0 1 1.127.608c.084.06.17.114.248.176.121.083.239.169.351.259a6.394 6.394 0 0 1 2.441 4.169 3.978 3.978 0 0 1 3.779 3.711 3.355 3.355 0 0 1-.369 1.512H72.757a4.333 4.333 0 0 1-.878-2.587c0-2.654 2.492-4.807 5.55-4.807a5.9 5.9 0 0 1 .755.052Z" className="cls-4" data-name="Path 6413"/>
    <path fill="#40566b" d="M41.987 51.814h17.025v26.543H41.987z" data-name="Rectangle 7939"/>
    <path  fill='#f6cf76' d="M83.268 6.084v6.088h-7.464V-.005h7.464Z" className="cls-2" data-name="Path 6414"/>
    <path  fill='#e24c5d' d="m46.044 6.086-28.75 29.053H6.844V6.086Z" className="cls-6" data-name="Path 6415"/>
    <path  fill='#e24c5d' d="M94.367 6.086v29.053H83.923L55.164 6.086h20.64v6.091h7.465V6.086Z" className="cls-6" data-name="Path 6416"/>
    <path  fill='#d2edef' d="M18.786 51.814h15.023V65.91H18.786z" className="cls-7" data-name="Rectangle 7940"/>
    <path  fill='#d2edef' d="M67.25 51.814h15.032V65.91H67.25z" className="cls-7" data-name="Rectangle 7941"/>
    <path  fill='#d2edef' d="M44.082 24.062h12.834v12.034H44.082z" className="cls-7" data-name="Rectangle 7942"/>
    <path  fill='#fea856' d="M0 84.119h101v2.577H0z" className="cls-3" data-name="Rectangle 7943"/>
  </g>

  </Icon>
);

export const Inbox = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h43.127v2.601H0z" className="cls-4" data-name="Rectangle 8793"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 6785">
    <g data-name="Group 5747">
      <g data-name="Group 5746">
        <path d="M72.307 27.534c-10.123.739-10.726 1.344-11.464 11.463-.747-10.119-1.352-10.724-11.47-11.463 10.118-.747 10.723-1.343 11.47-11.47.738 10.127 1.341 10.723 11.464 11.47Z" className="cls-2" data-name="Path 6303"/>
        <path d="M60.842 39.417a.422.422 0 0 1-.421-.389c-.731-9.936-1.144-10.348-11.082-11.074a.42.42 0 0 1-.388-.419.42.42 0 0 1 .388-.419c9.938-.734 10.351-1.148 11.082-11.083a.422.422 0 0 1 .421-.389.419.419 0 0 1 .417.389c.727 9.935 1.143 10.349 11.075 11.083a.42.42 0 0 1 .388.419.419.419 0 0 1-.388.419c-9.932.725-10.348 1.139-11.075 11.074a.419.419 0 0 1-.417.389Zm-7.075-11.884c4.985.683 6.385 2.082 7.075 7.066.683-4.984 2.082-6.383 7.064-7.066-4.983-.688-6.381-2.088-7.064-7.069-.69 4.982-2.09 6.381-7.075 7.073Z" className="cls-2" data-name="Path 6304"/>
      </g>
      <path d="M26.278 34.597H38.11v2.519H26.278z" className="cls-2" data-name="Rectangle 7875"/>
      <path d="M30.938 29.945h2.519v11.824h-2.519z" className="cls-2" data-name="Rectangle 7876"/>
      <path d="M80.172 40.92h9.859v2.519h-9.859z" className="cls-2" data-name="Rectangle 7877"/>
      <path d="M83.842 37.25h2.519v9.859h-2.519z" className="cls-2" data-name="Rectangle 7878"/>
    </g>
    <g data-name="Group 6660" transform="translate(12)">
      <path fill="#d2edef" d="M62.25 9.781H9.988v65.016h61.723V19.243Z" data-name="Path 7056"/>
      <path d="M62.252 16.773v2.469h9.46l-9.46-9.461Z" className="cls-4" data-name="Path 7057"/>
      <path fill="#f2c45e" d="M81.709 88.103v2.828a4.075 4.075 0 0 1-4.076 4.073H4.075A4.069 4.069 0 0 1 0 90.931V65.264h25.232a6.312 6.312 0 0 0 6.312 6.312h18.385a6.312 6.312 0 0 0 6.312-6.312H81.71v22.839Z" data-name="Path 7058"/>
      <path d="M81.482 44.292v20.969h-4.89V49.182h-4.881v-4.889h9.771Z" className="cls-6" data-name="Path 7059"/>
      <path d="M71.84 49.311h4.882v16.076H71.84z" className="cls-4" data-name="Rectangle 8789"/>
      <path fill="#28b5a8" d="M71.711 9.78V4.89H9.988v4.891h52.266l9.46 9.459V9.78Z" data-name="Path 7060"/>
      <path d="M9.988 0h61.723v4.889H9.988z" className="cls-4" data-name="Rectangle 8790"/>
      <path d="M10.118 49.31v16.077h-5.1V49.31h5.1Z" className="cls-4" data-name="Path 7061"/>
      <path d="M9.988 44.292v4.889h-5.1V65.26H-.003V44.291h9.989Z" className="cls-6" data-name="Path 7062"/>
      <path d="M19.059 21.105h19.069v2.601H19.059z" className="cls-4" data-name="Rectangle 8791"/>
      <path d="M19.288 32.18h43.127v2.6H19.288z" className="cls-4" data-name="Rectangle 8792"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8793" transform="translate(19.288 39.272)"/>
      <path d="M19.288 46.373h43.127v2.603H19.288z" className="cls-4" data-name="Rectangle 8794"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8795" transform="translate(19.288 53.475)"/>
      <path d="M57.851 82.412a2.819 2.819 0 0 1-2.817 2.819H26.67a2.82 2.82 0 0 1-2.82-2.819 2.82 2.82 0 0 1 2.82-2.819h28.364a2.819 2.819 0 0 1 2.817 2.819Z" className="cls-6" data-name="Path 7063"/>
    </g>
  </g>

  </Icon>
);

export const Instrument = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10588">
    <path d="M3 0v95h22.919V56.559h-6.56V0Z" className="cls-2" data-name="Path 9413"/>
    <path d="M32.469 0v56.559h-6.55V95h24.588V56.559h-6.56V0Z" className="cls-3" data-name="Path 9414"/>
    <path d="M57.057 0v56.559h-6.55V95h24.6V56.559h-6.574V0Z" className="cls-2" data-name="Path 9415"/>
    <path d="M81.644 0v56.559h-6.539V95h22.9V0Z" className="cls-3" data-name="Path 9416"/>
    <path d="M19.359 0v56.559H32.47V0Z" className="cls-4" data-name="Path 9417"/>
    <path d="M43.946 0v56.559h13.111V0Z" className="cls-4" data-name="Path 9418"/>
    <path d="M68.533 0v56.559h13.111V0Z" className="cls-4" data-name="Path 9419"/>
  </g>

  </Icon>
);

export const Insurance = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h9.826v2.511H0z" className="cls-8" data-name="Rectangle 8636"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 6786" transform="translate(6.042)">
      <path d="M43.203 35.452h2.511V51.17h-2.511z" className="cls-2" data-name="Rectangle 8635"/>
      <path d="M51.81 95a8.618 8.618 0 0 1-8.607-8.6v-6.628h2.512V86.4a6.093 6.093 0 1 0 12.185 0h2.512A8.613 8.613 0 0 1 51.81 95Z" className="cls-2" data-name="Path 6935"/>
      <path fill="#e24c5d" d="M0 44.416A44.418 44.418 0 0 1 44.232.008c-11.64 13.827-16.587 30.171-14.857 44.057A17.188 17.188 0 0 0 0 44.416Z" data-name="Path 6936"/>
      <path fill="#cd4160" d="M44.682.008a44.515 44.515 0 0 1 44.234 44.524 17.113 17.113 0 0 0-29.367-.451C61.277 30.189 56.335 13.844 44.682.008Z" data-name="Path 6937"/>
      <path fill="#f6cf76" d="M44.685.008c11.649 13.835 16.594 30.18 14.861 44.073-.091.134-.184.268-.265.411a17.063 17.063 0 0 0-14.823-8.512h-.066a17.24 17.24 0 0 0-14.748 8.512c-.09-.143-.183-.285-.269-.427-1.731-13.884 3.216-30.23 14.857-44.057.076 0 .152-.008.226-.008s.15.008.227.008Z" data-name="Path 6938"/>
      <path fill="#6ab528" d="M74.402 51.17v28.6H14.637v-28.6h59.765ZM54.384 65.464a9.867 9.867 0 1 0-9.866 9.868 9.868 9.868 0 0 0 9.866-9.868Z" data-name="Path 6939"/>
      <circle cx="9.867" cy="9.867" r="9.867" fill="#daf1f3" data-name="Ellipse 608" transform="rotate(-80.748 59.885 17.29)"/>
      <use xlinkHref="#b" className="cls-8" data-name="Rectangle 8636" transform="translate(58.667 64.209)"/>
      <use xlinkHref="#b" className="cls-8" data-name="Rectangle 8637" transform="translate(20.412 64.209)"/>
      <path fill="#00c0b7" d="M42.792 68.862a2.244 2.244 0 0 0 1.231.671v-3.348a1.586 1.586 0 0 0-.211-.072 2.872 2.872 0 0 0-.3-.073 12.07 12.07 0 0 1-1.169-.377 3.758 3.758 0 0 1-1.034-.578 2.669 2.669 0 0 1-.731-.9 2.98 2.98 0 0 1-.272-1.338 3.144 3.144 0 0 1 1.14-2.49 3.7 3.7 0 0 1 1.186-.657 5.371 5.371 0 0 1 1.389-.281v-1.234h.948v1.234a5.881 5.881 0 0 1 1.356.314 3.675 3.675 0 0 1 1.113.639 3.259 3.259 0 0 1 .784 1.018 3.7 3.7 0 0 1 .351 1.443h-2.274a1.7 1.7 0 0 0-.4-1.089 1.17 1.17 0 0 0-.931-.449v2.833l.4.1c.132.03.271.068.425.109a5.6 5.6 0 0 1 1.824.793 3.452 3.452 0 0 1 .944.987 2.674 2.674 0 0 1 .365 1.025c.035.343.054.644.054.913a3.079 3.079 0 0 1-.81 1.937 4.236 4.236 0 0 1-1.224.914 4.99 4.99 0 0 1-1.972.48v1.364h-.948v-1.364a4.3 4.3 0 0 1-2.8-1.106 4.348 4.348 0 0 1-1.168-2.868h2.255a1.983 1.983 0 0 0 .479 1.45Zm.7-7.483a1.443 1.443 0 0 0-.463.247 1.18 1.18 0 0 0-.328.418 1.335 1.335 0 0 0-.124.583 1.016 1.016 0 0 0 .341.817 2.641 2.641 0 0 0 1.1.465v-2.61a1.78 1.78 0 0 0-.521.075Zm2.074 8.018a1.987 1.987 0 0 0 .564-.3 1.619 1.619 0 0 0 .408-.474 1.355 1.355 0 0 0 .159-.664 1.12 1.12 0 0 0-.393-.937 3.863 3.863 0 0 0-1.337-.57v3.077a2.935 2.935 0 0 0 .603-.136Z" data-name="Path 6940"/>
    </g>
  </g>

  </Icon>
);

export const Invoices = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h13.893v2.766H0z" className="cls-4" data-name="Rectangle 8833"/>
    <path id="c" d="M0 0h34.497v2.766H0z" className="cls-4" data-name="Rectangle 8837"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 6113" transform="translate(4.174)">
      <path fill="#d2edef" d="M83.379 11.588H27.79a9.268 9.268 0 0 1 9.268 9.264V95h55.59V20.852a9.267 9.267 0 0 0-9.269-9.264Z" data-name="Path 7104"/>
      <g data-name="Group 6671">
        <path fill="#00c0b7" d="M55.587.003v11.588H27.794a9.266 9.266 0 0 0-9.265 9.264V63.17a9.265 9.265 0 0 1-9.266 9.265A9.265 9.265 0 0 1 0 63.17V.003Z" data-name="Path 7105"/>
        <path d="M27.791 11.588a9.267 9.267 0 0 1 9.267 9.264v51.58h-27.8a9.265 9.265 0 0 0 9.263-9.265V20.852a9.266 9.266 0 0 1 9.27-9.264Z" className="cls-4" data-name="Path 7106"/>
        <path d="M53.282 72.755v-6.49a3.94 3.94 0 0 0-2.255.949 3.048 3.048 0 0 0-.933 2.407 2.844 2.844 0 0 0 .24 1.236 2.336 2.336 0 0 0 .665.856 4.481 4.481 0 0 0 1.013.6 10.708 10.708 0 0 0 1.27.442Zm4.843 7.809a4.165 4.165 0 0 0 .26-1.54 2.961 2.961 0 0 0-.279-1.346 2.947 2.947 0 0 0-.746-.932 4.275 4.275 0 0 0-1.134-.655 16.003 16.003 0 0 0-1.423-.478v6.96a5.724 5.724 0 0 0 1.433-.277 3.388 3.388 0 0 0 1.142-.655 2.891 2.891 0 0 0 .747-1.077Zm2.758-1.789a5.975 5.975 0 0 1-.453 2.407 5.6 5.6 0 0 1-1.261 1.816 5.9 5.9 0 0 1-1.919 1.208 8.428 8.428 0 0 1-2.446.553v2.276h-1.521v-2.276a6.95 6.95 0 0 1-4.414-1.927 6.986 6.986 0 0 1-1.852-4.563h2.358a4.1 4.1 0 0 0 1.152 2.941 4.956 4.956 0 0 0 2.755 1.364v-7.357a19.343 19.343 0 0 1-2.534-.784 6.144 6.144 0 0 1-1.777-1.034 3.532 3.532 0 0 1-1.042-1.446 5.837 5.837 0 0 1-.332-2.084 5.846 5.846 0 0 1 .44-2.285 5.65 5.65 0 0 1 1.208-1.807 5.619 5.619 0 0 1 1.81-1.208 6.867 6.867 0 0 1 2.227-.524V62.21h1.521v1.862a5.7 5.7 0 0 1 3.582 1.669 7.859 7.859 0 0 1 1.886 3.826h-2.357a3.682 3.682 0 0 0-3.11-3.218v6.821l1.67.443a7.536 7.536 0 0 1 1.3.489 6.033 6.033 0 0 1 1.447.949 5.569 5.569 0 0 1 1.171 1.531 4.509 4.509 0 0 1 .491 2.192Z" className="cls-4" data-name="Path 7107"/>
      </g>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8833" transform="translate(47.3 22.374)"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8834" transform="translate(68.403 64.246)"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8835" transform="translate(68.403 72.229)"/>
      <path d="M68.394 80.84h13.902v2.766H68.394z" className="cls-4" data-name="Rectangle 8836"/>
      <use xlinkHref="#c" className="cls-4" data-name="Rectangle 8837" transform="translate(47.909 31.27)"/>
      <use xlinkHref="#c" className="cls-4" data-name="Rectangle 8838" transform="translate(47.909 38.996)"/>
      <use xlinkHref="#c" className="cls-4" data-name="Rectangle 8839" transform="translate(47.909 46.712)"/>
      <use xlinkHref="#c" className="cls-4" data-name="Rectangle 8840" transform="translate(47.909 54.437)"/>
    </g>
  </g>

  </Icon>
);

export const Invite = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
    <defs>
    <clipPath id="clip-Invite_Team">
      <rect width="101" height="95"/>
    </clipPath>
    </defs>
    <g id="Invite_Team" data-name="Invite Team" clipPath="url(#clip-Invite_Team)">
      <g id="Group_10610" data-name="Group 10610" transform="translate(-9.571 -18.606)">
        <g id="App_Icon_Atom" transform="translate(13.411 18.701)">
          <path id="Path_8841" data-name="Path 8841" d="M0,0H47.321V47.321H0Z" fill="none"/>
        </g>
        <g id="Side_Panel_Icon" data-name="Side Panel Icon" transform="translate(13.411 22.926)">
          <rect id="Rectangle_11746" data-name="Rectangle 11746" width="93.321" height="90.68" fill="none"/>
          <g id="Group_9844" data-name="Group 9844">
            <g id="Group_9844-2" data-name="Group 9844">
              <path id="Path_8823" data-name="Path 8823" d="M4906.613-1153.446h-54.949v44.243l23,18.564,11.521,9.3,11.52-9.3,1.275-1.022L4920.472-1109v-31.027Z" transform="translate(-4840.027 1153.446)" fill="#d2edef"/>
              <path id="Path_8824" data-name="Path 8824" d="M4926.384-1140.03h-13.86v-13.416Z" transform="translate(-4845.935 1153.446)" fill="#40566b"/>
              <path id="Path_8825" data-name="Path 8825" d="M4850.413-1124.316v18.782l-11.64-9.387Z" transform="translate(-4838.773 1149.776)" fill="#40566b"/>
              <path id="Path_8826" data-name="Path 8826" d="M4939.744-1114.949l-11.873,9.588v-19.177Z" transform="translate(-4847.426 1149.804)" fill="#40566b"/>
              <path id="Path_8827" data-name="Path 8827" d="M4873.4-1085.583l-34.625,27.856v-55.719Z" transform="translate(-4838.773 1148.407)" fill="#fea856"/>
              <path id="Path_8828" data-name="Path 8828" d="M4937.3-1057.729l-34.636-27.873,1.273-1.023,33.362-26.833Z" transform="translate(-4844.981 1148.409)" fill="#fea856"/>
              <path id="Path_8829" data-name="Path 8829" d="M4873.417-1081.587l11.519,9.3,11.523-9.3,34.636,27.873h-92.321l34.625-27.856Z" transform="translate(-4838.773 1144.393)" fill="#f6cf76"/>
            </g>
            <g id="Group_9845" data-name="Group 9845" transform="translate(26.091 8.953)">
              <path id="Path_8831" data-name="Path 8831" d="M1976.1,145.193c.009.226.029.444.029.661v5.208a13.561,13.561,0,0,1-2.191,7.418,14.763,14.763,0,0,1-24.638,0,13.56,13.56,0,0,1-2.192-7.418v-5.208c0-.365.021-.73.052-1.1a14.708,14.708,0,0,0,3.761.493,14.537,14.537,0,0,0,13.185-8.186A14.483,14.483,0,0,0,1976.1,145.193Z" transform="translate(-1941.542 -131.807)" fill="#fff"/>
              <path id="Path_8832" data-name="Path 8832" d="M1974.04,158.775c5.779,1.855,7.744,7.112,7.744,13.336h-40.137c0-6.224,1.978-11.482,7.755-13.336a14.763,14.763,0,0,0,24.638,0Z" transform="translate(-1941.646 -132.102)" fill="#00c0b7"/>
              <path id="Path_8833" data-name="Path 8833" d="M1965.059,132.235a14.164,14.164,0,0,1,10.721,12.892A14.483,14.483,0,0,1,1963.786,137,13.631,13.631,0,0,0,1965.059,132.235Z" transform="translate(-1941.226 -131.742)" fill="#323954"/>
              <path id="Path_8834" data-name="Path 8834" d="M1965.377,132.228a13.634,13.634,0,0,1-1.276,4.764,14.535,14.535,0,0,1-13.185,8.186,14.711,14.711,0,0,1-3.76-.493,14.331,14.331,0,0,1,14.459-12.951c.4,0,.795.03,1.182.059A14.736,14.736,0,0,1,1965.377,132.228Z" transform="translate(-1941.542 -131.735)" fill="#40566b"/>
              <path id="Path_8835" data-name="Path 8835" d="M1958.333,150.467h-4.657a.987.987,0,1,1,0-1.973h4.657a.987.987,0,1,1,0,1.973Z" transform="translate(-1941.436 -131.963)" fill="#40566b"/>
              <path id="Path_8836" data-name="Path 8836" d="M1969.132,150.467h-4.657a.987.987,0,1,1,0-1.973h4.657a.987.987,0,1,1,0,1.973Z" transform="translate(-1941.232 -131.963)" fill="#40566b"/>
              <path id="Path_8837" data-name="Path 8837" d="M1961.19,159.882a6.91,6.91,0,0,1-3.468-.939.97.97,0,0,1-.351-1.353,1.039,1.039,0,0,1,1.4-.338,4.865,4.865,0,0,0,5.949-.9,1.043,1.043,0,0,1,1.437-.1.965.965,0,0,1,.1,1.392A6.791,6.791,0,0,1,1961.19,159.882Z" transform="translate(-1941.35 -132.065)" fill="#40566b"/>
            </g>
          </g>
        </g>
      </g>
    </g>

  </Icon>
);

export const Item_added = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10554">
    <g data-name="Group 10398">
      <path d="M101.443 70.893V95H0V49.811h35.043l9.222-13.04h57.177v34.122Zm-9.222 16.831V74.772H61.143v12.956Z" className="cls-2" data-name="Path 8964"/>
      <path d="M101.443 20.761v16.01h-9.637v-8.023H9.636v11.961h-.009v9.1H.001V11.998h43.343l9.222 8.761Z" className="cls-3" data-name="Path 8965"/>
      <path d="M91.807 36.614v.157h-47.54l-9.222 13.041H9.628v-9.1h11.961v-11.96h70.218Z" className="cls-4" data-name="Path 8966"/>
      <path d="M21.588 28.749 9.637 40.7V28.749Z" className="cls-4" data-name="Path 8968"/>
      <path d="M57.379 73.198h38.607v16.094H57.379z" className="cls-2" data-name="Rectangle 12761"/>
    </g>
    <g data-name="Group 10428">
      <g data-name="Group 10424">
        <path d="M49.956 55.268a3.618 3.618 0 0 1 2.194 6.494 3.651 3.651 0 0 1-1.016.546c-.088.033-.18.059-.274.084h-.007a2.577 2.577 0 0 1-.29.063 2.636 2.636 0 0 1-.3.037 3.166 3.166 0 0 1-.608 0 2.627 2.627 0 0 1-.3-.037 3.266 3.266 0 0 1-.571-.147 3.651 3.651 0 0 1-1.016-.546 3.617 3.617 0 0 1 2.2-6.494Z" className="cls-3" data-name="Path 8969"/>
        <path d="M52.151 61.761a3.512 3.512 0 0 1-1.019.546 2.354 2.354 0 0 1-.275.084h-.007a2.681 2.681 0 0 1-.29.062 2.631 2.631 0 0 1-.3.037 2.817 2.817 0 0 1-.3.015 2.78 2.78 0 0 1-.3-.015 2.649 2.649 0 0 1-.3-.037 3.273 3.273 0 0 1-.571-.147 3.41 3.41 0 0 1-1.019-.546 6.47 6.47 0 0 0-4.277 6.08v8.869h2.444v-8.918h.733v9.283a.366.366 0 0 1-.366.366h-.539v9.038h3.833V74.652h.732v11.826h3.833V77.44h-.521a.366.366 0 0 1-.366-.366v-9.283h.733v8.917h2.426v-8.869a6.471 6.471 0 0 0-4.284-6.078Z" className="cls-3" data-name="Path 8970"/>
      </g>
      <g data-name="Group 10425">
        <path d="M66.081 55.268a3.618 3.618 0 0 1 2.2 6.494 3.648 3.648 0 0 1-1.015.546 2.76 2.76 0 0 1-.275.084h-.007a2.546 2.546 0 0 1-.289.063 2.654 2.654 0 0 1-.3.037 3.171 3.171 0 0 1-.609 0 2.636 2.636 0 0 1-.3-.037 3.3 3.3 0 0 1-.571-.147 3.648 3.648 0 0 1-1.015-.546 3.616 3.616 0 0 1 2.195-6.494Z" className="cls-3" data-name="Path 8971"/>
        <path d="M68.277 61.761a3.512 3.512 0 0 1-1.019.546 2.334 2.334 0 0 1-.275.084h-.006a3.327 3.327 0 0 1-.59.1 2.82 2.82 0 0 1-.3.015 2.777 2.777 0 0 1-.3-.015 2.627 2.627 0 0 1-.3-.037 3.25 3.25 0 0 1-.572-.147 3.39 3.39 0 0 1-1.019-.546 6.471 6.471 0 0 0-4.277 6.08v8.869h2.445v-8.919h.733v9.283a.367.367 0 0 1-.367.366h-.539v9.038h3.833V74.652h.733v11.826h3.833V77.44h-.521a.366.366 0 0 1-.366-.366v-9.283h.733v8.917h2.426v-8.869a6.471 6.471 0 0 0-4.285-6.078Z" className="cls-3" data-name="Path 8972"/>
      </g>
      <g data-name="Group 10426">
        <path d="M82.206 55.268a3.618 3.618 0 0 1 2.2 6.494 3.655 3.655 0 0 1-1.015.546c-.088.033-.18.059-.275.084h-.007a2.554 2.554 0 0 1-.29.063 2.641 2.641 0 0 1-.3.037 3.166 3.166 0 0 1-.608 0 2.658 2.658 0 0 1-.3-.037 3.3 3.3 0 0 1-.571-.147 3.641 3.641 0 0 1-1.015-.546 3.617 3.617 0 0 1 2.195-6.494Z" className="cls-3" data-name="Path 8973"/>
        <path d="M84.401 61.761a3.5 3.5 0 0 1-1.019.546 2.392 2.392 0 0 1-.275.084H83.1a3.311 3.311 0 0 1-.59.1 2.826 2.826 0 0 1-.3.015 2.78 2.78 0 0 1-.3-.015 2.619 2.619 0 0 1-.3-.037 3.239 3.239 0 0 1-.572-.147 3.406 3.406 0 0 1-1.02-.546 6.472 6.472 0 0 0-4.277 6.08v8.869h2.445v-8.919h.733v9.283a.366.366 0 0 1-.367.366h-.539v9.038h3.833V74.652h.734v11.826h3.833V77.44h-.521a.366.366 0 0 1-.366-.366v-9.283h.733v8.917h2.426v-8.869a6.47 6.47 0 0 0-4.284-6.078Z" className="cls-3" data-name="Path 8974"/>
      </g>
    </g>
  </g>

  </Icon>
);

export const Jackhammer = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10590">
    <path d="m63.092 72.159-1.922 9.279-6.845 10.563-6.845-10.563-1.922-9.279h17.534Z" className="cls-2" data-name="Path 8370"/>
    <path d="M65.534 15.659v44.832H43.112V15.659h22.422Z" className="cls-2" data-name="Path 8371"/>
    <path d="M50.733 60.489h7.186v11.669h-7.186z" className="cls-3" data-name="Rectangle 10724"/>
    <path d="M52.529 4.295h3.593v11.363h-3.593z" className="cls-3" data-name="Rectangle 10725"/>
    <path d="M36.298 2.498h36.065v3.593H36.298z" className="cls-3" data-name="Rectangle 10726"/>
    <path d="M7.652 92.305h93.347V95H7.652z" className="cls-3" data-name="Rectangle 10727"/>
    <path d="m26.408 79.551 1.906-1.906 7.6 7.6-1.906 1.905z" className="cls-2" data-name="Rectangle 10728"/>
    <path d="m27.54 67.163 1.905-1.905 9.88 9.881-1.904 1.905z" className="cls-2" data-name="Rectangle 10729"/>
    <path d="m72.74 85.244 7.598-7.6 1.905 1.905-7.599 7.6z" className="cls-2" data-name="Rectangle 10730"/>
    <path d="m69.326 75.139 9.882-9.881 1.905 1.905-9.882 9.88z" className="cls-2" data-name="Rectangle 10731"/>
    <path d="M37.196 4.6a4 4 0 0 1-4 4h-9.764a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h9.764a4 4 0 0 1 4 4v.6Z" className="cls-4" data-name="Path 8372"/>
    <path d="M89.214 3.998v.6a4 4 0 0 1-4 4H75.45a3.994 3.994 0 0 1-3.988-4v-.6a4 4 0 0 1 3.988-4h9.764a4 4 0 0 1 4 4Z" className="cls-4" data-name="Path 8373"/>
  </g>

  </Icon>
);

export const Kitchen = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h2.218v20.037H0z" className="cls-3" data-name="Rectangle 8114"/>
    <path id="c" d="M0 0h2.218v9.989H0z" className="cls-3" data-name="Rectangle 8117"/>
    <path id="d" d="M0 0h2.218v36.555H0z" className="cls-3" data-name="Rectangle 8120"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 3" transform="translate(1196.671 460.6)">
      <path fill="#f6cf76" d="M-1095.673-427.396v6.529h-62.493v-6.529h62.493Z" data-name="Path 6537"/>
      <use xlinkHref="#b" className="cls-3" data-name="Rectangle 8114" transform="translate(-1158.458 -404.353)"/>
      <use xlinkHref="#b" className="cls-3" data-name="Rectangle 8115" transform="translate(-1116.751 -404.353)"/>
      <use xlinkHref="#b" className="cls-3" data-name="Rectangle 8116" transform="translate(-1139.546 -404.353)"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 8117" transform="translate(-1139.546 -420.871)"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 8118" transform="translate(-1116.751 -420.871)"/>
      <path d="M-1117.86-442.993h-18.357a3.332 3.332 0 0 0-3.328 3.327v12.266h25.013v-12.266a3.331 3.331 0 0 0-3.328-3.327Z" className="cls-4" data-name="Path 6538"/>
      <path d="M-1153.123-411.991h-31v8.747h31a1.109 1.109 0 0 0 1.109-1.109v-6.529a1.11 1.11 0 0 0-1.109-1.109Z" className="cls-4" data-name="Path 6539"/>
      <path d="m-1188.221-384.039-2.148-.555 5.141-19.9v-6.263l-2.132-9.327 2.162-.494 2.16 9.449a1.171 1.171 0 0 1 .027.247v6.529a1.109 1.109 0 0 1-.035.277Z" className="cls-3" data-name="Path 6540"/>
      <rect width="33.213" height="8.747" className="cls-4" data-name="Rectangle 8119" rx="1.5" transform="translate(-1143.524 -411.991)"/>
      <use xlinkHref="#d" className="cls-3" data-name="Rectangle 8120" transform="translate(-1107.012 -420.871)"/>
      <use xlinkHref="#d" className="cls-3" data-name="Rectangle 8121" transform="translate(-1149.039 -420.871)"/>
      <path d="m-1182.761-421.126-4.356-19.016a3.364 3.364 0 0 0-3.252-2.6 3.351 3.351 0 0 0-.723.079l-.6.134a3.338 3.338 0 0 0-2.519 3.985l4.355 19.017a1.109 1.109 0 0 0 1.081.862 1.125 1.125 0 0 0 .242-.027l4.934-1.1a1.1 1.1 0 0 0 .694-.491 1.108 1.108 0 0 0 .144-.843Z" className="cls-4" data-name="Path 6541"/>
      <path d="M-1196.671-385.425h100.261v2.218h-100.261z" className="cls-3" data-name="Rectangle 8122"/>
    </g>
  </g>

  </Icon>
);

export const Landscape = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 11">
    <path fill="#e24c5d" d="M76.651 56.299 61.546 72.714l.589 6.821 1.221 14.22h-8.928a4.712 4.712 0 0 0 .406-1.935 4.8 4.8 0 0 0-4.195-4.76 8.282 8.282 0 0 0-.814-2.792c-.05-.108-.108-.216-.165-.324a1.507 1.507 0 0 0-.125-.223 8.372 8.372 0 0 0-1.146-1.554l.639-8.049-16.207-16.914 2.035-1.4.016-.006a4.831 4.831 0 0 0 .583-.26 5.45 5.45 0 0 0 .474-.23c.215-.11.431-.226.639-.352.184-.1.366-.206.54-.323l12.434 11.416 1.106-14.736a10.651 10.651 0 0 0 1.246.408 5.621 5.621 0 0 0 .64.165 12.807 12.807 0 0 0 1.977.324c.448.042.9.067 1.369.067a14.642 14.642 0 0 0 1.486-.075c.218-.026.434-.05.648-.083s.424-.057.623-.1c.117-.026.224-.042.341-.075.182-.034.356-.075.54-.125s.366-.091.547-.149c.051-.017.092-.026.141-.041l.907 14.194 11.658-12.66a14.911 14.911 0 0 0 2.086 1.486Z" data-name="Path 6387"/>
    <path d="M50.641 87.058a4.8 4.8 0 0 1 4.194 4.76 4.7 4.7 0 0 1-.406 1.935H28.295a6.082 6.082 0 0 1-.963-3.3 6.172 6.172 0 0 1 6.173-6.181 6.663 6.663 0 0 1 .838.067 8.594 8.594 0 0 1 7.758-4.91 8.572 8.572 0 0 1 6.289 2.74 8.328 8.328 0 0 1 1.145 1.555l.126.222c.057.108.115.215.165.325a8.318 8.318 0 0 1 .815 2.787Z" className="cls-3" data-name="Path 6388"/>
    <path d="M78.217 90.449a6.083 6.083 0 0 1-.972 3.3H63.356L62.135 79.53a8.473 8.473 0 0 1 1.305-.108 8.583 8.583 0 0 1 7.757 4.91 6.679 6.679 0 0 1 .84-.067 6.179 6.179 0 0 1 6.18 6.184Z" className="cls-3" data-name="Path 6389"/>
    <path d="M85.816 30.352a14.736 14.736 0 0 1 9.321 13.7 14.744 14.744 0 0 1-14.744 14.742 14.628 14.628 0 0 1-7.476-2.033l-.033-.032a14.35 14.35 0 0 1-2.043-1.464l-.009.01a14.879 14.879 0 0 1-4.625-7.228 14.678 14.678 0 0 1-6 3.579c-.051.017-.091.024-.143.043-.182.058-.363.108-.547.149s-.357.091-.539.125c-.108.032-.225.049-.341.074-.2.041-.407.075-.623.1s-.431.058-.649.083a14.65 14.65 0 0 1-1.486.074c-.466 0-.922-.024-1.37-.066a15.753 15.753 0 0 1-1.977-.325c-.217-.057-.433-.108-.64-.166a20.672 20.672 0 0 1-1.246-.416v.009a14.47 14.47 0 0 1-5.092-3.265 14.762 14.762 0 0 1-6.3 8.457 6.423 6.423 0 0 1-.541.322 10.73 10.73 0 0 1-.639.351c-.159.081-.316.157-.474.231-.19.092-.39.175-.583.258l-.015.007a14.578 14.578 0 0 1-5.632 1.12 14.746 14.746 0 0 1-14.751-14.742 14.743 14.743 0 0 1 8.289-13.247 23.394 23.394 0 0 1-1.18-7.36 23.452 23.452 0 0 1 23.45-23.45 23.442 23.442 0 0 1 20.933 12.886 12.624 12.624 0 0 1 7.043-2.136 12.7 12.7 0 0 1 12.71 12.7 12.656 12.656 0 0 1-2.048 6.91Z" className="cls-3" data-name="Path 6390"/>
    <path d="m67.504 39.96-2.2-1.175a10.6 10.6 0 0 0 1.24-4.986 10.683 10.683 0 0 0-10.665-10.675 10.672 10.672 0 0 0-8.851 4.71l-2.065-1.4a13.161 13.161 0 0 1 10.916-5.8A13.177 13.177 0 0 1 69.036 33.8a13.105 13.105 0 0 1-1.532 6.16Z" className="cls-4" data-name="Path 6391"/>
    <path d="M44.874 17.817a7 7 0 0 1-1.074-.082 7.1 7.1 0 0 1-3.158-1.311l1.486-2a4.532 4.532 0 0 0 2.044.849 4.555 4.555 0 0 0 3.416-.844 4.556 4.556 0 0 0 1.817-3.01 4.6 4.6 0 0 0-1.431-4.073l1.689-1.833a7.089 7.089 0 0 1 2.206 6.278 7.034 7.034 0 0 1-2.8 4.645 7.046 7.046 0 0 1-4.195 1.381Z" className="cls-4" data-name="Path 6392"/>
    <path d="M40.314 42.251a9.189 9.189 0 0 0-8.787-6.593 9.084 9.084 0 0 0-6.491 2.7l-1.77-1.753a11.555 11.555 0 0 1 8.262-3.441 11.694 11.694 0 0 1 11.18 8.39Z" className="cls-4" data-name="Path 6393"/>
    <path d="M73.203 47.845a8.985 8.985 0 0 1-.1-6.956 9.071 9.071 0 0 1 9.3-5.627l-.257 2.479a6.573 6.573 0 0 0-6.733 4.076 6.513 6.513 0 0 0 .073 5.039Z" className="cls-4" data-name="Path 6394"/>
    <path d="M19.74 92.508h69.358V95H19.74z" className="cls-4" data-name="Rectangle 7918"/>
  </g>

  </Icon>
);

export const Landscape1 = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h2.439v6.504H0z" className="cls-4" data-name="Rectangle 10095"/>
    <path id="c" d="M0 0h2.439v8.691H0z" className="cls-4" data-name="Rectangle 10098"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Landscape_b &#x2013; 1">
    <g data-name="Group 8218" transform="translate(6.801)">
      <path d="M52.724 47.674H65.65v4.993c0 2.529-3.691 4.772-9.35 6.122-.219.049-.448.106-.675.154-.69.154-1.4.293-2.138.415l-.748.122c-.252.042-.5.073-.756.114-.512.073-1.031.137-1.561.194-.406.049-.82.09-1.235.123s-.863.073-1.293.1c-.285.017-.569.042-.854.05-.561.033-1.13.057-1.707.064q-.8.024-1.633.024t-1.635-.024a36.492 36.492 0 0 1-1.707-.064c-.284-.009-.569-.033-.853-.05-.431-.023-.87-.056-1.294-.1s-.829-.075-1.235-.123a48.504 48.504 0 0 1-1.56-.194c-.253-.042-.5-.073-.757-.114l-.748-.122a37.593 37.593 0 0 1-2.14-.415c-.226-.049-.455-.106-.673-.154-5.659-1.35-9.35-3.594-9.35-6.122v-4.993h30.977Z" className="cls-2" data-name="Path 7870"/>
      <path d="M82.521 70.545v6.707H4.878v-6.707h77.643Z" className="cls-2" data-name="Path 7871"/>
      <path d="M7.715 77.252h71.968V93.78H7.715z" className="cls-3" data-name="Rectangle 10091"/>
      <path d="M56.3 58.789v11.755H31.1V58.789c.219.057.448.106.673.154.684.154 1.4.293 2.14.415.244.049.495.088.748.122s.5.082.757.114c.512.073 1.031.137 1.56.194.406.048.821.09 1.236.123s.862.073 1.293.1c.284.026.569.042.853.05.561.033 1.13.057 1.707.064q.8.023 1.635.024t1.633-.024a36.587 36.587 0 0 0 1.708-.064c.284-.009.569-.024.854-.05.43-.023.869-.056 1.293-.1s.83-.075 1.236-.123a48.504 48.504 0 0 0 2.317-.308c.252-.041.5-.073.748-.122a36.05 36.05 0 0 0 2.138-.415c.223-.048.452-.097.671-.154Z" className="cls-3" data-name="Path 7872"/>
      <path d="M44.91 13.024h-2.438a10.583 10.583 0 0 0-14.93-9.65l-1-2.225a13.022 13.022 0 0 1 18.37 11.875Z" className="cls-4" data-name="Path 7873"/>
      <path d="m21.388 11.662-2.419-.314a12.991 12.991 0 0 1 4.288-8.075l1.614 1.829a10.564 10.564 0 0 0-3.483 6.56Z" className="cls-4" data-name="Path 7874"/>
      <path d="m36.34 26.834-2.438-.009a10.92 10.92 0 0 0-2.706-7.217l1.836-1.605a13.338 13.338 0 0 1 3.308 8.831Z" className="cls-4" data-name="Path 7875"/>
      <path d="M11.966 26.825H9.528a13.41 13.41 0 0 1 21.213-10.91l-1.42 1.983a10.972 10.972 0 0 0-17.355 8.927Z" className="cls-4" data-name="Path 7876"/>
      <path d="M42.471 16.903h2.439v30.772h-2.439z" className="cls-4" data-name="Rectangle 10092"/>
      <path d="M33.902 29.488h2.439v18.187h-2.439z" className="cls-4" data-name="Rectangle 10093"/>
      <path d="M10.366 29.488h2.439v10.35h-2.439z" className="cls-4" data-name="Rectangle 10094"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 10095" transform="translate(10.366 44.529)"/>
      <path d="M10.366 55.114h2.439v10.959h-2.439z" className="cls-4" data-name="Rectangle 10096"/>
      <path d="M18.959 20.732h2.439v6.098h-2.439z" className="cls-4" data-name="Rectangle 10097"/>
      <use xlinkHref="#c" className="cls-4" data-name="Rectangle 10098" transform="translate(18.959 30.35)"/>
      <path d="m47.336 6.928-1.989-1.414a13.026 13.026 0 0 1 15.967-4.331l-1 2.223a10.591 10.591 0 0 0-12.977 3.523Z" className="cls-4" data-name="Path 7877"/>
      <path d="M66.456 11.694a10.566 10.566 0 0 0-3.476-6.56l1.618-1.826a13.02 13.02 0 0 1 4.279 8.073Z" className="cls-4" data-name="Path 7878"/>
      <path d="M53.942 26.855h-2.438a13.424 13.424 0 0 1 3.308-8.818l1.839 1.6a10.993 10.993 0 0 0-2.709 7.218Z" className="cls-4" data-name="Path 7879"/>
      <path d="M78.318 26.855h-2.44a10.966 10.966 0 0 0-17.347-8.92l-1.42-1.983a13.405 13.405 0 0 1 21.207 10.9Z" className="cls-4" data-name="Path 7880"/>
      <path d="M51.504 47.699V29.52h2.439v18.154Z" className="cls-4" data-name="Path 7881"/>
      <path d="M75.04 29.52h2.439v10.342H75.04z" className="cls-4" data-name="Rectangle 10099"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 10100" transform="translate(75.04 44.561)"/>
      <path d="M75.04 55.139h2.439v10.967H75.04z" className="cls-4" data-name="Rectangle 10101"/>
      <path d="M66.447 20.765h2.439v6.09h-2.439z" className="cls-4" data-name="Rectangle 10102"/>
      <use xlinkHref="#c" className="cls-4" data-name="Rectangle 10103" transform="translate(66.447 30.383)"/>
      <path fill="#40566b" d="M0 92.561h87.398V95H0z" data-name="Rectangle 10104"/>
    </g>
  </g>

  </Icon>
);

export const Level = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h7.086v10.536H0z" className="cls-3" data-name="Rectangle 10666"/>
    <path id="c" d="M0 0h2.537v10.536H0z" className="cls-4" data-name="Rectangle 10669"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 8200" transform="translate(1596.246 3873.512)">
      <path fill="#e24c5d" d="m-1496.729-3852.133-73.631 73.622-21.369-21.369 26.46-26.46 7.449 7.449 5.006-5.015 10.69-10.68 5.006-5.015-7.451-7.451 26.46-26.46Zm-17.1 5a6.56 6.56 0 0 0 0-9.277 6.559 6.559 0 0 0-9.277 0 6.56 6.56 0 0 0 0 9.277 6.559 6.559 0 0 0 9.279-.002Zm-51.535 51.533a6.552 6.552 0 0 0 0-9.284 6.556 6.556 0 0 0-9.277 0 6.568 6.568 0 0 0 0 9.284 6.558 6.558 0 0 0 9.28 0Z" data-name="Path 8304"/>
      <path d="M-1565.362-3804.887a6.553 6.553 0 0 1 0 9.284 6.559 6.559 0 0 1-9.277 0 6.568 6.568 0 0 1 0-9.284 6.557 6.557 0 0 1 9.277 0Z" className="cls-3" data-name="Path 8305"/>
      <path d="M-1513.827-3856.412a6.56 6.56 0 0 1 0 9.277 6.559 6.559 0 0 1-9.277 0 6.56 6.56 0 0 1 0-9.277 6.56 6.56 0 0 1 9.277 0Z" className="cls-3" data-name="Path 8306"/>
      <use xlinkHref="#b" className="cls-3" data-name="Rectangle 10666" transform="rotate(-45.04 -5393.82 -25.592)"/>
      <use xlinkHref="#b" className="cls-3" data-name="Rectangle 10667" transform="rotate(-45.04 -5404.894 -52.352)"/>
      <path d="m-1560.261-3831.358 10.69-10.68 7.446 7.452-10.69 10.681z" className="cls-3" data-name="Rectangle 10668"/>
      <use xlinkHref="#c" className="cls-4" data-name="Rectangle 10669" transform="rotate(-45 -5404.353 -30.744)"/>
      <use xlinkHref="#c" className="cls-4" data-name="Rectangle 10670" transform="rotate(-45 -5412.3 -49.977)"/>
    </g>
  </g>

  </Icon>
);

export const Lighting = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h2.652v40.546H0z" className="cls-2" data-name="Rectangle 9003"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10563">
    <path d="M63.695 84.649v4.985H37.387v-4.985h26.308Z" className="cls-2" data-name="Path 9"/>
    <path d="M63.695 89.635v5.366H37.387v-5.366h26.308Z" className="cls-2" data-name="Path 10"/>
    <path d="M50.551 78.568h13.145v6.086H37.388v-6.086h13.163Z" className="cls-2" data-name="Path 11"/>
    <path d="M63.695 85.975H37.388a1.325 1.325 0 0 1-1.326-1.326 1.325 1.325 0 0 1 1.326-1.326h26.307a1.327 1.327 0 0 1 1.326 1.326 1.327 1.327 0 0 1-1.326 1.326Z" className="cls-3" data-name="Path 13"/>
    <path d="M63.695 90.961H37.388a1.327 1.327 0 0 1-1.326-1.327 1.325 1.325 0 0 1 1.326-1.326h26.307a1.327 1.327 0 0 1 1.326 1.326 1.328 1.328 0 0 1-1.326 1.327Z" className="cls-3" data-name="Path 14"/>
    <g data-name="Group 10562">
      <path d="M76.006 58.232c-4.591 6.169-12.486 9.658-12.315 20.332H37.309c.172-10.674-7.738-14.163-12.333-20.332a35.074 35.074 0 0 1-3.211-4.034 34.573 34.573 0 0 1-5.977-19.489A34.71 34.71 0 0 1 50.498 0c.016 0 .038.016.048.016a34.7 34.7 0 0 1 34.661 34.693 34.558 34.558 0 0 1-5.977 19.489 34.709 34.709 0 0 1-3.224 4.034Z" className="cls-4" data-name="Path 8"/>
      <path fill="#f99640" d="M24.119 34.709h-2.651a29.129 29.129 0 0 1 12.01-23.5l1.554 2.146a26.482 26.482 0 0 0-10.913 21.354Z" data-name="Path 12"/>
      <g data-name="Group 1" transform="translate(33.825 28.187)">
        <path d="M27.777 1.326a4.249 4.249 0 0 1 4.25 4.259 4.245 4.245 0 0 1-4.25 4.245h-4.254V5.585a4.256 4.256 0 0 1 4.254-4.259Z" className="cls-4" data-name="Path 7176"/>
        <path d="M23.527 9.83v37.134h-13.7V9.83Z" className="cls-4" data-name="Path 7177"/>
        <path d="M5.585 1.326a4.246 4.246 0 0 1 4.243 4.259V9.83H5.585a4.245 4.245 0 0 1-4.259-4.245 4.249 4.249 0 0 1 4.259-4.259Z" className="cls-4" data-name="Path 7178"/>
        <use xlinkHref="#b" className="cls-2" data-name="Rectangle 9003" transform="translate(22.197 9.83)"/>
        <path d="M27.777 11.156h-4.255a1.325 1.325 0 0 1-1.326-1.327V5.584a5.59 5.59 0 0 1 5.581-5.585 5.586 5.586 0 0 1 5.574 5.585 5.579 5.579 0 0 1-5.574 5.572Zm-2.931-2.652h2.931A2.925 2.925 0 0 0 30.7 5.585a2.931 2.931 0 0 0-2.923-2.932 2.936 2.936 0 0 0-2.931 2.932Z" className="cls-2" data-name="Path 7179"/>
        <path d="M9.83 8.504h13.693v2.652H9.83z" className="cls-2" data-name="Rectangle 9004"/>
        <path d="M9.831 11.156H5.584A5.584 5.584 0 0 1 .001 5.584 5.591 5.591 0 0 1 5.584-.001a5.586 5.586 0 0 1 5.573 5.585v4.245a1.327 1.327 0 0 1-1.326 1.327Zm-4.247-8.5a2.936 2.936 0 0 0-2.933 2.932 2.93 2.93 0 0 0 2.933 2.919h2.921V5.588a2.93 2.93 0 0 0-2.921-2.936Z" className="cls-2" data-name="Path 7180"/>
        <use xlinkHref="#b" className="cls-2" data-name="Rectangle 9005" transform="translate(8.504 9.83)"/>
      </g>
    </g>
  </g>

  </Icon>
);

export const Linens = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10584">
    <path d="M24.214 15.27h52.578a8.764 8.764 0 0 0-8.766 8.767v45.42H15.447v-45.42a8.77 8.77 0 0 1 8.767-8.767" className="cls-2" data-name="Path 9003"/>
    <path d="M68.026 24.49h17.525v38.053H68.026z" className="cls-3" data-name="Rectangle 12794"/>
    <path d="M68.026 24.037a8.763 8.763 0 1 1 17.525 0v.453H68.026Z" className="cls-3" data-name="Path 9004"/>
    <path d="M15.448 76.608h52.578v9.916H15.448z" className="cls-2" data-name="Rectangle 12795"/>
    <path fill="#f6cf76" d="M15.448 69.458h52.578v7.15H15.448z" data-name="Rectangle 12796"/>
    <path d="M68.026 22.394h32.973v4.191H68.026z" className="cls-5" data-name="Rectangle 12797"/>
    <path d="M0 22.394h15.447v4.191H0z" className="cls-5" data-name="Rectangle 12798"/>
  </g>

  </Icon>
);

export const Livingrm = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h27.643v13.499H0z" className="cls-6" data-name="Rectangle 7923"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <path d="M9.527 90.84h6.957V95H9.527z" className="cls-2" data-name="Rectangle 7919"/>
    <path d="M84.517 90.84h6.955V95h-6.955z" className="cls-2" data-name="Rectangle 7920"/>
    <path fill="#e24c5d" d="M94.744 42.544v10.551c-.152-.01-.3-.019-.456-.019a6.708 6.708 0 0 0-6.706 6.705v4.363H13.418v-4.365a6.706 6.706 0 0 0-6.7-6.705c-.151 0-.3.009-.456.019V42.544a9.017 9.017 0 0 1 9.016-9.017h70.453a9.018 9.018 0 0 1 9.013 9.017Z" data-name="Path 6395"/>
    <path fill="#f6cf76" d="M6.713 53.077a6.706 6.706 0 0 1 6.705 6.7v17.641h74.161V59.783a6.707 6.707 0 0 1 6.706-6.7 6.712 6.712 0 0 1 6.713 6.7v24.355a6.717 6.717 0 0 1-3.513 5.9 6.751 6.751 0 0 1-3.2.808H6.713A6.714 6.714 0 0 1-.001 84.14V59.783a6.713 6.713 0 0 1 6.714-6.706Z" data-name="Path 6396"/>
    <path d="M13.418 64.148H50.5v13.275H13.418z" className="cls-5" data-name="Rectangle 7921"/>
    <path d="M50.5 64.148h37.081v13.275H50.5z" className="cls-5" data-name="Rectangle 7922"/>
    <use xlinkHref="#b" className="cls-6" data-name="Rectangle 7923" transform="translate(15.39 7.875)"/>
    <use xlinkHref="#b" className="cls-6" data-name="Rectangle 7924" transform="translate(57.967 7.875)"/>
  </g>

  </Icon>
);

export const Location = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="b">
      <path fill="none" d="M0 0h77.102v95H0z" data-name="Rectangle 13033"/>
    </clipPath>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10599">
    <g clipPath="url(#b)" data-name="Group 10598" transform="translate(11.949)">
      <path fill="#00c0b7" d="M59.187 19.191a17.688 17.688 0 0 0-.161-1.485l12.08 5.861 5.992 2.909V95L51.404 82.527V39.835c.122-.183.245-.376.367-.559.288-.445.576-.892.856-1.345.114-.175.218-.342.323-.515.218-.349.428-.7.638-1.048a53.524 53.524 0 0 0 3.747-7.39c.1-.245.192-.48.288-.725s.183-.48.262-.725.175-.48.245-.717c.087-.235.157-.48.227-.715a20.717 20.717 0 0 0 .576-2.342 4.031 4.031 0 0 0 .079-.427 4.952 4.952 0 0 0 .079-.5 3.793 3.793 0 0 0 .052-.455 13.625 13.625 0 0 0 .104-1.668c0-.271-.009-.533-.017-.795-.009-.245-.017-.489-.044-.725" className="cls-4" data-name="Path 9420"/>
      <path fill="#e24c5d" d="M29.934 24.424c.087.209.192.41.3.611a5.135 5.135 0 0 0 .307.559c.008.009.017.026.026.035a8.559 8.559 0 0 0 .89 1.223c.114.131.227.253.341.376a9.464 9.464 0 0 0 3.415 2.262 2.382 2.382 0 0 0 .393.14.767.767 0 0 0 .183.061 1.966 1.966 0 0 0 .3.087 1.7 1.7 0 0 0 .228.061c.1.026.209.052.322.079a8.98 8.98 0 0 0 1.3.175c.21.017.428.026.646.026a9.4 9.4 0 0 0 9.4-9.406c0-.193-.009-.385-.017-.569a7.874 7.874 0 0 0-.062-.629 6.596 6.596 0 0 0-.07-.463 8.016 8.016 0 0 0-.332-1.293 3.992 3.992 0 0 0-.166-.463 7.561 7.561 0 0 0-.5-1.074 9.4 9.4 0 0 0-16.9 8.2m29.191-5.949c.026.236.045.472.061.716s.035.48.044.725c.01.262.018.524.018.8a15.096 15.096 0 0 1-.104 1.668 3.8 3.8 0 0 1-.053.454 4.968 4.968 0 0 1-.079.5 3.858 3.858 0 0 1-.079.427 20.75 20.75 0 0 1-.581 2.337c-.07.236-.148.48-.227.716s-.156.48-.245.716-.165.48-.262.725-.183.48-.288.725a53.378 53.378 0 0 1-3.747 7.39c-.21.349-.418.7-.637 1.048-.1.175-.211.341-.323.515-.28.454-.569.9-.857 1.345-.122.183-.244.376-.367.559a127.3 127.3 0 0 1-7.66 10.272c-.42.516-.831 1-1.205 1.45-.228.271-.446.524-.655.769-.28.323-.55.629-.795.926-.114.122-.227.253-.324.367a3.997 3.997 0 0 0-.3.349c-.1.114-.2.227-.288.323-.132.148-.263.288-.376.419l-.332.358a.107.107 0 0 1-.026.035c-.139.157-.271.3-.376.419s-.21.218-.28.306a.585.585 0 0 0-.1.1c-.088.1-.132.14-.132.14s-.043-.044-.131-.14a.564.564 0 0 0-.1-.1c-.069-.087-.166-.183-.279-.306-.1-.114-.211-.236-.342-.384-.087-.087-.165-.183-.262-.28-.07-.086-.157-.175-.244-.271a138.39 138.39 0 0 1-11.5-14.725v-.009a88.995 88.995 0 0 1-1.511-2.358q-.275-.432-.523-.865c-.149-.245-.3-.489-.437-.734q-.983-1.664-1.834-3.328c-.1-.218-.218-.428-.315-.646-.245-.5-.48-.987-.7-1.476-.113-.245-.227-.489-.323-.734a41.22 41.22 0 0 1-.62-1.529c-.079-.218-.167-.437-.237-.655-.087-.245-.164-.48-.243-.716a23.47 23.47 0 0 1-.586-2.061 18.81 18.81 0 0 1-.384-2.018h-.007a16.069 16.069 0 0 1-.141-2.026 20.706 20.706 0 0 1 41.194-3.006c.035.253.07.507.1.769" data-name="Path 9421"/>
      <path fill="#daf1f3" d="m29.934 24.423 16.9-8.2a7.528 7.528 0 0 1 .5 1.075 4.148 4.148 0 0 1 .166.462 8.051 8.051 0 0 1 .332 1.294c.026.148.053.3.07.463.027.209.052.418.062.629.008.183.017.375.017.568a9.4 9.4 0 0 1-9.4 9.407c-.218 0-.437-.009-.646-.027a8.745 8.745 0 0 1-1.3-.175c-.114-.025-.218-.052-.322-.079a1.7 1.7 0 0 1-.228-.06 2.948 2.948 0 0 1-.3-.088.752.752 0 0 1-.183-.06 2.434 2.434 0 0 1-.393-.14 9.286 9.286 0 0 1-2.611-1.521 8.938 8.938 0 0 1-.8-.742 15.98 15.98 0 0 1-.341-.376 11.088 11.088 0 0 1-.89-1.222c-.01-.01-.018-.026-.027-.035-.1-.183-.21-.367-.306-.56s-.209-.4-.3-.611" className="cls-6" data-name="Path 9422"/>
      <path fill="#daf1f3" d="M25.698 39.866a138.285 138.285 0 0 0 11.486 14.726c.087.1.175.184.245.272.1.1.175.192.261.28.132.148.245.271.342.384.114.122.21.218.279.306a.546.546 0 0 1 .106.1c.087.1.131.14.131.14s.044-.044.131-.14a.586.586 0 0 1 .1-.1c.071-.087.167-.183.28-.306s.236-.262.375-.419a.108.108 0 0 0 .027-.035c.1-.1.21-.227.332-.358s.245-.271.376-.419c.087-.1.183-.21.288-.323s.2-.227.306-.349.21-.245.323-.367c.245-.3.515-.6.795-.926.21-.245.428-.5.655-.769.376-.454.786-.935 1.205-1.45a127.292 127.292 0 0 0 7.66-10.272v42.688l-3.333 1.614-7.3 3.546L25.698 95V39.866Z" className="cls-6" data-name="Path 9423"/>
      <path fill="#00c0b7" d="M21.391 32.572q.85 1.664 1.834 3.329c.14.245.28.488.437.733q.249.432.523.866c.49.795 1 1.58 1.512 2.358V95L19.7 92.092 6.394 85.627 0 82.526V14.002l6.726 3.27 7.3 3.546 3.949 1.922a17.649 17.649 0 0 0 .393 2.019c.079.34.166.69.271 1.039s.2.681.314 1.022c.079.237.157.472.244.716.07.218.158.437.237.655.192.507.4 1.014.62 1.529.1.245.21.489.323.734.218.49.453.978.7 1.477.1.218.21.427.314.646" className="cls-4" data-name="Path 9424"/>
    </g>
  </g>

  </Icon>
);

export const Message = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h45.379v2.777H0z" className="cls-4" data-name="Rectangle 8841"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 6782" transform="translate(3.181)">
      <path fill="#d2edef" d="M68.261.001H11.928v46.851l23.584 19.659 11.809 9.849 11.812-9.849 1.3-1.083 22.029-18.362V14.209Z" data-name="Path 7108"/>
      <path fill="#f99640" d="M94.638 37.014v59.013l-35.5-29.516 1.3-1.083Z" className="cls-3" data-name="Path 7109"/>
      <path fill="#324459" d="m94.637 37.141-12.17 10.154V26.989Z" className="cls-4" data-name="Path 7110"/>
      <path fill="#f2c45e" d="M94.637 96.026H0l35.5-29.5.018-.017 11.81 9.849 11.81-9.849Z" data-name="Path 7111"/>
      <path fill="#324459" d="M82.466 14.437H68.259V.231Z" className="cls-4" data-name="Path 7112"/>
      <path fill="#f99640"d="m35.496 66.523-35.5 29.5v-59Z" className="cls-3" data-name="Path 7113"/>
      <path fill="#324459" d="M11.931 27.193v19.89L0 37.142Z" className="cls-4" data-name="Path 7114"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8841" transform="translate(24.509 24.758)"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8842" transform="translate(24.509 34.477)"/>
      <path fill = "#324459" d="M24.509 44.195h32.117v2.777H24.509z" className="cls-4" data-name="Rectangle 8843"/>
      <path fill = "#324459" d="M24.509 12.023h14.503V14.8H24.509z" className="cls-4" data-name="Rectangle 8844"/>
    </g>
  </g>

  </Icon>
);

export const Masterbedroom = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 6">
    <path d="M41.771 41.47a2.824 2.824 0 0 1 2.818 2.806V56.71H16.831V44.276a2.822 2.822 0 0 1 2.815-2.807Z" className="cls-2" data-name="Path 6551"/>
    <path d="M84.162 56.009v.7H56.406V44.276a2.822 2.822 0 0 1 2.815-2.807h22.126a2.816 2.816 0 0 1 2.815 2.807v11.732Z" className="cls-2" data-name="Path 6552"/>
    <path d="M94.928 81.221H101v13.78h-6.072z" className="cls-3" data-name="Rectangle 8136"/>
    <path fill="#f6cf76" d="M100.999 72.21v9.007h-101V72.21Z" data-name="Path 6553"/>
    <path fill="#40566b" d="M100.999 72.209h-101V62.024a5.321 5.321 0 0 1 5.313-5.312h90.363a5.33 5.33 0 0 1 5.323 5.313v10.188Z" data-name="Path 6554"/>
    <path fill="#6ab528" d="M16.832 56.009v.7H8.86v-5.762l.179-26.09a5.329 5.329 0 0 1 5.314-5.315h72.643a5.338 5.338 0 0 1 5.324 5.315l-.179 26.09v5.762H84.16V44.277a2.817 2.817 0 0 0-2.815-2.808H59.219a2.823 2.823 0 0 0-2.815 2.808v12.432H44.588V44.277a2.824 2.824 0 0 0-2.817-2.808H19.647a2.822 2.822 0 0 0-2.815 2.808v11.732Z" data-name="Path 6555"/>
    <path d="M0 81.221h6.063v13.78H0z" className="cls-3" data-name="Rectangle 8137"/>
  </g>

  </Icon>
);

export const News = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="c" d="M0 0h49.375v1.842H0z" className="cls-5" data-name="Rectangle 13054"/>
  </defs>
  <defs>
    <clipPath id="b">
      <path fill="none" d="M0 0h86.544v95H0z" data-name="Rectangle 13058"/>
    </clipPath>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10604">
    <g clipPath="url(#b)" data-name="Group 10603" transform="translate(9)">
      <path fill="#daf1f3" d="M67.77 85.613A9.391 9.391 0 0 0 77.157 95H9.387A9.391 9.391 0 0 1 0 85.613V0h67.77v85.613Zm-23.3-49.448 2.772-20.422h-1.99l-2.3 17.419L40.7 15.77h-2.25l-2.275 17.364-2.4-17.392h-2.21l2.8 20.422h3.122L39.5 22.311l1.962 13.854Zm8.718-20.625c-3.067 0-4.532 1.953-4.532 5.1 0 5.748 6.835 6.19 6.835 10.74 0 1.834-.765 2.921-2.394 2.921s-2.4-1.087-2.4-2.921V30.1h-2.124v1.161c0 3.1 1.456 5.112 4.578 5.112s4.615-2.017 4.615-5.112c0-5.748-6.863-6.181-6.863-10.768 0-1.805.7-2.883 2.34-2.883s2.331 1.078 2.331 2.911v.679h2.166v-.553c0-3.1-1.466-5.1-4.551-5.1M19.381 36.165V15.743h-2.016v14.766l-4.726-14.766H9.746v20.422h2.045V19.649l5.224 16.517ZM21.5 15.743v20.422h8.4V34.1h-6.124v-7.266h4.984v-2.045h-4.984v-6.974H29.9v-2.072Zm36.266 26.364H9.81v20.965h47.956Z" data-name="Path 9432"/>
      <path d="M86.543 85.613a9.387 9.387 0 0 1-18.773 0V52.092h18.773Z" className="cls-5" data-name="Path 9433"/>
      <path fill="#00c0b7" d="M9.81 42.107h47.956v20.966H9.81z" data-name="Rectangle 13053"/>
      <path d="M57.766 31.264c0 3.1-1.492 5.113-4.615 5.113s-4.577-2.018-4.577-5.113v-1.16h2.127v1.28c0 1.833.755 2.921 2.4 2.921s2.394-1.088 2.394-2.921c0-4.551-6.835-4.993-6.835-10.74 0-3.15 1.465-5.1 4.532-5.1 3.086 0 4.551 2.007 4.551 5.1v.552h-2.169v-.672c0-1.833-.7-2.911-2.331-2.911s-2.34 1.078-2.34 2.883c0 4.586 6.863 5.02 6.863 10.768" className="cls-5" data-name="Path 9434"/>
      <path d="m42.945 33.162 2.3-17.419h1.99l-2.771 20.422h-3l-1.966-13.854-2.007 13.854h-3.123l-2.8-20.422h2.211l2.4 17.392 2.275-17.364h2.244Z" className="cls-5" data-name="Path 9435"/>
      <path d="M23.776 24.789h4.984v2.045h-4.984v7.267h6.125v2.064h-8.4V15.743h8.4v2.073h-6.125Z" className="cls-5" data-name="Path 9436"/>
      <path d="m12.639 15.743 4.727 14.766V15.743h2.016v20.422h-2.367l-5.224-16.512v16.512H9.746V15.743Z" className="cls-5" data-name="Path 9437"/>
      <use xlinkHref="#c" className="cls-5" data-name="Rectangle 13054" transform="translate(9.368 69.364)"/>
      <use xlinkHref="#c" className="cls-5" data-name="Rectangle 13055" transform="translate(9.368 75.692)"/>
      <use xlinkHref="#c" className="cls-5" data-name="Rectangle 13056" transform="translate(9.368 82.012)"/>
      <use xlinkHref="#c" className="cls-5" data-name="Rectangle 13057" transform="translate(9.368 8.373)"/>
    </g>
  </g>

  </Icon>
);

export const Notes = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h44.642v2.783H0z" className="cls-3" data-name="Rectangle 7856"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 6111" transform="translate(0 3.674)">
      <path fill="#00c0b7" d="M101 55.913 85.352 71.561V55.913Z" data-name="Path 6287"/>
      <path d="M71.55 71.561v16.092H0v-71.55h29.449v55.458Z" className="cls-3" data-name="Path 6288"/>
      <path fill="#f6cf76" d="M101 0v55.912H85.352v15.649h-55.9V.001Z" data-name="Path 6289"/>
      <path d="M42.722 12.132h22.503v2.783H42.722z" className="cls-3" data-name="Rectangle 7855"/>
      <use xlinkHref="#b" className="cls-3" data-name="Rectangle 7856" transform="translate(42.899 26.398)"/>
      <use xlinkHref="#b" className="cls-3" data-name="Rectangle 7857" transform="translate(42.899 37.121)"/>
    </g>
  </g>

  </Icon>
);

export const Office = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 15">
      <path d="M40.832 90.178a1.123 1.123 0 0 1-1.064-1.175V71.895a1.123 1.123 0 0 1 1.064-1.174 1.123 1.123 0 0 1 1.063 1.174V89a1.123 1.123 0 0 1-1.063 1.178Z" className="cls-2" data-name="Path 6734"/>
      <path d="M26.51 90.178a1.063 1.063 0 0 1-.837-.406 1.063 1.063 0 0 1 .179-1.493l5.318-4.182a5.234 5.234 0 0 1 3.647-1.717h6.015a1.063 1.063 0 0 1 1.063 1.063 1.063 1.063 0 0 1-1.062 1.063h-6.015a3.155 3.155 0 0 0-2.06 1.01 1.144 1.144 0 0 1-.181.182l-5.41 4.254a1.061 1.061 0 0 1-.657.226Z" className="cls-2" data-name="Path 6735"/>
      <path d="M55.55 90.178a1.062 1.062 0 0 1-.658-.227l-5.409-4.254a1 1 0 0 1-.184-.186 3.141 3.141 0 0 0-2.058-1h-6.014a1.063 1.063 0 0 1-1.062-1.063 1.063 1.063 0 0 1 1.063-1.063h6.014a5.207 5.207 0 0 1 3.649 1.719l5.316 4.18a1.061 1.061 0 0 1 .178 1.493 1.059 1.059 0 0 1-.835.401Z" className="cls-2" data-name="Path 6736"/>
      <path d="M18.228 55.749a1.063 1.063 0 0 1-1.063-1.064v-4.743a7.753 7.753 0 0 1 7.742-7.749 1.064 1.064 0 0 1 1.064 1.063 1.064 1.064 0 0 1-1.064 1.063 5.625 5.625 0 0 0-5.616 5.623v4.743a1.063 1.063 0 0 1-1.063 1.064Z" className="cls-2" data-name="Path 6737"/>
      <path d="M63.44 55.749a1.064 1.064 0 0 1-1.064-1.064v-4.743a5.628 5.628 0 0 0-5.62-5.623 1.063 1.063 0 0 1-1.064-1.063 1.063 1.063 0 0 1 1.064-1.063 7.758 7.758 0 0 1 7.747 7.749v4.743a1.063 1.063 0 0 1-1.063 1.064Z" className="cls-2" data-name="Path 6738"/>
      <path fill="#f2c45e" d="M97.224 54.686v5.388H.004v-5.388h97.224Z" data-name="Path 6739"/>
      <path fill="#f99640" d="M91.17 63.605v30.686h-5.78V64.314H11.661v29.977H5.887V60.783l.2-.709h85.087Z" data-name="Path 6740"/>
      <path d="M20.22 64.314h41.334a4.568 4.568 0 0 1-4.566 4.567h-32.2a4.563 4.563 0 0 1-4.568-4.567Z" className="cls-5" data-name="Path 6741"/>
      <path d="M56.258 88.442a3.279 3.279 0 0 1 3.283 3.276 3.281 3.281 0 0 1-3.283 3.283 3.279 3.279 0 0 1-3.274-3.283 3.277 3.277 0 0 1 3.274-3.276Z" className="cls-5" data-name="Path 6742"/>
      <path d="m57.197 43.272.681 11.415H23.896l.68-11.409.879-14.739a7.51 7.51 0 0 1 7.516-7.508h15.84a7.511 7.511 0 0 1 7.513 7.508Z" className="cls-5" data-name="Path 6743"/>
      <path d="M52.343 68.88v3.687H29.316V68.88Z" className="cls-2" data-name="Path 6744"/>
      <path d="M40.83 88.406a3.277 3.277 0 0 1 3.276 3.276 3.278 3.278 0 0 1-3.276 3.283 3.274 3.274 0 0 1-3.275-3.283 3.273 3.273 0 0 1 3.275-3.276Z" className="cls-5" data-name="Path 6745"/>
      <path d="M25.801 88.442a3.277 3.277 0 0 1 3.274 3.276 3.279 3.279 0 0 1-3.274 3.283 3.278 3.278 0 0 1-3.276-3.283 3.276 3.276 0 0 1 3.276-3.276Z" className="cls-5" data-name="Path 6746"/>
      <path d="M36.968 33.366a1.7 1.7 0 0 1-1.7 1.7 1.7 1.7 0 0 1-1.7-1.7 1.7 1.7 0 0 1 1.7-1.7 1.7 1.7 0 0 1 1.7 1.7Z" className="cls-2" data-name="Path 6747"/>
      <path d="M48.046 33.366a1.7 1.7 0 0 1-1.7 1.7 1.7 1.7 0 0 1-1.7-1.7 1.7 1.7 0 0 1 1.7-1.7 1.7 1.7 0 0 1 1.7 1.7Z" className="cls-2" data-name="Path 6748"/>
      <path d="M36.968 40.456a1.7 1.7 0 0 1-1.7 1.7 1.7 1.7 0 0 1-1.7-1.7 1.7 1.7 0 0 1 1.7-1.7 1.7 1.7 0 0 1 1.7 1.7Z" className="cls-2" data-name="Path 6749"/>
      <path d="M48.046 40.456a1.7 1.7 0 0 1-1.7 1.7 1.7 1.7 0 0 1-1.7-1.7 1.7 1.7 0 0 1 1.7-1.7 1.7 1.7 0 0 1 1.7 1.7Z" className="cls-2" data-name="Path 6750"/>
      <path d="M36.968 47.546a1.7 1.7 0 0 1-1.7 1.7 1.7 1.7 0 0 1-1.7-1.7 1.7 1.7 0 0 1 1.7-1.7 1.7 1.7 0 0 1 1.7 1.7Z" className="cls-2" data-name="Path 6751"/>
      <path d="M48.046 47.546a1.7 1.7 0 0 1-1.7 1.7 1.7 1.7 0 0 1-1.7-1.7 1.7 1.7 0 0 1 1.7-1.7 1.7 1.7 0 0 1 1.7 1.7Z" className="cls-2" data-name="Path 6752"/>
    </g>
    <g data-name="Group 8221">
      <path d="M81.104 48.972V32.706h1.792v16.211Z" className="cls-6" data-name="Path 6556"/>
      <path fill="#f6cf76" d="m90.355 16.742 3.934 17.084H69.711l3.929-17.084Z" data-name="Path 6557"/>
      <path d="M82.001 47.802a6.683 6.683 0 0 1 6.682 6.688h-13.37a6.687 6.687 0 0 1 6.688-6.688Z" className="cls-6" data-name="Path 6558"/>
    </g>
  </g>

  </Icon>
);

export const Paint = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 8197">
    <path fill="#e24c5d" d="M51.726 62.219v7.016h-13.4v-7.016h13.4Z" data-name="Path 8292"/>
    <path d="M38.326 69.233h13.401V95H38.326z" className="cls-3" data-name="Rectangle 10661"/>
    <path fill="#f6cf76" d="M81.178 18.186v12.443a5.753 5.753 0 0 1-5.743 5.743H14.606a5.76 5.76 0 0 1-5.743-5.743V5.743A5.76 5.76 0 0 1 14.606 0h60.829a5.753 5.753 0 0 1 5.743 5.743Z" data-name="Path 8293"/>
    <path d="M46.462 62.217H43.59v-8.136a7.187 7.187 0 0 1 7.179-7.179h34.189a4.312 4.312 0 0 0 4.307-4.307V23.93c0-2.334-1.52-4.307-3.32-4.307h-4.768v-2.872h4.768c3.414 0 6.192 3.221 6.192 7.179v18.665a7.186 7.186 0 0 1-7.179 7.179h-34.19a4.312 4.312 0 0 0-4.307 4.307Z" className="cls-3" data-name="Path 8294"/>
    <path d="M27.442 5.983h42.681v2.872H27.442z" className="cls-5" data-name="Rectangle 10662"/>
    <path d="M20.349 5.983h3.025v2.872h-3.025z" className="cls-5" data-name="Rectangle 10663"/>
  </g>

  </Icon>
);

export const Payment = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 6109">
    <g data-name="Group 5742">
      <path fill="#f6cf76" d="M93.232 74.075 86.521 86.07l-6.566-11.723-.157-.271h13.434Z" data-name="Path 6290"/>
      <path d="M79.797 39.77h13.38v34.305h-13.38z" className="cls-3" data-name="Rectangle 7858"/>
      <path fill="#e24c5d" d="M95.064 10.122V39.77H77.912V5.47h17.152Z" data-name="Path 6291"/>
      <path d="M32.113 33.518h38.255v7.314H32.113z" className="cls-5" data-name="Rectangle 7859"/>
      <path d="M14.649 37.799a4.2 4.2 0 0 1 2.4-1.019v6.924a11.9 11.9 0 0 1-1.362-.468 4.714 4.714 0 0 1-1.079-.637 2.513 2.513 0 0 1-.7-.917 3.1 3.1 0 0 1-.254-1.316 3.228 3.228 0 0 1 .995-2.567Z" className="cls-6" data-name="Path 6292"/>
      <path d="M20.203 53.881a5.981 5.981 0 0 1-1.521.3v-7.425c.534.153 1.033.331 1.51.517a4.561 4.561 0 0 1 1.217.7 3.036 3.036 0 0 1 .788.985 3.055 3.055 0 0 1 .3 1.435 4.407 4.407 0 0 1-.279 1.649 2.966 2.966 0 0 1-.8 1.146 3.629 3.629 0 0 1-1.215.693Z" className="cls-6" data-name="Path 6293"/>
      <path d="M77.909 39.769h1.887v34.306l.153.272H0V25.252h77.909Zm-7.542 1.062v-7.314H32.113v7.314ZM24.667 52.7a6.363 6.363 0 0 0 .494-2.574 4.986 4.986 0 0 0-.52-2.337 5.743 5.743 0 0 0-1.254-1.631 6.519 6.519 0 0 0-1.541-1.02 8.479 8.479 0 0 0-1.391-.518l-1.777-.476v-7.269a3.928 3.928 0 0 1 3.315 3.433h2.513a8.4 8.4 0 0 0-2.014-4.086 6.117 6.117 0 0 0-3.814-1.775v-1.979h-1.63v1.954a7.394 7.394 0 0 0-2.381.56 6.168 6.168 0 0 0-1.918 1.283 6.181 6.181 0 0 0-1.292 1.929 6.139 6.139 0 0 0-.467 2.437 5.963 5.963 0 0 0 .358 2.218 3.75 3.75 0 0 0 1.1 1.555 6.885 6.885 0 0 0 1.893 1.1 19.991 19.991 0 0 0 2.7.841v7.83a5.275 5.275 0 0 1-2.94-1.453 4.335 4.335 0 0 1-1.224-3.134h-2.521a7.462 7.462 0 0 0 1.985 4.868 7.42 7.42 0 0 0 4.7 2.056v2.43h1.63v-2.43a8.885 8.885 0 0 0 2.6-.6 6.342 6.342 0 0 0 2.045-1.283 5.843 5.843 0 0 0 1.355-1.929Z" className="cls-6" data-name="Path 6294"/>
      <path d="M22.214 52.038a4.406 4.406 0 0 0 .279-1.649 3.052 3.052 0 0 0-.3-1.435 3.064 3.064 0 0 0-.79-.985 4.523 4.523 0 0 0-1.214-.7 17.016 17.016 0 0 0-1.512-.518v7.425a5.965 5.965 0 0 0 1.521-.3 3.588 3.588 0 0 0 1.214-.7 3.015 3.015 0 0 0 .802-1.138Zm-5.165-8.334V36.78a4.227 4.227 0 0 0-2.4 1.019 3.237 3.237 0 0 0-1 2.567 3.1 3.1 0 0 0 .254 1.316 2.461 2.461 0 0 0 .707.918 4.71 4.71 0 0 0 1.079.637 11.757 11.757 0 0 0 1.359.467Zm1.63-6.831v7.273l1.774.476a8.451 8.451 0 0 1 1.394.518 6.518 6.518 0 0 1 1.541 1.02 5.713 5.713 0 0 1 1.255 1.631 4.961 4.961 0 0 1 .519 2.336 6.326 6.326 0 0 1-.493 2.574 5.837 5.837 0 0 1-1.344 1.936 6.317 6.317 0 0 1-2.046 1.283 8.87 8.87 0 0 1-2.6.6v2.429h-1.63V56.52a7.417 7.417 0 0 1-4.7-2.056 7.476 7.476 0 0 1-1.985-4.868h2.521a4.336 4.336 0 0 0 1.223 3.134 5.278 5.278 0 0 0 2.94 1.453v-7.841a19.949 19.949 0 0 1-2.7-.841 6.889 6.889 0 0 1-1.9-1.1 3.732 3.732 0 0 1-1.1-1.555 5.975 5.975 0 0 1-.357-2.218 6.13 6.13 0 0 1 .467-2.437 6.278 6.278 0 0 1 1.292-1.929 6.155 6.155 0 0 1 1.918-1.283 7.4 7.4 0 0 1 2.38-.56v-1.954h1.63v1.979a6.116 6.116 0 0 1 3.814 1.776 8.407 8.407 0 0 1 2.014 4.086h-2.513a3.927 3.927 0 0 0-3.314-3.433Z" className="cls-5" data-name="Path 6295"/>
    </g>
    <g data-name="Group 5743">
      <path d="M101 40.373h-2.548V11.396h-3.391V8.847h4.666a1.274 1.274 0 0 1 1.272 1.274Z" className="cls-3" data-name="Path 6296"/>
      <path d="M32.112 45.964h38.255v2.549H32.112z" className="cls-7" data-name="Rectangle 7860"/>
      <path d="M32.112 52.139h26.506v2.549H32.112z" className="cls-7" data-name="Rectangle 7861"/>
      <path d="M8.529 62.614h34.703v2.549H8.529z" className="cls-7" data-name="Rectangle 7862"/>
      <path d="M49.723 62.614h20.644v2.549H49.723z" className="cls-7" data-name="Rectangle 7863"/>
    </g>
  </g>

  </Icon>
);

export const PDF = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
    <defs>
      <clipPath id="clipPath">
        <rect id="Rectangle_13680" data-name="Rectangle 13680" width="29.101" height="54.23" transform="translate(0 0)" fill="none"/>
      </clipPath>
      <clipPath id="clip-PDF">
        <rect width="101" height="95"/>
      </clipPath>
    </defs>
    <g id="PDF" clipPath="url(#clip-PDF)">
      <g id="Group_10617" data-name="Group 10617" transform="translate(14 12)">
        <g id="Group_10616" data-name="Group 10616" transform="translate(0 0)">
          <path id="Subtraction_1" data-name="Subtraction 1" d="M71,83H0V0H56.246L71,14.3V83Z" fill="#d2edef"/>
          <path id="Path_9914" data-name="Path 9914" d="M3368.516-2397.394h-14.251v-13.874Z" transform="translate(-3297.74 2411.51)" fill="#40566b"/>
        </g>
        <rect id="Rectangle_13679" data-name="Rectangle 13679" width="76" height="25" transform="translate(0 51)" fill="#e24c5d"/>
        <text id="PDF-2" data-name="PDF" transform="translate(37 69)" fill="#fff" fontSize="15" fontFamily="Poppins-Bold, Poppins" fontWeight="700"><tspan x="0" y="0">PDF</tspan></text>
      </g>
      <g id="Group_10620" data-name="Group 10620" transform="translate(21.449)">
        <g id="Group_10619" data-name="Group 10619" clipPath="url(#clipPath)">
          <path id="Path_9920" data-name="Path 9920" d="M14.551,54.23A14.56,14.56,0,0,1,0,39.68V11.58A11.521,11.521,0,0,1,11.731,0a11.57,11.57,0,0,1,8.286,3.445,11.621,11.621,0,0,1,3.433,8.114V36.22a8.36,8.36,0,0,1-16.719,0V21.13h3V36.22a5.36,5.36,0,1,0,10.719,0V11.58A8.743,8.743,0,0,0,11.731,3,8.545,8.545,0,0,0,3,11.54V39.68a11.55,11.55,0,0,0,23.1,0V20.89h3V39.68a14.566,14.566,0,0,1-14.55,14.55" fill="#00c0b7"/>
        </g>
      </g>
    </g>

  </Icon>
);

export const Plan = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h7.788v2.805H0z" className="cls-4" data-name="Rectangle 8820"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 6115" transform="translate(5.911)">
      <path d="M10.069 74.845V95A10.65 10.65 0 0 1-.001 84.557a9.549 9.549 0 0 1 10.07-9.712Z" className="cls-2" data-name="Path 7082"/>
      <path d="M89.178 64.61a10.083 10.083 0 0 0-10.079-10.079V0a10.076 10.076 0 0 1 10.079 10.069Z" className="cls-2" data-name="Path 7083"/>
      <path fill="#d2edef" d="M79.099 54.531a10.084 10.084 0 0 1 10.079 10.078 10.082 10.082 0 0 1-10.079 10.078l-69.029.159A9.551 9.551 0 0 0 .001 84.56V25.84A10.067 10.067 0 0 1 10.07 15.771h69.029Z" data-name="Path 7084"/>
      <path d="M55.774 44.836h-2.8V27.251H14.448v17.585h-2.8V25.849a1.4 1.4 0 0 1 1.4-1.4h41.33a1.4 1.4 0 0 1 1.4 1.4Z" className="cls-4" data-name="Path 7085"/>
      <path d="M25.411 65.216h-12.37a1.4 1.4 0 0 1-1.4-1.4V44.838a1.4 1.4 0 0 1 1.4-1.4h12.37v2.8H14.444v16.174h10.967Z" className="cls-4" data-name="Path 7086"/>
      <path d="M40.611 44.835h2.805v18.978h-2.805z" className="cls-4" data-name="Rectangle 8817"/>
      <path d="M54.372 65.216H25.411a1.4 1.4 0 0 1-1.4-1.4V44.838a1.4 1.4 0 0 1 1.4-1.4h28.961a1.4 1.4 0 0 1 1.4 1.4v18.978a1.4 1.4 0 0 1-1.4 1.4Zm-27.559-2.8H52.97V46.242H26.813Z" className="cls-4" data-name="Path 7087"/>
      <path d="M32.309 25.849h2.805v18.987h-2.805z" className="cls-4" data-name="Rectangle 8818"/>
      <path d="M63.749 25.849h2.805v37.964h-2.805z" className="cls-4" data-name="Rectangle 8819"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8820" transform="translate(61.262 62.411)"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8821" transform="translate(60.916 24.792)"/>
    </g>
  </g>

  </Icon>
);

export const Plants = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h8.716v3.441H0z" className="cls-4" data-name="Rectangle 5"/>
    <path id="c" d="M0 0h6.422v3.441H0z" className="cls-3" data-name="Rectangle 7"/>
    <path id="d" d="M0 0h3.441v4.358H0z" className="cls-5" data-name="Rectangle 12"/>
    <path id="e" d="M0 0h3.441v4.347H0z" className="cls-6" data-name="Rectangle 18"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 10568" transform="translate(-69.96 295.997)">
      <path fill="#323954" d="M165.292-220.976v19.979H75.628v-19.979h89.664Z" data-name="Path 21"/>
      <path d="M124.578-240.475a13.451 13.451 0 0 1 13.453-13.451c.4 0 .8.02 1.193.056.323.023.655.069.975.116.345.058.689.125 1.022.216h.033c.332.091.665.187 1 .3a13.426 13.426 0 0 1 9.234 12.765v19.5h-26.906v-19.5Z" className="cls-3" data-name="Path 22"/>
      <path d="M124.577-228.992v8.017H89.436v-38.273a26.4 26.4 0 0 1 26.4-26.412 26.408 26.408 0 0 1 26.41 26.412v6a8.6 8.6 0 0 0-1-.286h-.033a10.781 10.781 0 0 0-1.022-.22c-.321-.045-.643-.091-.975-.115-.39-.034-.791-.054-1.193-.054a13.449 13.449 0 0 0-13.453 13.451v11.48Z" className="cls-4" data-name="Path 23"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 5" transform="translate(80.721 -246.002)"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 6" transform="translate(80.721 -232.503)"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 7" transform="translate(151.484 -230.714)"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 8" transform="translate(151.484 -241.462)"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 9" transform="translate(118.397 -230.714)"/>
      <use xlinkHref="#c" className="cls-3" data-name="Rectangle 10" transform="translate(118.397 -241.462)"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 11" transform="translate(80.721 -259.502)"/>
      <use xlinkHref="#d" className="cls-5" data-name="Rectangle 12" transform="translate(99.186 -251.37)"/>
      <use xlinkHref="#d" className="cls-5" data-name="Rectangle 13" transform="translate(103.441 -265.775)"/>
      <use xlinkHref="#d" className="cls-5" data-name="Rectangle 14" transform="translate(114.119 -276.314)"/>
      <path d="M126.47-266.692h3.441v4.359h-3.441z" className="cls-5" data-name="Rectangle 15"/>
      <use xlinkHref="#d" className="cls-5" data-name="Rectangle 16" transform="translate(114.119 -255.543)"/>
      <use xlinkHref="#d" className="cls-5" data-name="Rectangle 17" transform="translate(103.441 -235.142)"/>
      <use xlinkHref="#e" className="cls-6" data-name="Rectangle 18" transform="translate(136.873 -245.247)"/>
      <use xlinkHref="#e" className="cls-6" data-name="Rectangle 19" transform="translate(142.148 -231.163)"/>
      <use xlinkHref="#d" className="cls-6" data-name="Rectangle 20" transform="translate(131.298 -234.121)"/>
      <path d="M140.566-262.38h10.39v3.441h-10.39z" className="cls-4" data-name="Rectangle 21"/>
    </g>
  </g>

  </Icon>
);

export const Profile = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path fill="#40566b" id="b" d="M0 0h13.222v13.222H0z" className="cls-4" data-name="Rectangle 13035"/>
    <path fill="#FFF" id="c" d="M0 0h3.084v2.689H0z" className="cls-6" data-name="Rectangle 13039"/>
    <path fill="#40566b" id="d" d="M0 0h28.686v1.793H0z" className="cls-5" data-name="Rectangle 13043"/>
    <path fill="#40566b" id="e" d="M0 0h27.045v1.793H0z" className="cls-5" data-name="Rectangle 13044"/>
    <path fill="#40566b" id="f" d="M0 0h20.107v1.793H0z" className="cls-5" data-name="Rectangle 13047"/>
  </defs>
  <defs>
    <clipPath id="g">
      <path fill="none" d="M0 0h101v90.412H0z" data-name="Rectangle 13052"/>
    </clipPath>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10602">
    <path fill="#daf1f3" d="M0 12.002h101v78.41H0z" className="cls-3" data-name="Rectangle 13034"/>
    <use xlinkHref="#b" className="cls-4" data-name="Rectangle 13035" transform="translate(48.479 65.367)"/>
    <use xlinkHref="#b" className="cls-4" data-name="Rectangle 13036" transform="translate(48.479 43.996)"/>
    <use xlinkHref="#b" className="cls-4" data-name="Rectangle 13037" transform="translate(48.479 22.635)"/>
    <path fill="#4c6275" d="M0 0h101v12.002H0z" className="cls-5" data-name="Rectangle 13038"/>
    <use xlinkHref="#c" className="cls-6" data-name="Rectangle 13039" transform="translate(5.612 4.992)"/>
    <use xlinkHref="#c" className="cls-6" data-name="Rectangle 13040" transform="translate(12.335 4.992)"/>
    <use xlinkHref="#c" className="cls-6" data-name="Rectangle 13041" transform="translate(19.058 4.992)"/>
    <path fill="#fff" d="M53.884 4.992h40.832v2.689H53.884z" className="cls-6" data-name="Rectangle 13042"/>
    <use xlinkHref="#d" className="cls-5" data-name="Rectangle 13043" transform="translate(8.695 60.983)"/>
    <use xlinkHref="#e" className="cls-5" data-name="Rectangle 13044" transform="translate(66.031 23.557)"/>
    <path fill="#4c6275" d="M66.031 29.734h14.28v1.793h-14.28z" className="cls-5" data-name="Rectangle 13045"/>
    <use xlinkHref="#e" className="cls-5" data-name="Rectangle 13046" transform="translate(66.031 44.919)"/>
    <use xlinkHref="#f" className="cls-5" data-name="Rectangle 13047" transform="translate(66.031 51.096)"/>
    <use xlinkHref="#e" className="cls-5" data-name="Rectangle 13048" transform="translate(66.031 66.281)"/>
    <use xlinkHref="#f" className="cls-5" data-name="Rectangle 13049" transform="translate(66.031 72.458)"/>
    <use xlinkHref="#d" className="cls-5" data-name="Rectangle 13050" transform="translate(8.695 68.602)"/>
    <use xlinkHref="#d" className="cls-5" data-name="Rectangle 13051" transform="translate(8.695 76.222)"/>
    <g data-name="Group 10601">
      <g clipPath="url(#g)" data-name="Group 10600">
        <path fill="#daf1f3"d="M32.176 36.869a8.85 8.85 0 1 1-8.849-8.85 8.851 8.851 0 0 1 8.85 8.85" className="cls-3" data-name="Path 9425"/>
        <path fill="#00c0b7" d="M37.851 22.635v28.47h-3.125v-3.1a7.431 7.431 0 0 0-5.09-7.046 7.38 7.38 0 0 0 1.117-3.919v-2.755c0-.12-.015-.234-.015-.354a7.461 7.461 0 0 0-6.8-7.046c-.205-.014-.408-.03-.612-.03a7.434 7.434 0 0 0-7.4 6.85c-.014.189-.03.385-.03.581v2.757a7.4 7.4 0 0 0 1.118 3.926 7.414 7.414 0 0 0-5.083 7.039v3.1H8.792V22.635Z" className="cls-4" data-name="Path 9426"/>
        <path fill="#323954" d="M24.598 29.634a7.4 7.4 0 0 0 .649-2.515 7.424 7.424 0 0 1 5.491 6.812 7.417 7.417 0 0 1-6.14-4.3" data-name="Path 9427"/>
        <path fill="#40566b"d="M25.246 27.12a7.412 7.412 0 0 1-9.325 6.585 7.434 7.434 0 0 1 7.4-6.85c.2 0 .407.016.611.03a7.492 7.492 0 0 1 1.313.234" className="cls-5" data-name="Path 9428"/>
        <path fill="#e24c5d" d="M34.722 48.008v3.1h-22.8v-3.1a7.415 7.415 0 0 1 5.086-7.041 7.444 7.444 0 0 0 12.627 0 7.429 7.429 0 0 1 5.09 7.045" data-name="Path 9429"/>
        <path fill="#40566b" d="M20.952 37.789h-.488a.9.9 0 0 1 0-1.793h.488a.9.9 0 0 1 0 1.793" className="cls-5" data-name="Path 9430"/>
        <path fill="#40566b" d="M26.345 37.789h-.489a.9.9 0 1 1 0-1.793h.489a.9.9 0 1 1 0 1.793" className="cls-5" data-name="Path 9431"/>
      </g>
    </g>
  </g>

  </Icon>
);

export const Progress = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 9064">
    <path fill="#00c0b7" d="M101 15.363v64.274a13.042 13.042 0 0 0-13.046-13.049 13.049 13.049 0 0 0-13.049 13.049V15.363A13.049 13.049 0 0 1 87.954 2.314 13.042 13.042 0 0 1 101 15.363Z" data-name="Path 8849"/>
    <path fill="#d2edef" d="M87.954 66.588a13.041 13.041 0 0 1 13.044 13.049 13.047 13.047 0 0 1-13.044 13.048H0V15.362h74.9v64.275a13.05 13.05 0 0 1 13.054-13.049Z" data-name="Path 8850"/>
    <path d="m52.053 81.264 10.122-10.121 2.05 2.05-10.122 10.121z" className="cls-4" data-name="Rectangle 12526"/>
    <path d="m51.115 72.148 2.05-2.05 10.121 10.121-2.05 2.05z" className="cls-4" data-name="Rectangle 12527"/>
    <path d="M19.332 36.623a6.651 6.651 0 0 1-6.644-6.643 6.652 6.652 0 0 1 6.644-6.646 6.653 6.653 0 0 1 6.645 6.646 6.652 6.652 0 0 1-6.645 6.643Zm0-10.39a3.75 3.75 0 0 0-3.744 3.747 3.749 3.749 0 0 0 3.744 3.744 3.75 3.75 0 0 0 3.745-3.744 3.751 3.751 0 0 0-3.745-3.748Z" className="cls-4" data-name="Path 8851"/>
    <path d="M48.044 77.63h-2.9v-2.9h2.9Zm-5.8 0h-2.856l-.045-2.9h2.9Zm-6.383-.919a7.356 7.356 0 0 1-2.6-2.461l2.448-1.554a4.443 4.443 0 0 0 1.568 1.484Zm-3.709-5.873c-.01-.151-.014-.3-.014-.455v-.872a4.25 4.25 0 0 0-.178-1.228l2.78-.826a7.186 7.186 0 0 1 .3 2.053v.872c0 .091 0 .182.006.272Zm-1.3-4.4a4.367 4.367 0 0 0-1.851-1.1l.815-2.782a7.31 7.31 0 0 1 3.085 1.83Zm-4.32-1.275h-2.9v-2.9h2.9Zm-5.8 0h-2.9v-2.9h2.9Zm-6.047-.11a7.261 7.261 0 0 1-3.265-1.485l1.814-2.261a4.368 4.368 0 0 0 1.957.891ZM9.261 60.71a7.191 7.191 0 0 1-.564-2.8v-.606h2.9v.606a4.291 4.291 0 0 0 .335 1.672Zm2.337-6.3h-2.9v-2.053a7.09 7.09 0 0 1 .079-1.055l2.867.423a4.409 4.409 0 0 0-.044.632Zm.881-4.667-2.314-1.748a7.325 7.325 0 0 1 2.8-2.241l1.2 2.639a4.392 4.392 0 0 0-1.682 1.35Zm46.962-1.736h-2.464v-2.9h2.464c.108 0 .217 0 .323-.012l.228 2.891a7.02 7.02 0 0 1-.546.021Zm-5.361 0h-2.9v-2.9h2.9Zm-5.8 0h-2.9v-2.9h2.9Zm-5.8 0h-2.9v-2.9h2.9Zm-5.8 0h-2.9v-2.9h2.9Zm-5.8 0h-2.9v-2.9h2.9Zm-5.8 0h-2.9v-2.9h2.9Zm-5.8 0h-2.9v-2.9h2.9Zm44.1-1.174-1.586-2.427a4.421 4.421 0 0 0 1.465-1.588l2.548 1.385a7.333 7.333 0 0 1-2.415 2.627Zm3.3-6.105h-2.9v-2.9h2.9Zm-2.928-5.636a4.334 4.334 0 0 0-.764-2.007l2.372-1.671a7.248 7.248 0 0 1 1.273 3.354Zm-2.4-3.414a4.3 4.3 0 0 0-1.92-.45h-.241v-2.9h.241a7.142 7.142 0 0 1 3.208.752Zm-5.061-.45h-2.9v-2.9h2.9Zm-5.8 0h-2.9v-2.9h2.9Zm-5.8 0h-2.9v-2.9h2.9Zm-5.8 0h-2.9v-2.9h2.9Zm-5.8 0h-2.9v-2.9h2.9Z" className="cls-4" data-name="Path 8852"/>
  </g>

  </Icon>
);

export const Project = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="b">
      <path fill="none" d="M0 0h93.106v95H0z" data-name="Rectangle 12528"/>
    </clipPath>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10113">
    <g clipPath="url(#b)" data-name="Group 10112" transform="translate(3.947)">
      <path fill="#bb2a4e" d="M88.615 86.018a4.491 4.491 0 1 1 0 8.982H73.754v-1.611a3.287 3.287 0 1 0-6.573 0v1.609H26.9v-1.611a3.286 3.286 0 1 0-6.571 0v1.611H4.491a4.491 4.491 0 1 1 0-8.982h84.124Z" data-name="Path 8853"/>
      <path d="M86.533 45.997v40.02H72.97l-6.723-42.466h17.84a2.445 2.445 0 0 1 2.446 2.446" className="cls-5" data-name="Path 8854"/>
      <path fill="#f1c45e" d="m66.238 43.551 6.732 42.466h-5.789l-6.733-42.466-1.867-11.8a2.874 2.874 0 0 1 .48-2.08 15.243 15.243 0 1 0-25.025 0 2.812 2.812 0 0 1 .48 2.072l-8.4 54.276h-5.785l6.571-42.466 1.8-11.607a20.957 20.957 0 1 1 35.705 0l1.842 11.607Z" data-name="Path 8855"/>
      <path d="m60.451 43.551 6.73 42.467H26.112l6.58-42.466Z" className="cls-5" data-name="Path 8856"/>
      <path d="M26.903 43.551 20.33 86.017H6.573v-40.02a2.444 2.444 0 0 1 2.446-2.446Z" className="cls-5" data-name="Path 8857"/>
    </g>
  </g>

  </Icon>
);

export const Proposals = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h20.574v2.353H0z" className="cls-4" data-name="Rectangle 8798"/>
    <path id="c" d="M0 0h49.688v2.353H0z" className="cls-4" data-name="Rectangle 8802"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 6780" transform="translate(17.604)">
      <path d="M62.191 82.066 49.249 95V82.066Z" className="cls-2" data-name="Path 7072"/>
      <path fill="#d2edef" d="M24.322 9.083h37.869v72.983H49.249V95H0V9.083h24.322Zm30.566 55.319v-8.917h-20.9v8.917Zm-26.534 0v-8.917h-20.9v8.917Z" data-name="Path 7073"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8798" transform="translate(35.146 19.162)"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8799" transform="translate(35.146 26.441)"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8800" transform="translate(35.146 33.719)"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8801" transform="translate(35.146 40.998)"/>
      <use xlinkHref="#c" className="cls-4" data-name="Rectangle 8802" transform="translate(6.031 48.276)"/>
      <use xlinkHref="#c" className="cls-4" data-name="Rectangle 8803" transform="translate(6.031 70.113)"/>
      <path d="M6.031 76.356h23.295v2.353H6.031z" className="cls-4" data-name="Rectangle 8804"/>
      <path d="M18.519 42.535a11.321 11.321 0 0 1-8.074-3.35 11.33 11.33 0 0 1-3.339-8.062V9.083A9.037 9.037 0 0 1 16.306 0a9.074 9.074 0 0 1 6.5 2.7 9.11 9.11 0 0 1 2.693 6.363v19.343a6.564 6.564 0 0 1-6.557 6.557 6.513 6.513 0 0 1-4.635-1.921 6.511 6.511 0 0 1-1.921-4.636V16.57h2.353v11.836a4.179 4.179 0 0 0 1.231 2.973 4.179 4.179 0 0 0 2.972 1.231 4.208 4.208 0 0 0 4.2-4.2V9.084a6.769 6.769 0 0 0-2.006-4.719 6.742 6.742 0 0 0-4.833-2.011 6.7 6.7 0 0 0-6.848 6.7v22.071a8.992 8.992 0 0 0 2.652 6.4 8.978 8.978 0 0 0 6.408 2.659 9.069 9.069 0 0 0 9.059-9.059V16.388h2.353v14.737a11.424 11.424 0 0 1-11.408 11.41Z" className="cls-2" data-name="Path 7074"/>
      <path d="M6.031 55.485h22.224v8.918H6.031z" className="cls-5" data-name="Rectangle 8805"/>
      <path d="M34.083 55.485H55.72v8.918H34.083z" className="cls-5" data-name="Rectangle 8806"/>
    </g>
  </g>

  </Icon>
);

export const Punch = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h30.154v2.661H0z" className="cls-7" data-name="Rectangle 9678"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10103">
    <path fill="none" d="M3.11.066h94.779v94.779H3.11z" data-name="Rectangle 12204"/>
    <g data-name="Group 10103" transform="translate(13.639)">
      <path d="M21.073 66.796a6.209 6.209 0 1 1-5.108-2.678 6.193 6.193 0 0 1 5.108 2.678Z" className="cls-4" data-name="Path 7581"/>
      <path d="M20.948 48.471a6.206 6.206 0 1 1-4.983-2.51 6.176 6.176 0 0 1 4.983 2.51Z" className="cls-4" data-name="Path 7582"/>
      <path d="M21.054 30.465a6.107 6.107 0 0 1 1.118 3.547 6.219 6.219 0 1 1-1.118-3.547Z" className="cls-4" data-name="Path 7583"/>
      <path d="M21.045 12.336a6.1 6.1 0 0 1 1.127 3.556 6.225 6.225 0 1 1-1.127-3.556Z" className="cls-4" data-name="Path 7584"/>
      <path fill="#daf1f3" d="M0 0h72.228v89.7L65 95l-7.229-5.3-7.219 5.3-7.228-5.3-7.22 5.3-7.219-5.3-7.219 5.3-7.219-5.3-7.22 5.3L0 89.7Z" data-name="Path 7585"/>
      <path d="M22.172 15.892a6.225 6.225 0 1 1-1.127-3.556 6.1 6.1 0 0 1 1.127 3.556Z" className="cls-6" data-name="Path 7586"/>
      <path d="M22.172 34.012a6.219 6.219 0 1 1-1.118-3.547 6.107 6.107 0 0 1 1.118 3.547Z" className="cls-6" data-name="Path 7587"/>
      <path d="M22.172 52.169a6.214 6.214 0 1 1-1.225-3.7 6.2 6.2 0 0 1 1.225 3.7Z" className="cls-6" data-name="Path 7588"/>
      <path d="M22.172 70.326a6.2 6.2 0 1 1-1.1-3.531 6.2 6.2 0 0 1 1.1 3.531Z" className="cls-6" data-name="Path 7589"/>
      <use xlinkHref="#b" className="cls-7" data-name="Rectangle 9678" transform="translate(30.748 14.163)"/>
      <use xlinkHref="#b" className="cls-7" data-name="Rectangle 9679" transform="translate(30.748 32.677)"/>
      <use xlinkHref="#b" className="cls-7" data-name="Rectangle 9680" transform="translate(30.748 50.841)"/>
      <use xlinkHref="#b" className="cls-7" data-name="Rectangle 9681" transform="translate(30.748 69.005)"/>
      <path d="M16.336 19.131a1.331 1.331 0 0 1-.941-.389L12.353 15.7l1.881-1.881 2.029 2.029 7.065-8.193 2.014 1.738-8 9.277a1.328 1.328 0 0 1-.958.46Z" className="cls-7" data-name="Path 7590"/>
      <path d="M16.336 37.276a1.331 1.331 0 0 1-.941-.389l-3.042-3.041 1.881-1.883 2.029 2.03 7.065-8.192 2.014 1.737-8 9.277a1.328 1.328 0 0 1-.958.46Z" className="cls-7" data-name="Path 7591"/>
      <path d="M16.336 55.16a1.332 1.332 0 0 1-.939-.388l-3.043-3.033 1.879-1.885 2.03 2.023 7.066-8.193 2.014 1.738-8 9.277a1.329 1.329 0 0 1-.957.46Z" className="cls-7" data-name="Path 7592"/>
      <path d="M16.336 73.636a1.331 1.331 0 0 1-.941-.39l-3.042-3.043 1.881-1.881 2.029 2.029 7.064-8.2 2.016 1.737-8 9.285a1.336 1.336 0 0 1-.959.462Z" className="cls-7" data-name="Path 7593"/>
    </g>
  </g>

  </Icon>
);

export const Receipt = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h49.3v2.827H0z" className="cls-4" data-name="Rectangle 8807"/>
    <path id="c" d="M0 0h25.875v2.827H0z" className="cls-4" data-name="Rectangle 8809"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 6781" transform="translate(11.806)">
      <path fill="#d2edef" d="M77.387 7.133V95H17.601V77.388H.001V7.133L9.668 0l9.677 7.133L29.013 0l9.677 7.133L48.367 0l9.668 7.133L67.712 0Z" data-name="Path 7075"/>
      <path fill="#00c0b7" d="M17.601 77.389V95L.001 77.389Z" data-name="Path 7076"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8807" transform="translate(14.049 23.095)"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 8808" transform="translate(14.049 34.355)"/>
      <use xlinkHref="#c" className="cls-4" data-name="Rectangle 8809" transform="translate(14.049 44.833)"/>
      <use xlinkHref="#c" className="cls-4" data-name="Rectangle 8810" transform="translate(14.049 55.321)"/>
      <use xlinkHref="#c" className="cls-4" data-name="Rectangle 8811" transform="translate(14.049 65.808)"/>
      <path d="M47.594 82.506h15.755v2.827H47.594z" className="cls-4" data-name="Rectangle 8812"/>
      <path d="M51.278 63.503a5.237 5.237 0 0 0 2.9 1.427v-7.715a19.865 19.865 0 0 1-2.666-.83 6.814 6.814 0 0 1-1.864-1.077 3.73 3.73 0 0 1-1.092-1.529 6.047 6.047 0 0 1-.349-2.184 6.089 6.089 0 0 1 .463-2.4 6 6 0 0 1 1.268-1.893 5.955 5.955 0 0 1 1.894-1.267 7.272 7.272 0 0 1 2.345-.552v-1.921h1.6v1.95a6.053 6.053 0 0 1 3.756 1.748 8.388 8.388 0 0 1 1.981 4.018h-2.475a3.876 3.876 0 0 0-3.261-3.377v7.163l1.746.466a7.821 7.821 0 0 1 1.367.51 6.273 6.273 0 0 1 1.515 1 5.594 5.594 0 0 1 1.238 1.6 4.877 4.877 0 0 1 .51 2.3 6.285 6.285 0 0 1-.482 2.533 5.749 5.749 0 0 1-1.324 1.907 6.287 6.287 0 0 1-2.008 1.266 8.865 8.865 0 0 1-2.563.584v2.388h-1.6V67.23a7.3 7.3 0 0 1-4.63-2.024 7.338 7.338 0 0 1-1.952-4.791h2.476a4.322 4.322 0 0 0 1.207 3.088Zm.527-14.689a3.177 3.177 0 0 0-.976 2.519 3.133 3.133 0 0 0 .245 1.3 2.479 2.479 0 0 0 .7.9 4.517 4.517 0 0 0 1.064.627 10.646 10.646 0 0 0 1.34.465v-6.814a4.15 4.15 0 0 0-2.375 1.003Zm5.471 15.825a3.448 3.448 0 0 0 1.195-.683 3 3 0 0 0 .789-1.136 4.3 4.3 0 0 0 .277-1.615 3.083 3.083 0 0 0-.291-1.413 3.064 3.064 0 0 0-.789-.975 4.437 4.437 0 0 0-1.2-.685 18.016 18.016 0 0 0-1.481-.51v7.309a5.88 5.88 0 0 0 1.5-.291Z" className="cls-4" data-name="Path 7077"/>
    </g>
  </g>

  </Icon>
);

export const Review = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h3.034v2.9H0z" className="cls-2" data-name="Rectangle 10425"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 9062" transform="translate(-3457.408 174.635)">
      <path d="M3507.907-101.565a16.924 16.924 0 0 0 16.924-16.923 16.924 16.924 0 0 0-16.924-16.923 16.924 16.924 0 0 0-16.924 16.923 16.924 16.924 0 0 0 16.924 16.923Zm0-39.182c21.232 0 38.447 22.258 38.447 22.258s-17.215 22.259-38.447 22.259-38.447-22.259-38.447-22.259 17.212-22.258 38.447-22.258Z" className="cls-2" data-name="Path 8038"/>
      <path d="M3457.408-171.153h100.999v12.255h-100.999z" className="cls-3" data-name="Rectangle 10424"/>
      <path d="M3507.906-112.593a5.893 5.893 0 0 0 5.9-5.9 5.894 5.894 0 0 0-5.9-5.9 5.9 5.9 0 0 0-5.9 5.9 5.894 5.894 0 0 0 5.9 5.9Zm0 11.028a16.923 16.923 0 0 1-16.921-16.924 16.922 16.922 0 0 1 16.921-16.923 16.925 16.925 0 0 1 16.924 16.923 16.925 16.925 0 0 1-16.924 16.924Z" className="cls-4" data-name="Path 8039"/>
      <path d="M3507.906-112.593a5.892 5.892 0 0 1-5.893-5.9 5.892 5.892 0 0 1 5.893-5.9 5.9 5.9 0 0 1 5.9 5.9 5.894 5.894 0 0 1-5.9 5.9Z" className="cls-3" data-name="Path 8040"/>
      <path d="M3507.908-96.225c21.232 0 38.447-22.258 38.447-22.258s-17.215-22.259-38.447-22.259-38.447 22.259-38.447 22.259 17.213 22.258 38.447 22.258Zm50.5-62.668v79.258h-101v-79.263Z" className="cls-4" data-name="Path 8041"/>
      <use xlinkHref="#b" className="cls-2" data-name="Rectangle 10425" transform="translate(3465.45 -166.475)"/>
      <path d="M3472.36-166.475h3.035v2.9h-3.035z" className="cls-2" data-name="Rectangle 10426"/>
      <use xlinkHref="#b" className="cls-2" data-name="Rectangle 10427" transform="translate(3479.272 -166.475)"/>
      <path d="M3502.292-166.475h48.547v2.9h-48.547z" className="cls-2" data-name="Rectangle 10428"/>
    </g>
  </g>

  </Icon>
);

export const Saw = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10589">
    <path d="M90.407 60.176v7.44H10.591v-7.44h79.815Z" className="cls-2" data-name="Path 8301"/>
    <path fill="#00c0b7" d="M83.996 60.175H56.94a6.439 6.439 0 0 0-6.44-6.446 6.44 6.44 0 0 0-6.446 6.446H16.998l4.356-4.2-3.01-5.242 5.365-2.793-1.394-5.885 5.933-1.164.311-6.04 6.019.548 2-5.709 5.622 2.226 3.53-4.911 4.769 3.727 4.762-3.727 3.531 4.911 5.628-2.226 2 5.709 6.02-.548.319 6.04 5.931 1.164-1.4 5.885 5.363 2.793-3 5.242Z" data-name="Path 8302"/>
    <path fill="#fff" d="M72.537 52.167a23.586 23.586 0 0 0-14.729-14.275l.631-1.929a25.634 25.634 0 0 1 16.006 15.512Z" data-name="Path 8303"/>
    <path d="M0 66.601h101v2.029H0z" className="cls-2" data-name="Rectangle 10665"/>
  </g>

  </Icon>
);

export const Seating = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h9.535v5.702H0z" className="cls-4" data-name="Rectangle 12931"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 10581" transform="translate(-2984.674 -2278.903)">
      <path fill="#f6cf76" d="M3001.261 2316.447c.1-.009.21-.009.309-.009a9.2 9.2 0 0 1 9.2 9.19v24.183h48.81v-24.183a9.189 9.189 0 0 1 9.19-9.19c.1 0 .209 0 .309.009s.218.018.317.018v-.009a9.2 9.2 0 0 1 8.573 9.172V2359a9.186 9.186 0 0 1-9.2 9.2h-67.2a9.191 9.191 0 0 1-9.19-9.2v-33.372a9.192 9.192 0 0 1 8.563-9.172v.009c.1 0 .208-.009.318-.018" data-name="Path 9279"/>
      <path fill="#fea856" d="M3010.769 2331.614h48.81v18.198h-48.81z" data-name="Rectangle 12930"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 12931" transform="translate(3005.429 2368.201)"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 12932" transform="translate(3055.375 2368.201)"/>
      <path fill="#e24c5d" d="M3069.396 2293.409v23.048c-.1 0-.208-.009-.319-.009s-.208-.009-.308-.009a9.19 9.19 0 0 0-9.191 9.19v5.984h-48.808v-5.985a9.2 9.2 0 0 0-9.2-9.19c-.1 0-.208 0-.309.009s-.209.009-.318.009v-23.047a12.361 12.361 0 0 1 12.359-12.359h43.732a12.355 12.355 0 0 1 12.358 12.359" data-name="Path 9280"/>
    </g>
  </g>

  </Icon>
);

export const Service = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h9.535v5.702H0z" className="cls-4" data-name="Rectangle 12931"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 10581" transform="translate(-2984.674 -2278.903)">
      <path fill="#f6cf76" d="M3001.261 2316.447c.1-.009.21-.009.309-.009a9.2 9.2 0 0 1 9.2 9.19v24.183h48.81v-24.183a9.189 9.189 0 0 1 9.19-9.19c.1 0 .209 0 .309.009s.218.018.317.018v-.009a9.2 9.2 0 0 1 8.573 9.172V2359a9.186 9.186 0 0 1-9.2 9.2h-67.2a9.191 9.191 0 0 1-9.19-9.2v-33.372a9.192 9.192 0 0 1 8.563-9.172v.009c.1 0 .208-.009.318-.018" data-name="Path 9279"/>
      <path fill="#fea856" d="M3010.769 2331.614h48.81v18.198h-48.81z" data-name="Rectangle 12930"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 12931" transform="translate(3005.429 2368.201)"/>
      <use xlinkHref="#b" className="cls-4" data-name="Rectangle 12932" transform="translate(3055.375 2368.201)"/>
      <path fill="#e24c5d" d="M3069.396 2293.409v23.048c-.1 0-.208-.009-.319-.009s-.208-.009-.308-.009a9.19 9.19 0 0 0-9.191 9.19v5.984h-48.808v-5.985a9.2 9.2 0 0 0-9.2-9.19c-.1 0-.208 0-.309.009s-.209.009-.318.009v-23.047a12.361 12.361 0 0 1 12.359-12.359h43.732a12.355 12.355 0 0 1 12.358 12.359" data-name="Path 9280"/>
    </g>
  </g>

  </Icon>
);

export const Share = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="b">
      <path fill="none" d="M0 0h94.885v95H0z" data-name="Rectangle 13059"/>
    </clipPath>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10606">
    <g clipPath="url(#b)" data-name="Group 10605">
      <path d="M50.475 69.688a25.237 25.237 0 1 1-25.237-25.236 25.236 25.236 0 0 1 25.237 25.236" className="cls-4" data-name="Path 9438"/>
      <path d="M12.756 64.047a12.484 12.484 0 0 0 14.628-6.871 12.529 12.529 0 0 0 10.361 7.262c.009.192.025.384.025.593v4.641a12.528 12.528 0 0 1-12.528 12.529 12.517 12.517 0 0 1-12.539-12.53v-4.64c0-.34.027-.662.053-.984" className="cls-5" data-name="Path 9439"/>
      <path fill="#e24c5d" d="M14.591 76.298a12.543 12.543 0 0 0 21.289-.009 12.552 12.552 0 0 1 8.455 10v.009c-.051.053-.095.113-.148.166-.174.2-.357.4-.549.591-.1.113-.209.226-.313.331a7.841 7.841 0 0 1-.479.47c-.183.192-.374.366-.565.54-.279.253-.558.5-.845.74-.313.262-.635.523-.966.766a24.963 24.963 0 0 1-3.179 2.038 7.177 7.177 0 0 1-.575.3c-.253.131-.505.253-.758.374a.583.583 0 0 1-.052.026l-.758.34c-.366.158-.731.3-1.1.435-.123.052-.253.1-.384.139-.531.192-1.061.357-1.61.505-.2.061-.4.113-.6.166-.67.165-1.349.313-2.037.417-.218.044-.428.07-.645.1-.357.053-.714.1-1.08.123-.34.034-.67.06-1.009.078-.088.008-.183.008-.271.008-.392.027-.774.035-1.167.035s-.792-.008-1.184-.035c-.078 0-.165 0-.243-.008-.366-.018-.732-.044-1.088-.078s-.723-.088-1.08-.131l-.618-.1c-.226-.035-.454-.078-.68-.121s-.443-.1-.661-.139h-.018c-.34-.078-.67-.157-1-.253s-.653-.182-.975-.287a25.477 25.477 0 0 1-11.576-7.653 12.654 12.654 0 0 1 8.469-9.883" data-name="Path 9440"/>
      <path d="M36.718 92.247c.192-.1.383-.192.575-.3a6.01 6.01 0 0 1-.575.3" className="cls-7" data-name="Path 9441"/>
      <path d="M37.746 64.439a12.531 12.531 0 0 1-10.361-7.262 12.152 12.152 0 0 0 1.1-4.24 12.508 12.508 0 0 1 9.256 11.5" className="cls-8" data-name="Path 9442"/>
      <path d="M26.261 52.544a12.562 12.562 0 0 1 2.229.391 12.153 12.153 0 0 1-1.106 4.24 12.484 12.484 0 0 1-14.628 6.871 12.534 12.534 0 0 1 12.486-11.555c.348 0 .679.027 1.019.053" className="cls-9" data-name="Path 9443"/>
      <path d="M22.752 69.105h-3.84a.871.871 0 0 1 0-1.741h3.84a.871.871 0 1 1 0 1.741" className="cls-9" data-name="Path 9444"/>
      <path d="M31.842 69.105h-3.84a.871.871 0 1 1 0-1.741h3.84a.871.871 0 1 1 0 1.741" className="cls-9" data-name="Path 9445"/>
      <path d="M25.191 77.058a5.631 5.631 0 0 1-2.885-.8.873.873 0 1 1 .892-1.5 3.9 3.9 0 0 0 4.876-.77.87.87 0 1 1 1.319 1.135 5.542 5.542 0 0 1-4.202 1.935" className="cls-9" data-name="Path 9446"/>
      <path d="M94.885 25.236A25.237 25.237 0 1 1 69.648 0a25.236 25.236 0 0 1 25.237 25.236" className="cls-4" data-name="Path 9447"/>
      <path d="M57.166 19.595a12.484 12.484 0 0 0 14.628-6.871 12.529 12.529 0 0 0 10.361 7.262c.009.192.025.384.025.593v4.641a12.528 12.528 0 0 1-12.528 12.529 12.517 12.517 0 0 1-12.539-12.53v-4.64c0-.34.027-.662.053-.984" className="cls-5" data-name="Path 9448"/>
      <path fill="#f6cf76" d="M59.003 31.845a12.543 12.543 0 0 0 21.293-.009 12.552 12.552 0 0 1 8.455 10v.009c-.051.053-.095.113-.148.166-.174.2-.357.4-.549.591-.1.113-.209.226-.313.331a7.841 7.841 0 0 1-.479.47c-.183.192-.374.366-.565.54-.279.253-.558.5-.845.74-.313.262-.635.523-.966.766a24.961 24.961 0 0 1-3.179 2.038 7.177 7.177 0 0 1-.575.3c-.253.131-.505.253-.758.374-.017.01-.035.018-.051.026l-.758.34c-.365.158-.731.3-1.1.435-.123.052-.253.1-.384.139-.531.192-1.061.357-1.61.505-.2.061-.4.113-.6.166-.67.165-1.349.313-2.037.417-.218.044-.428.07-.645.1-.357.053-.714.1-1.08.123-.34.034-.67.06-1.009.078-.088.009-.183.009-.271.009-.392.026-.774.034-1.166.034s-.793-.008-1.185-.034a2.266 2.266 0 0 1-.243-.009c-.366-.018-.732-.044-1.088-.078s-.723-.088-1.08-.131l-.618-.105c-.226-.035-.454-.078-.68-.121s-.443-.1-.661-.139h-.018c-.339-.078-.67-.157-1-.253s-.653-.182-.975-.287a25.477 25.477 0 0 1-11.572-7.661 12.654 12.654 0 0 1 8.471-9.883" data-name="Path 9449"/>
      <path d="M81.128 47.797c.192-.1.383-.192.575-.3a6.008 6.008 0 0 1-.575.3" className="cls-7" data-name="Path 9450"/>
      <path d="M82.155 19.986a12.531 12.531 0 0 1-10.361-7.262 12.152 12.152 0 0 0 1.1-4.24 12.508 12.508 0 0 1 9.256 11.5" className="cls-8" data-name="Path 9451"/>
      <path d="M70.671 8.093a12.484 12.484 0 0 1 2.229.391 12.153 12.153 0 0 1-1.106 4.24 12.484 12.484 0 0 1-14.628 6.871A12.534 12.534 0 0 1 69.652 8.04c.348 0 .679.027 1.019.053" className="cls-9" data-name="Path 9452"/>
      <path d="M67.162 24.653h-3.836a.871.871 0 1 1 0-1.741h3.84a.871.871 0 0 1 0 1.741" className="cls-9" data-name="Path 9453"/>
      <path d="M76.252 24.653h-3.84a.871.871 0 1 1 0-1.741h3.84a.871.871 0 0 1 0 1.741" className="cls-9" data-name="Path 9454"/>
      <path d="M69.6 32.603a5.632 5.632 0 0 1-2.886-.8.871.871 0 1 1 .892-1.495 3.9 3.9 0 0 0 4.876-.77.87.87 0 1 1 1.319 1.135 5.542 5.542 0 0 1-4.2 1.931" className="cls-9" data-name="Path 9455"/>
      <path d="M23.222 34.064H20.61V20.595a8.484 8.484 0 0 1 8.475-8.474h9.855v2.612h-9.855a5.869 5.869 0 0 0-5.863 5.862Z" className="cls-4" data-name="Path 9456"/>
      <path d="m17.487 29.018 4.428 4.431 4.428-4.431v3.76l-4.428 4.428-4.428-4.428Z" className="cls-4" data-name="Path 9457"/>
      <path d="M65.827 82.838h-9.858v-2.612h9.858a5.87 5.87 0 0 0 5.862-5.862V60.897h2.612v13.467a8.484 8.484 0 0 1-8.475 8.474" className="cls-4" data-name="Path 9458"/>
      <path d="m77.425 65.943-4.428-4.431-4.429 4.431v-3.76l4.428-4.428 4.428 4.428Z" className="cls-4" data-name="Path 9459"/>
    </g>
  </g>

  </Icon>
);

export const Shared = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h25.664v2.755H0z" className="cls-2" data-name="Rectangle 12763"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10561">
    <g data-name="Group 10398" transform="translate(0 12.363)">
      <path d="M101 58.636v24H0V37.645h34.891l9.182-12.983H101v33.973Zm-9.182 16.757v-12.9H60.876v12.9Z" className="cls-2" data-name="Path 8964"/>
      <path fill="#558ab1" d="M101 8.722v15.94h-9.6v-7.988H9.589v11.908H9.58v9.063H-.005V-.001h43.154l9.182 8.722Z" data-name="Path 8965"/>
      <path d="M91.405 24.506v.156H44.073l-9.182 12.984H9.585v-9.064h11.909V16.675h69.911Z" className="cls-4" data-name="Path 8966"/>
      <path d="m21.494 16.675-11.9 11.9v-11.9Z" className="cls-4" data-name="Path 8968"/>
      <path d="M57.128 60.931h38.438v16.024H57.128z" className="cls-2" data-name="Rectangle 12761"/>
      <path d="M9.228 58.176h15.609v2.755H9.228z" className="cls-2" data-name="Rectangle 12762"/>
      <use xlinkHref="#b" className="cls-2" data-name="Rectangle 12763" transform="translate(9.228 64.448)"/>
      <use xlinkHref="#b" className="cls-2" data-name="Rectangle 12764" transform="translate(9.228 70.719)"/>
    </g>
    <g data-name="Group 10428">
      <g data-name="Group 10424">
        <path d="M49.738 55.442a3.6 3.6 0 0 1 2.185 6.465 3.638 3.638 0 0 1-1.011.544 3.49 3.49 0 0 1-.273.084h-.008a2.456 2.456 0 0 1-.288.062 2.557 2.557 0 0 1-.3.037 3.089 3.089 0 0 1-.606 0 2.561 2.561 0 0 1-.3-.037 3.249 3.249 0 0 1-.569-.146 3.636 3.636 0 0 1-1.011-.544 3.6 3.6 0 0 1 2.186-6.465Z" className="cls-5" data-name="Path 8969"/>
        <path d="M51.924 61.907a3.5 3.5 0 0 1-1.015.544 2.319 2.319 0 0 1-.274.084h-.007a2.583 2.583 0 0 1-.289.062 2.61 2.61 0 0 1-.3.036 2.793 2.793 0 0 1-.3.015 2.754 2.754 0 0 1-.3-.015 2.645 2.645 0 0 1-.3-.036 3.256 3.256 0 0 1-.568-.146 3.389 3.389 0 0 1-1.014-.544 6.442 6.442 0 0 0-4.258 6.053v8.83h2.434v-8.877h.73v9.243a.365.365 0 0 1-.365.365h-.537v9h3.817V74.746h.729v11.775h3.817v-9h-.518a.364.364 0 0 1-.365-.365v-9.243h.73v8.877h2.415v-8.83a6.443 6.443 0 0 0-4.262-6.053Z" className="cls-5" data-name="Path 8970"/>
      </g>
      <g data-name="Group 10425">
        <path d="M65.792 55.442a3.6 3.6 0 0 1 2.186 6.465 3.643 3.643 0 0 1-1.011.544 3.49 3.49 0 0 1-.273.084h-.007a2.477 2.477 0 0 1-.288.062 2.583 2.583 0 0 1-.3.037 3.089 3.089 0 0 1-.606 0 2.557 2.557 0 0 1-.3-.037 3.272 3.272 0 0 1-.569-.146 3.643 3.643 0 0 1-1.011-.544 3.6 3.6 0 0 1 2.186-6.465Z" className="cls-5" data-name="Path 8971"/>
        <path d="M67.978 61.907a3.488 3.488 0 0 1-1.015.544 2.292 2.292 0 0 1-.274.084h-.007a2.514 2.514 0 0 1-.289.062 2.588 2.588 0 0 1-.3.036 2.793 2.793 0 0 1-.3.015 2.754 2.754 0 0 1-.3-.015 2.6 2.6 0 0 1-.3-.036 3.223 3.223 0 0 1-.569-.146 3.377 3.377 0 0 1-1.014-.544 6.443 6.443 0 0 0-4.259 6.053v8.83h2.434v-8.877h.73v9.243a.365.365 0 0 1-.365.365h-.537v9h3.817V74.746h.729v11.775h3.817v-9h-.519a.364.364 0 0 1-.364-.365v-9.243h.729v8.877h2.416v-8.83a6.442 6.442 0 0 0-4.26-6.053Z" className="cls-5" data-name="Path 8972"/>
      </g>
      <g data-name="Group 10426">
        <path d="M81.847 55.442a3.6 3.6 0 0 1 2.186 6.465 3.643 3.643 0 0 1-1.011.544 3.49 3.49 0 0 1-.273.084h-.007a2.5 2.5 0 0 1-.288.062 2.583 2.583 0 0 1-.3.037 3.089 3.089 0 0 1-.606 0 2.578 2.578 0 0 1-.3-.037 3.278 3.278 0 0 1-.568-.146 3.626 3.626 0 0 1-1.01-.544 3.6 3.6 0 0 1 2.185-6.465Z" className="cls-5" data-name="Path 8973"/>
        <path d="M84.032 61.907a3.488 3.488 0 0 1-1.015.544 2.292 2.292 0 0 1-.274.084h-.007a2.552 2.552 0 0 1-.288.062 2.623 2.623 0 0 1-.3.036 2.8 2.8 0 0 1-.3.015 2.768 2.768 0 0 1-.3-.015 2.593 2.593 0 0 1-.3-.036 3.223 3.223 0 0 1-.569-.146 3.386 3.386 0 0 1-1.015-.544 6.443 6.443 0 0 0-4.258 6.053v8.83h2.434v-8.877h.73v9.243a.365.365 0 0 1-.365.365h-.537v9h3.817V74.746h.73v11.775h3.816v-9h-.518a.364.364 0 0 1-.365-.365v-9.243h.729v8.877h2.416v-8.83a6.442 6.442 0 0 0-4.261-6.053Z" className="cls-5" data-name="Path 8974"/>
      </g>
    </g>
  </g>

  </Icon>
);

export const Shower = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <path d="M44.682 61.336c1.771 0 3.205-1.771 3.205-3.96h.053v18.6H22.26v-18.6c0 2.189 1.434 3.96 3.205 3.96s3.2-1.771 3.2-3.96c0 2.189 1.441 3.96 3.206 3.96s3.2-1.771 3.2-3.96c0 2.189 1.433 3.96 3.206 3.96s3.2-1.771 3.2-3.96c0 2.189 1.434 3.96 3.2 3.96" className="cls-2" data-name="Path 9409"/>
    <path d="M75.361 61.206c1.772 0 3.205-1.771 3.205-3.961h.008v18.734h-25.44V6.408h12.619v50.838c0 2.19 1.434 3.961 3.205 3.961s3.2-1.771 3.2-3.961c0 2.19 1.441 3.961 3.205 3.961" className="cls-2" data-name="Path 9410"/>
    <path fill="#40566b" d="M83.761 82.388H17.072V0h66.689Zm-5.189-6.409V6.408H53.134v69.571Zm-30.635 0V6.408H22.259v69.571Z" data-name="Path 9407"/>
    <path d="M72.156 57.246c0 2.189-1.434 3.961-3.2 3.961s-3.2-1.772-3.2-3.961V6.409h12.818v50.837h-.008c0 2.189-1.434 3.961-3.205 3.961s-3.205-1.772-3.205-3.961" className="cls-4" data-name="Path 9411"/>
    <path d="M35.074 57.376c0 2.188-1.434 3.96-3.205 3.96s-3.205-1.772-3.205-3.96c0 2.188-1.434 3.96-3.2 3.96s-3.205-1.772-3.205-3.96V6.408H47.94v50.968h-.053c0 2.188-1.434 3.96-3.205 3.96s-3.2-1.772-3.2-3.96c0 2.188-1.441 3.96-3.205 3.96s-3.205-1.772-3.205-3.96" className="cls-4" data-name="Path 9408"/>
    <path fill="#00c0b7" d="M90.795 82.388V95h-80.59V82.388h80.59Z" data-name="Path 9412"/>
  </g>

  </Icon>
);

export const Stairs = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 6108">
    <path d="M101 8.129v16.288L19.411 93.163H.001V64.82h32.388V36.474h7.071l-.013.038h13.971l.012-.038h14.154V8.13Z" className="cls-2" data-name="Path 6692"/>
    <path d="M0 91.324h101V95H0z" className="cls-2" data-name="Rectangle 8274"/>
  </g>

  </Icon>
);

export const Tape = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 8209">
    <path d="M101 67.211v19.193h-7.9V67.211Z" className="cls-2" data-name="Path 8356"/>
    <path fill="#00c0b7" d="M74.083 81.503h19.014v4.901H74.083z" data-name="Rectangle 10715"/>
    <path d="m74.926 26.872-4.319 4.319a26.424 26.424 0 0 0-1.21-2.24c-.2-.35-.411-.7-.635-1.048-.019-.01-.019-.018-.028-.036-.224-.358-.457-.7-.708-1.05-.233-.34-.475-.681-.735-1.021a33.294 33.294 0 0 0-2.589-3.037 14.28 14.28 0 0 0-.744-.753c-.3-.306-.627-.61-.949-.906a32.375 32.375 0 0 0-1.864-1.568c-.009-.008-.019-.017-.028-.017a31.615 31.615 0 0 0-4.211-2.823l4.266-4.256a40.018 40.018 0 0 1 13.754 14.436Z" className="cls-2" data-name="Path 8357"/>
    <path fill="#f6cf76" d="M35.743 33.449a18.521 18.521 0 0 1 18.522 18.512 18.523 18.523 0 0 1-18.522 18.521 18.516 18.516 0 0 1-18.521-18.521 18.514 18.514 0 0 1 18.521-18.512Z" data-name="Path 8358"/>
    <path fill="#e24c5d" d="M74.083 81.502v4.9h-62.39A11.69 11.69 0 0 1 0 74.709V46.122a33.794 33.794 0 0 1 33.789-33.8h6.5a33.525 33.525 0 0 1 16.621 4.373 34.279 34.279 0 0 1 4.211 2.823c.009 0 .019.009.028.017.635.5 1.263 1.031 1.864 1.568.322.3.644.6.949.906.251.242.5.493.744.753a33.294 33.294 0 0 1 2.589 3.037c.251.332.494.672.735 1.021s.475.7.708 1.05c.009.018.009.026.028.036.215.349.429.7.635 1.048.43.726.833 1.478 1.21 2.24a33.5 33.5 0 0 1 3.477 14.927v35.384ZM54.264 51.961a18.521 18.521 0 0 0-18.522-18.512 18.514 18.514 0 0 0-18.521 18.512 18.516 18.516 0 0 0 18.521 18.521 18.523 18.523 0 0 0 18.527-18.521Z" data-name="Path 8359"/>
    <path fill="#cd4160" d="M8.164 52.386H5.476v-6.272A28.345 28.345 0 0 1 33.79 17.801v2.688A25.655 25.655 0 0 0 8.165 46.114Z" data-name="Path 8360"/>
  </g>

  </Icon>
);

export const Theatre = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h10.716v1.933H0z" className="cls-6" data-name="Rectangle 8315"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)">
    <g data-name="Group 12" transform="translate(-5436.485 523.95)">
      <path d="m5486.979-507.524-8.681 5.355-4.234-3.16-10.227.454-3.586-2.6 11.632-9.355 6.717 4.146Z" className="cls-2" data-name="Path 6759"/>
      <path d="m5495.657-502.17 38.321 23.641a65.025 65.025 0 0 0-6.68-.424h-17.847l-22.475-16.755Z" className="cls-2" data-name="Path 6760"/>
      <path d="m5513.701-507.476-3.588 2.6-10.225-.454-4.234 3.161-8.673 6.469-22.475 16.751h-17.865a63.084 63.084 0 0 0-6.7.434l38.348-23.651 8.685-5.354 8.368-5.161 6.717-4.146Z" className="cls-3" data-name="Path 6761"/>
      <path fill="#e24c5d" d="M5528.46-469.925v24.83h-26.423c-4.167-5.857-7.682-8.505-10.343-9.713v-15.117Z" data-name="Path 6762"/>
      <path fill="#00c0b7" d="M5482.105-469.925v15.2c-2.639 1.247-6.089 3.9-10.146 9.626h-26.446v-24.826Z" data-name="Path 6763"/>
      <use xlinkHref="#b" className="cls-6" data-name="Rectangle 8315" transform="rotate(-45 2176.605 -6812.9)"/>
      <path d="m5456.02-449.443 15.754-15.764 1.367 1.366-15.754 15.764z" className="cls-6" data-name="Rectangle 8316"/>
      <use xlinkHref="#b" className="cls-6" data-name="Rectangle 8317" transform="rotate(-45.002 2199.843 -6871.886)"/>
      <path d="m5505.254-449.446 15.758-15.758 1.367 1.367-15.758 15.758z" className="cls-6" data-name="Rectangle 8318"/>
      <path d="M5532.007-478.953h-90.041a5.481 5.481 0 0 0-5.482 5.48v31.761a5.655 5.655 0 0 0 5.634 5.645h34.718s6.465-10.729 10.158-10.729 10.149 10.729 10.149 10.729h34.7a5.661 5.661 0 0 0 5.641-5.645v-31.759a5.48 5.48 0 0 0-5.477-5.482Zm-49.9 24.23c-2.634 1.247-6.085 3.9-10.146 9.626h-26.451v-24.83h36.59Zm46.357 9.626h-26.424c-4.168-5.857-7.683-8.505-10.343-9.713v-15.117h36.767Z" className="cls-3" data-name="Path 6764"/>
    </g>
  </g>

  </Icon>
);

export const Value = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <clipPath id="b">
      <path fill="none" d="M0 0h101v79.106H0z" data-name="Rectangle 12533"/>
    </clipPath>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 10116">
    <path d="m31.132 73.005 6.457 21.45 6.457-21.45Z" className="cls-3" data-name="Path 8858"/>
    <path d="M82.121 73.005H68.545L75.17 95H101l-7.947-21.995Z" className="cls-3" data-name="Path 8859"/>
    <path d="m74.174 51.019 7.947 21.986h-25.83l-6.615-21.986h24.5Z" className="cls-4" data-name="Path 8860"/>
    <path d="M56.292 73.005H44.047l-6.458 21.451.159.544H75.17l-6.625-21.995Z" className="cls-5" data-name="Path 8861"/>
    <path d="M18.544 73.005H6.625L0 95h37.422l.167-.544-6.457-21.45Z" className="cls-4" data-name="Path 8862"/>
    <path d="m49.846 51.019 6.616 21.986H18.715l6.624-21.986Z" className="cls-5" data-name="Path 8863"/>
    <g data-name="Group 10115">
      <g clipPath="url(#b)" data-name="Group 10114" transform="translate(0 15.893)">
        <path d="M71.134 11.86c-10.093.737-10.7 1.34-11.433 11.433-.745-10.092-1.349-10.7-11.442-11.433 10.093-.741 10.697-1.341 11.443-11.441.738 10.1 1.338 10.7 11.432 11.441" className="cls-7" data-name="Path 8864"/>
        <path d="M59.701 23.711a.42.42 0 0 1-.418-.389C58.552 13.413 58.139 13 48.23 12.278a.419.419 0 0 1 0-.836C58.139 10.71 58.552 10.3 59.283.388a.419.419 0 0 1 .836 0c.724 9.908 1.136 10.321 11.046 11.053a.419.419 0 0 1 0 .836c-9.91.723-10.323 1.136-11.044 11.045a.419.419 0 0 1-.418.389m-7.057-11.853c4.971.681 6.368 2.077 7.053 7.047.682-4.97 2.077-6.366 7.049-7.047-4.972-.686-6.367-2.082-7.049-7.051-.685 4.971-2.081 6.365-7.053 7.051" className="cls-7" data-name="Path 8865"/>
        <path d="M25.227 18.903h11.801v2.513H25.227z" className="cls-7" data-name="Rectangle 12529"/>
        <path d="M29.875 14.263h2.513v11.793h-2.513z" className="cls-7" data-name="Rectangle 12530"/>
        <path d="M78.981 25.209h9.833v2.513h-9.833z" className="cls-7" data-name="Rectangle 12531"/>
        <path d="M82.641 21.549h2.513v9.834h-2.513z" className="cls-7" data-name="Rectangle 12532"/>
      </g>
    </g>
  </g>

  </Icon>
);

export const Warranty = (props) => (
  <Icon viewBox="0 0 101 95" {...props}>
    
  <defs>
    <path id="b" d="M0 0h43.15v2.794H0z" className="cls-5" data-name="Rectangle 7663"/>
  </defs>
  <defs>
    <clipPath id="a">
      <path d="M0 0h101v95H0z"/>
    </clipPath>
  </defs>
  <g clipPath="url(#a)" data-name="Group 8223">
    <path fill="#d2edef" d="M89.894 94.812H27.469A10.712 10.712 0 0 1 16.76 84.101V10.71h41.21A10.713 10.713 0 0 1 68.684 0a10.709 10.709 0 0 1 10.708 10.71l.179 73.391a10.15 10.15 0 0 0 10.323 10.711Zm-29.2-10.711a7.371 7.371 0 0 0 7.366-7.366 7.366 7.366 0 0 0-7.366-7.367 7.366 7.366 0 0 0-7.369 7.367 7.371 7.371 0 0 0 7.371 7.366Z" data-name="Path 6151"/>
    <path d="M79.578 84.103H101a11.321 11.321 0 0 1-11.1 10.711 10.152 10.152 0 0 1-10.322-10.711Z" className="cls-3" data-name="Path 6152"/>
    <path d="M68.686 0a10.711 10.711 0 0 0-10.71 10.71H-.001A10.712 10.712 0 0 1 10.708 0Z" className="cls-3" data-name="Path 6153"/>
    <path fill="#d4334b" d="M60.7 84.102a7.372 7.372 0 0 1-7.369-7.366 7.367 7.367 0 0 1 7.369-7.368 7.366 7.366 0 0 1 7.366 7.368 7.371 7.371 0 0 1-7.366 7.366Z" data-name="Path 6154"/>
    <use xlinkHref="#b" className="cls-5" data-name="Rectangle 7663" transform="translate(26.777 22.045)"/>
    <use xlinkHref="#b" className="cls-5" data-name="Rectangle 7664" transform="translate(26.777 30.12)"/>
    <use xlinkHref="#b" className="cls-5" data-name="Rectangle 7665" transform="translate(26.777 38.186)"/>
    <use xlinkHref="#b" className="cls-5" data-name="Rectangle 7666" transform="translate(26.777 46.261)"/>
    <use xlinkHref="#b" className="cls-5" data-name="Rectangle 7667" transform="translate(26.777 54.335)"/>
    <path d="M26.777 80.097h17.696v2.794H26.777z" className="cls-5" data-name="Rectangle 7668"/>
  </g>

  </Icon>
);

export const FullLogo = (props) => (
  <Icon viewBox="0 0 562 138" {...props}>
    
  <defs>
    <clipPath id="clip-Web_1920_1">
      <rect width="562" height="138"/>
    </clipPath>
  </defs>
  <g id="Web_1920_1" data-name="Web 1920 &#x2013; 1" clipPath="url(#clip-Web_1920_1)">
    <g id="Group_1" data-name="Group 1">
      <path id="Path_6509" data-name="Path 6509" d="M1009.182,312.316H894.963a12.142,12.142,0,0,0-12.142,12.142V438.677a12.142,12.142,0,0,0,12.142,12.143h35.694V404.592a20.794,20.794,0,0,1,41.589,0v36.147h-9.6V404.592a11.2,11.2,0,0,0-22.393,0v46.228h44.913V400.167a32.974,32.974,0,1,0-65.948,0v32.707h-9.6V400.167c0-1.208.056-2.451.167-3.712H901a5.388,5.388,0,0,1-4.9-2.915,4.522,4.522,0,0,1,1.05-5.331l41.289-40.574L951.43,335.1l.643-.621.643.621,12.993,12.546,41.266,40.552a4.532,4.532,0,0,1,1.067,5.347,5.389,5.389,0,0,1-4.9,2.915H994.6c.112,1.264.169,2.507.169,3.712v50.653h14.416a12.142,12.142,0,0,0,12.143-12.143V324.458A12.142,12.142,0,0,0,1009.182,312.316Z" transform="translate(-882.821 -312.316)" fill="#6ab528"/>
      <g id="Group_5829" data-name="Group 5829" transform="translate(176.063 33.702)">
        <path id="Path_6494" data-name="Path 6494" d="M771.387,480.521v58.628h-9.549V512.824h-27.57v26.324H724.8V480.521h9.467V505.1h27.57V480.521Z" transform="translate(-724.801 -469.989)" fill="#464646"/>
        <path id="Path_6495" data-name="Path 6495" d="M825.952,497.889a20.282,20.282,0,0,1,8.3,8.138,27.617,27.617,0,0,1,0,25,20.267,20.267,0,0,1-8.3,8.138,26.642,26.642,0,0,1-23.875,0,20.523,20.523,0,0,1-8.3-8.179,27.2,27.2,0,0,1,0-24.955,20.6,20.6,0,0,1,8.3-8.138,26.65,26.65,0,0,1,23.875,0Zm-18.684,7.059a12.244,12.244,0,0,0-4.941,5.107,20.037,20.037,0,0,0,0,16.9,12.273,12.273,0,0,0,4.941,5.066,14.446,14.446,0,0,0,13.452,0,12.274,12.274,0,0,0,4.941-5.066,20.028,20.028,0,0,0,0-16.9,12.245,12.245,0,0,0-4.941-5.107,14.448,14.448,0,0,0-13.452,0Z" transform="translate(-735.022 -472.244)" fill="#464646"/>
        <path id="Path_6496" data-name="Path 6496" d="M925.965,500.018q4.858,5.151,4.858,14.7v26.656h-9.467v-25.66q0-5.979-2.99-9.259a10.567,10.567,0,0,0-8.222-3.28A11.492,11.492,0,0,0,901.3,506.7q-3.282,3.531-3.28,10.339v24.331h-9.467v-25.66q0-5.979-2.99-9.259a10.565,10.565,0,0,0-8.222-3.28A11.491,11.491,0,0,0,868.5,506.7q-3.281,3.531-3.28,10.339v24.331h-9.467V495.617h9.467v9.549a14.551,14.551,0,0,1,5.813-7.64,17.074,17.074,0,0,1,9.55-2.657,17.348,17.348,0,0,1,9.924,2.824,15.989,15.989,0,0,1,6.1,8.137A16.528,16.528,0,0,1,903,497.776a17.677,17.677,0,0,1,9.965-2.907Q921.106,494.869,925.965,500.018Z" transform="translate(-745.099 -472.213)" fill="#464646"/>
        <path id="Path_6497" data-name="Path 6497" d="M999.421,520.394h-35.21q.25,7.059,3.9,10.463a12.764,12.764,0,0,0,9.052,3.405,13.165,13.165,0,0,0,8.014-2.408,9.942,9.942,0,0,0,4.027-6.394h10.132a19.44,19.44,0,0,1-11.377,14.407,24.762,24.762,0,0,1-10.38,2.118,23.876,23.876,0,0,1-11.751-2.824,20.075,20.075,0,0,1-8.014-8.138,28.33,28.33,0,0,1,0-25,20.09,20.09,0,0,1,8.014-8.138,26.038,26.038,0,0,1,23.5-.042,19.37,19.37,0,0,1,7.723,7.64,23.6,23.6,0,0,1,2.367,14.907ZM988.667,508.56a10.689,10.689,0,0,0-4.65-4.484,14.363,14.363,0,0,0-6.435-1.453,13.3,13.3,0,0,0-9.3,3.4q-3.737,3.406-4.07,10.049H990.2A14.08,14.08,0,0,0,988.667,508.56Z" transform="translate(-760.469 -472.244)" fill="#464646"/>
        <path id="Path_6498" data-name="Path 6498" d="M1035.1,484.8q-2.243,2.036-2.243,6.934v.748H1042.9v8.138h-10.048V538.24H1023.3V500.623h-6.229v-8.138h6.229V490.99q0-8.387,4.9-12.581t14.7-3.7v8.3Q1037.339,482.769,1035.1,484.8Z" transform="translate(-770.104 -469.081)" fill="#464646"/>
        <path id="Path_6499" data-name="Path 6499" d="M1057.321,475.022a6.131,6.131,0,0,1,4.4-1.577,6.281,6.281,0,0,1,4.442,1.577,5.2,5.2,0,0,1,1.7,3.987,4.946,4.946,0,0,1-1.7,3.9,6.485,6.485,0,0,1-4.442,1.495,6.328,6.328,0,0,1-4.4-1.495,5,5,0,0,1-1.661-3.9A5.263,5.263,0,0,1,1057.321,475.022Zm9.134,17.274v45.756h-9.467V492.3Z" transform="translate(-776.085 -468.892)" fill="#464646"/>
        <path id="Path_6500" data-name="Path 6500" d="M1088.154,477.18v61.451h-9.466V477.18Z" transform="translate(-779.655 -469.471)" fill="#464646"/>
        <path id="Path_6501" data-name="Path 6501" d="M1144.108,520.394H1108.9q.25,7.059,3.9,10.463a12.764,12.764,0,0,0,9.052,3.405,13.168,13.168,0,0,0,8.013-2.408,9.943,9.943,0,0,0,4.027-6.394h10.131a19.444,19.444,0,0,1-11.376,14.407,24.765,24.765,0,0,1-10.379,2.118,23.88,23.88,0,0,1-11.751-2.824,20.075,20.075,0,0,1-8.013-8.138,28.326,28.326,0,0,1,0-25,20.09,20.09,0,0,1,8.013-8.138,26.04,26.04,0,0,1,23.5-.042,19.379,19.379,0,0,1,7.722,7.64,23.6,23.6,0,0,1,2.367,14.907Zm-10.754-11.834a10.7,10.7,0,0,0-4.65-4.484,14.361,14.361,0,0,0-6.435-1.453,13.3,13.3,0,0,0-9.3,3.4q-3.737,3.406-4.069,10.049h25.992A14.078,14.078,0,0,0,1133.354,508.56Z" transform="translate(-782.896 -472.244)" fill="#464646"/>
        <g id="Homefile" transform="translate(364.053)" >
          <text id="TM" transform="translate(0 16)" fill="#464646" fontSize="15" fontFamily="Poppins-Bold, Poppins" fontWeight="700"><tspan x="0" y="0">TM</tspan></text>
        </g>
      </g>
    </g>
  </g>

  </Icon>
);

export const Icon_logo = (props) => (
  <Icon viewBox="0 0 138 138" {...props}>
    
  <defs>
    <clipPath id="clip-Web_1920_2">
      <rect width="138" height="138"/>
    </clipPath>
  </defs>
  <g id="Web_1920_2" data-name="Web 1920 &#x2013; 2" clipPath="url(#clip-Web_1920_2)">
    <path id="Path_6509" data-name="Path 6509" d="M1009.182,312.316H894.963a12.142,12.142,0,0,0-12.142,12.142V438.677a12.142,12.142,0,0,0,12.142,12.143h35.694V404.592a20.794,20.794,0,0,1,41.589,0v36.147h-9.6V404.592a11.2,11.2,0,0,0-22.393,0v46.228h44.913V400.167a32.974,32.974,0,1,0-65.948,0v32.707h-9.6V400.167c0-1.208.056-2.451.167-3.712H901a5.388,5.388,0,0,1-4.9-2.915,4.522,4.522,0,0,1,1.05-5.331l41.289-40.574L951.43,335.1l.643-.621.643.621,12.993,12.546,41.266,40.552a4.532,4.532,0,0,1,1.067,5.347,5.389,5.389,0,0,1-4.9,2.915H994.6c.112,1.264.169,2.507.169,3.712v50.653h14.416a12.142,12.142,0,0,0,12.143-12.143V324.458A12.142,12.142,0,0,0,1009.182,312.316Z" transform="translate(-882.821 -312.316)" fill="#6ab528"/>
  </g>

  </Icon>
);

export const ThreeDots = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    
  <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>

  </Icon>
);

export const IconLogoDefault = (props) => (
  <Icon viewBox="0 0 138 138" {...props}>
    
  <defs>
    <clipPath id="clip-Web_1920_2">
      <rect width="138" height="138"/>
    </clipPath>
  </defs>
  <g id="Web_1920_2" data-name="Web 1920 &#x2013; 2" clipPath="url(#clip-Web_1920_2)">
    <path id="Path_6509" data-name="Path 6509" d="M1009.182,312.316H894.963a12.142,12.142,0,0,0-12.142,12.142V438.677a12.142,12.142,0,0,0,12.142,12.143h35.694V404.592a20.794,20.794,0,0,1,41.589,0v36.147h-9.6V404.592a11.2,11.2,0,0,0-22.393,0v46.228h44.913V400.167a32.974,32.974,0,1,0-65.948,0v32.707h-9.6V400.167c0-1.208.056-2.451.167-3.712H901a5.388,5.388,0,0,1-4.9-2.915,4.522,4.522,0,0,1,1.05-5.331l41.289-40.574L951.43,335.1l.643-.621.643.621,12.993,12.546,41.266,40.552a4.532,4.532,0,0,1,1.067,5.347,5.389,5.389,0,0,1-4.9,2.915H994.6c.112,1.264.169,2.507.169,3.712v50.653h14.416a12.142,12.142,0,0,0,12.143-12.143V324.458A12.142,12.142,0,0,0,1009.182,312.316Z" transform="translate(-882.821 -312.316)" fill="#DADEE0"/>
  </g>

  </Icon>
);