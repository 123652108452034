import { DrawerContent, DrawerHeader, Center, DrawerBody } from '@chakra-ui/react'
import { 
  PanelHeader, 
  AssignableReceipts, 
  FooterDrawer, 
  FooterButtons,
  ReceiptsInfo,
  SalesTax,
  Summary,
  ReceiptsHeader,
  ReceiptsDisplayOptions,
  DisplayReceipts, } from "@homefile/components";
import { ReceiptsIcon } from "../../../assets/images";
import { t } from 'i18next'
import { SelectItemI } from "@homefile/components/dist/interfaces";
import BeatLoader from "react-spinners/BeatLoader";
import HomeboardReceiptListSlider from "../../Sliders/HomeboardReceiptListSlider";
import { useReceipts } from '../../../hooks/useReceipts';
import GenericDialog from "../../Dialogs/GenericDialog";
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store";
import {useHomeBoardReceipts} from '../../../hooks/useHomeBoardReceipts'
import {useState, useEffect} from 'react'

interface ReceiptsListI {
    handleClose: () => void
    onLaunchpad: boolean
    homes: SelectItemI[]
}

const ReceiptList = ({handleClose, onLaunchpad, homes}: ReceiptsListI) => {
  const homeId = useAppSelector((state: RootState) => state.home.id)
  const [type, setType] = useState<'list' | 'grid' | 'item'>('grid')
  const [totalShowing, updateTotalShowing] = useState(0)
  const [receiptsTaxValue, updateReceiptsTaxValue] = useState(0)
  const [itemQuantity, updateItemQuantity] = useState(0)
  const [receiptsTotalValue, updateReceiptsTotalValue] = useState(0)

  const {
    openInfo,
    updateOpenInfo
  } = useHomeBoardReceipts(homeId)

  const {
      receiptList,
      loading,
      onToggle,
      isOpen,
      receiptId,
      handleRemove,
      handleReceiptClick,
      onSelectHome,
      selectReceiptsToAssign,
      handleAssignReceipts,
      selectedReceipt,
      openDeleteDialog,
      updateOpenDeleteDialog,
      deleteReceipt,
      retailerFilters,
      onRetailerChange,
      dateFilters,
      onDateChange,
      searchValue,
      handleOnSearchChange,
      filteredList
  } = useReceipts(homes)

  const handleTypeChange = (type: 'list' | 'grid' | 'item') => {
    if (type === "item" && filteredList.length > 0) {
      updateTotalShowing(1)
    } else {
      updateTotalShowing(filteredList.length)
    }
    setType(type)
  }

  useEffect(() => {
    if (type === "item" && filteredList.length > 0) {
      updateTotalShowing(1)
    } else {
      updateTotalShowing(filteredList.length)
    }

    let receiptTaxVal = 0
    let quantityVal = 0
    let totalVal = 0
    receiptList.forEach((receipt:any) => {
      receiptTaxVal += Math.round(Number(receipt.tax))
      quantityVal += itemQuantity
      totalVal += Math.round(Number(receipt.total))
    })
    updateReceiptsTaxValue(receiptTaxVal)
    updateItemQuantity(quantityVal)
    updateReceiptsTotalValue(totalVal)

  }, [receiptList, filteredList])

  const menuItems = [
      {
          label: "Remove",
          handleClick: handleRemove
      }
  ]

  const ButtonsFooter = {
      button1: {
        buttonStyle: 'primaryFooter',
        label: 'Update',
        onClick: () => {handleAssignReceipts()},
      },
      button2: {
        buttonStyle: 'secondaryFooter',
        label: 'Cancel',
        onClick: () => {handleClose()},
      },
  }

  return (
    <DrawerContent bg="lightBlue.2">
      <DrawerHeader p="0">
        <PanelHeader
          handleCloseButton={handleClose}
          title={t('receipts.title')}
          icon={ReceiptsIcon}
        />
      </DrawerHeader>
      <DrawerBody p="0">
      {
        loading ? (
            <Center w={"100%"} h="4rem" bg="white">
                <BeatLoader color="gray" size={8} />
            </Center>
        ) : (
            onLaunchpad ? <>
            <AssignableReceipts 
            receipts={receiptList}
            rightOptions={[]}
            onRightSelectChange={() => {}}
            leftOptions={homes}
            onLeftSelectChange={onSelectHome}
            onItemSelectedChange={selectReceiptsToAssign}
            isSelectDisabled={false}
            />
            <FooterDrawer
            children={
              <FooterButtons
                button1={ButtonsFooter.button1}
              />
            }
            isOpen={true}
          /></> : 
          <>
            <ReceiptsHeader
              dateMenu={dateFilters}
              isCollapsed={openInfo}
              onCollapse={() => {updateOpenInfo(!openInfo)}}
              onRetailerChange={onRetailerChange}
              onDateChange={onDateChange}
              retailerMenu={retailerFilters}
            />
            <ReceiptsInfo isOpen={openInfo}>
              <Summary incidentalSpent={0} inventorySpent={0} total={receiptsTotalValue} />
              <SalesTax
                totalPaid={receiptsTaxValue}
                totalReceipts={receiptList.length}
                totalItems={itemQuantity}
                totalItemsAdded={itemQuantity}
              />
            </ReceiptsInfo>
            <ReceiptsDisplayOptions
              initialDisplay={type}
              onDisplayClick={handleTypeChange}
              onSearchChange={handleOnSearchChange}
              searchValue={searchValue}
            />
            <DisplayReceipts
              totalShowing={totalShowing}
              type={type}
              menuItems={menuItems}
              onClick={handleReceiptClick}
              receipts={filteredList}
            />
          </>
        )
      }
      {
        !onLaunchpad && (
            <HomeboardReceiptListSlider 
                receiptId={receiptId}
                open={isOpen}
                back={onToggle}
                receipt={selectedReceipt}
                handleDeleteReceipt={handleRemove}
            />
        )
      }
      </DrawerBody>
      <GenericDialog header={"Warning!"} body={"Deleting a receipt will remove all associated items from your home."} action={deleteReceipt} close={() => {updateOpenDeleteDialog(false)}} openModal={openDeleteDialog} actionText={"Delete"} cancelText={"Cancel"}  />
    </DrawerContent>
  )
}

export default ReceiptList