import ClientsListModule from "../../Modules/ClientsListModule";

import { 
  SharedHomeContent,
} from "@homefile/components";

const AccountListDrawer = ({ handleClose }: { handleClose: () => void }) => {

  
    
  return (
    <SharedHomeContent>
      <ClientsListModule homeDrawer={false} handleClose={handleClose}/> 
    </SharedHomeContent>
  )
}

export default AccountListDrawer