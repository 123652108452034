import { useEffect, useState } from "react";
import { ContactsContent, ShareContactsContent, RightPanel, useCustomToast } from "@homefile/components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { clearDrawer } from "../../../features/drawer/drawerSlice";
import { useGetProgressReportsQuery, useUpdateProgressReportMutation, useDeleteReportMutation, useGetItemSubTypesByItemTypeQuery, useGetUserContactsQuery, useShareContactMutation, useGetUserByEmailQuery, useDeleteContactMutation } from "../../../features/api";
import { RootState } from "../../../app/store";
import { InfoContactCardI, ShareHomeInfoContactCardI } from "@homefile/components/dist/interfaces";
import BeatLoader from "react-spinners/BeatLoader";
import { Center } from "@chakra-ui/react";
import GenericDialog from "../../Dialogs/GenericDialog";
import { ResponseType } from "../../../interfaces/DataInterfaces";
import { useDisclosure, Box } from "@chakra-ui/react";
import { useGetDefaultConfig } from "../../../hooks/useGetDefaultConfig";
import CreateItemDrawer from '../../Drawers/CreateItem'
import {useFileDetails} from '../../../hooks/useFileDetails'

const ContactListDrawer = () => {
    const [contacts, updateContacts] = useState<InfoContactCardI[]>([])
    const dispatch = useAppDispatch();
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const userType = useAppSelector((state: RootState) => state.auth.homeAssociationType)
    const homeAccounts = useAppSelector((state: RootState) => state.home.associatedAccounts)
    const [selectedContactId, updateSelectedContactId] = useState("")
    const [openModal, updateOpenModal] = useState(false)
    const [deleteSelectedContact] = useDeleteContactMutation()
    const [share] = useShareContactMutation() 
    const handleClose = () => {
        dispatch(clearDrawer());
    }
    const [modalHeader, updateModalHeader] = useState("Remove Contact from Home")
    const [modalBody, updateModalBody] = useState("Are you sure you want to remove this contact information?")
    const [actionText, updateActionText] = useState("Delete")
    const { isOpen, onToggle } = useDisclosure();
    const {defaultConfig} = useGetDefaultConfig()
    const [selectedContact, updateSelectedContact] = useState({} as any)
    const [skip, updateSkip] = useState(true)
    const {data: contactSubTypes} = useGetItemSubTypesByItemTypeQuery(defaultConfig.config.contactTypeId, {skip})
    const [subTypes, updateSubsTypes] = useState([] as any)
    const {data: contactData, isLoading} = useGetUserContactsQuery(null)
    const [openSharePanel, updateOpenSharePanel] = useState(false)
    const [shareContacts, updateShareContacts] = useState<InfoContactCardI[]>([])
    const [deleteContacts, updateDeleteContacts] = useState<string[]>([])
    const toast = useCustomToast()
    const {mapOldItem} = useFileDetails([])
    const [shareLoading, updateShareLoading] = useState(false)
    const [shareWithList, updateShareWithList] = useState<string[]>([])
    const [homefileContacts, updateHomefileContacts] = useState<ShareHomeInfoContactCardI[]>([])
    const [searchEmail, updateSearchEmail] = useState("")
    const [searchSkip, updateSearchSkip] = useState(true)
    const {data: emailData } = useGetUserByEmailQuery({email: searchEmail}, {skip: searchSkip})
    const [homefileShareList, updateHomefileShareList] = useState<any[]>([])

    useEffect(() => {
        if (defaultConfig.config.contactTypeId !== "") {
            updateSkip(false)
        }
    }, [defaultConfig])

    useEffect(() => {
        if (contactSubTypes) {
            updateSubsTypes(contactSubTypes)
        }
    }, [contactSubTypes])


    useEffect(() => {
        if (emailData) {
            if (emailData.email) {
                updateHomefileShareList([...homefileShareList, emailData])
                updateShareWithList([...shareWithList, emailData.email])
            } else {
                updateModalHeader("User not found.")
                updateModalBody("Please enter a valid homefile account email.")
                updateActionText("")
                updateOpenModal(true)
            }
            updateShareLoading(false)
        }
    }, [emailData])

    useEffect(() => {
        if (searchEmail !== "") {
            updateSearchSkip(false)
        }
    }, [searchEmail])

    useEffect(() => {
        if (homeAccounts.length > 0) {
            let temp: any[] = []
            homeAccounts.forEach((account:any) => {
                const user = {
                    email: account.user.email,
                    firstName: account.user.firstName,
                    _id: account._id,
                    lastName: account.user.lastName,
                }
                temp = [...temp, user]
            })
            updateHomefileContacts(temp)
        } else {
            updateHomefileContacts([])
        }
    }, [homeAccounts])

    useEffect(() => {
        if (contactData && subTypes.length > 0) {
            let contactList: InfoContactCardI[] = []
            contactData.forEach((contact: any) => {
                if (contact.report.length > 0) {
                    const reportObj: any = {}
                    contact.report.forEach((field: any) => {
                        if (field.type === "grid") {
                            field.children.forEach((child: any) => {
                                const keyValue = child.name.replace(/\s+/g, '').toLowerCase()
                                reportObj[keyValue] = child.value
                            })
                        } else {
                            const keyValue = field.name.replace(/\s+/g, '').toLowerCase()
                            reportObj[keyValue] = field.value
                        }
                    })
                    const sendCategory = subTypes.find((type: any) => type._id === contact.subType)
                    const newContact = {
                        address: reportObj.address,
                        category: sendCategory.name || "",
                        city: reportObj.city,
                        company: reportObj.company,
                        email: reportObj.email,
                        firstName: reportObj.firstname,
                        _id: contact._id,
                        lastName: reportObj.lastname,
                        phone: reportObj.phone,
                        state: reportObj.state,
                        title: contact.title,
                        zip: reportObj.zip
                    }
                    contactList = [...contactList, newContact]
                } else {
                    const newContact = {
                        address: "",
                        category: "",
                        city: "",
                        company: "",
                        email: "",
                        firstName: "",
                        _id: contact._id,
                        lastName: "",
                        phone: "",
                        state: "",
                        title: contact.title,
                        zip: ""
                    }
                    contactList = [...contactList, newContact]
                }
            })
            updateContacts(contactList)
        }
    }, [contactData, subTypes])

    const handleDelete = (contact: InfoContactCardI) => {
        // updateModalHeader("Remove Contact from Home")
        // updateModalBody("Are you sure you want to remove this contact information?")
        // updateActionText("Delete")
        // updateSelectedContactId(contact._id)
        // updateOpenModal(true)
    }

    const handleDetails = (contact: InfoContactCardI) => {
        const targetContact = contactData.find((tContact: any) => tContact._id === contact._id)
        if (targetContact.report.length === 10) {
            const mappedContact = mapOldItem(targetContact)
            updateSelectedContact(mappedContact)
        } else {
            updateSelectedContact(targetContact)
        }
        onToggle()
    }

    const handleCloseModal = () => {
        updateOpenModal(false)
    }

    const handleAdd = () => {
        updateSelectedContact({})
        onToggle()
    }

    // const deleteContact = async () => {

    //     const contactRes: ResponseType = await deleteSelectedContact(selectedContactId)


    //     if (contactRes.data) {
    //         updateOpenModal(false)
    //     } else {
    //         console.log('fail', contactRes.error)
    //         updateModalHeader("Something went wrong.")
    //         updateModalBody("Please try again later.")
    //     }
        
    // }

    const handleDeleteContacts = async () => {
        let successFlag = true
        for (const id of deleteContacts) {
            const contactRes: ResponseType = await deleteSelectedContact(id)
            if (contactRes.data) {
                toast({
                    title: 'Contact Removed',
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    position: "top-right"
                  })
            } else {
                successFlag = false
                console.log('fail', contactRes.error)
                updateModalHeader("Something went wrong.")
                updateModalBody("Please try again later.")
                updateActionText("")
            }
        }
        if (successFlag) {
            updateOpenModal(false)
        }
    }

    const onDeleteContacts = (contactsDelete: string[]) => {
        updateDeleteContacts(contactsDelete)
        updateModalHeader("Remove Contact(s) from Home")
        updateModalBody("Are you sure you want to remove this contact information?")
        updateActionText("Delete")
        updateOpenModal(true)
    }

    const onShareContacts = (contactsShare: InfoContactCardI[]) => {
        if (contactsShare.length > 0) {
            updateShareContacts(contactsShare)
            updateOpenSharePanel(true)
        }
    }

    const handleShare = async (form: any) => {
        if (form.shareBy !== undefined && form.contacts.length > 0) {
            let successFlag = true
            if (form.shareBy.email !== undefined && form.shareBy.email.length > 0) {
                for (const sContact of form.contacts) {
                    for (const email of form.shareBy.email) {
                        const sendData = {
                            reportId: sContact._id,
                            shareBy: "email",
                            phoneOrEmail: email,
                            message: `${sContact.title} information - name: ${sContact.firstName} ${sContact.lastName}, company: ${sContact.company}, email: ${sContact.email}, phone: ${sContact.phone}`
                        }

                        const sendContact: ResponseType = await share(sendData)

                        if (sendContact.error) {
                            successFlag = false
                        } else {
                            toast({
                                title: 'Contact Share Success!',
                                description: `Contact shared to ${email}`,
                                status: 'success',
                                duration: 2000,
                                isClosable: true,
                                position: "top-right"
                            })
                        }
                    }
                }
            } else if (form.shareBy.sms !== undefined && form.shareBy.sms.length > 0) {
                for (const sContact of form.contacts) {
                    for (const num of form.shareBy.sms) {
                        const sendData = {
                            reportId: sContact._id,
                            shareBy: "sms",
                            phoneOrEmail: num,
                            message: `${sContact.title} information - name: ${sContact.firstName} ${sContact.lastName}, company: ${sContact.company}, email: ${sContact.email}, phone: ${sContact.phone}`
                        }

                        const sendContact: ResponseType = await share(sendData)

                        if (sendContact.error) {
                            successFlag = false
                        } else {
                            toast({
                                title: 'Contact Share Success!',
                                description: `Contact shared to ${num}`,
                                status: 'success',
                                duration: 2000,
                                isClosable: true,
                                position: "top-right"
                            })
                        }
                    }
                }
            } else if (form.shareBy.homefile !== undefined && form.shareBy.homefile.length > 0) {
                for (const sContact of form.contacts) {
                    for (const account of form.shareBy.homefile) {
                        const tUser = homefileShareList.find((user: any) => user.email === account)
                        if (tUser) {
                            const sendData = {
                                reportId: sContact._id,
                                shareBy: "copy",
                                user: tUser._id
                            }
    
                            const sendContact: ResponseType = await share(sendData)
    
                            if (sendContact.error) {
                                successFlag = false
                            } else {
                                toast({
                                    title: 'Contact Share Success!',
                                    description: `Contact shared to ${account}`,
                                    status: 'success',
                                    duration: 2000,
                                    isClosable: true,
                                    position: "top-right"
                                })
                            }
                        }
                    }
                }
            }
            if (!successFlag) {
                updateModalHeader("Something went wrong.")
                updateModalBody("Please try again later.")
                updateActionText("")
                updateOpenModal(true)
            }
        }
    }

    const onContactSearch = (email: string) => {
        updateShareLoading(true)
        updateSearchEmail(email)
    }

    const onDropdownSelect = (contact: ShareHomeInfoContactCardI) => {
        if (contact._id && contact.email) {
            updateHomefileShareList([...homefileShareList, contact])
            updateShareWithList([...shareWithList, contact.email])
        }
    }

    const onRemoveContact = (email: string) => {
        const newList = shareWithList.filter((tEmail: string) => email !== tEmail)
        updateShareWithList(newList)
        const hShareList = homefileShareList.filter((tEmail: string) => email !== tEmail)
        updateHomefileShareList(hShareList)
    }


    const menuItems = [
        {
            label: 'Edit',
            handleClick: (contact: InfoContactCardI) => {handleDetails(contact)},
        },
        {
            label: 'Delete',
            handleClick: (contact: InfoContactCardI) => {handleDelete(contact)},
        },
    ]

    return (
        <>
            {
                isLoading ? (
                    <Center h="8rem" bg="container.primary">
                      <BeatLoader color="gray" size={8} />
                    </Center>
                  ) : (
                    <Box overflow="scroll" maxHeight="500px">
                        <ContactsContent onDeleteContacts={onDeleteContacts} onShareContacts={onShareContacts} disabled={false} handleAdd={handleAdd} menuItems={userType === "member" ? [] : menuItems} contacts={contacts} handleClose={handleClose} onClick={handleDetails}>
                            <>
                            {
                                isOpen && (
                                    <CreateItemDrawer 
                                        open={isOpen}
                                        back={onToggle}
                                        formName="Contact"
                                        formType={defaultConfig.config !== undefined ? defaultConfig.config.contactTypeId : ""}
                                        completedForm={selectedContact}
                                        isSlider={true}
                                    />
                                )
                            }
                            </>
                        </ContactsContent>
                        <RightPanel
                            isOpen={openSharePanel}
                            onClose={() => {updateOpenSharePanel(false)}}
                        >
                            <ShareContactsContent
                                contacts={shareContacts}
                                onClose={() => {updateOpenSharePanel(false)}}
                                onShare={handleShare}
                                isLoading={shareLoading}
                                shareWithList={shareWithList}
                                onContactSearch={onContactSearch}
                                onDropdownSelect={onDropdownSelect}
                                homefileContacts={homefileContacts}
                                onRemoveContact={onRemoveContact}
                            />
                        </RightPanel>
                        <GenericDialog header={modalHeader} body={modalBody} action={handleDeleteContacts} close={handleCloseModal} openModal={openModal} actionText={actionText} cancelText="Cancel"  />
                    </Box>
                )
            }
        </>
    );
}

export default ContactListDrawer