export const Textarea = {
  variants: {
    outline: {
      fontFamily: 'secondary',
      w: '100%',
      border: '1px solid',
      borderColor: 'lightBlue.6',
      borderRadius: 'sm',
      px: '2',
      color: 'gray.3',

      _placeholder: {
        color: 'gray.4',
      },

      _hover: {
        borderColor: 'blue.1',
      },

      _focus: {
        borderColor: 'blue.3',
      },

      _autofill: {
        boxShadow: '0 0 0px 1000px #F4F7F8 inset',
        transition: 'background-color 5000s ease-in-out 0s',
      },

      _disabled: {
        bg: 'transparent',
        _hover: {
          borderColor: 'lightBlue.6',
        },
      },

      _invalid: {
        borderColor: 'pink.500',
      },
    },
  },
  defaultProps: {
    variant: 'outline',
  },
}

export default Textarea
