import { RolePermissionObject } from '@homefile/components/dist/interfaces'

export const featuresSelectedMock: RolePermissionObject = {
    member: {
        view: [
        'property-data',
        'financial-data',
        'partners',
        'folders',
        'home-items',
        'rooms',
        'rooms-categories',
        ],
        add: [],
        edit: [],
        delete: [],
    },
    contributor: {
        view: [
        'property-data',
        'financial-data',
        'partners',
        'folders',
        'home-items',
        'rooms',
        'rooms-categories',
        ],
        add: ['home-items', 'partners','rooms', 'rooms-categories'],
        edit: ['folders', 'rooms', 'rooms-categories'],
        delete: ['rooms-categories'],
    },
    manager: {
        view: [
        'property-data',
        'financial-data',
        'partners',
        'folders',
        'home-items',
        'rooms',
        'rooms-categories',
        ],
        add: [
        'property-data',
        'financial-data',
        'partners',
        'folders',
        'home-items',
        'rooms',
        'rooms-categories',
        ],
        edit: [
        'property-data',
        'financial-data',
        'folders',
        'home-items',
        'rooms',
        'rooms-categories',
        ],
        delete: [
        'financial-data',
        'partners',
        'folders',
        'home-items',
        'rooms',
        'rooms-categories',
        ],
    }
}

const permissionsObj: any = {
    "GetHomeInfo": {
        action: 'view',
        value: 'property-data'
    },
    "GetPartnerTilesUser": {
        action: 'view',
        value: 'partners'
    },
    "GetReportProgressReportsId": {
        action: 'view',
        value: 'home-items'
    },
    "GetRoomId": {
        action: 'view',
        value: 'rooms'
    },
    "PostHomeRealState": {
        action: 'add',
        value: 'property-data'
    },
    "PostHomeTile": {
        action: 'add',
        value: 'partners'
    },
    "PostReportProgressReport": {
        action: 'add',
        value: 'home-items'
    },
    "PostRoom": {
        action: 'add',
        value: 'rooms'
    },
    "PostRoomSubType": {
        action: 'add',
        value: 'rooms-categories'
    }, 
    "PatchHomeRealState": {
        action: 'edit',
        value: 'property-data'
    },
    "PatchReportId": {
        action: 'edit',
        value: 'home-items'
    },
    "PatchRoomUpdateId": {
        action: 'edit',
        value: 'rooms'
    },
    "PatchRoomAddItemId": {
        action: 'edit',
        value: 'rooms-categories'
    },
    "PatchHomeTile": {
        action: 'delete',
        value: 'partners'
    },
    "DeleteReportId": {
        action: 'delete',
        value: 'home-items'
    },
    "DeleteRoomId": {
        action: 'delete',
        value: 'rooms'
    },
    "PatchRoomRemoveItem": {
        action: 'delete',
        value: 'rooms-categories'
    } 
}

export const mapPermissions = (homePermissions: any) => {
    const componentObj: any ={
        member: {
            view: [
              'rooms-categories',
            ],
            add: [],
            edit: [],
            delete: [],
          },
          contributor: {
            view: [
              'rooms-categories',
            ],
            add: [],
            edit: [],
            delete: [],
          },
          manager: {
            view: [
              'rooms-categories',
            ],
            add: [],
            edit: [],
            delete: [],
        },
    }

    if (homePermissions.member) {
        homePermissions.member.forEach((permission: string) => {
            if (permissionsObj[permission]) {
                const action = permissionsObj[permission].action
                const value = permissionsObj[permission].value
                componentObj.member[action] = [...componentObj.member[action], value]
            }
        })
    }

    if (homePermissions.contributor) {
        homePermissions.contributor.forEach((permission: string) => {
            if (permissionsObj[permission]) {
                const action = permissionsObj[permission].action
                const value = permissionsObj[permission].value
                componentObj.contributor[action] = [...componentObj.contributor[action], value]
            }
        })
    }

    if (homePermissions.manager) {
        homePermissions.manager.forEach((permission: string) => {
            if (permissionsObj[permission]) {
                const action = permissionsObj[permission].action
                const value = permissionsObj[permission].value
                componentObj.manager[action] = [...componentObj.manager[action], value]
            }
        })
    }
    return componentObj
}

const updateObj: any = {
    view: {
        "property-data": "GetHomeInfo",
        "partners": "GetPartnerTilesUser",
        "home-items": "GetReportProgressReportsId",
        "rooms": "GetRoomId"
    },
    add: {
        'property-data': "PostHomeRealState",
        'partners': "PostHomeTile",
        'home-items': "PostReportProgressReport",
        'rooms': "PostRoom",
        'rooms-categories': "PostRoomSubType"
    },
    edit: {
        'property-data': "PatchHomeRealState",
        'home-items': "PatchReportId",
        'rooms': "PatchRoomUpdateId",
        'rooms-categories': "PatchRoomAddItemId"
    },
    delete: {
        'partners': "PatchHomeTile",
        'home-items': "DeleteReportId",
        'rooms': "DeleteRoomId",
        'rooms-categories': "PatchRoomRemoveItem"
    }
}

export const reverseMap = (selected: any) => {

    const updatedPermissions: any = {
        "member": [
            "GetHomeId",
            "GetItemTypeId",
            "GetItemTypeIdSubTypesId",
            "GetItemTypeIdSubTypes",
            "GetReceiptAllId",
            "GetReceiptWithoutHome",
            "GetReceiptItemsId",
            "GetReportInfoId",
            "GetReportForms",
            "GetReportSetForDeletion",
            "GetReportId",
            "GetRoomHomeId",
            "GetUserAssociationHomeId",
            "GetUserAssociationFolderId",
            "GetUserAssociationReportsIdId",
            "PostPartnerTile"
        ],
        "contributor": [
            "GetHomeId",
            "GetItemTypeId",
            "GetItemTypeIdSubTypesId",
            "GetItemTypeIdSubTypes",
            "PostItemType",
            "PostItemTypeIdSubType",
            "GetReceiptAllId",
            "GetReceiptWithoutHome",
            "GetReceiptItemsId",
            "PatchReceiptUpdateHome",
            "PatchReceiptId",
            "DeleteReceipt",
            "GetReportInfoId",
            "GetReportForms",
            "GetReportSetForDeletion",
            "GetReportId",
            "PatchReportChanceFolder",
            "PatchReportRestoreId",
            "GetRoomHomeId",
            "DeleteRoomSubType",
            "GetUserAssociationHomeId",
            "GetUserAssociationFolderId",
            "GetUserAssociationReportsIdId",
            "GetPartnerAll",
            "PostPartnerTile"
        ],
        "manager": [
            "GetHomeId",
            "PatchHomeRestoreId",
            "PatchHomeId",
            "DeleteHomeExclude",
            "DeleteHomeId",
            "GetItemTypeId",
            "GetItemTypeIdSubTypesId",
            "GetItemTypeIdSubTypes",
            "PostItemType",
            "PostItemTypeIdSubType",
            "GetPartnerTiles",
            "GetPartnerAll",
            "GetPartner",
            "GetReceiptAllId",
            "GetReceiptWithoutHome",
            "GetReceiptItemsId",
            "PatchReceiptUpdateHome",
            "PatchReceiptId",
            "DeleteReceipt",
            "GetReportInfoId",
            "GetReportForms",
            "GetReportSetForDeletion",
            "GetReportId",
            "PatchReportChanceFolder",
            "PatchReportRestoreId",
            "GetRoomHomeId",
            "DeleteRoomSubType",
            "GetUserAssociationHomeId",
            "GetUserAssociationFolderId",
            "GetUserAssociationReportsIdId",
            "PostUserAssociationHome",
            "PostUserAssociationFolder",
            "PatchUserAssociationHome",
            "DeleteUserAssociationHome",
            "DeleteUserAssociationFolder",
            "PostPartnerTile"
        ]
    }

    if (selected.member) {
        let permissionsArray: string[] = [...updatedPermissions.member]
        selected.member.view.forEach((permission: string) => {
            if (updateObj.view[permission]) {
                permissionsArray = [...permissionsArray, updateObj.view[permission]]
            }
        })
        updatedPermissions.member = permissionsArray
    }

    if (selected.contributor) {
        let permissionsArray: string[] = [...updatedPermissions.contributor]

        selected.contributor.view.forEach((permission: string) => {
            if (updateObj.view[permission]) {
                permissionsArray = [...permissionsArray, updateObj.view[permission]]
            }
        })

        selected.contributor.add.forEach((permission: string) => {
            if (updateObj.add[permission]) {
                permissionsArray = [...permissionsArray, updateObj.add[permission]]
            }
        })

        selected.contributor.edit.forEach((permission: string) => {
            if (updateObj.edit[permission]) {
                permissionsArray = [...permissionsArray, updateObj.edit[permission]]
            }
        })

        selected.contributor.delete.forEach((permission: string) => {
            if (updateObj.delete[permission]) {
                permissionsArray = [...permissionsArray, updateObj.delete[permission]]
            }
        })

        updatedPermissions.contributor = permissionsArray
    }

    if (selected.manager) {
        let permissionsArray: string[] = [...updatedPermissions.manager]

        selected.manager.view.forEach((permission: string) => {
            if (updateObj.view[permission]) {
                permissionsArray = [...permissionsArray, updateObj.view[permission]]
            }
        })

        selected.manager.add.forEach((permission: string) => {
            if (updateObj.add[permission]) {
                permissionsArray = [...permissionsArray, updateObj.add[permission]]
            }
        })

        selected.manager.edit.forEach((permission: string) => {
            if (updateObj.edit[permission]) {
                permissionsArray = [...permissionsArray, updateObj.edit[permission]]
            }
        })

        selected.manager.delete.forEach((permission: string) => {
            if (updateObj.delete[permission]) {
                permissionsArray = [...permissionsArray, updateObj.delete[permission]]
            }
        })

        updatedPermissions.manager = permissionsArray
    }

    return updatedPermissions
}
