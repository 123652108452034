import { Slide, Box, Center } from "@chakra-ui/react";
import { RecordsInputs, FooterDrawer, FooterButtons, recordsInputsProxy, recordsInputsToDBProxy } from "@homefile/components";
import {EditCallbackI} from '@homefile/components/dist/interfaces'
import BeatLoader from "react-spinners/BeatLoader";


interface EditPropertyI extends EditCallbackI {
    back: () => void;
    open: boolean;
    handleSaveEdit: (data: any) => void
    isLoading: boolean
}

const EditPropertyDetails = (props: EditPropertyI) => {
    const { back, open, data, session, handleSaveEdit, isLoading } = props;

    const handleEdit = () => {
        const {data: fieldData} = recordsInputsToDBProxy
        handleSaveEdit(fieldData)
    }

    const ButtonsFooter = {
        button1: {
            buttonStyle: 'secondaryFooter',
            label: "Back",
            onClick: back,
        },
        button2: {
            buttonStyle: 'primaryFooter',
            label: "Save",
            onClick: handleEdit,
        }
    }

    return (
        <Slide direction="right" in={open} style={{ zIndex: 4 }}>
            {
                    isLoading ? (
                        <Center bg="lightBlue.1" h="100%">
                            <Center h="4rem">
                                <BeatLoader color="gray" size={8} />
                            </Center>
                        </Center>
                    ) : (
                        <Box>
                            <Box bg="lightBlue.1" h="100%">
                        
                                <Box overflow="scroll" height="calc(100vh - 100px)">
                                    <RecordsInputs 
                                        onBack={back}
                                        session={session}
                                        data={data}
                                    />  
                                </Box>        
                            </Box>
                            <FooterDrawer
                                    children={
                                        <FooterButtons
                                            button1={ButtonsFooter.button1}
                                            button2={ButtonsFooter.button2}
                                        />
                                    }
                                    isOpen={true}
                                />
                        </Box>
                    )
                }
        </Slide>
    )
}

export default EditPropertyDetails