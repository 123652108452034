import { homefileApi } from './emptySplitApi'

const fileUploadApi = homefileApi.injectEndpoints({
    endpoints: (builder) => ({
        getFileUpload: builder.query({
            query: (fileId: string) => ({
                url: `/api/file-upload/${fileId}`,
            }),
            providesTags: ["ProgressReport", "ProgressReports"],
        }),
        createFileUpload: builder.mutation({
            query: (data: any) => ({
                url: '/api/file-upload',
                method: "POST",
                body: data
            }),
        }),
        createHomeImageFileUpload: builder.mutation({
            query: (data: any) => ({
                url: '/api/file-upload/image/home',
                method: "POST",
                body: data
            }),
        }),
        updateFileUpload: builder.mutation({
            query: (data: any) => ({
                url: `/api/file-upload/info/${data.imageId}`,
                method: "PATCH",
                body: {description: data.description, name: data.name}
            }),
            invalidatesTags: ["ProgressReport", "ProgressReports", "Folders"],
        }),
        deleteFileUpload: builder.mutation({
            query: (id: string) => ({
                url: `/api/file-upload/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["ProgressReport", "ProgressReports", "Folders", "Room", "Rooms"],
        }),
    }),
    overrideExisting: false,
})
  
export const {
    useCreateFileUploadMutation,
    useUpdateFileUploadMutation,
    useCreateHomeImageFileUploadMutation,
    useDeleteFileUploadMutation,
    useGetFileUploadQuery
} = fileUploadApi