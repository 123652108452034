import { useState, useEffect } from "react";
import {NotificationsPanel} from '@homefile/components';
import {useNotifications} from "../../../hooks/useNotifications"

const NotificationsDrawer = ({ handleClose }: { handleClose: () => void }) => {
    const {simpleNotifications, updateType, notifications, markRead} = useNotifications()

    useEffect(() => {
        updateType("simple")
    }, [])

    return (
        <NotificationsPanel 
            notifications={notifications}
            onClose={handleClose}
            onDismiss={markRead}
        />
    )
}

export default NotificationsDrawer