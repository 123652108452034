import { useAddSubTypeToRoomMutation } from '../features/api';
import { ResponseType } from "../interfaces/DataInterfaces";
import { useAppDispatch} from "../app/hooks";
import { openDialog } from "../features/dialog/dialogSlice";

interface AddSubTypeI {
    room: string 
    subType: string
}

export const useRoomSubType = () => {
    const [addSubType] = useAddSubTypeToRoomMutation()
    const dispatch = useAppDispatch()

    const addRoomSubType = async ({room, subType}: AddSubTypeI) => {
        const addCategory: ResponseType = await addSubType({
            room: room,
            subType
        })

        if (addCategory.error) {
            if ('status' in addCategory.error) {
                if (addCategory.error.status === 403) {
                    dispatch(openDialog({message: "Error: access denied, no permissions to add category to rooms."}))
                }
            }
        }
    }

    return {
        addRoomSubType
    }
}