import { Flex, Box, Center, Button, Text } from "@chakra-ui/react";
import { GenericBackHeader, 
    SubscriptionCard,
    SubscriptionTable
} from "@homefile/components";
import { usePaymentInfo } from "../../hooks/usePaymentInfo";
import GenericDialog from "../../components/Dialogs/GenericDialog";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import AddSubscriptionModule from "../../components/Modules/AddSubscriptionModule";
import { useGetTempUserCardsQuery } from "../../features/api";
import { PaymentMethodI } from "@homefile/components/dist/interfaces"
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { closePaymentDialog } from "../../features/dialog/dialogSlice";

interface PaymentI {
    authenticated: boolean
}

const Payment = ({authenticated}: PaymentI) => {
    const navigate = useNavigate()
    const [addPaymentBody, updatePaymentBody] = useState("Please add a payment method to continue to your account")
    const [addPaymentHeader, updatePaymentHeader] = useState("Payment Information Required")
    const [addPaymentModal, updateAddPaymentModal] = useState(false)
    const { state } = useLocation();
    const [accountActive, updateAccount] = useState(false)
    const [skip, updateSkip] = useState(true)
    const [tempToken, updateTempToken] = useState(state.token || "")
    const {data} = useGetTempUserCardsQuery(tempToken, {skip})
    const [existingCard, updateExistingCard] = useState({} as PaymentMethodI)
    const [existingCardId, updateCardId] = useState("")
    const [date, updateDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() + 1)))
    const closePaymentModal = () => {
        updateAddPaymentModal(false)
    }
    const dispatch = useAppDispatch()
    const [showPaymentMessage, updateShowMessage] = useState(true)
    const {paymentModalOpen, paymentModalHeader, paymentModalBody} = useAppSelector((state: RootState) => state.dialog)


    const {
        handleCloseModal,
        userCard,
        isLoading,
        openModal,
        modalHeader,
        modalBody,
        cancelText,
        receipts,
        cardLoading,
        cardSuccess,
        handleUnAuthSubscription,
        handleCardSuccess
    } = usePaymentInfo({authenticated, authState: state})

    useEffect(() => {
        if (!authenticated && !skip && showPaymentMessage) {
            if (existingCard.isPaymentMethodValid) {
                updatePaymentBody("Please confirm payment information and submit to process payment and continue to your account.")
                updateAddPaymentModal(true)
                updateShowMessage(false)
            } else {
                updatePaymentBody("Please add a payment method and submit to process payment and continue to your account.")
                updateAddPaymentModal(true)
                updateShowMessage(false)
            }
        }

    }, [authenticated, existingCard])

    useEffect(() => {
        if (data) {
            if (data.length > 0) {
                updateExistingCard({
                    brand: data[0].brand,
                    number: data[0].lastCardNumbers,
                    isPaymentMethodValid: true,
                })
                updateCardId(data[0]._id)
            } else {
                updateExistingCard({} as PaymentMethodI)
            }
        }
    }, [data])

    useEffect(() => {
        if (!authenticated) {
            if (state !== null) {
                updateSkip(false)
            }
        }
    }, [authenticated])

    useEffect(() => {
        if (userCard.isPaymentMethodValid || existingCard.isPaymentMethodValid) {
            updateAccount(true)
        } else {
            updateAccount(false)
        }
    }, [userCard, existingCard])

    const handleExistingPayment = () => {
        handleUnAuthSubscription({cardId: existingCardId, token: tempToken})
    }

    const handleCloseGlobalPaymentModal = () => {
        dispatch(closePaymentDialog())
    }

    return (
        <>
            {
                (authenticated && state === null) && (
                    <Box p="1rem">
                        <GenericBackHeader handleClick={() => navigate("/dashboard")} label="Payment Details" backLabel="Return to Launchpad" />
                    </Box>
                )
            }
            <Flex maxW={"50rem"} flexDir="column" alignItems="center" justifyContent="center" margin={"1rem auto"}>
                {isLoading ? (
                    <Center w={"100%"} h="4rem" bg="white">
                        <BeatLoader color="gray" size={8} />
                    </Center>
                ) : (
                    <>
                    <Flex w="100%" mb="1rem" flexDir="column" alignItems="center">
                        <Text variant="titleBolder" fontSize="2rem" p="1rem">Payment</Text>
                        <SubscriptionCard 
                            storage={2}
                            totalCharged={36}
                        />

                        <SubscriptionTable
                            nextCharge={date}
                            subscriptionPrice={36}
                            subtotal={36}
                            taxPercentage={0}
                            totalCharged={36}
                            taxValue={0}
                        />
                    </Flex>
                        <AddSubscriptionModule cardSuccess={cardSuccess} handleCardSuccess={handleCardSuccess} authToken={tempToken !== "" ? tempToken : null} cardLoading={cardLoading} userCard={existingCard.isPaymentMethodValid ? existingCard : userCard} receipts={receipts} handleCardSubmit={() =>{}} activePayment={accountActive} />
                        <GenericDialog header={modalHeader} body={modalBody} action={() => {}} close={handleCloseModal} openModal={openModal} actionText={""} cancelText={cancelText}  />
                        {
                            existingCard.isPaymentMethodValid && (
                                <Flex p="2rem" flexDir="column" alignItems="center">
                                    <Text textAlign="center">
                                        We have an existing payment method on file. Click 'Submit' to continue or add a new payment method above.
                                    </Text>
                                    <Button onClick={handleExistingPayment} disabled={isLoading} m="1rem" variant="primaryFooter">
                                        Submit Payment
                                    </Button>
                                </Flex>
                            )
                        }
                    </>
                )}
            </Flex>
            {
                (authenticated === false) && (
                    <GenericDialog header={addPaymentHeader} body={addPaymentBody} action={() =>{}} close={closePaymentModal} openModal={addPaymentModal} actionText={""} cancelText={"Continue"}  />
                )
            }
            <GenericDialog header={paymentModalHeader} body={paymentModalBody} action={() => {}} close={handleCloseGlobalPaymentModal} openModal={paymentModalOpen} actionText={""} cancelText={"Continue"}  />
        </>
    )
}

export default Payment