export const Switch = {
  baseStyle: {
    track: {
      borderRadius: 'full',
      bg: 'lightBlue.5',
      _checked: {
        bg: 'green.1',
        borderColor: 'green.1',
      },
    },
    thumb: {
      bg: 'neutral.white',
      borderColor: 'lightBlue.5',
      boxShadow: 'md',
      _checked: {
        transform: 'translateX(100%)',
        borderColor: 'green.1',
      },
    },
  },
  sizes: {
    lg: {
      track: {
        w: '51px',
        h: '25px',
      },
      thumb: {
        w: '25px',
        h: '25px',
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
}