import {useState, useEffect} from 'react'
import {AddPropertyRecords, useCustomToast} from '@homefile/components'
import {usePropertyInfo} from '../../../hooks/usePropertyInfo';
import { useAppDispatch} from "../../../app/hooks";
import { clearDrawer } from "../../../features/drawer/drawerSlice";
import {ResponseType} from '../../../interfaces/DataInterfaces';
import { openDialog } from "../../../features/dialog/dialogSlice";


const AddPropertyRecordsDrawer = ({ handleClose, homeData }: { handleClose: () => void, homeData: any }) => {
    const {handleSearch, addHomeInfo, searchData} = usePropertyInfo("")
    const [wizardStep, updateWizardStep] = useState<'initial' | 'loading' | 'properties'>('initial')
    const [isLoading, updateLoading] = useState(false)
    const [properties, updateProperties] = useState([] as any)
    const [values, updateValues] = useState<any>({})
    const dispatch = useAppDispatch();
    const toast = useCustomToast()


    useEffect(() => {
        const passValues = {...homeData.address, name: homeData.user.firstName}
        updateValues(passValues)
    }, [homeData])

    useEffect(() => {
        if (searchData) {
          updateProperties(searchData.data)
          updateWizardStep('properties')
        }
    }, [searchData])

    const handlePropertySearch = async (data: any) => {
        if (data.isOwner && data.searchRecords) {
            updateWizardStep('loading')        
    
            const sendData = {
                house: values.number ? data.number : "",
                street: values.street,
                city: values.city,
                state: values.state,
                zip: values.zip
            }
        
            handleSearch(sendData)
        } else {
            handleClose()
        }
    
    }

    const handleSuccess = () => {
        toast({
          title: 'Success!',
          description: `Property Records Added`,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: "top-right"
        })
        updateLoading(false)
        dispatch(clearDrawer())
      }

    const handleAddress = async (id: string) => {
        if (id === "none" || properties.length < 1) {
            toast({
                title: 'Warning',
                description: `We are unable to locate property records at this time.`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top-right"
              })
              updateLoading(false)
              dispatch(clearDrawer())
        } else {
          const sendData = {
            realStateId: id,
            home: homeData._id
          }
          const homeInfo: ResponseType = await addHomeInfo(sendData)
          if (homeInfo.data) {
            handleSuccess()
          } else {
            dispatch(clearDrawer())
            dispatch(openDialog({message: "Something went wrong please try again."}))
          }
        }
      }

    return (
        <AddPropertyRecords 
            onConfirmAddress={handlePropertySearch}
            isLoading={isLoading}
            properties={properties}
            step={wizardStep}
            values={values}
            onSelectAddress={handleAddress}
            onClose={handleClose}
        />
    )
}

export default AddPropertyRecordsDrawer;