import {IFormItem} from "../interfaces/DocumentMenu.interface";
import { useGetFormsQuery } from "../features/api";
import {useEffect, useState} from 'react';

export const useGetForm = () => {
    const [formType, updateType] = useState("")
    const [skipValue, updateSkip] = useState(true)
    const { data } = useGetFormsQuery({type: formType, subType: ""}, {skip: skipValue});
    const [form, updateForm] = useState<IFormItem>({} as IFormItem);

    useEffect(() => {
        if (formType !== "") {
            updateSkip(false)
        } else {
            updateSkip(true)
        }
    }, [formType])

    useEffect(() => {
        if (data) {
            updateForm(data[0])
        }
    }, [data])

    const getForm = (type: string) => {
        updateType(type)
    }

    const resetForm = () => {
        updateForm({} as IFormItem)
        setTimeout(() => {
            if (data) {
                updateForm(data[0])
            }
        }, 500)
    }

    return {
        getForm,
        form,
        updateForm,
        resetForm
    }
}