import { useState, useEffect } from "react";
import { useGetHomeQuery, useUpdateHomeMutation } from "../../../features/api";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { HomeInterface } from "../../../interfaces/HomeInterfaces";
import { HomeCard, homeCardProxy } from "@homefile/components";
import { useFileUpload } from "../../../hooks/useFileUpload";
import Loading from "../../Loading";
import ClientsListModule from "../../Modules/ClientsListModule";
import {FooterButtons, FooterDrawer, useCustomToast} from '@homefile/components'
import { ResponseType } from '../../../interfaces/DataInterfaces';

const HomeInfoModule = () => {
  const homeId = useAppSelector((state: RootState) => state.home.id);
  const { data, isLoading } = useGetHomeQuery(homeId);
  const [featuredHome, updateHome] = useState<HomeInterface>(
    {} as HomeInterface
  );
  const [userEmail, updateEmail] = useState("");
  const [userPhone, updatePhone] = useState("");
  const {handleNewHomeImage, fileUploading} = useFileUpload()
  const [showFooter, updateShowFooter] = useState(false)
  const [editHome] = useUpdateHomeMutation();
  const toast = useCustomToast()

  useEffect(() => {
    if (data) {
      updateHome(data);
      if (
        data.associations &&
        data.associations.length > 0 &&
        data.associations[0].user !== undefined
      ) {
        if (data.associations[0].user.email) {
          updateEmail(data.associations[0].user.email);
        }
        if (data.associations[0].user.phone) {
          updatePhone(data.associations[0].user.phone);
        }
      }
    }
  }, [data]);


  const addImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleNewHomeImage(e, homeId)

  };

  const handleSave = async () => {
    const { fields: homeFields } = homeCardProxy
    const updatedHome: ResponseType = await editHome({
      homeId,
      home: {
        name: homeFields.name,
        address: {
          apartmentNumber: homeFields.apartmentNumber,
          city: homeFields.city,
          obs: homeFields.obs,
          state: homeFields.state,
          street: homeFields.street,
          zip: homeFields.zip,
          number: homeFields.number
        }
      }
    })
    
    if (updatedHome.data) {
      toast({
        title: 'Success',
        description: `Home Details Updated`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: "top-right"
      })
    } else {
      toast({
        title: 'Error!',
        description: `Something went wrong.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: "top-right"
      })
    }

  }

  const ButtonsFooter = {
    button1: {
        buttonStyle: 'primaryFooter',
        label: "Save",
        onClick: () => {handleSave()},
    },
  }

  const testFooter = (flag: boolean) => {
    updateShowFooter(flag)
  }


  return (
    <div>
      <Loading 
        isLoading={isLoading}
        children={
          <>
            <HomeCard
              maxW="100%"
              edit={true}
              phone={userPhone || ''}
              mail={userEmail || ''}
              apartmentNumber={featuredHome?.address?.apartmentNumber || ''}
              street={featuredHome?.address?.street || 'test'}
              number={featuredHome?.address?.number || ''}
              city={featuredHome?.address?.city || ''}
              obs={featuredHome?.address?.obs || ''}
              imageUrl={featuredHome?.image || null}
              state={featuredHome?.address?.state || ''}
              zip={featuredHome?.address?.zip || ''}
              name={featuredHome?.name || ''}
              addImage={addImage}
              loading={fileUploading}
              isEditMode={true}
              setIsDirty={testFooter}
            />
            <ClientsListModule homeDrawer={true} />
          </>
        }
      />
      <FooterDrawer
        children={
            <FooterButtons
            button1={ButtonsFooter.button1}
            />
        }
        isOpen={showFooter}
        />
    </div>
  );
};

export default HomeInfoModule;
