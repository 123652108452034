import {useState, useEffect} from 'react';
import {Box} from '@chakra-ui/react'
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {HomeAssistant} from '@homefile/components';
import { setDrawer } from "../../../features/drawer/drawerSlice";
import {setPanel, setStep} from "../../../features/assistant/assistantSlice"
import {useHomeAssistant} from "../../../hooks/useHomeAssistant"
import {HomeMonitorModule} from "./HomeMonitorModule"
import { useGetDefaultConfig } from "../../../hooks/useGetDefaultConfig";

export const HomeAssistantModule = ({address, activeTour}: {address: any, activeTour: boolean}) => {
    const dispatch = useAppDispatch()
    const currentPanel = useAppSelector((state: RootState) => state.assistant.currentPanel)
    const homeBoardTourState = useAppSelector((state: RootState) => state.tour.homeBoardTour);
    const {completeNum} = useHomeAssistant()
    const [completedStep, updateCompletedStep] = useState(1)
    const [showTutorial, updateShowTutorial] = useState(true)
    const {defaultConfig} = useGetDefaultConfig()
    const [tutorialUrl, updateTutorialUrl] = useState("")

    const handleStepClick = (step: number) => {
        dispatch(setPanel({currentPanel: step}))
        dispatch(setStep({currentStep: step}))
        dispatch(setDrawer({ location: "right", content:'HomeAssistant', size: "md"}))
    }

    const onStart = () => {
        updateShowTutorial(false)
        dispatch(setPanel({currentPanel: 1}))
        dispatch(setStep({currentStep: 1}))
        dispatch(setDrawer({ location: "right", content:'HomeAssistant', size: "md"}))
    }

    useEffect(() => {
        if (defaultConfig.config && defaultConfig.config.homeAssistantTutorialUrl) {
            updateTutorialUrl(defaultConfig.config.homeAssistantTutorialUrl)
        }
    }, [defaultConfig])

    useEffect(() => {
        if (completeNum === 0) {
            updateShowTutorial(true)
            updateCompletedStep(1)
        } else if (completeNum < 6) {
            updateCompletedStep(completeNum + 1)
            updateShowTutorial(false)
        } else {
            updateCompletedStep(7)
            updateShowTutorial(false)
        }
    }, [completeNum])

    return (
        <>
        {
            completeNum < 6 ? (
                <HomeAssistant 
                    showTutorial={showTutorial}
                    currentStep={completedStep}
                    onStepClick={handleStepClick}
                    currentPanel={currentPanel}
                    tutorialUrl={tutorialUrl}
                    onStart={onStart}
                    zIndex={(activeTour || homeBoardTourState) ? "2" : "auto"}
                />
            ) : (
                <HomeMonitorModule activeTour={activeTour} handleStep={handleStepClick} address={address} />
            )
        }
        </>
    )
}

