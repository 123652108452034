import {ICreateItem, CreateFunctionI } from '../interfaces/CreateDocumentInterfaces';
import { ResponseType } from '../interfaces/DataInterfaces';
import {useState, useEffect} from 'react';
import {
    useCreateProgressReportMutation,
    useGetItemSubTypesByItemTypeQuery,
    useCreateContactMutation
  } from "../features/api";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { openDialog } from "../features/dialog/dialogSlice";
import { useUpdateItem } from "./useUpdateItem";
import { useRoomSubType } from './useRoomSubType';
import { SelectItemI, ReportsI } from "@homefile/components/dist/interfaces";
import { useGetDefaultConfig } from "../hooks/useGetDefaultConfig";
import {useHasPermission} from './useHasPermission'

export const useCreateItem = (props: ICreateItem) => {
    const {success, formType, completedForm} = props
    const accounts = useAppSelector((state: RootState) => state.home.associatedAccounts)
    const dispatch = useAppDispatch();
    const [itemTitle, updateTitle] = useState("");
    const [createReport] = useCreateProgressReportMutation();
    const [createContact] = useCreateContactMutation()
    const [newItemId, updateId] = useState("");
    const {addRoomSubType} = useRoomSubType()
    const [initSubType, updateInitSubType] = useState({} as SelectItemI)
    const [skip, updateSkip] = useState(true)
    const {data} = useGetItemSubTypesByItemTypeQuery(formType || "", {skip})
    const [subTypes, updateSubsTypes] = useState([] as any)
    const [subTypeLabel, updateLabel] = useState("")
    const [selectedSubType, updateSelectedSubType] = useState("")
    const [itemName, updateName] = useState("")
    const {handleUpdate, handleContactUpdate, handleBatchUpdate} = useUpdateItem(success);
    const [typeId, updateTypeId] = useState("")
    const [folderId, updateFolderId] = useState("")
    const {defaultConfig} = useGetDefaultConfig()
    const {checkPermission} = useHasPermission()

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateName(event.target.value)
    }

    const handleSubTypeChange = (subType: SelectItemI | string) => {
      if (typeof subType === "object") {
        updateSelectedSubType(subType._id)
      }
    }

    useEffect(() => {
        if (formType) {
            updateSkip(false)
        }
    }, [formType])

    useEffect(() => {
        if (data) {
          updateSubsTypes(data)
        }
      }, [data])
  
      useEffect(() => {
        if (subTypes.length > 1) {
          updateLabel("Category")
          if (completedForm && completedForm.title) {
            const targetSubType: SelectItemI = subTypes.filter((type: SelectItemI) => type._id === completedForm.subType)[0]
            updateInitSubType(targetSubType)
            updateSelectedSubType(targetSubType._id)
          } else {
            updateInitSubType(subTypes[0])
            updateSelectedSubType(subTypes[0]._id)
          }
        }
      }, [subTypes, completedForm])

      useEffect(() => {
        // use here to update init value for form title
        if (completedForm !== null) {
          if (completedForm.title !== undefined) {
            updateName(completedForm.title)
          } else {
            updateName("")
          }
        } 
    }, [completedForm])

    useEffect(() => {
        if (defaultConfig.config && formType) {
          updateTypeId(formType)
          if (formType === defaultConfig.config.noteTypeId) {
            updateFolderId(defaultConfig.config.notesFolderId)
          } else {
            updateFolderId(defaultConfig.config.contactsFolderId)
          }
        }
      }, [defaultConfig, formType])

    const handleBatchCreateAndUpdate = async ({items, success}: {items: CreateFunctionI[], success: () => void}) => {
      let fail = false
      for (const item of items) {
        const sendData = {
            home: item.homeId,
            reportForm: item.formId,
            title: item.title,
            type: item.type,
            subType: item.subType,
            folder: item.folder,
            room: item.room
        }

        const newReport: ResponseType = await createReport(sendData);
        if (newReport.data) {

            if (checkPermission("PostRoomSubType", accounts)) {
              if (item.room !== undefined && item.subType !== undefined) {
                addRoomSubType({
                    room: item.room,
                    subType: item.subType
                })
              }
            }
            if (!checkPermission("PatchReportId", accounts)) {
              success()
            } else {
              const updateWorked: any = await handleBatchUpdate({
                newReportId: newReport.data._id,
                homeId: item.homeId,
                reportTitle: item.title,
                report: item.report
              })
              if (updateWorked === false) {
                fail = true
                break;
              }
            }
        } else {
            console.log("error", newReport);
            dispatch(openDialog({message: newReport.error.data.message}))
            fail = true
            break;
        }
      }
      if (!fail) {
        success()
      }
    }

    const handleCreateAndUpdate = async ({
        title,
        type,
        subType,
        folder,
        room,
        report,
        homeId,
        formId
    }: CreateFunctionI) => {

        const sendData = {
            home: homeId,
            reportForm: formId,
            title,
            type,
            subType,
            folder,
            room
        }

        const newReport: ResponseType = await createReport(sendData);
        if (newReport.data) {
            updateId(newReport.data._id);
            updateTitle(title);
            if (checkPermission("PostRoomSubType", accounts)) {
              if (room !== undefined && subType !== undefined) {
                addRoomSubType({
                    room,
                    subType: subType
                })
              }
            }
            if (!checkPermission("PatchReportId", accounts)) {
              success(newReport.data)
            } else {
              handleUpdate({
                newReportId: newReport.data._id,
                homeId,
                reportTitle: title,
                report
              })
            }
        } else {
            console.log("error", newReport);
            dispatch(openDialog({message: newReport.error.data.message}))
        }
    }

    const handleCreateContact = async ({
      title,
      type,
      subType,
      folder,
      report,
      homeId,
      formId
  }: CreateFunctionI) => {

      const sendData = {
          home: homeId,
          reportForm: formId,
          title,
          type,
          subType,
          folder
      }

      const newReport: ResponseType = await createContact(sendData);
      if (newReport.data) {
          updateId(newReport.data._id);
          updateTitle(title);
          handleContactUpdate({
            newReportId: newReport.data._id,
            homeId,
            reportTitle: title,
            report
          })
      } else {
          console.log("error", newReport);
          dispatch(openDialog({message: newReport.error.data.message}))
      }
  }

    return {
        newItemId,
        itemTitle,
        handleCreateAndUpdate,
        handleCreateContact,
        itemName,
        subTypes,
        initSubType,
        subTypeLabel,
        selectedSubType,
        handleNameChange,
        handleSubTypeChange,
        typeId,
        folderId,
        defaultConfig,
        updateId,
        handleBatchCreateAndUpdate
    }
}