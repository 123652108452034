import { homefileApi } from './emptySplitApi'

const itemsApi = homefileApi.injectEndpoints({
    endpoints: (builder) => ({
        getItemTypesByHome: builder.query({
            query: (homeId: string) => `/api/item-type/${homeId}`,
            providesTags: ["Rooms", "Items"]
        }),
        getItemSubTypesByItemType: builder.query({
            query: (itemTypeId: string) => `/api/item-type/${itemTypeId}/sub-types`,
            providesTags: ["Rooms", "Items"]
        }),
        getItemSubTypesByItemTypeAndHome: builder.query({
            query: ({itemTypeId, homeId}: {itemTypeId: string, homeId: string}) => `/api/item-type/${itemTypeId}/sub-types/${homeId}`,
            providesTags: ["Rooms", "Items"]
        }),
        createItemType: builder.mutation({
            query: (data: any) => ({
                url: `/api/item-type`,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["Rooms", "Items"],
        }),
        createItemSubType: builder.mutation({
            query: (data: any) => ({
                url: `/api/item-type/${data.itemTypeId}/sub-type`,
                method: "POST",
                body: data.body
            }),
            invalidatesTags: ["Rooms", "Items"],
        }),
    })
})

export const {
    useGetItemTypesByHomeQuery,
    useGetItemSubTypesByItemTypeQuery,
    useCreateItemTypeMutation,
    useCreateItemSubTypeMutation,
    useGetItemSubTypesByItemTypeAndHomeQuery,
} = itemsApi
