import { ReactNode, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import {
  AppBar,
  BarDivider,
  HelpContent,
  PeopleConnected,
  RightPanel,
  TourButton,
  TutorialsButton,
  ContactList,
  StorageUsed,
  Trash,
  useCustomToast,
  NotificationsReminder
} from '@homefile/components';
import { RootState } from "../../app/store";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { clearAuth, setAvatarBg } from "../../features/auth/authSlice";
import { t } from 'i18next';
import GlobalDrawer from "../../components/Drawers/GlobalDrawer";
import PermissionDialog from '../Dialogs/PermissionDialog';
import { useNavigate } from "react-router-dom";
import { homefileApi, useUserSendFeedbackMutation, useGetUserContactsQuery } from '../../features/api';
import { Tutorials } from '../../util/tutorials';
import { useLocation } from "react-router-dom";
import { setLaunchpad, setHomeBoard, setRoom } from '../../features/tour/tourSlice';
import { setDrawer } from "../../features/drawer/drawerSlice";
import RoomNav from './RoomNav';
import { FeedbackFormProps } from '@homefile/components/dist/interfaces';
import { ResponseType } from '../../interfaces/DataInterfaces';
import { openDialog, closePaymentDialog } from "../../features/dialog/dialogSlice";
import { useAssociatedAccount } from '../../hooks/useAssociatedAccount';
import {useNotifications} from "../../hooks/useNotifications"
import { useGetReportsSetToDelete } from '../../hooks/useGetReportsSetToDelete';
import { useSubscriptionData } from "../../hooks/useSubscriptionData";
import {randomColor} from '../../util/randomColor'
import GenericDialog from "../../components/Dialogs/GenericDialog";


export default function Layout({children}: { children: ReactNode; }) {
    const user = useAppSelector((state: RootState) => state.auth.user)
    const bg = useAppSelector((state: RootState) => state.auth.avatarBg)
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const homeAccounts = useAppSelector((state: RootState) => state.home.associatedAccounts)
    // const contactsLength = useAppSelector((state: RootState) => state.home.contactsLength)
    const homeStorage = useAppSelector((state: RootState) => state.home.homeStorage)
    const {paymentModalOpen, paymentModalHeader, paymentModalBody} = useAppSelector((state: RootState) => state.dialog)
    const dispatch = useAppDispatch()
    let navigate = useNavigate();
    const {pathname} = useLocation()
    const [sendFeedback] = useUserSendFeedbackMutation()
    const [loading, updateLoading] = useState(false)
    const [contactsLength, updateContactsLength] = useState(0)
    const toast = useCustomToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const categories = t('feedback.categories').split(',')
    const {handleShowAccounts} = useAssociatedAccount(homeAccounts)
    const {itemsSetForDeletion} = useGetReportsSetToDelete(homeId.length > 0 ? false:true)
    const {data: contactData} = useGetUserContactsQuery(null)
    const handleSignOut = () => {
      // dispatch clear auth and redirect here
      dispatch(homefileApi.util.resetApiState())
      dispatch(clearAuth())
    }
    const {simpleNotifications} = useNotifications()

    useEffect(() => {
      if (contactData !== undefined && contactData.length > 0) {
          updateContactsLength(contactData.length)
      } else {
        updateContactsLength(0)
      }
  }, [contactData])


    useEffect(() => {
      if (bg === "") {
        const newColor: string = randomColor()
        dispatch(setAvatarBg(newColor))
      }
    }, [])


    const {
      totalStorage,
      usedStorage,
      state,
      singleHomeStorage
  } = useSubscriptionData(homeStorage)

    const userMenuItems = [
      {
        handleClick: () => dispatch(setDrawer({ location: "right", content:'Profile', size: "md" })),
        label: t('launchpad.menu.profile'),
      },
      {
        handleClick: handleSignOut,
        label: t('launchpad.menu.logout'),
      },
    ]

    const handleTour = () => {
      if (pathname === "/dashboard") {
        dispatch(setLaunchpad({launchpadTour: true}))
      } else if (pathname === "/homes/base") {
        dispatch(setHomeBoard({homeBoardTour: true}))
      } else if (pathname.indexOf("/rooms/") === 0) {
        dispatch(setRoom({roomTour: true}))
      }
    }

    const handleTutorialsClick = () => {
      onOpen()
    }

    const handleContactsClick = () => {
      dispatch(setDrawer({ location: "right", content:'ContactList', size: "md" }))
    }

    const handleShowTour = () => {
      onClose()
      handleTour()
    }

  const handleViewDelete = () => {
    dispatch(setDrawer({ location: "right", content:'DeleteHomesItems', size: "md", data: {deleteHomes: false} }))
  }

  const openProfile = () => {
    dispatch(setDrawer({ location: "right", content:'Profile', size: "md", data: {defaultTab: 1} }))
  }
  const handleCloseModal = () => {
    dispatch(closePaymentDialog())
  }

  const handleNotificationsClick = () => {
    dispatch(setDrawer({ location: "right", content:'Notifications', size: "md"}))
  }

    const handleFeedback = async (form: FeedbackFormProps) => {
      
      updateLoading(true)
      
      const feedbackSend: ResponseType = await sendFeedback({
        message: form.message,
        category: form.type,
        email: user.email
      })

      if (feedbackSend.data) {
        toast({
          title: 'Feedback Submitted',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: "top-right"
        })
      } else {
        console.log('fail', feedbackSend)
        dispatch(openDialog({message: feedbackSend.error.data.message}))
      }

      updateLoading(false)
    }
    
    return (
      <Box minH="100vh" bg="#DCE8EC" pt="0" pb={{base: "4rem", md: "0"}}>
        <Flex>
          {
            (homeId.length > 0 && pathname !== "/dashboard") && <RoomNav id={homeId} />
          }
          <Stack spacing="base" width={'100%'}>
            <AppBar
              onLogoClick={() => navigate("/dashboard")}
              user={{
                avatarUrl: '',
                firstName: user !== undefined ? user.firstName: "",
                userName: user !== undefined ? `${user.firstName} ${user.lastName}`: "",
                menuItems: userMenuItems,
                bg: bg
              }}
            >
              <Box display={{base: "none", md: "block"}}>
                <TourButton onClick={handleTour} label="Show tour" />
              </Box>
              <BarDivider />
              <NotificationsReminder 
                total={simpleNotifications.length}
                onClick={handleNotificationsClick}
              />
              <BarDivider />
              {
                (homeId.length > 0 && pathname !== "/dashboard") ? 
                <>
                  <PeopleConnected
                    onClick={handleShowAccounts}
                    total={(homeAccounts !== undefined && homeAccounts.length > 0) ? (homeAccounts.length - 1) : 0}
                  />
                  <BarDivider />
                    <ContactList
                      onClick={handleContactsClick}
                      total={contactsLength}
                    />
                  <BarDivider />
                </> : 
                <></>
              }
              
              <TutorialsButton onClick={handleTutorialsClick} />

              {
                (homeId.length > 0 && pathname !== "/dashboard") ? (
                  <>
                    <BarDivider />
                    <StorageUsed 
                      onClick={openProfile}
                      totalUsed={singleHomeStorage}
                      totalStorage={totalStorage}
                      state={state}
                    />
                    {
                      itemsSetForDeletion.length > 0 && (
                        <Trash 
                          onClick={handleViewDelete}
                          total={itemsSetForDeletion.length}
                        />
                      )
                    }
                  </>
                ) : <></>
              }
              <RightPanel
                children={
                  <HelpContent
                    categories={categories}
                    onClose={onClose}
                    onFeedback={handleFeedback}
                    onShowTour={handleShowTour}
                    isLoading={loading}
                    tutorials={Tutorials || []}
                  />
                }
                isOpen={isOpen}
                onClose={onClose}
              />
            </AppBar>
              <Box bg="#DCE8EC" ml={{ base: 0 }} p="0" pt="0" mt="0 !important">
                {children}
                <GlobalDrawer />
                <PermissionDialog />
                <GenericDialog header={paymentModalHeader} body={paymentModalBody} action={() => {}} close={handleCloseModal} openModal={paymentModalOpen} actionText={""} cancelText={"Continue"}  />
            </Box>
          </Stack>
        </Flex>
      </Box>
    );
}


