import {useGetRoomsByHomeQuery} from "../features/api";
import {useEffect, useState} from 'react';
import {RoomItemI} from '@homefile/components/dist/interfaces/rooms/RoomsMenu.interface'


export const useGetHomeRooms = (id: string) => {
    const [skip, updateSkip] = useState(true)
    const {data } = useGetRoomsByHomeQuery(id, {skip})
    const [rooms, updateRooms] = useState([] as RoomItemI[])


    useEffect(() => {
        if (id.length > 0) {
            updateSkip(false)
        } else {
            updateSkip(true)
        }
    }, [id])

    useEffect(() => {
        if (data) {
            updateRooms(data as RoomItemI[]) 
        }  
    }, [data])

    return {
        rooms
    }
}