import { Flex, Button, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDeleteUserSubscriptionMutation } from "../../features/api";
import GenericDialog from "../../components/Dialogs/GenericDialog";
import {useState} from 'react'
import { ResponseType } from "../../interfaces/DataInterfaces";

const DeleteAccount = () => {
    const navigate = useNavigate()
    const [deleteUser] = useDeleteUserSubscriptionMutation()
    const [deleteModal, updateDeleteModal] = useState(false)

    const handleDelete = async () => {
        console.log('delete request')
        // const removedUser: ResponseType = await deleteUser(null)

        // if (removedUser.data) {

        // } else {
        //     console.log('error in removal', removedUser)
        // }
    }

    const handleDeleteModal = () => {
        updateDeleteModal(true)
    }

    const closeDeleteModal = () => {
        updateDeleteModal(false)
    }

    const handleCancel = () => {
        navigate("/dashboard")
    }

    return (
        <Flex flexDir={"column"} w="100%" alignItems={"center"} mt={'2rem'}>
            <Text>Are you sure you want to delete your account?</Text>
            <Flex mt={'1rem'} align="center" gap="2">
                <Button 
                    variant={"primaryFooter"}
                    onClick={handleCancel}
                    w={'fit-content'}
                >
                    Cancel
                </Button>
                <Button 
                    variant={"secondaryFooter"}
                    onClick={handleDeleteModal}
                    w={'fit-content'}
                >
                    Delete Account
                </Button>
            </Flex>
            <GenericDialog header={"Delete Account Request"} body={"This action is irreversible."} action={handleDelete} close={closeDeleteModal} openModal={deleteModal} actionText={"Continue"} cancelText={"Cancel"}  />
        </Flex>
    )
}

export default DeleteAccount