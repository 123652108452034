import {i18n} from '../../i18next';

export const UserMenuItems = [
  {
    handleClick: () => console.log("Clicked on 'My Profile'"),
    label: i18n.t('launchpad.menu.profile'),
  },
  {
    handleClick: () => {},
    label: i18n.t('launchpad.menu.logout'),
  },
]

export const MyHomeMenuItems = [
  {
    handleClick: () => {},
    label: i18n.t('myHomes.menu.settings'),
  },
  {
    handleClick: () => {},
    label: i18n.t('myHomes.menu.help'),
  },
]
