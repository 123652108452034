import {ICreateDocument, CreateFunctionI } from '../interfaces/CreateDocumentInterfaces';
import { ResponseType } from '../interfaces/DataInterfaces';
import {useState, useEffect} from 'react';
import {
    useCreateProgressReportMutation
  } from "../features/api";
import { useAppDispatch } from "../app/hooks";
import { openDialog } from "../features/dialog/dialogSlice";
import { useGetDefaultConfig } from "./useGetDefaultConfig";

export const useCreateReport = (props: ICreateDocument) => {
    const {formId, homeId, homeName, needsTitle, success} = props
    const dispatch = useAppDispatch();
    const [reportTitle, updateTitle] = useState("");
    const [createReport] = useCreateProgressReportMutation();
    const [newReportId, updateId] = useState("");
    const {defaultConfig} = useGetDefaultConfig()

    useEffect(() => {
        if (homeId !== "" && homeId !== undefined && (needsTitle === undefined || needsTitle) && defaultConfig.config !== undefined) {
            handleReportCreation()
        }
    }, [homeId, defaultConfig])


    const handleReportCreation = async () => {
        updateTitle("");
        const dateString = new Date().toLocaleDateString();
        const titleString = `${homeName} ${dateString}`;
        const sendData = {
            home: homeId,
            reportForm: formId,
            title: titleString,
            folder: process.env.REACT_APP_DOCUMENTS_FOLDER_ID,
            type: defaultConfig.config.constructionTypeId,
            subType: defaultConfig.config.progressReportSubTypeId
        };
        const newReport: ResponseType = await createReport(sendData);

        if (newReport.data) {
            updateId(newReport.data._id);
            updateTitle(titleString);
        } else {
            console.log("error", newReport);
            dispatch(openDialog({message: newReport.error.data.message}))
        }
    };

    return {
        handleReportCreation,
        newReportId,
        reportTitle
    }
}