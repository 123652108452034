import { homefileApi } from './emptySplitApi'

const partnerApi = homefileApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserPartners: builder.query({
            query: () => "api/partner",
            providesTags: ['Partners']
        }),
        getUserPartnerTiles: builder.query({
            query: () => "api/partner/tiles/user",
            providesTags: ['Partners']
        }),
        getPartnerTiles: builder.query({
            query: (partner: any) => ({
                url: "api/partner/tiles",
                params: {partner}
            }),
            providesTags: ['Partners']
        }),
        addTileToHome: builder.mutation({
            query: (data: any) => ({
                url: "api/home/tile",
                method: "POST",
                body: {tile: data.tileId, display: data.display, home: data.homeId},
                headers: {
                    "home": `${data.homeId}`,
                  }
            }),
            invalidatesTags: ["Partners", "Homes", "Home"],
        }),
        removeTileFromHome: builder.mutation({
            query: (tileId: string) => ({
                url: "api/home/tile",
                method: "PATCH",
                body: {tile: tileId}
            }),
            invalidatesTags: ["Partners", "Homes", "Home"],
        }),
        addPartnerToUser: builder.mutation({
            query: (code: string) => ({
                url: "api/partner",
                method: "POST",
                body: {partner: code}
            }),
            invalidatesTags: ["Partners"],
        }),
        addTileToUser: builder.mutation({
            query: (data: any) => ({
                url: "api/partner/tile",
                method: "POST",
                body: {partner: data.partnerId, tile: data.tileId}
            }),
            invalidatesTags: ["Partners"],
        }),
        removeTileFromUser: builder.mutation({
            query: (data: any) => ({
                url: "api/partner/tile",
                method: "DELETE",
                body: {partner: data.partnerId, tile: data.tileId}
            }),
            invalidatesTags: ["Partners"],
        }),
        removePartnerFromUser: builder.mutation({
            query: (code: string) => ({
                url: "api/partner",
                method: "DELETE",
                body: {partner: code}
            }),
            invalidatesTags: ["Partners"],
        }),
        listAllPartners: builder.query({
            query: () => "api/partner/all",
            providesTags: ['Partners']
        }),
        shareTile: builder.mutation({
            query: (data: any) => ({
                url: "api/partner/share-tile",
                method: "POST",
                body: {tile: data.tileId, user: data.userId }
            }),
            // invalidatesTags: ["Partners"],
        }),
    })
})

export const {
    useGetUserPartnerTilesQuery,
    useGetPartnerTilesQuery,
    useAddTileToHomeMutation,
    useRemoveTileFromHomeMutation,
    useAddPartnerToUserMutation,
    useAddTileToUserMutation,
    useRemoveTileFromUserMutation,
    useRemovePartnerFromUserMutation,
    useListAllPartnersQuery,
    useGetUserPartnersQuery,
    useShareTileMutation
} = partnerApi