import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation, Pagination } from "swiper";
import {
    Flex,
    Image,
    Text
} from '@chakra-ui/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import {ArrowRight, ArrowLeft} from '../../../assets/icons';
import '../styles/Carousel.scss'
import { ImageDBI, CarouselProps } from '../../../interfaces/ImageInterfaces';


const DocumentImagesCarousel = ({initialSlide, images}: CarouselProps) => {
    const navObject = {
        nextEl: '.image-carousel-next',
        prevEl: '.image-carousel-prev'
    }

    const { REACT_APP_STORAGE_URL: storageUrl } = process.env

    return (
        <Swiper pagination={{el: '.image-carousel-pagination', type: "fraction"}} initialSlide={initialSlide} navigation={navObject} modules={[Navigation, Pagination]} className="modal-swiper" >
            {
                images.map((image: ImageDBI) => (
                    <SwiperSlide key={image.fileName}>
                        <Flex flexDir="column" alignItems="center" justifyContent="center">
                            <Image src={`${storageUrl}/${image.bucketName}/${image.fileName}.${image.extension}`} />
                            {
                                image.description &&
                                <Text marginTop="1rem">{image.description}</Text>
                            }
                        </Flex>
                    </SwiperSlide>
                ))
            }
            <ArrowLeft className="image-carousel-prev" />
            <ArrowRight className="image-carousel-next"/>
            <Flex>
                <Text>Showing</Text>
                <Flex pl=".5rem" className='image-carousel-pagination'></Flex>
            </Flex>
      </Swiper>
    )
}

export default DocumentImagesCarousel;