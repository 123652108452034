import { useState, useEffect } from "react";
import {
    Flex,
    Image,
    Text,
  } from '@chakra-ui/react'

const PrivateImageTest = () => {

    return (
        <Flex flexDir="column" width="100%" alignItems="center" marginTop="10rem">
            <Text>This is a private page to test secure images</Text>
            <Image 
                src="https://homefile-test.storage.googleapis.com/city.jpeg"
                alt="Test Image"
            />
        </Flex>
    )
}

export default PrivateImageTest