import { useEffect, useState, useRef } from "react";
import { FormModel, ReportFormProps, FieldInterface } from "../interfaces/FormInterfaces";
import { Formik, Form, Field, FormikProps } from "formik";
import { Flex, Text, Stack, Center } from "@chakra-ui/react";
import { RootState } from "../app/store";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {setDocument} from '../features/document/documentSlice';
import BeatLoader from "react-spinners/BeatLoader";
import input from './styles/Input.module.scss'


const DynamicProgressReport = (props: ReportFormProps) => {
  const { form, reportTitle, needTitle } =
    props;
  const [initValues, updateInitValues] = useState({});
  const sendData = useAppSelector((state: RootState) => state.document.sendDocumentInfo)
  const editData = useAppSelector((state: RootState) => state.document.editDocumentInfo)
  const previewData = useAppSelector((state: RootState) => state.document.previewDocumentInfo)
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<FormModel>>(null);

  useEffect(() => {
      const fetchReport = async () => {
        if (formRef.current) {
          const report = await formRef.current.submitForm()
          dispatch(setDocument({report: report}))
        }
      }
      fetchReport()
  }, [sendData, editData])

  useEffect(() => {
    const fetchReport = async () => {
      if (formRef.current) {
        const report = await formRef.current.submitForm()
        dispatch(setDocument({report: report}))
      }
    }
    fetchReport()
}, [previewData])

  useEffect(() => {
    updateInitValues({})
    if (reportTitle !== "") {
      let newObj = {};
      form.forEach((field: any) => {
        newObj = { ...newObj, [field.name]: field.value };
      });
      updateInitValues(newObj);

      
    } else if (needTitle !== undefined && !needTitle) {
      let newObj = {};
      form.forEach((field: any) => {
        newObj = { ...newObj, [field.name]: field.value };
      });
      updateInitValues(newObj);
    }

  }, [form, reportTitle]);

  const handleSubmit = async (values: any, actions: any) => {
    let newForm: any = [];
    form.forEach((field) => {
      newForm.push({ ...field, value: values[field.name] });
    });


    return newForm;

  };

  return (
    <Stack spacing="4" p="4" mb="100px">
      {((needTitle === undefined || needTitle) && reportTitle.length < 2) ? (
          <Center h="8rem" bg="container.primary">
            <BeatLoader color="gray" size={8} />
          </Center>
      ) : (
        <Stack spacing="4">
          {(Object.keys(initValues).length === (form.length) && (reportTitle !== "" || (needTitle !== undefined && !needTitle))) && (
            <Formik innerRef={formRef} initialValues={initValues} onSubmit={handleSubmit}>
              <Form>
                <Flex flexDirection="column">
                  {form.map((field: FieldInterface) => {
                    if (field.type === "text" || field.type === "string" || field.type === "textarea") {
                      return (
                        <div key={field.name}>
                          <Text>{field.name}</Text>
                          <Text fontFamily="Assistant">
                            {field.description}
                          </Text>
                          <Field
                            as="textarea"
                            className={input.TextAreaInput}
                            name={field.name}
                            placeholder={field.name}
                          />
                        </div>
                      );
                    } else if (field.type === "email") {
                      return (
                        <div key={field.name}>
                          <Text>{field.name}</Text>
                          <Text fontFamily="Assistant">
                            {field.description}
                          </Text>
                          <Field
                            type="email"
                            className={input.TextAreaInput}
                            name={field.name}
                            placeholder={field.name}
                          />
                        </div>
                      );
                    } else if (field.type === "telephone") {
                      return (
                        <div key={field.name}>
                          <Text>{field.name}</Text>
                          <Text fontFamily="Assistant">
                            {field.description}
                          </Text>
                          <Field
                            type="tel"
                            className={input.TextAreaInput}
                            name={field.name}
                            placeholder={field.name}
                          />
                        </div>
                      );
                    } else if (field.type === "number") {
                      return (
                        <div key={field.name}>
                          <Text>{field.name}</Text>
                          <Text fontFamily="Assistant">
                            {field.description}
                          </Text>
                          <Field
                            className={input.Input}
                            type="number"
                            name={field.name}
                            placeholder={field.name}
                            step="0.01"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            min="0"
                          />
                        </div>
                      );
                    }else if (field.type === "date") {
                      return (
                        <div key={field.name}>
                          <Text>{field.name}</Text>
                          <Text fontFamily="Assistant">
                            {field.description}
                          </Text>
                          <Field
                            className={input.Input}
                            type="date"
                            name={field.name}
                            placeholder={field.name}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div key={field.name}>
                          <Text>{field.name}</Text>
                          <Text fontFamily="Assistant">
                            {field.description}
                          </Text>
                          <Field
                            className={input.Input}
                            type="checkbox"
                            name={field.name}
                          />
                        </div>
                      );
                    }
                  })}
                </Flex>
              </Form>
            </Formik>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default DynamicProgressReport;
