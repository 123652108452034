import {useState, useEffect} from 'react'
import { Box } from "@chakra-ui/react";
import { NewPassword} from "@homefile/components";
import { useResetPassword } from "../../hooks/useResetPassword";
import { INewPasswordForm } from "../../interfaces/NewPassword.interface";
import GenericDialog from '../../components/Dialogs/GenericDialog'

const ResetPassword = () => {

  const {
    handlePasswordSubmit,
    loading,
    paramValues,
    error
  } = useResetPassword();

  const [showError, updateError] = useState(error)

  useEffect(() => {
    updateError(error)
  }, [error])

  return (
    <Box>
      <NewPassword
        isLoading={loading}
        userEmail={paramValues.email}
        handleReset={(form: INewPasswordForm) => {
          handlePasswordSubmit(form)
        }}
      />
      <GenericDialog openModal={showError} close={() => updateError(false)} action={() => null} actionText="" cancelText="Continue" header="Something Went Wrong" body="Please try again." />
    </Box>
  );
};

export default ResetPassword;
