import {FooterDrawer, FooterButtons} from '@homefile/components';
import { t } from 'i18next';

interface CreateItemFooterI {
    handleSave: () => void
}
export const CreateItemFooter = ({handleSave}: CreateItemFooterI) => {

    const ButtonsFooter = {
        button1: {
          buttonStyle: 'primaryFooter',
          label: t('Save'),
          onClick: () => {handleSave()},
        },
      }

    return (
        <FooterDrawer
            children={
                <FooterButtons
                button1={ButtonsFooter.button1}
                />
            }
            isOpen={true}
        />
    )
}