import { Slide, Box, Drawer, DrawerOverlay, useDisclosure} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { SliderInfoProps } from '../../../interfaces/DocumentMenu.interface';
import CreateDocumentTabs from '../../Drawers/CreateDocument/CreateDocumentTabs';
import {DocumentNameHeader, FooterButtons, BackHeader, DocumentPreview, FooterDrawer, useCustomToast} from '@homefile/components';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { setSendDocument, setPreviewDocument, clearPreviewDocument, clearDocumentState, setEditDocument } from "../../../features/document/documentSlice";
import { RootState } from "../../../app/store";
import { useSendReport } from "../../../hooks/useSendReport";
import { useUpdateItem } from "../../../hooks/useUpdateItem";
import {useUploadMedia} from '../../../hooks/useUploadMedia';


const DocumentInfoSlider = (props: SliderInfoProps) => {
  const [documentName, updateName] = useState("")
  const {homeId, editDocument, back, open, documentId} = props;
  const toast = useCustomToast()
  const dispatch = useAppDispatch();
  const recipients = useAppSelector((state: RootState) => state.document.recipients)
  const reportData = useAppSelector((state: RootState) => state.document.document)
  const sendData = useAppSelector((state: RootState) => state.document.sendDocumentInfo)
  const editData = useAppSelector((state: RootState) => state.document.editDocumentInfo)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {images} = useUploadMedia({documentId})
  const dateString = new Date().toLocaleDateString();
  const homeName = useAppSelector((state: RootState) => state.home.name)
  const previewData = useAppSelector((state: RootState) => state.document.previewDocumentInfo)

  const successFunction = () => {
    dispatch(clearDocumentState())
    back()
  }
  const {handleSendReport} = useSendReport(successFunction);
  const {handleUpdate} = useUpdateItem(successFunction);

  useEffect(() => {
    updateName(editDocument.title)
  }, [editDocument])

  const handlePreview = () => {
    dispatch(setPreviewDocument({preview: true}))
  }

  useEffect(() => {
    if (previewData && reportData) {
      onOpen()
    }
  }, [previewData])

  const handleSend = () => {
    if (recipients.length > 0) {
      dispatch(setSendDocument({send: true, edit: false}))
    } else {
      toast({
        title: 'Add Recipients to Send Document',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: "top-right"
      })
    }
  }

  const handleEdit = () => {
    dispatch(setEditDocument({edit: true, send: false}))
  }

  useEffect(() => {
    if (reportData && sendData && documentId !== "") {
      handleSendReport({
        newReportId: documentId,
        homeId,
        reportTitle: documentName,
        report: reportData
      })
    } else if (reportData && editData && documentId !== "") {
      handleUpdate({
        newReportId: documentId,
        homeId,
        reportTitle: documentName,
        report: reportData
      })
    }
  }, [reportData])

  const handleClose = () => {
    dispatch(clearPreviewDocument())
    onClose()
  }
  

  const ButtonsFooter = {
    button1: {
      buttonStyle: 'primaryFooter',
      label: t('createDocument.buttons.send'),
      onClick: () => {handleSend()},
    },
    button2: {
      buttonStyle: 'secondaryFooter',
      label: t('createDocument.buttons.save'),
      onClick: () => {handleEdit()},
    },
    button3: {
      buttonStyle: 'secondaryFooter',
      label: t('createDocument.buttons.preview'),
      onClick: () => {handlePreview()},
    },
  }

  return (
    <Slide direction="right" in={open} style={{ zIndex: 9999 }}>
      <Box bg="lightBlue.1" h="100%" zIndex="dropdown" overflow="scroll">
        <BackHeader handleClick={back} />
        {open && (
          <>
            <DocumentNameHeader
              handleChange={(event) => {
                updateName(event.target.value)
              }}
              value={documentName}
            />
            <CreateDocumentTabs showMediaIcon={true} wizardTab={false} detailedWizard={false} homeId={homeId} report={editDocument} reportId={documentId} reportTitle={editDocument.title}/>
            <FooterDrawer
            children={
              <FooterButtons
                button1={ButtonsFooter.button1}
                button2={ButtonsFooter.button2}
                button3={ButtonsFooter.button3}
              />
            }
            isOpen={true}
          />
            <Drawer placement="right" onClose={handleClose} isOpen={isOpen} size="md">
              <DrawerOverlay />
              <DocumentPreview
                date={dateString}
                document={documentName}
                form={reportData}
                handleClose={handleClose}
                handleSend={handleSend}
                home={homeName}
                images={images}
                />
            </Drawer>
          </>
        )}
      </Box>
    </Slide>
  );
}

export default DocumentInfoSlider;