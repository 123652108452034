import '@fontsource/assistant'
import '@fontsource/poppins'
import { extendTheme } from '@chakra-ui/react'
import {
  Button,
  Checkbox,
  Container,
  Drawer,
  Input,
  Progress,
  Select,
  Switch,
  Text,
  Tooltip,
  Textarea,
} from './components'
import { colors } from './colors'

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        backgroundColor: 'lightBlue.4',
        fontFamily: 'primary',
        margin: 0,
        padding: 0,
        webkitFontSmoothing: 'antialiased',
      },
      a: {
        color: 'blue.2',
        fontFamily: 'secondary',
        textDecoration: 'none',
        _hover: {
          textDecoration: 'underline',
        },
      },
    },
  },
  components: {
    Button,
    Checkbox,
    Container,
    Drawer,
    Input,
    Progress,
    Select,
    Switch,
    Text,
    Tooltip,
    Textarea,
  },
  colors,
  fonts: {
    primary: 'Poppins',
    secondary: 'Assistant',
    timesNewRoman: 'Times New Roman',
    helvetica: 'Helvetica',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  fontSizes: {
    xxs: '0.65rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 900,
  },
  space: {
    base: '0.8125rem',
    onboarding: {
      top: '3.125rem',
    },
    container: {
      sm: '1rem',
      md: '2rem',
      lg: '3rem',
      xl: '4rem',
    },
    input: {
      sm: '0.8rem',
    },
    grid: {
      sm: '0.6rem',
    },
  },
  sizes: {
    button: {
      max: 'max-content',
      min: 'min-content',
      xxs: '2.5rem',
      xs: '3.4rem',
      sm: '10rem',
      md: '20rem',
      lg: '28rem',
      full: '100%',
    },
    container: {
      roomIcon: '3.8rem',
      masthead: '4rem',
      xxs: '6rem',
      xs: '10rem',
      sm: '20rem',
      md: '30rem',
      lg: '35rem',
      xl: '50rem',
      full: '100%',
      footer: '32rem',
    },
    icon: {
      md: '30px',
    },
    image: {
      logo: '2rem',
    },
    input: {
      xs: '1.5rem',
      sm: '2.25rem',
      md: '3rem',
      lg: '4rem',
      xl: '5rem',
      xxl: '7.5rem',
      wXs: '10rem',
      wSm: '16rem',
      wMd: '22rem',
    },
  },
})

export default theme
