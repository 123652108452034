import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Flex,
    Center
} from '@chakra-ui/react'
import {
    ProfilePaymentTab
} from "@homefile/components"
import { PaymentMethodI } from "@homefile/components/dist/interfaces"
import BeatLoader from "react-spinners/BeatLoader";
import StripePaymentsModule from '../../Modules/StripePaymentsModule'

interface CreditCardDialogI {
    close: () => void
    header: string
    body: string
    cancelText: string
    openModal: boolean
    cardLoading: boolean
    cardSuccess: boolean
    authToken?: string
    handleCardSuccess: () => void
}

const CreditCardDialog = (props: CreditCardDialogI) => {

    const {close, header, body, cancelText, openModal, cardLoading, authToken, handleCardSuccess} = props;

    return (
        <Modal size={"xl"} isOpen={openModal} onClose={close}>
            <ModalOverlay />
            <ModalContent bg={'lightBlue.2'}>
            <ModalHeader>{header}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Flex flexDir={'column'}>
                    <Text variant="title" fontWeight="medium" whiteSpace={'initial'}>
                        {body}
                    </Text>
                    {
                        cardLoading ? (
                            <Center w={"100%"} h="4rem" bg="white">
                                <BeatLoader color="gray" size={8} />
                            </Center>
                        ) : (
                            <Flex w={"100%"} alignItems="center" justifyContent="center" p={"1rem"}>
                                <StripePaymentsModule handleCardSuccess={handleCardSuccess} authToken={authToken} />
                            </Flex>
                        )
                    }
                </Flex>
            </ModalBody>

            <ModalFooter>
                <Button m="1rem" variant="secondaryFooter" onClick={close}>
                    {cancelText}
                </Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default CreditCardDialog;