import { homefileApi } from './emptySplitApi'

const stripeApi = homefileApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserSubscription: builder.query({
            query: () => "api/stripe",
            providesTags: ['User', "Subscription"]
        }),
        getStripePaymentIntent: builder.query({
            query: () => "api/stripe/payment-intent",
            providesTags: ["Payment"]
        }),
        getUnAuthStripePaymentIntent: builder.query({
            query: (token) => ({
                url: "api/stripe/payment-intent",
                headers: {
                    "authorization": `Bearer ${token}`,
                }
            }),
            providesTags: ["Payment"]
        }),
        unAuthUpdatePaymentMethod: builder.mutation({
            query: (token: string) => ({
                url: `/api/stripe/update-payment-method`,
                method: "POST",
                headers: {
                    "authorization": `Bearer ${token}`,
                }
              }),
              invalidatesTags: ["Payment"],
        }),
        updatePaymentMethod: builder.mutation({
            query: () => ({
                url: `/api/stripe/update-payment-method`,
                method: "POST",
              }),
              invalidatesTags: ["Payment"],
        }),
        getPaymentPlans: builder.query({
            query: () => "api/stripe/plans",
            providesTags: ['User', "Subscription"]
        }),
        createUserSubscription: builder.mutation({
            query: (data: any) => ({
              url: `/api/stripe`,
              method: "POST",
              body: data
            }),
            invalidatesTags: ['User', "Subscription"],
          }),
          createUnAuthUserSubscription: builder.mutation({
            query: (data: any) => ({
                url: `/api/stripe`,
                method: "POST",
                body: data.body,
                headers: {
                    "authorization": `Bearer ${data.token}`,
                }
            }),
            invalidatesTags: ['User', "Subscription"],
        }),
        deleteUserSubscription: builder.mutation({
            query: () => ({
                url: "api/stripe/subscription",
                method: "DELETE",
            }),
            invalidatesTags: ["User", "Subscription"],
        })
    })
})

export const {
    useGetUserSubscriptionQuery,
    useDeleteUserSubscriptionMutation,
    useCreateUserSubscriptionMutation,
    useGetPaymentPlansQuery,
    useGetStripePaymentIntentQuery,
    useUpdatePaymentMethodMutation,
    useCreateUnAuthUserSubscriptionMutation,
    useGetUnAuthStripePaymentIntentQuery,
    useUnAuthUpdatePaymentMethodMutation
} = stripeApi
