import {useState, useEffect} from 'react'
import {useUpdateProgressReportMutation, useGetFormsQuery} from "../features/api";
import { FileInfoI } from "@homefile/components/dist/interfaces";
import {FieldInterface, IFormItem} from "../interfaces/DocumentMenu.interface";
import { number } from 'yup/lib/locale';
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { RootState } from "../app/store";
import { ResponseType } from '../interfaces/DataInterfaces';
import { openDialog } from "../features/dialog/dialogSlice";

export const useCreateReceiptReport = () => {
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const [updateItem] = useUpdateProgressReportMutation()
    const { data } = useGetFormsQuery({type: "Household Item", subType: ""}, {skip: false});
    const [form, updateForm] = useState<any[]>([]);
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (data) {
            updateForm(data[0].form)
        }
    }, [data])

    const updateReceiptItem = async (file: any) => {
        const newForm = form.map((field: any) => {
            if (field.name === "Brand/Manufacturer") {
                return {...field, value: file.title}
            } else if (field.name === "Purchase Details") {
                // deeply nested field
                let detailsChildren: any[] = []
                let gridChildren: any[] = []
                let skuField = {}
                let companyField = {}
                field.children.forEach((detailsField: any) => {
                    if (detailsField.name === "SKU") {
                        const newSku = (file.metadata.sku).toString()
                        skuField = {...detailsField, value: newSku}
                    } else if (detailsField.name === "Retailer/Company Name" && file.metadata.receiptOrigin !== undefined) {
                        const origin = file.metadata.receiptOrigin === "homeDepot" ? "Home Depot" : file.metadata.receiptOrigin
                        companyField = {...detailsField, value: origin}
                    } else if (detailsField.type === "grid") {
                        let numberField = {}
                        let dateField = {}
                        detailsField.children.forEach((gridChild: any) => {
                            if (gridChild.name === "Amount Paid for Item") {
                                const numberTotal = Number(file.metadata.total)
                                numberField = {...gridChild, value: numberTotal}
                            } else if (gridChild.name === "Purchase Date" && file.metadata.purchaseDate !== undefined) {
                                // needs to be in en-CA for now?
                                // const formatDate = new Date(file.metadata.purchaseDate).toLocaleDateString("en-CA")
                                const formatDate = new Date(file.metadata.purchaseDate).toISOString().split("T")[0]
                                dateField = {...gridChild, value: formatDate} 
                            }
                        })
                        gridChildren = {...detailsField, children: [dateField, numberField]}
                    }
                })
                detailsChildren = [companyField, skuField, gridChildren]
                return {...field, visible: true, children: detailsChildren}
            } else {
                return field
            }
        })
        
        const sendData = {
            report: newForm,
            title: file.title,
            description: file.description,
            home: homeId,
            id: file._id
        }

        const updatedReportResponse: ResponseType = await updateItem(
            sendData
        );

        if (updatedReportResponse.data) {
            return updatedReportResponse.data
        } else {
            
            console.log("update fail",updatedReportResponse);
            dispatch(openDialog({message: updatedReportResponse.error.data.message}))
            return
        }
    }

    return {
        updateReceiptItem
    }

    // const costField = item.report.find((field: any) => field.name === "Amount Paid for Item")

}