import { useState, useEffect } from "react";
import { useSetTwoFactorMutation, useConfirmTwoFactorMutation, useUpdateUserMutation
 } from "../features/api";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { ResponseType } from "../interfaces/DataInterfaces";
import { setUser } from "../features/auth/authSlice";
import {useCustomToast} from '@homefile/components'

export const useTwoFactor = () => {
    const user = useAppSelector((state: RootState) => state.auth.user);
    const [defaultValue, updateDefaultValue] = useState("")
    const email = user.email
    const sms = user.phone !== undefined ? user.phone.substring(2,12): ""
    const [selectedValue, updateSelectedValue] = useState("email")
    const [twoFactorMethod, updateTwoFactorMethod] = useState<"sms" | "email">("email")
    const [setTwoFactor] = useSetTwoFactorMutation()
    const [confirmTwoFactorCode] = useConfirmTwoFactorMutation()
    const [updateUser] = useUpdateUserMutation()
    const [loading, updateLoading] = useState(false)
    const toast = useCustomToast()
    const dispatch = useAppDispatch()
    const [active, updateActive] = useState("active")
    const [openTwoFactorModal, updateOpenTwoFactorModal] = useState(false)

    useEffect(() => {
        if (user.provider && user.provider !== null) {
            updateDefaultValue(user.provider)
            updateSelectedValue(user.provider)
            updateTwoFactorMethod(user.provider)
        } else {
            updateActive("")
        }
    }, [user])

    const onChange = (value: string) => {
        updateSelectedValue(value)
        if (value === "email") {
            updateTwoFactorMethod("email")
        } else {
            updateTwoFactorMethod("sms")
        }
    }

    const updateProvider = async (provider: string | null) => {
        const updatedProvider: ResponseType = await setTwoFactor({provider, code: ""})

        if (updatedProvider.data) {
            if (provider === "") {
                toast({
                    title: 'Two Factor Authentication Removed',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: "top-right"
                })
                dispatch(setUser({...user, provider: ""}));
                updateLoading(false)
            } else {
                // next action for a user to confirm their 2fa method
                updateOpenTwoFactorModal(true)
            }
        } else {
            toast({
                title: 'Error, please try again.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: "top-right"
            })
        }
    }  
    
    const addUserPhone = async (inputPhone: string) => {
        const userPhone = {...user, phone: `+1${inputPhone}`}
        const updatedUser: ResponseType = await updateUser(userPhone)

        if (updatedUser.data) {
            dispatch(setUser(updatedUser.data));
            updateProvider('sms')
        }
    }

    const onSave = async (value: any) => {
        updateLoading(true)
        if (value["2fa"]) {
            // 
            if (value.email === "" && value.sms === "") {
                // throw toast error
                toast({
                    title: 'Complete fields before submitting',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: "top-right"
                })
            } else {
                if (selectedValue === "sms") {
                    if (user.phone !== undefined && user.phone.substring(2,12) === value.sms) {
                        // set 2fa sms
                        updateProvider('sms')
                    } else {    
                        // fire call to udpate user then set 2fa
                        addUserPhone(value.sms)
                    }
                } else {
                    // set 2fa email
                    updateProvider('email')
                }
            }
        } else {
            // update user provider to null
            updateProvider("")
        }
    }

    const confirmTwoFactor = async (code: string) => { 
      const confirmedTwoFactor: ResponseType = await confirmTwoFactorCode({provider: selectedValue, code})

      if (confirmedTwoFactor.data) {
        //success
        toast({
            title: 'Two Factor Authentication Activated!',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: "top-right"
        })
        dispatch(setUser({...user, provider: confirmedTwoFactor.data.provider}));
        updateOpenTwoFactorModal(false)
        updateLoading(false)
      } else {
        // error
        updateLoading(false)
      }
    }  

    return {
        defaultValue, 
        email,
        sms,
        onChange,
        onSave,
        loading,
        active,
        confirmTwoFactor,
        openTwoFactorModal, 
        updateOpenTwoFactorModal,
        twoFactorMethod
    }

}