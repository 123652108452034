import {
    MonthlyCharge,
    ActiveSubscription,
    CancelAccount
} from "@homefile/components"
import { useSubscriptionData } from "../../../hooks/useSubscriptionData"
import { useGetUserSubscriptionData } from "../../../hooks/useGetUserSubscriptionData"
import {useState, useEffect} from 'react'
import GenericDialog from "../../Dialogs/GenericDialog";
import { ResponseType } from "../../../interfaces/DataInterfaces";
import { useDeleteUserSubscriptionMutation, homefileApi } from "../../../features/api";
import { useAppDispatch } from "../../../app/hooks";
import { clearAuth } from "../../../features/auth/authSlice";


const SubscriptionDetailsModule = () => {

    const [monthlyCharge, updateMonthlyCharge] = useState(0)
    const [date, updateDate] = useState<Date | string>("")
    const [deleteUser] = useDeleteUserSubscriptionMutation()
    const [deleteModal, updateDeleteModal] = useState(false)
    const dispatch = useAppDispatch()
    const [monthDate, updateMonthDate] = useState<Date | string>("")
    const [storageDate, updateStorageDate] = useState<Date | string>("")

    const {
        homes,
        totalStorage,
        usedStorage,
        state,
        availableStorage
    } = useSubscriptionData(0)

    const {subscriptionData} = useGetUserSubscriptionData(true)

    const handleSignOut = () => {
        // dispatch clear auth and redirect here
        dispatch(homefileApi.util.resetApiState())
        dispatch(clearAuth())
      }

    useEffect(() => {
        if (usedStorage >= 2) {
            const calcCharge = ((totalStorage - 2) / 2) * 1.99
            updateMonthlyCharge(calcCharge)
            const mDate = new Date()
            updateMonthDate(mDate)
        }
        
    }, [usedStorage, totalStorage])

    useEffect(() => {
        if (subscriptionData.plan) {
            updateDate(subscriptionData.plan.current_period_end)
        }

        if (subscriptionData.storage) {
            updateStorageDate(subscriptionData.storage.current_period_end)
        }
    }, [subscriptionData])

    const handleDelete = async () => {
        const removedUser: ResponseType = await deleteUser(null)

        if (removedUser.data) {
            handleSignOut()
        } else {
            console.log('error in removal', removedUser)
        }
    }

    const handleDeleteModal = () => {
        updateDeleteModal(true)
    }

    const closeDeleteModal = () => {
        updateDeleteModal(false)
    }

    return(
        <>
            <ActiveSubscription 
                totalStorage={totalStorage}
                state={state}
                totalUsed={usedStorage}
                homes={homes}
                nextCharge={date}
                subscriptionPrice={36}
                availableStorage={availableStorage}
            />

            {
                (monthlyCharge > 0) && (
                    <MonthlyCharge 
                        monthlyCharge={monthlyCharge}
                        nextCharge={storageDate}
                        additionalPrice={monthlyCharge}
                        taxValue={0}
                        monthDate={monthDate}
                        taxPercentage={0}
                        subtotal={monthlyCharge}
                        additionalStorage={totalStorage - 2}
                    />
                )
            }

            <CancelAccount label="Cancel Account" onClick={handleDeleteModal} />

            <GenericDialog header={"Delete Account Request"} body={"This action is irreversible."} action={handleDelete} close={closeDeleteModal} openModal={deleteModal} actionText={"Continue"} cancelText={"Cancel"}  />
        </>
    )
}

export default SubscriptionDetailsModule
