import {
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    Center
} from '@chakra-ui/react'
import { PanelHeader,
    ToBeDeletedBody,
    ToBeDeletedHeader,
    ToBeDeletedFooter
} from "@homefile/components";
import {useState, useEffect} from 'react'
import BeatLoader from "react-spinners/BeatLoader";
import GenericDialog from "../../Dialogs/GenericDialog";
import { useHardDeleteHomesItems } from '../../../hooks/useHardDeleteHomesItems';
import { HomesToBeDeletedI, ItemsToBeDeletedI } from '@homefile/components/dist/interfaces';
import { useGetReportsSetToDelete } from '../../../hooks/useGetReportsSetToDelete';
import { useGetHomesQuery } from "../../../features/api";


const DeleteHomeItemsDrawer = ({ handleClose, deleteHomes}: { handleClose: () => void , deleteHomes: boolean}) => {
    const [bannerText, updateBannerText] = useState("")
    const [homes, updateHomes] = useState<HomesToBeDeletedI[]>([])
    const [items, updateItems] = useState<ItemsToBeDeletedI[]>([])
    const [homesToBeDeleted, updateHomesToBeDeleted] = useState<HomesToBeDeletedI[]>([])
    const [itemsToBeDeleted, updateItemsToBeDeleted] = useState<ItemsToBeDeletedI[]>([])
    const [skipReports, updateSkipReports] = useState(true)
    const {itemsSetForDeletion} = useGetReportsSetToDelete(skipReports)
    const [skip, updateSkip] = useState(true)
    const { data } = useGetHomesQuery(null, {skip});

    const { REACT_APP_STORAGE_URL: storageUrl } = process.env

    const {
        isLoading,
        openModal,
        hasClosed,
        handleCloseModal,
        deleteHomesSelection,
        deleteItemsSelection,
        handleOpenModal,
        handleReactivateHomes,
        handleReactivateItems
    } = useHardDeleteHomesItems(deleteHomes)

    const formatDate = (date: Date | string) => {
        const incomingDate = new Date(date)
        const weekAhead = incomingDate.setDate(incomingDate.getDate() + 7)
        return new Date(weekAhead)
    }

    useEffect(() => {
        if (hasClosed) {
            if (items.length === 0 && homes.length === 0) {
                handleClose()
            }
        }
    }, [items, homes])

    useEffect(() => {
        if (itemsSetForDeletion.length > 0) {
            const itemsArray: ItemsToBeDeletedI[] = itemsSetForDeletion.map((item: ItemsToBeDeletedI) => {
                const passDate = formatDate(item.deletedAt)
                if (item.images && item.images.length > 0) {
                    const imgURL = `${storageUrl}/${item.images[0].bucketName}/${item.images[0].fileName}.${item.images[0].extension}`
                    return {...item, imgURL, deletedAt: passDate, type: undefined}
                } else {
                    return {...item, deletedAt: passDate, type: undefined}
                }
            })
            updateItems(itemsArray)
            if (itemsSetForDeletion.length > 1) {
                updateBannerText(`${itemsSetForDeletion.length} items are scheduled to be deleted. Select an item or items to delete now or re-activate.`)
            } else {
                updateBannerText("1 item is scheduled to be deleted. Select the item to delete now or re-activate.")
            }  
        } else {
            updateItems([])
            updateBannerText("No Items Set For Deletion")
        }
    }, [itemsSetForDeletion])

    useEffect(() => {
        if (data) {
            const removedHomes = data.filter((home: HomesToBeDeletedI) => !home.active)

            if (removedHomes.length > 0) {
                const homesArray: HomesToBeDeletedI[] = removedHomes.map((home: HomesToBeDeletedI) => {
                    const passDate = formatDate(home.deletedAt)
    
                    if (home.image) {
                        const imgURL = `${storageUrl}/${home.image.bucketName}/${home.image.fileName}.${home.image.extension}`
                        return {...home, imgURL, deletedAt: passDate}
                    } else {
                        return {...home, deletedAt: passDate}
                    }
                })
                updateHomes(homesArray)
            } else {
                updateHomes([])
            }

            if (removedHomes.length > 1) {
                updateBannerText(`${removedHomes.length} homes are scheduled to be deleted. Select a home or homes to delete now or re-activate.`)
            } else if (removedHomes.length === 1) {
                updateBannerText("1 home is scheduled to be deleted. Select the home to delete now or re-activate.")
            } else {
                updateBannerText("No Homes Set For Deletion")
            }
        }

    }, [data])


    useEffect(() => {
        if (deleteHomes) {
            updateSkip(false)
            updateSkipReports(true)
            updateItems([])
        } else {
            updateSkip(true)
            updateSkipReports(false)
            updateHomes([]) 
        }
    }, [deleteHomes])

    const onHomesSelected = (homes: HomesToBeDeletedI[]) => {
        updateHomesToBeDeleted(homes)
    }

    const onItemsSelected = (items: ItemsToBeDeletedI[]) => {
        updateItemsToBeDeleted(items)
    }

    const deleteSelection = () => {
        if (deleteHomes) {
            deleteHomesSelection(homesToBeDeleted)
        } else {
            deleteItemsSelection(itemsToBeDeleted)
        }
    }

    const handleReactivate = () => {
        if (deleteHomes) {
            handleReactivateHomes(homesToBeDeleted)
        } else {
            handleReactivateItems(itemsToBeDeleted)
        }
    }

    return (
        <>
            <DrawerContent bg="lightBlue.1" p="0">
                <ToBeDeletedHeader 
                    onClose={handleClose}
                    title={deleteHomes ? "Homes" : "Items"}
                    bannerText={bannerText}
                />
                {
                    isLoading ? (
                        <Center h="4rem" bg="white">
                            <BeatLoader color="gray" size={8} />
                        </Center>
                    ) : (
                        <ToBeDeletedBody 
                            homes={homes}
                            items={items}
                            onHomesSelectedChange={onHomesSelected}
                            onItemsSelectedChange={onItemsSelected}
                        />
                        )
                    }
                <ToBeDeletedFooter
                    isDeleteDisabled={(itemsToBeDeleted.length === 0 && homesToBeDeleted.length === 0) ? true : false}
                    isUndeleteDisabled={(itemsToBeDeleted.length === 0 && homesToBeDeleted.length === 0) ? true : false}
                    onDeleteClick={handleOpenModal}
                    onUndeleteClick={handleReactivate}
                    onCloseClick={handleClose}
                />
            </DrawerContent>
            <GenericDialog header={"Delete Selection?"} body={"Warning! This action is irreversible."} action={deleteSelection} close={handleCloseModal} openModal={openModal} actionText={"Delete"} cancelText={"Cancel"}  />
        </>
    )
}

export default DeleteHomeItemsDrawer