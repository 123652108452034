import {  useGetUserByIdQuery } from "../features/api";
import { useEffect, useState } from "react";
import { ClientInterface } from "../interfaces/UserInterfaces";

export const useGetUserData = (id: string) => {
    const [skip, updateSkip] = useState(true)
    const {data} = useGetUserByIdQuery(id, {skip})
    const [userData, updateData] = useState({} as ClientInterface)

    useEffect(() => {
        if (id !== "") {
            updateSkip(false)
        }
    }, [id])

    useEffect(() => {
        if (data) {
            updateData(data as ClientInterface)
        }
    }, [data])

    return {
        userData
    }
}