import { useEffect, useState } from "react";
import { useUpdateHomeReceiptsMutation, useDeleteHomeReceiptMutation } from "../features/api";
import { ResponseType } from "../interfaces/DataInterfaces";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { useLaunchPadReceipts } from "./useLaunchPadReceipts";
import { useHomeBoardReceipts } from './useHomeBoardReceipts';
import { SelectItemI, ReceiptDetailsDBI } from "@homefile/components/dist/interfaces";
import { useDisclosure } from '@chakra-ui/react'
import {useCustomToast} from '@homefile/components'

export const useReceipts = (homes: SelectItemI[]) => {
    const id = useAppSelector((state: RootState) => state.home.id)
    // const [receiptList, updateReceiptList] = useState<ReceiptDetailsDBI[]>(mockReceipts)
    // const [filteredList, updateFilteredList] = useState<ReceiptDetailsDBI[]>(mockReceipts)
    const [receiptList, updateReceiptList] = useState<ReceiptDetailsDBI[]>([])
    const [filteredList, updateFilteredList] = useState<ReceiptDetailsDBI[]>([])
    const [loading, updateLoading] = useState(false)
    const [receiptId, updateReceiptId] = useState("")
    const { isOpen, onToggle } = useDisclosure();
    const [selectedHome, updateSelectedHome] = useState(homes.length > 0 ? homes[0]._id : "")
    const [selectedReceipts, updateSelectedReceipts] = useState<string[]>([])
    const [updateReceipt] = useUpdateHomeReceiptsMutation()
    const toast = useCustomToast()
    const {receipts: homeReceipts} = useHomeBoardReceipts(id)
    const {receipts: noHomeReceipts} = useLaunchPadReceipts(id)
    const [selectedReceipt, updateSelectedReceipt] = useState({})
    const [idToDelete, updateIdToDelete] = useState("")
    const [openDeleteDialog, updateOpenDeleteDialog] = useState(false)
    const [deleteSelectedReceipt] = useDeleteHomeReceiptMutation()
    const [searchValue, setSearchValue] = useState('')
    const [dateChanged, updateDateChanged] = useState("")
    const [retailerChanged, updateRetailerChanged] = useState("")
    
    const handleOnSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }

    useEffect(() => {
        const updatedReceipts = filteredList.filter((receipt: ReceiptDetailsDBI) => {
            const stringDate = new Date(receipt.purchaseDate).toString()
            if (receipt.total.includes(searchValue)) {
                return true
            } else if (receipt.subTotal?.includes(searchValue)) {
                return true
            } else if (receipt.receiptOrigin?.toLowerCase().includes(searchValue.toLowerCase())) {
                return true
            } else if (stringDate.toLowerCase().includes(searchValue.toLowerCase())) {
                return true
            } else {
                return false
            }
        })
        updateFilteredList(updatedReceipts)
    }, [searchValue])

    useEffect(() => {
        if (id === "") {
            if (noHomeReceipts.length > 0) {
                const newReceipts = noHomeReceipts.map((receipt: ReceiptDetailsDBI) => {
                    if (receipt.purchaseDate) {
                        const newDate = new Date(receipt.purchaseDate)
                        return {...receipt, purchaseDate: newDate}
                    } else {
                        return {...receipt}
                    }
                })
    
                updateReceiptList(newReceipts)
                updateFilteredList(newReceipts)
    
            } else {
                updateReceiptList([])
                updateFilteredList([])
            }
        } else {
            if (homeReceipts.length > 0) {
                const newReceipts = homeReceipts.map((receipt: ReceiptDetailsDBI) => {
                    let passReceipt: ReceiptDetailsDBI = {...receipt}

                    if (receipt.purchaseDate) {
                        const newDate = new Date(receipt.purchaseDate)
                        passReceipt = {...receipt, purchaseDate: newDate}
                    } 

                    if (receipt.items !== undefined) {

                        const filedItems: any[] = receipt.items.filter((item: any) => item.room !== undefined)
                        const unFiledItems: any[] = receipt.items.filter((item: any) => item.room === undefined)

                        if (filedItems.length > 0 && unFiledItems.length === 0) {
                            passReceipt = {...passReceipt, assignStatus: "filed"}
                        } else if (filedItems.length > 0 && unFiledItems.length > 0) {
                            passReceipt = {...passReceipt, assignStatus: "filing"}
                        } else {
                            passReceipt = {...passReceipt, assignStatus: "unfiled"}
                        }
                    }

                    return passReceipt
                })
                updateReceiptList(newReceipts)
                updateFilteredList(newReceipts)
    
            } else {
                updateReceiptList([])
                updateFilteredList([])
            }
        }
    }, [homeReceipts, noHomeReceipts])

    const launchPadFilters = [
        'By Date',
        'By Price',
        'By Vendor'
    ]

    const handleReceiptClick = (_id: string) => {
        const tReceipt = receiptList.find((receipt) => receipt._id === _id)
        if (tReceipt) {
            updateSelectedReceipt(tReceipt)
        }
        updateReceiptId(_id)
        onToggle();
    }

    const onSelectHome = (value: string | SelectItemI) => {
        if (typeof value === "object") {
            updateSelectedHome(value._id)
        } else {
            updateSelectedHome(value)
        }
    }

    const selectReceiptsToAssign = (_ids: string[]) => {
        updateSelectedReceipts(_ids)
    }

    const handleAssignReceipts = async () => {
        if (selectedReceipts.length > 0) {
            for (const selectedReceipt of selectedReceipts) {
                const updatedReceipt: ResponseType = await updateReceipt({
                    receipt: selectedReceipt,
                    home: selectedHome,
                    name: ""
                })
                if (updatedReceipt.data) {
                    toast({
                        title: 'Receipt Assigned!',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                        position: "top-right"
                    })
                } else {
                    console.log('receipt update failed')
                    toast({
                        title: 'Error, please try again',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                        position: "top-right"
                    })
                }
            }
        } else {
            toast({
                title: 'Select Items First',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: "top-right"
            })
        }
    }

    const handleRemove = (id: any) => {
        updateIdToDelete(id)
        updateOpenDeleteDialog(true)
    }

    const deleteReceipt = async () => {
        const removedReceipt: ResponseType = await deleteSelectedReceipt(idToDelete)

        if (removedReceipt.data) {
            updateOpenDeleteDialog(false)
            toast({
                title: 'Receipt Removed!',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: "top-right"
            })
            if(isOpen) {
                onToggle()
            }
        } else {
            console.log('receipt delete failed', removedReceipt)
            toast({
                title: 'Error, please try again',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: "top-right"
            })
        }
        
    }


    const resetRetailerChange = () => {
        if (retailerChanged === 'Home Depot') {
            const newReceipts = receiptList.filter((receipt:ReceiptDetailsDBI) => receipt.receiptOrigin === "homeDepot")
            updateFilteredList(newReceipts)
        } else if (retailerChanged === 'Lowe\'s') {
            const newReceipts = receiptList.filter((receipt:ReceiptDetailsDBI) => receipt.receiptOrigin === "Lowes")
            updateFilteredList(newReceipts)
        } 
    }

    const resetDateChange = () => {
        const today = new Date()
        let prior = new Date(new Date().setDate(today.getDate() - 30))
        if (dateChanged === "Last 60 days") {
            prior = new Date(new Date().setDate(today.getDate() - 60))
        } else if (dateChanged === "Last 90 days") {
            prior = new Date(new Date().setDate(today.getDate() - 90))
        }

        const newReceipts = receiptList.filter((receipt:ReceiptDetailsDBI) => receipt.purchaseDate <= prior)
        updateFilteredList(newReceipts)
    }

    const dateFilters: string[] = [
        "All Time",
        'Last 30 days',
        'Last 60 days',
        'Last 90 days',
    ]

    const onDateChange = (value: string | SelectItemI) => {
        const today = new Date()
        if (value === "All Time") {
            updateDateChanged("")
            if (retailerChanged !== "") {
                resetRetailerChange()
            } else {
                updateFilteredList(receiptList)
            }
        } else {
            let prior = new Date(new Date().setDate(today.getDate() - 30))
            if (value === "Last 60 days") {
                updateDateChanged(value)
                prior = new Date(new Date().setDate(today.getDate() - 60))
            } else if (value === "Last 90 days") {
                updateDateChanged(value)
                prior = new Date(new Date().setDate(today.getDate() - 90))
            } else if (value === "Last 30 Days") {
                updateDateChanged(value)
            }
            if (retailerChanged !== "") {
                const newReceipts = filteredList.filter((receipt:ReceiptDetailsDBI) => receipt.purchaseDate <= prior)
                updateFilteredList(newReceipts)
            } else {
                const newReceipts = receiptList.filter((receipt:ReceiptDetailsDBI) => receipt.purchaseDate <= prior)
                updateFilteredList(newReceipts)
            }
        }

    }

    const retailerFilters: string[] = [
        'All Retailers',
        // 'Amazon',
        // 'Best Buy',
        // 'Costco',
        'Home Depot',
        'Lowe\'s',
        // 'Target',
        // 'Walmart',
    ]

    const onRetailerChange = (value: string | SelectItemI) => {

        if (value === 'Home Depot') {
            updateRetailerChanged(value)
            if (dateChanged !== "") {
                const newReceipts = filteredList.filter((receipt:ReceiptDetailsDBI) => receipt.receiptOrigin === "homeDepot")
                updateFilteredList(newReceipts)
            } else {
                const newReceipts = receiptList.filter((receipt:ReceiptDetailsDBI) => receipt.receiptOrigin === "homeDepot")
                updateFilteredList(newReceipts)
            }
        } else if (value === 'Lowe\'s') {
            updateRetailerChanged(value)
            if (dateChanged !== "") {
                const newReceipts = filteredList.filter((receipt:ReceiptDetailsDBI) => receipt.receiptOrigin === "Lowes")
                updateFilteredList(newReceipts)
            } else {
                const newReceipts = receiptList.filter((receipt:ReceiptDetailsDBI) => receipt.receiptOrigin === "Lowes")
                updateFilteredList(newReceipts)
            }
        } else {
            updateRetailerChanged("")
            if (dateChanged !== "") {
                resetDateChange()
            } else {
                updateFilteredList(receiptList)
            }
        }
    }

    return {
        receiptList,
        filteredList,
        loading,
        onToggle,
        isOpen,
        receiptId,
        handleRemove,
        handleReceiptClick,
        onSelectHome,
        selectReceiptsToAssign,
        handleAssignReceipts,
        selectedReceipt,
        launchPadFilters,
        openDeleteDialog,
        updateOpenDeleteDialog,
        deleteReceipt,
        retailerFilters,
        onRetailerChange,
        dateFilters,
        onDateChange,
        searchValue,
        handleOnSearchChange
    }
}