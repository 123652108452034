import DynamicProgressReport from "../../../forms/DynamicProgressReport";
import SharedClientsModule from "../../Modules/SharedClientsModule";
import { TabsHeader } from "@homefile/components";
import { t } from 'i18next'
import DocumentImageUpload from "../../../forms/DocumentImageUpload";
import { TabsInterface } from "../../../interfaces/CreateDocumentInterfaces";


const CreateDocumentTabs = (props: TabsInterface) => {
  const { homeId, report, reportTitle, reportId } = props;

  const tabList = [
    {
      label: t('createDocument.tabs.tab1'),
      component: (
        <>
          {homeId !== undefined &&
            report !== undefined && (
              <DynamicProgressReport
                reportTitle={reportTitle}
                formId={report._id}
                homeId={homeId}
                name={report.name}
                needsReview={report.needsReview}
                form={report.report === undefined ? report : report.report}
              />
            )}
        </>
      )
    },
    {
      label: t('createDocument.tabs.tab2'),
      component: (
      <>
        {
          (reportId !== "" && reportId !== undefined) &&
            <>
              <DocumentImageUpload documentId={reportId} />
            </>
        }
      </>
      ),
    },
    // {
    //   label: t('createDocument.tabs.tab3'),
    //   component: <SharedClientsModule documentId={reportId} />
    // }
  ];

  return <TabsHeader tabList={tabList} />;
};

export default CreateDocumentTabs;
